import React from 'react';
import {schemaFunction} from "../schemas/task.schema";

export const getOptions = (list, value, text) => {

    if (list.length === 1)
        return <option value={list[0][value]}>{list[0][text]}</option>

    return (
        <React.Fragment>
            <option value="">---</option>
            {list.map(item => <option value={item[value]}>{item[text]}</option>)}
        </React.Fragment>
    )
}


export const transformSchema = (schema = {}, type = 'G') => {
    return {
        ...schema,
        properties: {
            ...schema.properties,
            type: {
                ...schema.properties.type,
                anyOf: JSON.parse(JSON.stringify(schema.properties.type.anyOf[type]))
            }
        }
    }
}

export const getSchema = (schema = {}, meterPointsData = []) => {
    if (!meterPointsData || !Array.isArray(meterPointsData) || meterPointsData.length < 1 || !meterPointsData[0].purpose) {
        return {}
    }
    if (meterPointsData[0].purpose === 'Gas') {
        return transformSchema(schema, 'G') //transformSchema(schemaFunction("filter-hidden-first-one-element"), 'E')
    }
    if (meterPointsData[0].purpose === 'Electricity' || meterPointsData[0].purpose === 'Power') {
        return transformSchema(schema, 'E')
    }
    return {}
}

export const getSchemaCreteQuery = (schema = {}, meterPointsData = []) => {
    if (!meterPointsData || !Array.isArray(meterPointsData) || meterPointsData.length < 1 || !meterPointsData[0].purpose) {
        return {}
    }
    if (meterPointsData[0].purpose === 'Gas') {
        return transformSchema(schemaFunction("filter-hidden-first-one-element"), 'G')
    }
    if (meterPointsData[0].purpose === 'Electricity' || meterPointsData[0].purpose === 'Power') {
        return transformSchema(schemaFunction("filter-hidden-first-one-element"), 'E')
    }
    return {}
}

export const getType = (meterPointsData = []) => {
    if (!meterPointsData || !Array.isArray(meterPointsData) || meterPointsData.length < 1 || !meterPointsData[0].purpose) {
        return null;
    }
    if (meterPointsData[0].purpose === 'Gas') {
        return 'G';
    } else if (meterPointsData[0].purpose === 'Electricity' || meterPointsData[0].purpose === 'Power') {
        return 'E';
    }
    return null;
}

export const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export const validateMeterPoints = meterPoints => (
    meterPoints.filter(x => (
        x.status === 'Live' &&
        x.energisationStatus === 'E' &&
        (x.purpose === 'Power' || x.purpose === 'Electricity') &&
        x.supplyEndDate === null &&
        x.registers &&
        Array.isArray(x.registers) &&
        x.registers.length &&
        x.registers.filter(y => (
            y.settlementType === 'S' && y.effectiveTo === null && y.meter && y.meter.type === 'N'
        )).length
    )).length
);