import React, {Component} from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import PropTypes from "prop-types";
import {withApollo} from "react-apollo";
import {accountNumbers, customerNames, meterPointNames, mpanNames, siteNames} from "../../queries/Queries";

class QueryPickerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            useCache: true,
            allMatched: true
        }

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSearch(queryString) {
        let query;
        let objectName;
        let displayFiled;

        const enablePaste = !!(this.props.enablePaste && this.props.enablePaste === 'true');
        const multiple = queryString.includes(",");
        this.setState({loading: true, allMatched: true});

        switch (this.props.query) {
            case 'meterPointNames':
                query = meterPointNames;
                objectName = 'MeterPoint';
                break;
            case 'accountNumbers':
                query = accountNumbers;
                objectName = 'Account';
                break;
            case 'customerNames':
                query = customerNames;
                objectName = 'Customer';
                break;
            case 'siteNames':
                query = siteNames;
                objectName = 'Site';
                break;
            case 'mpanNames':
                query = mpanNames;
                objectName = 'Mpan';
                break;
        }
        this.props.client.query({
            query: query,
            variables: {
                pageNumber: 0,
                pageSize: 20,
                field: this.props.field,
                where: queryString && queryString.includes(",") && enablePaste ? queryString.split(",").map(arg => this.props.field + "==" + `'${arg.trim()}'`).join(' or ') : this.props.field + "=='*" + queryString + "*'"
            }
        }).then(({data}) => {
            //remove duplicates
            data[objectName].content = data[objectName].content.filter((object, index) => {
                const _object = JSON.stringify(object);
                return index === data[objectName].content.findIndex(obj => {
                    return JSON.stringify(obj) === _object;
                });
            });

            if (multiple && enablePaste) {
                const opt = [];
                const selected = [];
                const matches = queryString.split(",").length;
                data[objectName].content.forEach(i => {
                    let value = i[this.props.field];
                    selected.push(value);
                });
                //this.handleChange(selected);
                if (selected.length > 0) {
                    const instance = this._typeahead.getInstance()
                    instance.clear();
                    instance.focus();
                }
                this.setState({
                    options: opt,
                    sel: selected,
                    loading: false,
                    useCache: false,
                    allMatched: matches === selected.length
                });
            } else {
                let options = [];
                if (this.props.query === 'customerNames') {
                    data[objectName].content.map(option => {
                        option.computed = (option.id !== '0' && option.id !== '1' && option.id !== '1997' ? option.id.includes("power_") ? "(E) " : "(G) " : "") +  option.number + ', ' + option.name;
                    });
                }
                if (this.props.query === 'siteNames') {
                    data[objectName].content.map(option => {
                        option.computed = (option.id !== '0' && option.id !== '1' && option.id !== '1997' ? option.id.includes("power_") ? "(E) " : "(G) " : "") + ' ' + option.name;
                    });
                }
                if (this.props.query === 'accountNumbers') {
                    data[objectName].content.map(option => {
                        option.computed = (option.customer.id !== '0' && option.customer.id !== '1' && option.customer.id !== '1997' ? option.customer.id.includes("power_") ? "(E) " : "(G) " : "") + ' ' + option.number;
                    });
                }
                if (this.props.query === 'mpanNames') {
                    data[objectName].content.map(option => {
                        option.identifier = option.meterPoint.identifier
                    });
                }
                options = data[objectName].content;

                this.setState({
                    options: options,
                    loading: false,
                    useCache: true
                });
            }
        });
    }

    handleChange(selected) {
        const selectedValue = [];
        selected.forEach(value => {
            if (typeof value === "string") {
                selectedValue.push(value);
            } else {
                selectedValue.push(value[this.props.field]);
            }
        });
        this.props.onChange(selectedValue.join(","));
    }

    render() {
        return <div>
            <AsyncTypeahead labelKey={this.props.query === 'customerNames' || this.props.query === 'accountNumbers' || this.props.query === 'siteNames' ? 'computed' : this.props.field}
                            isLoading={this.state.loading}
                            onSearch={(query) => {
                                this.setState({queryString: query});
                                this.handleSearch(query);
                            }}
                            onChange={(selected) => {
                                this.handleChange(selected);
                            }}
                            useCache={this.state.useCache}
                            multiple={true}
                            ref={(ref) => this._typeahead = ref} //call getInput on the typeahead instance to get the input node
                            options={this.state.loading ? [] : this.state.options && this.state.options.length > 0 ? this.state.options : []}
                            selected={this.state.sel}
            />
            <div style={{
                display: this.state.allMatched ? 'none' : 'block',
                'padding-top': '10px'
            }}>{this.props.validationMessage}</div>
        </div>
    }
}

function QueryPicker(props) {

    const {
        id,
        options,
        placeholder,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        formContext,
        client,
        enablePaste,
        validationMessage
    } = props;


    const _onChange = (value) => {
        return onChange(value === "" ? options.emptyValue : value);
    };

    const query = options.query;

    return (
        <QueryPickerComponent field={options.field || 'identifier'} onChange={_onChange} value={value} query={query}
                              enablePaste={options.enablePaste || 'false'}
                              validationMessage={options.validationMessage || 'Not all data matched'}
                              client={client}/>)

}

QueryPicker.defaultProps = {
    autofocus: false,
    options: {},
};

if (process.env.NODE_ENV !== "production") {
    QueryPicker.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default withApollo(QueryPicker);
