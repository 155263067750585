import React, {Component} from "react";
import cronstrue from "cronstrue";

class CronFormatter extends Component {

    constructor(props) {
        super(props);
    }

    getVal() {
        let val = '';
        if (this.props.row.skipNextExecutionDate) {
            val = "Every two weeks ";
        }
        val += cronstrue.toString(this.props.cell.toString().replace(/,/g, ' ').replace(/!/g, ','))
        if(val === 'At 12:00 AM'){
            val = "Every day";
        }
        val = val.replace('At 12:00 AM, ', '');
        val = val.charAt(0).toUpperCase() + val.slice(1);
        if (val.search('undefined') === -1) {
            return val;
        }
        return '-';
    }

    render() {
        return (
            <div>
                {<div>{this.getVal()} </div>}
            </div>)
    }
}

export default CronFormatter;