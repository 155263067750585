import {Query} from 'react-apollo';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import Autosuggest from 'react-autosuggest';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import JsxParser from 'react-jsx-parser';
import _ from 'lodash';
import {withRouter} from 'react-router';
import i18n from '../../views/Pages/Login/i18n';
import React, {Component} from 'reactn';
import gql from "graphql-tag";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


class QuerySearch extends Component {

    constructor(props) {
        super(props);
        this.state = {totalSize: 0, sort:{orders:[{property:this.props.label, direction:'asc'}]}, filters:[],[this.props.dataPath]:this.props.value};

        this.getColumns = this.getColumns.bind(this);
        this.filter = this.filter.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.setParents = this.setParents.bind(this);
        this.getFilterValue = this.getFilterValue.bind(this);
        this.setFilterValue = this.setFilterValue.bind(this);


    }

    getQuery() {
        return this.props.query;
    }

    getColumns(refetch) {
        for (const col of this.props.columns) {
            if (col.template) {
                col.formatter = (cell, row, rowIndex, formatExtraData) =>
                    (<JsxParser
                        bindings={{ row }}
                        components={{ MoreInfoButton }}
                        jsx={_.template(this.props.columns[col].template)(row)}
                        showWarnings={true}
                        key={rowIndex + "_" + col}
                    />)
            }
        }
        if(this.props.menuItems){
            return [...this.props.columns, {
                dataField: 'options',
                text: ' ',
                formatter: (cell, row) => <MoreInfoButton
                    className="more-options-btn"
                    rowObject={row}
                    menuItems={this.props.menuItems}
                    refetch={refetch}
                    history={this.props.history}/>,
            }]
        } else {
            return this.props.columns;
        }
    }
    getUniqueValues(data, fieldPath) {
        return data.map(dataElem => this.getFieldValue(dataElem, fieldPath)).filter(function (value, index, self) {
            return self.indexOf(value) === index;
        });
    };

    setParents(obj, parents) {
        let newObj = JSON.parse(JSON.stringify(obj));
        const ownProps = Object.keys(newObj);
        for (let ownProp of ownProps) {
            if (newObj[ownProp] instanceof Object || newObj[ownProp] instanceof Array) {
                let newparents = parents.slice();
                newparents.unshift(obj);
                newObj[ownProp] = this.setParents(newObj[ownProp], newparents);
            }
        }
        newObj.__parents = parents;
        return newObj;
    }

    getFilters(data) {
        // if (this.props.filters) {
        //     let result = [];
        //     for (let i = 0; i < this.props.filters.length; i++) {
        //
        //         const filter1 = this.props.filters[i];
        //
        //     }
        //
        //     return {result}</Row></div>;
        // } else {
        //     return null;
        // }
    }
    getTitle() {
        if (this.props.title) {
            return (
                <div className="table-filers-title" >
                    <h3>{this.props.title}</h3>
                </div>
            );
        } else {
            return null;
        }
    }
    getButtons() {
        if (this.props.buttons) {
            return (
                <div className="table-filers-buttons" >
                    {this.props.buttons}
                </div>
            );
        } else {
            return null;
        }
    }

    getFieldValue(obj, path) {
        if(obj){
            var pathArray = path.split(".");
            if (pathArray.length > 1) {
                if (pathArray[0] === "#") {
                    let concatArr = [];
                    for (let j = 0; j < obj.length; j++) {
                        concatArr = concatArr.concat(this.getFieldValue(obj[j], path.substring(path.indexOf(".") + 1)));
                    }
                    return concatArr;
                } else {
                    return this.getFieldValue(obj[pathArray[0]], path.substring(path.indexOf(".") + 1));
                }
            } else {
                return obj[pathArray[0]];
            }
        } else {
            return [];
        }
    }

    getFilterValue(){
        let value = this.state[this.props.dataPath];
        return value ? value : null;
    }

    setFilterValue(value){
        this.setState({[this.props.dataPath]: value, changed: !this.state.changed});
    }

    getPaging(){
        let value = this.global["pagination."+this.props.dataPath];
        return !value ? {page: 1, sizePerPage: 20} : JSON.parse(value);
    }

    setPaging(value){
        this.setGlobal({["pagination."+this.props.dataPath] : JSON.stringify(value)});
    }

    filter(element) {

        let result = true;
        if (this.props.filters) {
            for (let i = 0; i < this.props.filters.length; i++) {
                let result1 = false;
                for (let j = 0; j < this.props.filters[i].fields.length; j++) {
                    const fieldName = this.props.filters[i].fields[j];
                    const fieldValue = this.getFieldValue(element, fieldName);
                    const searchTerm = this.getFilterValue(this.props.filters[i].fields[0]); //this.state['search' + i];
                            result1 = result1 || !searchTerm || (fieldValue && fieldValue.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
                }
                result = result && result1;
            }
        }
        // this.state.page = 1;
        return result;
    }

    getRemoveDuplicatesFunction(key, removeDuplicates){
        return removeDuplicates ? (value, index, self) => self.findIndex(v => v[key] === value[key]) === index : () => true;
    }

    render() {
        const moment = require('moment');
        return <Query query={gql`${this.props.query}`} variables={{
            ...this.props.variables,
            pageNumber: 0,
            pageSize: 20,
            where: this.props.filters ? this.props.filters.map( filter => {
                        if(filter.fields.filter(field => field.includes('==')).length > 0){
                            return filter.fields.join(',');
                        } else {
                            const val = this.getFilterValue();
                            return val ? filter.fields.map((field) => field.replace('.#', '') + "=='*" + val.replace(/_/g, '\\_') + "*'").join(",") : null;
                        }
                    }
            ).filter(operand => operand)
                .map(operand => "("+operand+")")
                .join(";") : null,
            sort: this.state.sort}}>
            {(result) => {
                if (result.error) return <ErrorPage />;
                const { data } = result;
                // let page =  !result.loading ? this.getFieldValue(data, this.props.paginationPath).pageable.pageNumber+1 : this.getPaging().page;
                // const pageSize = !result.loading ?  this.getFieldValue(data, this.props.paginationPath).pageable.pageSize  : this.getPaging().sizePerPage;
                // const totalElements = !result.loading ?  this.getFieldValue(data, this.props.paginationPath).totalElements  : this.state.totalSize;
                // if(!result.loading && (page - 1) * pageSize > totalElements){ // we are requesting a page out of bounds
                //     this.setPaging({page: Math.max(Math.ceil(totalElements/pageSize), 1), sizePerPage: this.getPaging().sizePerPage});
                //     this.setState({reload: !this.state.reload});
                // }
                const dataWithParents = this.setParents(data, []);
                let tableData = this.getFieldValue(dataWithParents, this.props.dataPath).map(row => ({...row, label: this.props.filters[0].fields.map(f => this.getFieldValue(row, f)).join(',')}));

                // if(tableData.length > 0 && !tableData.reduce((ac, cu) => ac || Object.values(cu).reduce((acc, curr) => acc || Boolean(curr), false), false)){
                //     this.setState({reload: !this.state.reload});
                //     // client.resetStore();
                // }
                return <div className={(this.props.className || "") + (this.props.onSelectOpenPage || this.props.onSelect ? " table-with-links" : "")}>
                    {this.props.reload || !this.props.reload || this.state.reload || !this.state.reload}
                    <AsyncTypeahead id={"search"} ref="typeahead" placeholder={i18n.t('search.searchInput')} isLoading={result.loading}
                                    onSearch={(e) => {
                                        this.setFilterValue(e);
                                        this.props.onChange({});
                                    }}
                                    cssclassName="md-12"
                                    options={tableData}
                                    filterBy={() => true}
                                    labelKey={this.props.filters[0].fields[0]}
                                    selectHintOnEnter={true}
                                    onChange={selected=>{
                                        if(selected.length > 0) {
                                            this.props.onChange(selected[0]);
                                        }
                                    }}
                                    disabled={this.props.disabled}
                                    // defaultSelected={[{[this.props.ky]:this.props.value, [this.props.label]:(tableData.filter(option => option[this.props.ky] === this.props.value).length > 0 ? tableData.filter(option => option[this.props.ky] === this.props.value)[0][this.props.label] : "z")}]}
                                    selected={this.props.value ? [{
                                        [this.props.ky]: this.props.value,
                                        [this.props.label]: tableData.length > 0 ? (tableData.reduce( (acc, curr) => curr[this.props.ky] === this.props.value ? curr : acc, {[this.props.label] : '...'})[this.props.label]) : '...'
                                    }] : []}
                                    renderMenuItemChildren={(option, props, index) => {
                                        return (<div style={{display:'table-row'}} onClick={(e)=>{
                                            // this.props.onChange(option);
                                        } } >

                                            {/*<span className="user-col" style={{width:this.state.longestNameLength*8+"px", overflow:"hidden", paddingTop: "4px", textOverflow: "ellipsis", display: "inline-block", height:"38px", verticalAlign:"top"}}><Highlighter search={props.text}>{option.name}</Highlighter></span>*/}
                                            {/*<div style={{display: "inline-block", verticalAlign: "top"}}>{option.matches.filter( ({name}) => name !== 'id' && name !== 'name' ).map( match => {*/}
                                            {/*    return <span><span className="user-col" style={{ overflow:"hidden", paddingTop: "4px", textOverflow: "ellipsis", display: "inline-block", height:"38px", verticalAlign:"top"}}>{i18n.t('search.'+match.name)}: <Highlighter search={props.text}>{match.value}</Highlighter></span><br/></span>*/}
                                            {/*})}</div>*/}

                                            {this.props.columns.filter(({dataField}) => dataField).map(column => <span className="user-col" style={{ overflow:"hidden", paddingTop: "4px", paddingRight: "4px",textOverflow: "ellipsis", display: "table-cell", height:"38px", verticalAlign:"top"}}>{column.text}: {this.getFieldValue(option, column.dataField)}</span>)}
                                        </div>)
                                    }}
                    />
                    {/*<Autosuggest*/}
                    {/*    suggestions={tableData}*/}
                    {/*    onSuggestionsFetchRequested={(e) => {*/}
                    {/*        this.setFilterValue(e.value);*/}
                    {/*    }}*/}
                    {/*    onSuggestionsClearRequested={ (e) => this.setFilterValue('')}*/}
                    {/*    getSuggestionValue={suggestion => suggestion[this.props.label]}*/}
                    {/*    renderSuggestion={option => <div style={{display:'table-row'}} onClick={(e)=>{*/}
                    {/*        // this.props.onChange(option);*/}
                    {/*    } } >*/}

                    {/*        /!*<span className="user-col" style={{width:this.state.longestNameLength*8+"px", overflow:"hidden", paddingTop: "4px", textOverflow: "ellipsis", display: "inline-block", height:"38px", verticalAlign:"top"}}><Highlighter search={props.text}>{option.name}</Highlighter></span>*!/*/}
                    {/*        /!*<div style={{display: "inline-block", verticalAlign: "top"}}>{option.matches.filter( ({name}) => name !== 'id' && name !== 'name' ).map( match => {*!/*/}
                    {/*        /!*    return <span><span className="user-col" style={{ overflow:"hidden", paddingTop: "4px", textOverflow: "ellipsis", display: "inline-block", height:"38px", verticalAlign:"top"}}>{i18n.t('search.'+match.name)}: <Highlighter search={props.text}>{match.value}</Highlighter></span><br/></span>*!/*/}
                    {/*        /!*})}</div>*!/*/}

                    {/*        {this.props.columns.filter(({dataField}) => dataField).map(column => <span className="user-col" style={{ overflow:"hidden", paddingTop: "4px", paddingRight: "4px",textOverflow: "ellipsis", display: "table-cell", height:"38px", verticalAlign:"top"}}>{column.text}: {this.getFieldValue(option, column.dataField)}</span>)}*/}
                    {/*    </div>}*/}
                    {/*    inputProps={this.props} />*/}
                    </div>;
            }
            }
        </Query>
    }
}

export default withRouter(QuerySearch)
