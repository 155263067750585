import React, {Component} from 'react';
import fileDownload from "js-file-download";
import axios from "axios";

class ExportChartButton extends Component {


    render() {
        const {elementId, filename, title, orientation, ...props} = {...this.props};
        return (
                <div><h4 className='card-title'> {title} <small className="float-sm-right"><i style={{cursor: 'pointer'}}
                        className="icon icon-download" {...props} onClick={() => {
                    function filter(node) {
                        return (node.tagName !== 'i');
                    }

                    const svg = document.querySelector(`#${elementId} svg`).outerHTML;

                    const legendSVGs = Array.from(document.querySelectorAll(`#${elementId} .recharts-legend-item svg`));
                    const legendText = Array.from(document.querySelectorAll(
                            `#${elementId} .recharts-legend-item .recharts-legend-item-text`));

                    const legendSVG = legendSVGs.map(svg => 'legendSvg[]=' + encodeURIComponent(svg.outerHTML)).join(
                            '&');
                    const legendTxt = legendText.map(text => 'legendText[]=' + encodeURIComponent(text.innerHTML)).join(
                            '&');

                    axios.post(
                            `/api/svg_to_pdf?title=${title}&orientation=${orientation || ""}&${legendSVG}&${legendTxt}`,
                            svg, {
                                headers: {'Authorization': "Bearer " + localStorage.token, 'Content-Type': 'text/xml'},
                                responseType: 'blob'
                            }).then((response) => {
                        fileDownload(response.data, filename);
                    });
                }}> </i></small></h4></div>
        )

    }
}

export default ExportChartButton;
