import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {withApollo} from 'react-apollo';
import {AsyncTypeahead, Highlighter, Menu, MenuItem} from 'react-bootstrap-typeahead';
import {Link} from 'react-router-dom'
import {
    Button,
    Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Form,
    FormGroup,
    InputGroup,
    InputGroupButton,
} from 'reactstrap';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import JsxParser from 'react-jsx-parser';
import Widget01 from '../../views/Widgets/Widget01';
import _ from 'lodash';
import {withRouter} from 'react-router';
import i18n from '../../views/Pages/Login/i18n';
import ExpiringAlert from "../ExpiringAlert";
import jsonata from "./../../../node_modules/jsonata/jsonata-es5"
import TreeMenu from 'react-simple-tree-menu';
import 'react-simple-tree-menu/dist/main.css';

const treeData = [
    {
        key: 'all',
        label: 'All Organizations'},
];

if (localStorage.org == 'Total Gas & Power') {
    treeData.push({
        key: '0',
        label: 'Total Gas & Power',
        nodes: [
            {
                key: '06764C52E6494CE3B9C6BDCE742536E8',
                label: 'CCS Energy',
                nodes: [
                    {
                        key: '07C034249D7543308B29C26B7C1C4B64',
                        label: 'Manchester City Council',
                        nodes: [] // you can remove the nodes property or leave it as an empty array
                    },

                    {
                        key: 'D491E5F963F54E46A4A8C7DE2BE49684',
                        label: 'Liverpool City Council',
                        nodes: [] // you can remove the nodes property or leave it as an empty array
                    },
                ],
            },
        ],
    },);

} else if (localStorage.org == 'CCS Energy') {
    treeData.push({
        key: '06764C52E6494CE3B9C6BDCE742536E8',
        label: 'CCS Energy',
        nodes: [
            {
                key: '07C034249D7543308B29C26B7C1C4B64',
                label: 'Manchester City Council',
                nodes: [] // you can remove the nodes property or leave it as an empty array
            },

            {
                key: 'D491E5F963F54E46A4A8C7DE2BE49684',
                label: 'Liverpool City Council',
                nodes: [] // you can remove the nodes property or leave it as an empty array
            },
        ],
    },);
}

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {isLoading: false, isOpen: false, selectedOrganization: 'all', selectedOrganizationName: 'All Organizations'};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({isOpen: !this.state.isOpen});
    }

    searchHandler(query) {
        query = query.toLowerCase();
        let orgFilter;
        if (this.state.selectedOrganization && this.state.selectedOrganization != 'all') {
            const orgs = this.state.selectedOrganization.split("/");
            orgFilter = orgs[orgs.length-1];
        } else {
            orgFilter = '';
        }
        this.setState({isLoading: true});
        this.setState({query: query});
        this.props.client.query({
            query: this.props.query,
            fetchPolicy: 'no-cache', // skip the cache
            variables: {
                orderBy: this.props.orderBy,
                where: this.props.where ? _.template(this.props.where)({query, org: orgFilter}) : null
            }
        }).then((data) => {
            const options = data.data[Object.keys(data.data)[0]];
            let transformedOptions = options;
            if (this.props.jsonata) {
                //const jsonata = require("jsonata");
                const expression = jsonata(this.props.jsonata);
                transformedOptions = expression.evaluate(options);
            }
            const finalOptions = transformedOptions.map(option => ({
                name: option.name,
                matches: Object.keys(option).map(key => (Array.isArray(option[key])
                    ? option[key].map(value => ({name: key, value: value}))
                    : [{
                        name: key,
                        value: option[key]
                    }])).reduce((a, b) => a.concat(b), []).filter(match => match.name === 'id' || match.value.toLowerCase().indexOf(query.toLowerCase()) > -1)
            }));

            this.setState({
                isLoading: false,
                options: finalOptions,
                longestNameLength: Math.max(...transformedOptions.map(({name}) => name.length))
            })

        }).catch((error) => {
            ReactDOM.render(<ExpiringAlert color="danger"
                                           message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
        });
    }

    render() {
        const {data} = this.state;
        return (
            <div className="animated fadeIn margin-t-30 margin-l-30 search-users-header">
                <Form action="" method="post" className="form-horizontal">
                    <FormGroup row>
                        <Col md="12">
                            <InputGroup>
                                <AsyncTypeahead id={"search"} ref="typeahead" placeholder={i18n.t('search.searchInput')}
                                                isLoading={this.state.isLoading}
                                                className="search-autocomplete"
                                                onSearch={this.searchHandler.bind(this)}
                                                options={this.state.options}
                                                filterBy={() => true}
                                                labelKey="name"
                                                selectHintOnEnter={true}
                                                onChange={selected => {
                                                    if (selected.length > 0) {
                                                        this.props.history.push("/view/customer/" + selected[0].matches.filter(({name}) => name === 'id')[0].value);
                                                        setTimeout(() => this.refs.typeahead.getInstance().clear(), 1);
                                                    }
                                                }}
                                    /*onKeyDown={(event) => { if(event.keyCode == 13){
                                        this.props.history.push("/bpl/" + this.refs.typeahead.getInstance().state.text)
                                    }}}*/
                                                renderMenuItemChildren={(option, props, index) => {
                                                    return (<div onClick={(e) => {
                                                        this.props.history.push("/view/customer/" + option.matches.filter(({name}) => name === 'id')[0].value)
                                                        setTimeout(() => this.refs.typeahead.getInstance().clear(), 1);
                                                    }}><span className="user-col" style={{
                                                        display: "inline-block",
                                                        height: "42px",
                                                        verticalAlign: "top"
                                                    }}><i className="icon ion-person"></i></span>
                                                        <span className="user-col" style={{
                                                            width: this.state.longestNameLength * 8 + "px",
                                                            overflow: "hidden",
                                                            paddingTop: "4px",
                                                            textOverflow: "ellipsis",
                                                            display: "inline-block",
                                                            height: "38px",
                                                            verticalAlign: "top"
                                                        }}><Highlighter search={props.text}>{option.name}</Highlighter></span>
                                                        <div style={{
                                                            display: "inline-block",
                                                            verticalAlign: "top"
                                                        }}>{option.matches.filter(({name}) => name !== 'id' && name !== 'name').map(match => {
                                                            return <span><span className="user-col" style={{
                                                                overflow: "hidden",
                                                                paddingTop: "4px",
                                                                textOverflow: "ellipsis",
                                                                display: "inline-block",
                                                                height: "38px",
                                                                verticalAlign: "top"
                                                            }}>{i18n.t('search.' + match.name)}: <Highlighter
                                                                search={props.text}>{match.value}</Highlighter></span><br/></span>
                                                        })}</div>
                                                    </div>)
                                                }}
                                />

                                <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}
                                          className={'ticket-module-selector'}>
                                    <DropdownToggle caret>
                                        <i className={this.state.dropDownIcon}></i>{this.state.selectedOrganizationName}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <TreeMenu
                                            data={treeData}
                                            onClickItem={({ key, label, ...props }) => {
                                                this.setState({selectedOrganization: key, selectedOrganizationName: label,  isOpen: false});
                                            }}
                                            hasSearch={false}
                                            initialActiveKey='all' // the path to the active node
                                            debounceTime={125}>
                                        </TreeMenu>
                                    </DropdownMenu>
                                </Dropdown>
                                <i style={{marginLeft: "10px", marginRight: "50px"}} className='icon ion-ios-search-strong'/>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

export default withRouter(withApollo(Search));
