import React, {Component} from 'react';
import StepZilla from 'react-stepzilla';


class FormWizzard extends Component {
  constructor(props) {
      super(props);

      this.state = {
        step:0
      }
        
      this.handleStepChange = this.handleStepChange.bind(this);

  }
  handleStepChange(step) {
      this.setState({
          step: step
      });
    if(step === this.props.steps.length -1){
        this.props.onLastStep();
    }
  }
  render() {
      return (<StepZilla
          steps={this.props.steps}
          startAtStep={this.props.startAtStep}
          showSteps={true}
          backButtonText={"Go Back"}
          nextButtonText={"Continue"}
          nextButtonCls="btn btn-prev btn-primary btn-lg pull-left"
          backButtonCls="btn btn-prev btn-secondary btn-lg pull-left"
          nextTextOnFinalActionStep="Save"
          prevBtnOnLastStep={false}
          onStepChange={this.handleStepChange}
          preventEnterSubmission={true}
          stepsNavigation={false} />)
  }
}

export default FormWizzard;