import React, {Component} from 'react';
import JsxParser from 'react-jsx-parser';
import InfoCard from '../../components/InfoCard';
import QueryDataGrid1 from "../../components/QueryDataGrid1";
import {withRouter} from 'react-router';
import {
    TabContent,
    TabPane,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormFeedback,
    FormGroup,
    Label,
    Input,
    FormText,
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardText,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    CardHeader, CardTitle, CardBody
} from 'reactstrap';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import NavTabs from "../../components/NavTabs";
import * as formatters from "../../utils/Formatters";
import i18n from '../../views/Pages/Login/i18n';
import ProcessesTable from "../ProcessesTable";
import QueryInfoCard from "../QueryInfoCard";
import QueryForm from "../QueryForm";
import GenericView from "../GenericView";
import TableHeader from "../../components/TableHeader"
import QueryLink from "../QueryLink";
import BillsTab from "../BillsTab";
import ReadingsTab from "../../components/ReadingsTab";
import QueryNotesAndAttachments from "../QueryNotesAndAttachments";
import QueryBillsTabWrapper from "../QueryBillsTabWrapper";
import BusinessPartnerHeader from "../BusinessPartnerHeader";
import SiteHeader from "../SiteHeader";
import StartProcess from "../StartProcess";
import PaginatedQueryDataGrid from "../PaginatedQueryDataGrid";
import QueryWrapper from "../QueryWrapper";
import DisplayValue from "../DisplayValue";
import * as reCharts from "recharts";
import DataRepeat from "../DataRepeat";
import DynamicLineChart from "../DynamicLineChart";
import SimpleDataGrid from "../SimpleDataGrid/SimpleDataGrid";
import ExportChartButton from "../ExportChartButton";
import CustomAreaChart from '../CustomAreaChart';
import ServiceHeader from "../ServiceHeader";
class GenericViewPreview extends Component {

    constructor(props) {
        super(props);
    }

    getQueries() {

        const result = {};
        const uiQueries = this.props.configuration.uiQueries;
        if (uiQueries && uiQueries.length) for (let i = 0; i < uiQueries.length; i++) {
            result[uiQueries[i].name] = uiQueries[i].query;
        }
        return result;
    }

    getVariables() {

        const result = {};
        const uiQueries = this.props.configuration.uiQueries;
        if (uiQueries && uiQueries.length) for (let i = 0; i < uiQueries.length; i++) {
            result[uiQueries[i].name] = uiQueries[i].variables;
        }
        return result;
    }

    render() {

        const queries = this.getQueries();
        this.props.match.params["variables"] = this.getVariables();
        const configuration = this.props.configuration;

        this.props.match.params = {...this.props.match.params, ...this.props.context};

        const {ResponsiveContainer, ...re}  = {...reCharts};

        return (<JsxParser
            bindings={{
                ...this.props, queries: queries,
                configuration: configuration,
                i18n: i18n,
                ...formatters
            }}
            components={{
                Dropdown,
                DropdownToggle,
                DropdownItem,
                DropdownMenu,
                Row,
                Col,
                Nav,
                NavItem,
                TabContent,
                TabPane,
                InfoCard,
                Card,
                CardText,
                Link,
                Switch,
                Route,
                Redirect,
                QueryDataGrid1,
                Form,
                FormGroup,
                NavTabs,
                ProcessesTable,
                QueryForm,
                QueryInfoCard,
                GenericView,
                TableHeader,
                BillsTab,
                ReadingsTab,
                QueryNotesAndAttachments,
                QueryBillsTabWrapper,
                QueryLink,
                BusinessPartnerHeader,
                ServiceHeader,
                SiteHeader,
                CardHeader,
                CardTitle,
                CardBody,
                StartProcess,
                PaginatedQueryDataGrid,
                QueryWrapper,
                DisplayValue,
                DataRepeat,
                DynamicLineChart,
                SimpleDataGrid,
                ...re,
                ExportChartButton,
                CustomAreaChart
            }}
            jsx={this.props.jsx}
            showWarnings={false}
        />)
    }
}


export default withRouter(GenericViewPreview)
