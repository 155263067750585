import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withApollo } from "react-apollo";
import i18n from "../../../views/Pages/Login/i18n";
import CreateReading from "./CreateReading";
import CreateReadingPower from "./CreateReadingPower";
import CustomerSearch from "../../components/CustomerSearch";
import { Button, Col, Row } from "reactstrap";
import jsonata from "jsonata/jsonata-es5";
import { validateMeterPoints } from '../../../utils/Helpers';

class CreateReadingView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            siteId: null,
            timestamp: Date.now()
        }

        this.onChange = this.onChange.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    cancel() {
        this.setState({ timestamp: Date.now() })
    }

    onChange(selected) {
        if (selected) {
            const siteId = selected.site.id;
            const meterPoints = selected.meterPoints;
            meterPoints.forEach(mp => {mp.startDate = selected.startDate; mp.endDate = selected.endDate});
            selected.site.meterPoints.forEach(mp => {mp.startDate = selected.startDate; mp.endDate = selected.endDate});
            this.setState({
                siteId: siteId,
                estateList: selected.site,
                account: selected.account.number,
                accountOrganization: selected.account.organization,
                meterPoints: meterPoints,
                timestamp: Date.now()
            });
        }
    }

    render() {

        let type = null;
        const { estateList } = this.state;

        if (estateList && estateList && estateList.meterPoints && estateList.meterPoints.length) {
            type = estateList.meterPoints[0].purpose;
        }

        return (
            <div className="service-panel page-cnt">
                <Row className='subheader service-header'>
                    <Col className='col-8'>
                        <h1>{i18n.t('readings.singeRead')}</h1>
                    </Col>
                    <Col className="col-4">
                        <div className="dropdown show">
                            <Button className="pull-right" color="primary"
                                onClick={this.props.history.goBack}><i
                                    className="ion ion-ios-arrow-back" /> {i18n.t('search.go-back')}</Button>
                        </div>
                    </Col>
                </Row>

                <div className="service-panel-search">
                    <CustomerSearch onChange={this.onChange} activeAccounts={true}/>
                </div>
                <div style={{ display: this.state.siteId ? 'block' : 'none' }} key={this.state.timestamp}>

                    {type === "Gas"
                        ? (
                            <CreateReading
                                estateList={[this.state.estateList]}
                                account={this.state.account}
                                meterPoints={this.state.meterPoints}
                                toggle={this.cancel}
                                cancelButton={"Clear form"}
                            />
                        )
                        : estateList && estateList.meterPoints && validateMeterPoints(estateList.meterPoints) > 0
                            ? (
                                <CreateReadingPower
                                    estateList={[this.state.estateList]}
                                    account={this.state.account}
                                    accountOrganization={this.state.accountOrganization}
                                    meterPoints={this.state.meterPoints}
                                    toggle={this.cancel}
                                    cancelButton={"Clear form"}
                                />
                            )
                            : type === 'Power' ? (
                                <ul>
                                    {jsonata("$boolean(registers.meter[type='H'])").evaluate(estateList.meterPoints) &&
                                        <li>You are unable to enter a read for this MPAN as the MPAN is Half Hourly</li>
                                    }
                                    {jsonata("$boolean(energisationStatus='D')").evaluate(estateList.meterPoints) &&
                                        <li>You are unable to enter a read for this MPAN as the MPAN is de-energised</li>
                                    }
                                    {jsonata("$boolean(registers[settlementType='N'])").evaluate(estateList.meterPoints) &&
                                        <li>You are unable to enter a read for this MPAN as the registers are non-settlement</li>
                                    }
                                    {!jsonata("$boolean(status = 'Live' and purpose='Power' and $not(supplyEndDate) and $exists(registers[effectiveTo=null]))[$ = true]").evaluate(estateList.meterPoints) &&
                                        <li>You are unable to enter a read for this MPAN as there is no Meter installed against the MPAN</li>
                                    }
                                </ul>
                            ) : null
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(withApollo(CreateReadingView));