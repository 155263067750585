import React, {Component} from 'react';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {showPaymentInfo: false};
    }

  render() {
      const d = new Date();
    return (
      <footer role="contentinfo">
          <div className="footer-top">
              <div className="inner clearfix">
                  <div className="col" style={{display: 'none'}}><p><a href="/#/" title="Home">Home</a></p></div>
                  <div className="col"><p className="footer-header">Important Info</p>
                      <ul className="submenu">
                          <li><a target="_blank" href="https://business.totalenergies.uk/information-centre/emergency-essentials" title="Emergency Essentials">Emergency Essentials</a></li>
                          <li><a target="_blank" href="https://connect.totalgp.com/mygatewaysupport" title="MyGateway Support">MyGateway Support</a></li>
                          <li style={{display: 'none'}}><a target="_blank" href="#" title="Contact Us">Contact Us</a>
                          </li>
                          <li><a title="Payment information" onClick={() => this.setState({ showPaymentInfo: !this.state.showPaymentInfo})}>Payment information</a></li>
                          <li><a target="_blank" href="https://business.totalenergies.uk/information-centre/energy-essentials/smart-and-amr-meters-business/half-hourly-energy-consumption" title="Consumer Data Provision">Consumer Data Provision</a></li>
                      </ul>
                      <p style={{fontWeight: 'normal', textTransform: 'none', fontSize: '13px', display: (this.state.showPaymentInfo ? 'block' : 'none')}}>
                        
                          <br/>
                          Bank Name: HSBC Bank Plc<br/>
                          Account Name: TGP EBE Collections<br/>
                          Account No: 81403346<br/>
                          Sort Code: 40 - 02 - 50<br/>
                          Branch: London Park Street SE1<br/>
                      </p>
                  </div>
                  <div className="col"><p className="footer-header">Forms</p>
                      <ul className="submenu">
                          <li><a target="_blank" href="https://www.gas-power.total.co.uk/sites/g/files/wompnd341/f/atoms/files/vat_dec_of_use_certificate.pdf" title="VAT Form">VAT Form</a></li>
                          <li><a target="_blank" href="https://business.totalenergies.uk/sites/g/files/wompnd2326/f/atoms/files/totalenergies-direct_debit-mandate-large_business.pdf" title="DD Form">DD Form</a></li>
                          <li><a target="_blank" href="https://www.gov.uk/government/publications/climate-change-levy-supplier-certificate-pp11" title="CCL Supplier Certificate (PP11)">CCL Supplier Certificate (PP11)</a></li>
                      </ul>
                      <br />
                  </div>
                      <div className="col"><p className="footer-header">Siteworks (Gas)</p>
                      <ul className="submenu">
                          <li><a target="_blank" href="https://gas-power.total.co.uk/sites/g/files/wompnd341/f/atoms/files/site_works_request_form_tgp_removals_disconnections_0.pdf" title="Removals + Disconnections">Removals + Disconnections</a></li>
                          <li><a target="_blank" href="https://gas-power.total.co.uk/sites/g/files/wompnd341/f/atoms/files/site_works_request_form_tgp_other_requests_checks_0.pdf" title="Other Requests Checks">Other Requests Checks</a></li>
                          <li><a target="_blank" href="https://gas-power.total.co.uk/sites/g/files/wompnd341/f/atoms/files/site_works_request_form_tgp_installs_upgrades_relocations_0.pdf" title="Installs Upgrades Relocations">Installs Upgrades Relocations</a></li>
                      </ul>
                  </div>
                  <div className="col"><p className="footer-header">Siteworks (Electricity)</p>
                      <ul className="submenu">
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.gas-power.total.co.uk/sites/g/files/wompnd341/f/atoms/files/tgp_nhh_electricity_siteworks_request_form.pdf">NHH Siteworks</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.gas-power.total.co.uk/sites/g/files/wompnd341/f/atoms/files/tgp_hh_electricity_siteworks_request_form.pdf">HH Siteworks</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.gas-power.total.co.uk/sites/g/files/wompnd341/f/atoms/files/tgp_electricity_siteworks_faq.pdf">Electricity Siteworks FAQs</a></li>
                      </ul>
                  </div>
                  {/*<div className="col"><p><a href="#"*/}
                  {/*                           title="Total Web Factory for Business">Payment Information</a>*/}
                  {/*    </p>*/}
                  {/*    <p style={{fontWeight:'normal', textTransform:'none', fontSize: '13px'}}>*/}
                  {/*                Bank Name: HSBC Bank plc<br />*/}
                  {/*                Account Name: Total Gas & Power Limited<br />*/}
                  {/*                Account No: 81403346<br />*/}
                  {/*                Sort Code: 40 - 02 - 50<br />*/}
                  {/*                Branch: London Park Street SE1<br />*/}
                  {/*    </p>*/}
                  {/*</div>*/}
                  {/*<div className="col"><p><a href="/en/total-web-factory-business"
                                             title="Total Web Factory for Business">Total Web Factory for Business</a>
                  </p>
                      <ul className="submenu">
                          <li><a href="/en/total-web-factory-business/twf-presentation"
                                 title="What is the TWF 4Business ? ">What is the TWF 4Business ? </a></li>
                          <li>
                              <nolink title="Guidelines " className="nolink" tabindex="0">Guidelines</nolink>
                          </li>
                          <li><a href="/en/total-web-factory-business/contribution-training"
                                 title="Contribution training">Contribution training</a></li>
                          <li><a href="/en/total-web-factory-business/evolutions-and-functionalities"
                                 title="Evolutions and functionalities ">Evolutions and functionalities </a></li>
                      </ul>
                  </div>
                  <div className="col"><p><a href="/en/who-are-we" title="Who are we? ">Who are we? </a></p>
                      <ul className="submenu">
                          <li><a href="/en/who-are-we/digital-experience-presentation"
                                 title="Gouvernance presentation ">Gouvernance presentation </a></li>
                          <li><a href="/en/who-are-we/web-room-presentation" title="Web Room presentation ">Web Room
                              presentation </a></li>
                      </ul>
                  </div>*/}
              </div>
          </div>
          <div className="footer-bottom">
              <div className="inner">
                  <ul className="links">

                      <li><a href="https://www.gas-power.total.co.uk/sitemap.xml" title="Sitemap" target="_blank">Sitemap</a></li>
                      <li><a href="https://www.gas-power.total.co.uk/cookie-policy" title="Cookie Policy" target="_blank">Cookie Policy</a></li>
                      <li><a href="https://www.gas-power.total.co.uk/legal" title="Legal" target="_blank">Legal</a></li>
                      <li><a href="https://www.gas-power.total.co.uk/privacy-policy" title="Privacy Policy" target="_blank">Privacy Policy</a></li>
                      <li><a href="https://www.gas-power.total.co.uk/customer-data" title="Customer Data" target="_blank">Customer Data</a></li>
                      <li>© TotalEnergies Gas & Power Limited. All rights reserved</li>
                  </ul>
              </div>
          </div>
          <div id="block-block-10" className="block block-block">
              <div>
                  <script type="text/javascript"
                          src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c62d6f9bb887c42"></script>
              </div>
          </div>
          <div aria-label="Scroll to top" id="nav_up" className="nav_up" style={{display: "block"}}></div>
      </footer>
    )
      /*<footer className="app-footer">
          <span>Cockpit {VERSION} ✈ &copy; {d.getFullYear()} Methodia LTD.</span>
          <span className="ml-auto"></span>
      </footer>*/
  }
}

export default Footer;
