import React, {Component} from 'react';
import { withRouter } from 'react-router';
import {compose, graphql, withApollo} from "react-apollo";
import {branch, renderComponent, lifecycle} from "recompose";
import {TabContent, TabPane, Button, Breadcrumb, BreadcrumbItem, Form, FormFeedback, FormGroup, Label, Input, FormText, Container, Row, Col, Nav, NavItem, NavLink} from 'reactstrap';
import { ValidatingFormGroup } from 'reactstrap-validation';
import i18n from '../Pages/Login/i18n';
import classnames from "classnames";
import Loading from '../../components/Loading';
import {getUserQuery, getRolesForUserQuery} from "../../queries/Queries"


class ViewUser extends Component {

    render() {
        var phoneRepeater = function(){
            let result = [];
            for(let i=0;i<this.props.getUser.getUser.phoneNumbers.length; i++){
                result.push(
                    <Row>
                        <Col sm={"5"}>
                            {this.props.getUser.getUser.phoneNumbers[i].phoneNumber}
                        </Col>
                        <Col sm={"5"}>
                            {this.props.getUser.getUser.phoneNumbers[i].type}
                        </Col>
                        <Col sm={"1"}>
                            <Input type="radio" name="primaryNumber" checked={this.props.getUser.getUser.phoneNumbers[i].primaryNumber} />
                        </Col>
                    </Row>);
            }
            return result;
        }
        phoneRepeater = phoneRepeater.bind(this);
                return (<Container className='create-container'>

                <div>
                    <div className='manage-users'>
                        <Row className='manage-heading'>
                            <Col className='col-10'>
                                <h1><img style={{borderRadius: "50%"}} width={"32"} height={"32"} src={this.props.getUser.getUser.avatarUrl} />{this.props.getUser.getUser.firstName} {this.props.getUser.getUser.lastName}</h1>
                            </Col>
                            <Col className='col-2'>
                                <Breadcrumb>
                                    <BreadcrumbItem><a href="#">Home</a></BreadcrumbItem>
                                    <BreadcrumbItem active>Users</BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <div className='breadcrumbs'>

                        </div>
                    </div>
                    <div className='bp-nav-items'>
                                <Row>
                                    <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                        <Form>
                                            <FormGroup tag="fieldset">
                                                <FormGroup>
                                                    <Row>
                                                        <Col className={"col-5"}>
                                                            <Label className='user-label'>
                                                                { i18n.t('header.username') }
                                                            </Label>
                                                        </Col>
                                                        <Col>{this.props.getUser.getUser.username}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={"col-5"}>
                                                            <Label  className='user-label'>
                                                                Enabled
                                                            </Label>
                                                        </Col>
                                                        <Col className='role-field'>
                                                            <Input className='role-field' type="checkbox" value={this.props.getUser.getUser.enabled ? 'on' : 'off'} checked={this.props.getUser.getUser.enabled} disabled />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={"col-5"}>
                                                            <Label className='user-label'>
                                                                { i18n.t('header.title') }
                                                            </Label>
                                                        </Col>
                                                        <Col>{this.props.getUser.getUser.title}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={"col-5"}>
                                                            <Label className='user-label'>
                                                                { i18n.t('header.firstName') }
                                                            </Label>
                                                        </Col>
                                                        <Col>{this.props.getUser.getUser.firstName}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={"col-5"}>
                                                            <Label className='user-label'>
                                                                { i18n.t('header.middleName') }
                                                            </Label>
                                                        </Col>
                                                        <Col>
                                                            {this.props.getUser.getUser.middleName}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={"col-5"}>
                                                            <Label className='user-label'>
                                                                { i18n.t('header.lastName') }
                                                            </Label>
                                                        </Col>
                                                        <Col>
                                                            {this.props.getUser.getUser.lastName}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={"col-5"}>
                                                            <Label className='user-label'>
                                                                { i18n.t('header.phones') }
                                                            </Label>

                                                        </Col>
                                                        <Col>
                                                            {phoneRepeater()}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={"col-5"}>
                                                            <Label className='user-label'>
                                                                { i18n.t('header.jobTitle') }
                                                            </Label>
                                                        </Col>
                                                        <Col>
                                                            {this.props.getUser.getUser.jobTitle}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={"col-5"}>
                                                            <Label className='user-label'>
                                                                { i18n.t('header.organizations') }
                                                            </Label>
                                                        </Col>
                                                        <Col>
                                                            {this.props.getUser.getUser.organizations.map(o => o.name).toString()}
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </FormGroup>
                                            <FormGroup tag="fieldset">
                                                <Row>
                                                    <Col className='col-5'>
                                                        <Label className='user-label'>
                                                            { i18n.t('header.userRoles') }
                                                        </Label>
                                                    </Col>
                                                    <Col className='role-field'>
                                                        <Input type="checkbox" value={this.props.getRolesForUser.getRolesForUser.indexOf('USER') > -1 ? 'on' : 'off'}  checked={this.props.getRolesForUser.getRolesForUser.indexOf('USER') > -1 } disabled
                                                                 className="css-checkbox" id="user" />
                                                        <label htmlFor="user" className="css-label"><i className="icon ion-android-checkbox"></i> {i18n.t('header.userRole')}</label>

                                                   </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='col-5'>
                                                    </Col>
                                                    <Col className='role-field'>
                                                        <Input type="checkbox" value={this.props.getRolesForUser.getRolesForUser.indexOf('ADMIN') > -1 ? 'on' : 'off'}  checked={this.props.getRolesForUser.getRolesForUser.indexOf('ADMIN') > -1 } disabled
                                                               className="css-checkbox" id="admin" />
                                                        <label htmlFor="admin" className="css-label"><i className="icon ion-android-checkbox"></i> {i18n.t('header.adminRole')}</label>
                                                    </Col>
                                                </Row>

                                            </FormGroup>
                                        </Form>
                                        <Row className='margin-b-50'>
                                            <Col className='col-5'>
                                            </Col>
                                            <Col className='padding-l-0'>
                                                <Row>
                                                    <Col className='col-4'>
                                                        <Input className='btn btn-primary' type='submit' value={ i18n.t('header.editUser')} onClick={() => this.props.history.push('/users/edituser/'+this.props.getUser.getUser.username)}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                    </div>
                </div>
                </Container>)

    }
}




const LoadingComponent = () => <Loading/>

let withRouter2 = withRouter(ViewUser);
let withApollo1 = withApollo(withRouter2);
export default compose(
    graphql(getUserQuery, { name: 'getUser',
        options: props => ({

            variables: { username : props.match.params.username } }),} ),
    graphql(getRolesForUserQuery, {name: 'getRolesForUser',
        options: props => ({

             variables: { username : props.match.params.username } }),} ),
    branch(
        (props) => {
            return !props.getUser.getUser && props.getUser.loading || !props.getRolesForUser.getRolesForUser && props.getRolesForUser.loading
        },
        renderComponent(LoadingComponent)
    ))(withApollo1);
