import React, { Component } from 'react';
import { compose, graphql } from "react-apollo";
import { Link } from 'react-router-dom'
import { Field, reduxForm, SubmissionError } from "redux-form";
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import i18n from "../Pages/Login/i18n";
import MoreInfoButton from "../../components/MoreInfoButton";
import { Button, Breadcrumb, BreadcrumbItem, Card, CardBody, CardGroup, Col, Container, Row, FormGroup, InputGroup, InputGroupAddon, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import {getUsers} from "../../queries/Queries";
import QueryDataGrid from "../../components/QueryDataGrid";

function enabledFormatter(cell, row) {
    return (
        <span> {row.enabled ? (row.locked ? i18n.t('header.locked')  : i18n.t('header.active') ): i18n.t('header.deactivated')} </span>
    );
}
function editFormatter(cell, row) {
    return (
        <Link to={"/users/edituser/" + row.username}><Button>{i18n.t('header.edit')}</Button></Link>
    );
}


function primaryPhoneFormatter(cell, row) {
    return (
        cell.filter(({primaryNumber}) => primaryNumber).map(({phoneNumber}) => phoneNumber).join()
    );
}

//---------------------------------------------------------




class UserInfoTemplate extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (<div><Col><Row><Col sm="2"><Row><img width="32" height="32" src={CORE_URL+'/avatar/'+this.props.username} style={{borderRadius: "50%"}}/></Row></Col><Col sm="10"><Row>{this.props.firstName+' '+(this.props.lastName ? this.props.lastName : "")}</Row><Row><small>{this.props.roles}</small></Row></Col></Row></Col></div>);
    }
}



const columns = [{
    dataField: 'firstName',
    sort: true,
    text: 'NAME AND ROLES',
    formatter: (cell, row) => {
        return <UserInfoTemplate username={row.username} firstName={row.firstName} lastName={row.lastName} avatarUrl={row.avatarUrl} roles={row.roles.length > 0 ? row.roles.map(({role}) => role.name).join(', ') : 'No Roles'} className="more-options-btn"/>
        }
}, {
    dataField: 'username',
    sort: true,
    text: 'EMAIL'
}, {
    dataField: 'phoneNumbers',
    sort: true,
    text: 'PHONE',
    formatter:primaryPhoneFormatter
}, {
    dataField: 'enabled',
    formatter: enabledFormatter,
    sort: true,
    text: 'STATUS'
}, {
    dataField: 'options',
    text: ' ',
    formatter: (cell, row) => <MoreInfoButton user={row} className="more-options-btn" editUrl={"/users/edituser/"}/>

}];


//---------------------------------------------------------

class Users extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            userSearch:'',
            enabledSearch:''
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    render() {
        return (
            <div className="manage-users">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('header.manageUsers')}</h1>
                    </Col>
                    <Col className='col-4'>
                        <Breadcrumb>
                            <BreadcrumbItem><i className="icon ion-android-home"></i><a href="#">Home</a></BreadcrumbItem>
                            <BreadcrumbItem active>Users</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <QueryDataGrid query={getUsers}
                                   dataPath={"getUsers"}
                                   filters={[
                                       {
                                           type: "text",
                                           fields: ['firstName', 'lastName', 'roles.#.role.key', 'phoneNumbers.#.phoneNumber', 'username'],
                                           placeholder: i18n.t('users.userSearch'),
                                           classes: "search"
                                       },
                                       {
                                           type: "dropdown",
                                           fields: ['enabled'],
                                           placeholder: "Search",
                                           valueMappings:{true: 'Active', false:'Deactivated'}
                                       }
                                   ]}
                                   buttons={<Link to={"/users/createuser"} className='btn btn-primary pull-right'> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('users.newUser')} </Link>}
                                   columns={columns} />
                </div>
            </div>

        )
    }
}

export default Users;
