import React, {Component} from 'react';
import { Row, Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import i18n from '../../views/Pages/Login/i18n';
import {Link} from "react-router-dom";

class TableHeader extends Component {

    constructor() {
        super();
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        const dropdownitems = [];

        for (const item of this.props.items) {
            dropdownitems.push(<DropdownItem tag={Link} to={item.link} key={item.link}>{i18n.t(item.title)}</DropdownItem>)
        }

        return (
            <Row className='subheader'>
                <Col className='col-8'>
                    <h1>{i18n.t(this.props.title)}</h1>
                </Col>
                <Col className='col-4'>
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                        <DropdownToggle className='pull-right' color="primary">
                                <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('header.new')}
                        </DropdownToggle>
                        <DropdownMenu>
                            {dropdownitems}
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
        )
    }
}

export default TableHeader;
