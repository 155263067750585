import React from 'react';
import BasicQueryComponent from "../BasicQueryComponent";
import {
    Bar,
    BarChart,
    CartesianGrid, ComposedChart,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import jsonata from "jsonata/jsonata-es5";
import fileDownload from "js-file-download";
import {CustomizedAxisTick} from "./CustomizedAxisTick";
import {FormGroup, Input} from "reactstrap";

class DynamicLineChart extends BasicQueryComponent {

    constructor(props) {
        super(props);
        this.state = {checked: false, checkedCircles: false, color: [], id: Math.random().toString(36).substring(7)};
    }

    recursiveMap(children, extraProps) {
        return React.Children.map(children, child => {
            if (!React.isValidElement(child)) {
                return child;
            }

            if (child.props.children) {
                child = React.cloneElement(child, {
                    children: this.recursiveMap(child.props.children, extraProps),
                    ...extraProps
                });
            }

            return React.cloneElement(child, extraProps);
        });
    }

    transformData(data, exp) {
        let expr = exp;
        //console.log(expr, data);
        const expression = expr ? jsonata(expr) : null;
        const transformedData = expression ? expression.evaluate(data) : data;
        return transformedData;
    }

    addLines(chart, data) {

        let TypeSerie = this.props.chartType === "bar" ? Bar : Line;


        //console.log("Data is: " + JSON.stringify(data));
        const children = [];
        const transformedData = this.transformData(data, this.props.expression);
        let colorsArray = this.props.colors;
        if (transformedData !== 'undefined' && transformedData !== undefined) {
            if (Array.isArray(transformedData)) {
                if(this.props.colorPreferences && transformedData.length < colorsArray.length) {
                    transformedData.forEach(entry => {
                        this.props.colorPreferences.forEach(preference => {
                            if(entry.name.toLowerCase().includes(preference.keyword)) {
                                colorsArray = preference.colors;
                            }
                        });
                    });
                }
                transformedData.map((entry, i) => {
                    this.state.color[i] = this.state.color[i] || this.getColor(i, colorsArray);
                    const color = this.state.color[i];
                    const stroke = this.props.chartType === "bar" || entry.serieType === "bar" ? null : color;
                    const fill = this.props.chartType === "bar" || entry.serieType === "bar" ? color : null;
                    const extendedProps = entry.extended;
                    if (this.props.chartType === "mixed") {
                        TypeSerie = entry.serieType === "bar" ? Bar : Line;
                    }
                    children.push(<TypeSerie dataKey={entry.dataKey} data={entry.data} name={entry.name} stroke={stroke}
                                             fill={fill} dot={this.state.checkedCircles && {fill: color}} {...extendedProps}/>);
                });
            } else {
                const key = transformedData.dataKey;
                const names = transformedData.name;
                if (Array.isArray(names)) {
                    names.map((entry, i) => {
                        this.state.color[i] = this.state.color[i] || this.getColor(i, colorsArray);
                        const color = this.state.color[i];
                        const fill = this.props.chartType === "bar" ? color : null;
                        const extendedProps = this.props.extended;
                        children.push(<TypeSerie dataKey={key} data={entry.data} name={entry.name} fill={fill}
                                                 dot={this.state.checkedCircles && {fill: color}} {...extendedProps}/>);
                    });
                } else {
                    if(this.props.colorPreferences && colorsArray.length > 1) {
                        this.props.colorPreferences.forEach(preference => {
                            if(names.toLowerCase().includes(preference.keyword)) {
                                colorsArray = preference.colors;
                            }
                        });
                    }
                    this.state.color[0] = this.state.color[0] || this.getColor(0, colorsArray);
                    const color = this.state.color[0];
                    const fill = this.props.chartType === "bar" ? color : null;
                    const extendedProps = this.props.extended;
                    const stroke = this.props.chartType === "bar" ? null : color;
                    children.push(<TypeSerie dataKey={key} data={transformedData.data} name={names} fill={fill} stroke={stroke}
                                             dot={this.state.checkedCircles && {fill: color}} {...extendedProps}/>);
                }
            }
        }

        //console.log("Children are: " + children.length);
        return React.cloneElement(chart, {
                children: [children, ...chart.props.children],
                data: data
            }
        );
    }

    triggerDownload(imgURI) {

    }

    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    getColor(index, colorsArray) {

        const colors = colorsArray;

        if(colors === undefined || colors === 'undefined' || colors === null || colors === '') {
            return this.getRandomColor();
        }
        if(index >= colors.length) {
            return this.getRandomColor();
        } else {
            return colors[index];
        }
    }

    handleSaveClick = () => {


        let chartSVG = document.getElementById('node-to-convert');
        let svgURL = new XMLSerializer().serializeToString(chartSVG);
        let svgBlob = new Blob([svgURL], {type: "image/svg+xml;charset=utf-8"});

        var img = new Image();
        var DOMURL = window.URL || window.webkitURL || window;
        var url = DOMURL.createObjectURL(svgBlob);

        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            DOMURL.revokeObjectURL(url);
            var image = canvas.toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            fileDownload(image, 'dom-to-image.png');
        };
        img.src = url;

        // let img = new Image(width, height);

        //  saveSvgAsPng(chartSVG, 'chart.png');

        // img.onload = () => {
        //     ctx.drawImage(img, 0, 0);
        //     let imageData = canvas.toDataURL('image/png', 1.0);
        //     resolve(imageData)
        // }
        //
        // fileDownload(svgBlob, 'dom-to-image.svg');
        // domtoimage.toBlob(document.getElementById('node-to-convert'))
        //     .then(function (blob) {
        //         fileDownload(blob, 'dom-to-image.png');
        //     });
    }

    render() {
        const noDataComponent = <div>No records in graph</div>;

        const TypeChart={
            bar:BarChart,
            mixed: ComposedChart
        }[this.props.chartType] || ComposedChart;

        const chart = <TypeChart width={this.props.width || 600} height={this.props.height || 300}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey={this.props.xKey} allowDuplicatedCategory={false} tickFormatter={x => this.props.format || x}
                   tick={<CustomizedAxisTick tickFormatterExpression={this.props.tickFormatterExpression}
                                             tickRotation={this.props.tickRotation}/>} interval={ this.props.interval || "preserveEnd"} scale={this.props.chartType ? "auto" : "point" } />
            {!this.props.yAxis && this.state.checked ? <YAxis type="number" domain={['auto', 'auto']}/> : <YAxis/>}
            ...{this.props.yAxis && this.props.yAxis.map(y => <YAxis
                {...y}
            />)}
            <Tooltip/>
            <Legend/>
        </TypeChart>;

        if (this.props.data !== 'undefined' && this.props.data !== undefined && Object.keys(this.props.data).length > 0) {

            const updatedChart = this.addLines(chart, this.props.data);
            var checkBoxesDisplayed;

            if(this.props.checkBoxes === "hidden") {
                checkBoxesDisplayed = false;
            } else {
                checkBoxesDisplayed = true;
            }

            return (
                <div>
                    <ResponsiveContainer id="node-to-convert" width={"100%"}
                                         height={this.props.height || 300}>{updatedChart}
                        {/*<button onClick={this.handleSaveClick}>save</button>*/}
                    </ResponsiveContainer>
                    {/* this.props.yAxis && <div><pre>{JSON.stringify(this.props.data, null, 2) }</pre></div> */}
                    {checkBoxesDisplayed && <div>
                        <FormGroup tag="fieldset">
                            <Input className='role-field css-checkbox' type="checkbox" checked={this.state.checked}
                                   onChange={
                                       (e) => this.setState({checked: !this.state.checked})
                                   } id={this.state.id}/>
                            <label htmlFor={this.state.id} className="css-label"><i
                                className="icon ion-android-checkbox"></i>Dynamic Y Axis Minimum</label>
                        </FormGroup>
                        {this.props.chartType !== "bar" && <FormGroup tag="fieldset">
                            <Input className='role-field css-checkbox' type="checkbox"
                                   checked={this.state.checkedCircles}
                                   onChange={
                                       (e) => this.setState({checkedCircles: !this.state.checkedCircles})
                                   } id={this.state.id + 'circles'}/>
                            <label htmlFor={this.state.id + 'circles'} className="css-label"><i
                                className="icon ion-android-checkbox"></i>Data Marks</label>
                        </FormGroup>}
                    </div>}
                </div>
            );
        } else {
            return (noDataComponent);
        }
    }
}

export default DynamicLineChart;
