import React, {Component} from 'reactn';
import PaginatedQueryDataGrid from "../../components/PaginatedQueryDataGrid"
import {Button, Col, FormGroup, Row} from "reactstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import i18n from '../../views/Pages/Login/i18n';
import schema from "../../schemas/task.schema.js"
import 'react-dates/lib/css/_datepicker.css';
import TasksDetail from "../../components/TasksDetail";
import TasksComments from "../../components/TasksComments";
import CreateTask from "../../views/CreateTask";
import {dateFormatter, queryTypeFotmatter} from "../../utils/Formatters";
import {transformSchema} from '../../utils/Helpers';

class Tasks extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            showCreateTask: false,
            filters: {
                status: [{id: "Open", label: "Open"}, {id: "In Progress", label: "In Progress"}]
            },
            task: this.props.match && this.props.match.params && this.props.match.params.queryId ? this.props.match.params.queryId : null,
            page: 1,
            tasksPerPage: 10
        };
    }

    toggle() {
        this.setState({
            showCreateTask: !this.state.showCreateTask
        });
    }

    getQueries() {

        const result = {};
        const uiQueries = this.props.configuration.uiQueries;
        for (let i = 0; i < uiQueries.length; i++) {
            result[uiQueries[i].name] = uiQueries[i].query;
        }
        return result;
    }

    render() {

        const queries = this.getQueries();

        return (
            <div className="manage-users tasks-page">
                {!this.props.hideHeader && <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Queries</h1>
                    </Col>
                    {false && <Col className='col-4'>
                        <FormGroup>
                            <Button onClick={() => this.setState({showCreateTask: true})} className='pull-right'
                                    color="primary"> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;Add Query
                            </Button>
                        </FormGroup>
                    </Col>}
                </Row>}
                <div className="page-cnt">
                    <Row>
                        <Col sm={12}>
                                <PaginatedQueryDataGrid export={true} dataPath={this.props.queryPath + '.content'}
                                                        queryKey={this.props.queryKey} queries={queries}
                                                        paginationPath={this.props.queryPath}
                                                        keyField={'id'}
                                                        note={this.props.note}
                                                        updateKey="listOfTasksQuery"
                                                        defaultSorted={{
                                                            dataField: 'createTime',
                                                            order: 'desc'
                                                        }}
                                                        gridRowClass={'tasks-cnt1 row-eq-height '}
                                                        gridColClass={'tasks-list'}
                                                        gridColSM={this.state.task ? 4 : 12}
                                                        children={this.state.task && <Col sm={8} className="task-details-cnt">
                                                            <TasksDetail
                                                                power={this.props.power}
                                                                task={this.state.task}
                                                                resetView={() => this.setState({task: null})}></TasksDetail>
                                                            <TasksComments
                                                                task={this.state.task}/>
                                                        </Col>}
                                                        variables={this.props.variables ? this.props.variables : ''}
                                                        columns={[
                                                            {
                                                                dataField: 'variables.external_id',
                                                                text: 'Query Id',
                                                                sort: true,
                                                                formatter: (cell, row) => cell || 'Processing'
                                                            },
                                                            {
                                                                dataField: 'variables.account_code',
                                                                text: 'Account Number',
                                                                formatter: function(cell, row) {
                                                                    const regex = /power_\d+\./;
                                                                    const text = cell.replace(regex, "")
                                                                    return text
                                                                }.bind(this),
                                                                sort: true
                                                            },
                                                            ...(!this.state.task && [{
                                                                dataField: 'variables.type',
                                                                text: 'Type',
                                                                sort: true,
                                                                formatter: function(cell, row) {
                                                                    const text = queryTypeFotmatter(cell, row, null, this.props);
                                                                    return row.attachmentCount ? (
                                                                        <div><i className="icon ion-android-attach"
                                                                                title={"Attachments"}></i> {text}</div>) : text
                                                                }.bind(this),
                                                                csvformatter: function(cell, row) {
                                                                    const text = queryTypeFotmatter(cell, row, null, this.props);
                                                                    return row.attachmentCount ? "📎 "+ text : text
                                                                }.bind(this)
                                                            },
                                                                {
                                                                    dataField: 'owner',
                                                                    text: 'Raised By',
                                                                    sort: true
                                                                }, {
                                                                    dataField: 'createTime',
                                                                    text: 'Raised Date',
                                                                    formatter: dateFormatter,
                                                                    sort: true
                                                                }, {
                                                                    dataField: 'variables.status',
                                                                    text: 'Status',
                                                                    sort: true
                                                                }, {
                                                                    dataField: 'variables.mprn',
                                                                    text: this.props.power ? "MPAN" : "MPR",
                                                                    sort: true
                                                                }, {
                                                                    dataField: 'variables.estate',
                                                                    text: 'Site Name',
                                                                    sort: true
                                                                },{
                                                                    dataField: 'variables.closed_on',
                                                                    text: 'Completed date',
                                                                    formatter: dateFormatter,
                                                                    sort: true
                                                                }])]}
                                                        filters={[
                                                        ...(
                                                            this.props.queryKey === 'QueryByCustomer' && [{
                                                                type: "text",
                                                                fields: ['owner','variables.mprn', 'variables.account_code', 'variables.estate', 'variables.external_id', 'description', 'owner'],
                                                                placeholder: `Search by ${this.props.power ? "MPAN" : "MPR"}/Account Number/Site Name/Query ID/Queries Name`,
                                                                label: 'Search',
                                                                classes: "search",
                                                                cols: 4
                                                            }]
                                                        ),
                                                        ...(
                                                            this.props.queryKey === 'QueryByProperty' && [{
                                                                type: "text",
                                                                fields: ['owner','variables.external_id'],
                                                                placeholder: 'Search by Query Id or Raised By',
                                                                label: 'Search',
                                                                classes: "search",
                                                                cols: 4
                                                            }]
                                                        ),
                                                        {
                                                            type: "date",
                                                            fields: ["createTime"],
                                                            placeholder: 'Queries raised between dates',
                                                            label: 'Queries raised between dates',
                                                            cols: 4,
                                                            disableFutureDates: true
                                                        }, {
                                                            type: "typeahead",
                                                            fields: ["variables.status"],
                                                            possibleValues: [{key: 'Open', value: 'Open'}, {key: 'Closed', value: 'Closed'}],
                                                            placeholder: i18n.t('tasks.status'),
                                                            label: i18n.t('tasks.status'),
                                                            classes: 'select'
                                                        }, {
                                                            type: "typeahead",
                                                            fields: ["variables.type"],
                                                            enumValues: this.props.power ? transformSchema(schema, 'E').properties.type.anyOf : transformSchema(schema, 'G').properties.type.anyOf,
                                                            placeholder: i18n.t('tasks.type'),
                                                            label: i18n.t('tasks.type'),
                                                            classes: 'select'
                                                        }]}
                                                        initialFilters={{'variables.status': "Open"}}
                                                        onSelect={(selected) => {
                                                            this.setState({task: selected})
                                                        }}
                                />
                        </Col>
                        <CreateTask modalOpen={this.state.showCreateTask} onClose={this.toggle}
                                    user={this.global.username}/>
                    </Row>
                    {/*<Query query={getTasksQuery}>*/}
                        {/*{({loading, error, data}) => {*/}
                            {/*if (loading) return <Loading/>;*/}
                            {/*if (error) return `Error! ${error.message}`;*/}
                            {/*if (data.getOwnTasks) {*/}

                                {/*this.state.searchStart && console.log(moment(this.state.searchStart, "YYYY-MM-DDTHH:mm:ssZ"));*/}

                                {/*const filteredTasks = data.getOwnTasks.map(task => {*/}
                                    {/*let task1 = {*/}
                                        {/*...task, ...task.variables.reduce((accumulator, {name, value}) => {*/}
                                            {/*return {...accumulator, [name]: value};*/}
                                        {/*}, {})*/}
                                    {/*};*/}
                                    {/*task1.dueDate = task.dueDate ? task.dueDate.substring(0, 10) : "";*/}
                                    {/*task1.priority = task1.priority ? Math.floor(task1.priority / 50) * 50 : 0;*/}
                                    {/*task1.assignee = task1.assignee == null ? "" : task1.assignee;*/}
                                    {/*task1.status = task1.status == null ? "Open" : task1.status;*/}
                                    {/*return task1;*/}
                                {/*}).filter(task => Object.entries(this.state.filters)*/}
                                        {/*.map(entry => entry[1].length == 0 || entry[1].map(({id}) => id).indexOf(task[entry[0]]) > -1)*/}
                                        {/*.reduce((previousValue, currentValue) => previousValue && currentValue, true) && (!this.state.searchBy || (task.external_id && task.external_id.indexOf(this.state.searchBy) > -1) || (task.estate && task.estate.indexOf(this.state.searchBy) > -1) || (task.name && task.name.indexOf(this.state.searchBy) > -1) || (task.owner && task.owner.indexOf(this.state.searchBy) > -1) || (task.mprn && task.mprn.indexOf(this.state.searchBy) > -1))*/}
                                    {/*&& (!this.state.searchStart || moment(task.createTime, "YYYY-MM-DDTHH:mm:ssZ").isSameOrAfter(moment(this.state.searchStart, "YYYY-MM-DDTHH:mm:ssZ"), 'day'))*/}
                                    {/*&& (!this.state.searchEnd || moment(task.createTime, "YYYY-MM-DDTHH:mm:ssZ").isSameOrBefore(moment(this.state.searchEnd, "YYYY-MM-DDTHH:mm:ssZ"), 'day'))*/}
                                {/*)*/}
                                    {/*.sort((a, b) => moment(a.createTime, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.createTime, "YYYY-MM-DDTHH:mm:ssZ")) ? 1 : -1);*/}
                                {/*const numberOfPages = Math.ceil(filteredTasks.length / this.state.tasksPerPage);*/}
                                {/*const pagedTasks = filteredTasks.slice((this.state.page - 1) * this.state.tasksPerPage, this.state.page * this.state.tasksPerPage);*/}

                                {/*const rowEvents = {*/}
                                    {/*onClick: (e, task, rowIndex) => {*/}
                                        {/*this.setState({taskId: task.id})*/}
                                    {/*}*/}
                                {/*};*/}


                                {/*return (*/}
                                    {/*<Row className="tasks-cnt row-eq-height ">*/}
                                        {/*<Col sm={this.state.taskId ? 4 : 12} className="tasks-list">*/}
                                            {/*<div className="task-list-header clearfix">*/}
                    {/*<span*/}
                        {/*className="task-in-list pull-right">{Math.min((this.state.page - 1) * this.state.tasksPerPage + 1, filteredTasks.length)} - {(this.state.page - 1) * this.state.tasksPerPage + pagedTasks.length} of {filteredTasks.length} {i18n.t('tasks.tasks')}</span>*/}
                                            {/*</div>*/}
                                            {/*<BootstrapTable*/}
                                                {/*keyField={"id"}*/}
                                                {/*data={filteredTasks}*/}
                                                {/*columns={[*/}
                                                    {/*{*/}
                                                        {/*dataField: 'external_id',*/}
                                                        {/*text: 'Query Id',*/}
                                                        {/*sort: true*/}
                                                    {/*},*/}
                                                    {/*{*/}
                                                        {/*dataField: 'account_code',*/}
                                                        {/*text: 'Account number',*/}
                                                        {/*sort: true*/}
                                                    {/*},*/}
                                                    {/*...(!this.state.taskId && [{*/}
                                                        {/*dataField: 'type',*/}
                                                        {/*text: 'Type',*/}
                                                        {/*sort: true,*/}
                                                        {/*formatter: function attachmentIconFormatter(cell, row) {*/}
                                                            {/*const res = schema.properties.type.anyOf.find(el => el.enum.indexOf(parseInt(cell)) > -1)*/}
                                                            {/*const text = res ? res.title : "Unknown";*/}
                                                            {/*return row.hasAttachments ? (*/}
                                                                {/*<div><i className="icon ion-android-attach"*/}
                                                                        {/*title={"Attachments"}></i> {text}</div>) : text*/}
                                                        {/*}*/}
                                                    {/*},*/}
                                                        {/*{*/}
                                                            {/*dataField: 'owner',*/}
                                                            {/*text: 'Raised by',*/}
                                                            {/*sort: true*/}
                                                        {/*}, {*/}
                                                            {/*dataField: 'createTime',*/}
                                                            {/*text: 'Raised Date',*/}
                                                            {/*formatter: dateFormatter,*/}
                                                            {/*sort: true*/}
                                                        {/*}, {*/}
                                                            {/*dataField: 'status',*/}
                                                            {/*text: 'Status',*/}
                                                            {/*sort: true*/}
                                                        {/*}, {*/}
                                                            {/*dataField: 'mprn',*/}
                                                            {/*text: 'MPR',*/}
                                                            {/*sort: true*/}
                                                        {/*}, {*/}
                                                            {/*dataField: 'estate',*/}
                                                            {/*text: 'Site Name',*/}
                                                            {/*sort: true*/}
                                                        {/*}])]}*/}
                                                {/*bordered={false}*/}
                                                {/*hover*/}

                                                {/*pagination={paginationFactory({*/}
                                                    {/*page: this.state.page,*/}
                                                    {/*onPageChange: (page, sizePerPage) => {*/}
                                                        {/*this.setState({page: Math.min(Math.ceil(filteredTasks.length / sizePerPage), page)});*/}
                                                    {/*},*/}
                                                    {/*paginationSize: 10,*/}
                                                    {/*pageStartIndex: 1,*/}
                                                    {/*alwaysShowAllBtns: true,*/}
                                                    {/*firstPageText: 'First',*/}
                                                    {/*prePageText: 'Previous  ',*/}
                                                    {/*nextPageText: 'Next',*/}
                                                    {/*lastPageText: 'Last',*/}
                                                    {/*nextPageTitle: 'First page',*/}
                                                    {/*prePageTitle: 'Pre page',*/}
                                                    {/*firstPageTitle: 'Next page',*/}
                                                    {/*lastPageTitle: 'Last page',*/}
                                                    {/*sizePerPageList: [{*/}
                                                        {/*text: '10', value: 10*/}
                                                    {/*},*/}
                                                        {/*{*/}
                                                            {/*text: '20', value: 20*/}
                                                        {/*}]*/}
                                                {/*})}*/}

                                                {/*noDataIndication={() => <div>No records in table</div>}*/}
                                                {/*rowEvents={rowEvents}*/}
                                            {/*/>*/}
                                        {/*</Col>*/}

                                        {/*{this.state.taskId && <Col sm={8} className="task-details-cnt">*/}
                                            {/*<TasksDetail*/}
                                                {/*task={filteredTasks.filter(({id}) => id === this.state.taskId)[0]}*/}
                                                {/*resetView={() => this.setState({taskId: null})}></TasksDetail>*/}
                                            {/*<TasksComments*/}
                                                {/*task={filteredTasks.filter(({id}) => id === this.state.taskId)[0]}/>*/}
                                        {/*</Col>}*/}
                                        {/*<CreateTask modalOpen={this.state.showCreateTask} onClose={this.toggle}*/}
                                                    {/*user={this.global.username}/>*/}

                                    {/*</Row>);*/}
                            {/*} else {*/}
                                {/*return "No tasks";*/}
                            {/*}*/}
                        {/*}}*/}

                    {/*</Query>*/}
                </div>

            </div>

        )
    }
}

export default Tasks;
