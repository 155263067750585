import React, { Component } from 'react';
import i18n from "../Pages/Login/i18n";
import { Col, Row, Button } from "reactstrap";
import AgreementsGrid from "../../components/AgreementsGrid/AgreementsGrid";
import gql from "graphql-tag";
import { Link } from 'react-router-dom';


//---------------------------------------------------------

class Agreements extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            userSearch: '',
            enabledSearch: ''
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    render() {
        return (
            <div className="manage-users">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('agreements.agreements')}</h1>
                    </Col>
                    <Col className="col-4">
                        {/*<Link to={"/create-agreement"}>*/}
                            {/*<Button className='pull-right' color="primary"> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;Add Agreement </Button>*/}
                        {/*</Link>*/}
                    </Col>
                </Row>
                <div className="page-cnt">
                    <AgreementsGrid/>
                </div>
            </div>
        )
    }
}

export default Agreements;