import React, {Component} from "react";
import {Query, withApollo} from "react-apollo";
import {sitesByProperty} from "../../../queries/Queries";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import i18n from "../../../views/Pages/Login/i18n";

class CustomerSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            siteId: null,
            loading: false,
            result: {}
        }

        this.mergeRowsByMpr = this.mergeRowsByMpr.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    handleSearch(query) {
        this.setState({
            loading: true
        });
        let where = query ? this.props.activeAccounts ? `account.status==\"A\" and (name==\"%${query}%\" or account.number==\"%${query}%\" or meterPoints.identifier==\"%${query}%\")` : `name==\"%${query}%\" or account.number==\"%${query}%\" or meterPoints.identifier==\"%${query}%\"` : `id==-100000`;
        this.props.client.query({
            query: sitesByProperty,
            variables: {
                pageNumber: 0,
                pageSize: 20,
                field: this.props.field,
                where: where
            }
        }).then(({data}) => {
            this.setState({
                result: data,
                loading: false
            });
        });
    }

    mergeRowsByMpr(array) {
        try {
            let result = {};
            for (let i = 0; i < array.length; i++) {
                let curr = array[i];
                if (!result.hasOwnProperty(curr.account.number)) {
                    result[curr.account.number] = curr;
                    result[curr.account.number].meterPoints = [];
                }
                curr.site.meterPoints.forEach((mp) => {
                    let el = {
                        'id': mp.id,
                        'identifier': mp.identifier,
                        'registers': mp.registers,
                        'startDate': curr.startDate,
                        'endDate': curr.endDate,
                        'hasCorrector': mp.hasCorrector
                    };
                    if (!result[curr.account.number].meterPoints.some(item => item.id === el.id)) result[curr.account.number].meterPoints.push(el);
                });
                for (let j = 0; j < curr.properties.length; j++) {
                    curr.properties[j].site.meterPoints.forEach((mp) => {
                        let el = {
                            'id': mp.id,
                            'identifier': mp.identifier,
                            'registers': mp.registers,
                            'startDate': curr.startDate,
                            'endDate': curr.endDate,
                            'hasCorrector': mp.hasCorrector
                        };
                        if (!result[curr.account.number].meterPoints.some(item => item.id === el.id)) result[curr.account.number].meterPoints.push(el);
                    });
                }
            }
            array = [];
            for (let key of Object.keys(result)) {
                array.push(result[key]);
            }
            return array;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    render() {
        return <div>
            <AsyncTypeahead ref="typeahead" placeholder={i18n.t('search.searchSiteCriteria')}
                            isLoading={this.state.loading}
                            onSearch={(query) => {
                                this.handleSearch(query);
                            }}
                            cssclassName="md-12"
                            minLength="3"
                            options={this.state.result && this.state.result.SitesByProperty ? this.mergeRowsByMpr(this.state.result.SitesByProperty.content) : []}
                            filterBy={["name", "account.number", "site.meterPoints.identifier"]}
                            labelKey={option => `${option.name}, Account number: ${option.account.number}, ${option.site.meterPoints[0] ? option.site.meterPoints[0].purpose === "Power" || option.site.meterPoints[0].purpose === "Electricity" ? "MPAN:" : "MPR:" : "MPR/MPAN:"} ${option.meterPoints ? option.meterPoints.map(o => o.id).join(', ') : ''}`}
                            selectHintOnEnter={true}
                            onChange={(selected) => {
                                if (selected[0]) {
                                    this.props.onChange(selected[0]);
                                }
                            }}

                            selected={this.props.value ? [{
                                id: this.props.value,
                                name: this.state.result && this.state.result.businessPartner ? (this.state.result.businessPartner.reduce((acc, curr) => curr.id === this.props.value ? curr : acc, {name: '...'}).name) : '...'
                            }] : []}
            />
        </div>
    }
}

export default withApollo(CustomerSearch);
