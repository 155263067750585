import React, { Component } from 'react';
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from 'react-router';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, Dropdown, DropdownToggle, DropdownMenu,DropdownItem, CardTitle, CardBody, CardHeader, CardText, Row, Col, Form, FormGroup, Label, InputGroup, Input, FormText, Modal, ModalHeader, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import QueryDataGrid from "../../components/QueryDataGrid";
import ContractsDetail from '../../components/ContractsDetail';
import ContractsTable from '../../components/ContractsTable';
import ProcessesTable from '../../components/ProcessesTable';
import ReadingsTable from '../../components/ReadingsTable';
import ReadingsDetail from '../../components/ReadingsDetail';
import InfoCard from '../../components/InfoCard'
import i18n from '../Pages/Login/i18n';
import { productFormatter, priceFormatter, dateFormatter, periodFormatter } from "../../utils/Formatters";
import Loading from "../../components/Loading"
import NotesAndAttachments from "../../components/NotesAndAttachments";
import {getBusinessPartnerQuery} from "../../queries/Queries";
import BillsTab from "../../components/BillsTab";
import ReadingsTab from "../../components/ReadingsTab";
import CreateReading from "../../views/CreateReading";




class BusinessPartner extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            activeTab: '1',
            activeSubTab: '5',
            billsSearch: '',
            dropdownOpen: false,
            showCreateReadingModal: false
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    toggleModal() {
        this.setState({
            showCreateReadingModal: !this.state.showCreateReadingModal
        });
    }
    render() {
        if (this.props.getBusinessPartner.businessPartner) {
            let businessPartner = this.props.getBusinessPartner.businessPartner[0];
            let ownerUser = businessPartner.cRMBpartnerContactList ? businessPartner.cRMBpartnerContactList.filter(({user}) => user.isMdmIsowner)[0] : null;
            {/* TODO */}
            if (!ownerUser) ownerUser = { mdmContactAddress: {} };
            return (
                <div>
                    <div className='bp busines-parnter-cnt'>
                        <Row className='subheader'>
                            <Col className='col-8'>
                                <h1>{businessPartner.name}</h1>
                            </Col>
                            <Col className='col-4'>
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} >
                                    <DropdownToggle className='pull-right' color='primary'>
                                        <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('header.new')}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={()=>this.setState({showCreateReadingModal:true})}>Reading</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Link to={"/users/createuser"}></Link>
                            </Col>
                        </Row>
                        <div className='bp-nav-items'>
                            <Nav tabs>
                                <NavItem><NavLink className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}>{i18n.t('businessPartner.account')}</NavLink></NavItem>
                                <NavItem><NavLink className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}>{i18n.t('businessPartner.agreements')}</NavLink></NavItem>
                                <NavItem><NavLink className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggle('3'); }}>{i18n.t('businessPartner.billing')}</NavLink></NavItem>
                                <NavItem><NavLink className={classnames({ active: this.state.activeTab === '4' })}
                                    onClick={() => { this.toggle('4'); }}>{i18n.t('businessPartner.readings')}</NavLink></NavItem>
                                <NavItem><NavLink className={classnames({ active: this.state.activeTab === '5' })}
                                    onClick={() => { this.toggle('5'); }}>{i18n.t('businessPartner.processes')}</NavLink></NavItem>
                            </Nav>
                        </div>
                    </div>
                    <TabContent activeTab={this.state.activeTab} className='bp-tab-content'>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="6">
                                    <InfoCard hideEmpty={true} title={i18n.t('businessPartner.AccountHolder')} columns={2}
                                        info={[{ key: 'businessPartner.customerName', value: businessPartner.name },
                                        { key: 'businessPartner.customerNumber', value: businessPartner.mdmContragentno },
                                        { key: 'businessPartner.title', value: businessPartner.title },
                                        { key: 'businessPartner.companyName', value: businessPartner.mdmCompanyName },
                                        { key: 'businessPartner.firstName', value: businessPartner.firstName },
                                        { key: 'businessPartner.companyNumber', value: businessPartner.mdmEik },
                                        { key: 'businessPartner.lastName', value: businessPartner.lastName },
                                        { key: 'businessPartner.companyType', value: businessPartner.mdmCompanyType }]} />
                                </Col>
                                <Col sm="6">
                                    <InfoCard hideEmpty={true} title={i18n.t('businessPartner.correspondence')} columns={2}
                                        info={[{ key: 'businessPartner.title', value: ownerUser.mdmTitle },
                                        { key: 'businessPartner.email', value: ownerUser.email },
                                        { key: 'businessPartner.firstName', value: ownerUser.firstName },
                                        { key: 'businessPartner.phone', value: ownerUser.phone },
                                        { key: 'businessPartner.lastName', value: ownerUser.lastName },
                                        { key: 'businessPartner.address', value: ownerUser.mdmContactAddress && ownerUser.mdmContactAddress.locationAddress ? ownerUser.mdmContactAddress.locationAddress.addressLine1 : "" },
                                        { key: 'businessPartner.dob', value: dateFormatter(ownerUser.birthday) }]} />
                                </Col>
                            </Row>
                            <Row>
                                <NotesAndAttachments noteList={businessPartner.noteList} attachmentList={businessPartner.attachmentList} recordId={businessPartner.id} tableName={"BusinessPartner"} refetchQueries={[{query: getBusinessPartnerQuery, variables: { startRow: 0, offset: 10, where: "id = '" + this.props.match.params.id + "'", orderBy: "name" }}]}/>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Switch>
                                <Route path="/bp/:id/contract/:contractid" render={(props) => <ContractsDetail {...props} businessPartner={businessPartner} />} />
                                <Route path="/bp/:id" render={(props) => <ContractsTable {...props} businessPartner={businessPartner} />} />
                            </Switch>
                        </TabPane>
                        <TabPane tabId="3">
                            <BillsTab {...this.props} businessPartner={businessPartner}/>
                        </TabPane>
                        <TabPane tabId="4">
                            <ReadingsTab {...this.props} businessPartner={businessPartner}/>

                            <Modal isOpen={this.state.showCreateReadingModal} toggle={this.toggleModal} size={"large"}  style={{"maxWidth": "500px", "width": "90%"}} >
                                <ModalHeader toggle={this.toggleModal}>Create Reading</ModalHeader>
                                <ModalBody>
                                    <CreateReading estateList={businessPartner.mDMBPEstateList} toggle={this.toggleModal}/>
                                </ModalBody>
                            </Modal>
                        </TabPane>
                        <TabPane tabId="5">
                            <ProcessesTable {...this.props}/>
                        </TabPane>
                    </TabContent>
                </div>
            );
        } else {
            return <Loading />;
        }
    }
}






export default graphql(getBusinessPartnerQuery, {
    name: 'getBusinessPartner',
    options: props => ({
        variables: { startRow: 0, offset: 10, where: "id = '" + props.match.params.id + "'", orderBy: "name" }
    }),
})(withRouter(BusinessPartner));