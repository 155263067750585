import React from 'react';
import ReactDOM from 'react-dom';
import App from './apps/App';
import {} from './flatMap-polyfill';

ReactDOM.render(<App />,
    document.getElementById('root')
);

if (module.hot) module.hot.accept();
