import React, {Component} from 'react';
import Expire from '../Expire'
import {UncontrolledAlert} from 'reactstrap'

class ExpiringAlert extends Component {

    render() {
        return <Expire delay={this.props.color === "danger" ? 15000 : 5000}><UncontrolledAlert color={this.props.color}>{this.props.message}</UncontrolledAlert></Expire>;
    }
}

export default ExpiringAlert;
