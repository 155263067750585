import React, { Component } from 'react';

import { graphql } from "react-apollo";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import i18n from '../../views/Pages/Login/i18n';
import { priceFormatter } from "../../utils/Formatters";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardHeader, CardBody, Row, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Loading from '../Loading';
import {getBalance} from '../../queries/Queries'

class QueryBalance extends Component {

    constructor(props) {
        super(props);
    }

    render() {
      if(this.props.getBalance.getBalance){
          let unpaidInvoices = this.props.getBalance.getBalance.details.filter(inv => inv.type === "Invoice" && inv.balance > 0).map(({number, balance, currency, currencyISOCode, id}) => <Row noGutters className="invoiceurl" key={id}>
              <Col key={"number"} sm="7"><Link to={'/view/customer/'+this.props.match.params.param+'/bill/'+id} onClick={this.props.toggleTab}><small className='unpaid-result'>{number}:</small></Link></Col>
              <Col key={"amount"} sm="4"><Link to={'/view/customer/'+this.props.match.params.param+'/bill/'+id} onClick={this.props.toggleTab}><small className='unpaid-result'>{priceFormatter(balance, {currency: {symbol:currency, iSOCode:currencyISOCode}})}</small></Link></Col>
              <Col key={"link"} sm="1"><Link to={'/view/customer/'+this.props.match.params.param+'/bill/'+id} className='icon ion-forward' onClick={this.props.toggleTab}></Link></Col>
          </Row>);

            return (
                <Card >
                    <CardHeader>
                        <CardTitle>{i18n.t('businessPartner.Balance')}</CardTitle>
                    </CardHeader>
                    <CardBody >
                        <Row className={this.props.getBalance.getBalance.balance <= 0 ? 'icon-row success' :'icon-row'}>
                            <Col sm="12">
                                <span className={'icon ion-ios-calculator'}></span><span>{priceFormatter(-this.props.getBalance.getBalance.balance, this.props.getBalance.getBalance.details[0])}</span>
                            </Col>
                        </Row>
                        <div>
                            <small className='unpaid-label'>{i18n.t('businessPartner.UnpaidInvoices')}</small>
                        </div>
                        <div>
                            <a onClick={() => null} className={unpaidInvoices.length == 0 ? 'unpaid-invoices success ' :'unpaid-invoices '}> {unpaidInvoices.length == 0 ? i18n.t('businessPartner.PaidInvoicesAll') : unpaidInvoices.length +' '+ i18n.t('businessPartner.UnpaidInvoicesAll')}</a>
                        </div>
                        {unpaidInvoices.slice(0, 3)}
                    </CardBody>
                </Card>)
        } else {
            return (<Loading/>);
        }
    }
}


export default graphql(getBalance, {
    name: 'getBalance',
    options: props => {
        return {

                variables: {id: props.match.params.param}
            }},
    fetchPolicy: "cache-only",
})(withRouter(QueryBalance));