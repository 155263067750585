/**
 * Created by ogi on 1/22/18.
 */

import React, {Component} from 'reactn';
import BootstrapTable from 'react-bootstrap-table-next';
import {compose, graphql} from "react-apollo";
import JsxParser from 'react-jsx-parser';
import Widget01 from '../../views/Widgets/Widget01';
import MoreInfoButton from '../MoreInfoButton'
import _ from 'lodash';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {ButtonDropdown, DropdownToggle, DropdownMenu,DropdownItem,Col,Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router';


class DataGrid extends Component {

    constructor(props) {
        super(props);
        this.getColumns = this.getColumns.bind(this);
        this.state = {page: 1, sizePerPage: 5};
    }

    getQuery() {
        return this.props.query;
    }

    getColumns() {
        for (const col in this.props.columns) {
            if (this.props.columns[col].template) {
                this.props.columns[col].formatter = (cell, row, rowIndex, formatExtraData) =>
                    (<JsxParser
                        bindings={{row}}
                        components={{Widget01, MoreInfoButton}}
                        jsx={_.template(this.props.columns[col].template)(row)}
                        showWarnings={true}
                        key={rowIndex + "_" + col}
                    />)
            }
        }

        return this.props.columns;
    }

    componentWillReceiveProps(nextProps) {
        // reset the timer if children are changed
        if(!nextProps.dataQuery.loading) {
            this.setState({
                page: Math.min(Math.ceil(nextProps.getData(nextProps).filter(
                    nextProps.filter
                        ? nextProps.filter
                        : (o) => true
                ).length / this.state.sizePerPage), this.state.page)
            });
        }
    }

    render() {
        if (this.props.dataQuery.loading) {
            return (<div>Loading...</div>)
        } else {
            const data = this.props.getData(this.props).filter(
                this.props.filter
                    ? this.props.filter
                    : (o)=>true
            );
            if(data.length == 0 ) return this.props.noDataView? this.props.noDataView : <div>No records in table</div>;
            return (<BootstrapTable
                wrapperClasses="table-responsive"
                keyField={this.props.keyField}
                data={ data }
                columns={ this.getColumns() }
                bordered={ false }
                hover
                pagination={ paginationFactory({
                    page: this.state.page,
                    onPageChange: (page, sizePerPage) => {
                        this.setState({page : Math.min(Math.ceil(data.length/sizePerPage), page)});
                    },
                    paginationSize: 4,
                    pageStartIndex: 1,
                    alwaysShowAllBtns: true,
                    firstPageText: 'First',
                    prePageText: 'Previous  ',
                    nextPageText: 'Next',
                    lastPageText: 'Last',
                    nextPageTitle: 'First page',
                    prePageTitle: 'Pre page',
                    firstPageTitle: 'Next page',
                    lastPageTitle: 'Last page',
                    sizePerPageList: [{
                        text: '5 ', value: 5
                    }, {
                        text: '10', value: 10
                    }]
                }) }

                noDataIndication={() => <div>No records in table</div>}
            />)
        }
    }
}


export default DataGrid
