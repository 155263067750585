import i18next from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import XHR from 'i18next-xhr-backend';


i18next
    .use(XHR)
    .use(reactI18nextModule)
    .init({
        backend : {
            loadPath:  CORE_URL+'/translations/{{lng}}',
            parse: (data) => JSON.parse(data).reduce((accumulator, currentValue)=> {
                    const keyPath = currentValue.key.split('.');
                    if (keyPath[1]) {
                        if (!accumulator[keyPath[0]]) {
                            accumulator[keyPath[0]] = {};
                        }
                        accumulator[keyPath[0]][keyPath[1]] = currentValue.translation;
                    } else {
                        accumulator[keyPath[0]] = currentValue.translation;
                    }
                    return accumulator;
                }, {}
            )
        },
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        react: {
            wait: true
        },
        lng: window.location.href.indexOf("lng=") !== -1  ? window.location.href.substr(window.location.href.indexOf("lng=")+4, window.location.href.indexOf("lng=")+6) : 'en', // 'en' | 'es'
        // Using simple hardcoded resources for simple example
    }, (err, t) => {
        if(err) {
            console.error(err);
            console.warn(i18next.store.data);
        }
    })


export default i18next
