import React from 'react';
import i18n from '../../views/Pages/Login/i18n';

const ErrorPage = props => {
    return (
        <div>
            <h3><span>{i18n.t('query.error')}</span></h3>
        </div>
    );
}

export default ErrorPage;