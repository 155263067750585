import React, {Component} from 'react';
import i18n from "../../views/Pages/Login/i18n";
import {ApolloConsumer, Query} from "react-apollo";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {getBusinessPartnerShortQuery} from "../../queries/Queries"


class CustomerSearch extends Component{

    constructor(props) {
        super(props);
        this.state = {  options: [{id:this.props.value, name:""}] };
        this.nameCache = new Map();
        this.nameCache.set("", "");
        this.nameCache.set(this.props.value, "");
    }

    // componentDidMount(){
    //     this.doSearch(`id = '${this.props.value}'`);
    // }
    //
    // componentWillReceiveProps(prevProps, nextProps){
    //     this.doSearch(`id = '${prevProps.value}'`);
    // }
    // doSearch(query){
    //     this.setState({ isLoading: true });
    //     this.props.client.query({
    //         query: getBusinessPartnerShortQuery,
    //         variables:
    //     }).then((data) => {
    //         const options = data.data.businessPartner;
    //         let finalOptions = options;
    //         options.forEach(({id, name}) => this.nameCache.set(id, name));
    //         this.setState({
    //             isLoading: false,
    //             options: finalOptions,
    //         })
    //
    //     }).catch((error) => {
    //         //ReactDOM.render(<ExpiringAlert color="danger" message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
    //     });
    // }

    render(){

        return (
            <Query query={getBusinessPartnerShortQuery} variables={{
                orderBy: "name",
                where: `lower(name) like '%${this.state.query}%' or id = '${this.props.value}'`
            }}>
                { result => <AsyncTypeahead ref="typeahead" placeholder={i18n.t('search.searchInput')}
                                           isLoading={result.loading}
                                           onSearch={(query) => {
                                               this.setState({query})
                                           }}
                                            cssclassName="md-12"
                                           options={result.data.businessPartner}
                                           filterBy={["name"]}
                                           labelKey="name"
                                           selectHintOnEnter={true}
                                           onChange={(selected) => {
                                               if (selected[0]) {
                                                   this.props.onChange(selected[0].id);
                                               }
                                           }}

                                           selected={this.props.value ? [{
                                               id: this.props.value,
                                               name: result.data.businessPartner ? (result.data.businessPartner.reduce( (acc, curr) => curr.id === this.props.value ? curr : acc, {name : '...'}).name) : '...'
                                           }] : []}
                />}
            </Query>
        );
    }

}

export default CustomerSearch;
