import React, {Component} from 'react';
import i18n from "../Pages/Login/i18n";
import {Button, Col, Row} from "reactstrap";
import CSVReader from 'react-csv-reader';
import gql from 'graphql-tag';
import {Mutation, withApollo} from "react-apollo";
import Loading from "../../components/Loading";
import {withRouter} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ReactDOM from "react-dom";
import ExpiringAlert from "../../components/ExpiringAlert";


const SAVE_USERS = gql`
    mutation saveUsers($data: [UserExtInput]) {
        saveUsers(data: $data) {
            username
            userDetails {
                communicationChannel
            }
        }
    }
`;

const communicationChannels = {
    POST: 'Post',
    ONLINE: 'Online Only',
    PUSH : 'E-mail push',
    PULL: 'E-mail pull'
}

const normalizeChannel = (chanel) => {
    chanel = chanel.toLowerCase().replace(/\W/g, '').trim();

    if (chanel.includes('post')) {
        return communicationChannels.POST;
    } else if (chanel.includes('online')) {
        return communicationChannels.ONLINE;
    } else if (chanel.includes('mail') && chanel.includes('push')) {
        return communicationChannels.PUSH;
    } else if (chanel.includes('mail') && chanel.includes('pull')) {
        return communicationChannels.PULL;
    } else {
        return chanel;
    }
}

const normalizedRole = (role) => {
    role = role.toLowerCase().trim();
    if (role === 'useradmin') {
        return role.replace('useradmin', 'userAdmin');
    } else if (role === 'user' || role === 'dataflows' || role === 'system' || role === 'admin' || role === 'billing') {
        return role.toUpperCase();
    }

    if (role.includes('read only')) {
        role.replace('read only', 'ro')
    }
    const roleWords = role.split(" ");
    role = roleWords.map((word) => {
        return word.toLowerCase() === 'ro' ? word.toUpperCase() : word[0].toUpperCase() + word.substring(1);
    }).join(" ");

    return role.trim();
}

//---------------------------------------------------------

const papaparseOptions = {
    header: true,
    dynamicTyping: false,
    skipEmptyLines: true,
    transformHeader: header =>
        header
            .toLowerCase()
            .replace(/\W/g, '_')
            .replace('communicationchannel', 'communicationChannel')
}



class UsersUpload extends Component {
    constructor() {
        super();
        this.state = {
            results: [],
            successCounter: 0,
            incorrectFile: false,
            loading: false
        }
    }

    render() {
        return (
            <div className="busines-parnter-cnt">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('users.upload')}</h1>
                    </Col>
                    <Col className="col-4">
                        <div className="dropdown show">
                            <Button className="pull-right" color="primary"
                                    onClick={() => this.props.history.push('/view/users-admin')}><i
                                className="ion ion-ios-arrow-back"/> {i18n.t('users.back-to-list')}</Button>
                        </div>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <Mutation mutation={SAVE_USERS}>
                        {(saveUsers, {data}) => (
                            <CSVReader
                                cssClass="csv-reader-input btn btn-primary"
                                label={[<i className="fa fa-cloud-upload"></i>, ' Select CSV with Users']}
                                onFileLoaded={async (data, fileName) => {
                                    this.setState({loading: true, results: [], successCounter: 0, incorrectFile: false});
                                    document.querySelector('.csv-input').value = null;

                                    const incorrectFormat = data.find(d => Object.keys(d).length !== 6);

                                    const result = incorrectFormat ? {} : data.map(d => {

                                        const {account, customer, consultant, role, communicationChannel, ...transform} = d;

                                        let orgs = [];

                                        account && orgs.push(...(`${account.trim()}`.split(' ').map(c => {
                                            return c !== '' ? {organization: {id: c.toLowerCase()}} : {};
                                        })));

                                        customer && orgs.push(...(`${customer.trim()}`.split(' ').map(c => {
                                            return c !== '' ? {organization: {name: c.toLowerCase()}} : {};
                                        })));
                                        consultant && orgs.push(...(`${consultant.trim()}`.split(' ').map(c => {
                                            return c !== '' ? {organization: {id: c.toLowerCase()}} : {};
                                        })));
                                        orgs = orgs.filter(element => Object.keys(element).length !== 0)

                                        const allRoles = [];
                                        const roles = role.split(';');
                                        roles.map(r => {
                                            allRoles.push({role: {key: normalizedRole(r)}});
                                        });

                                        return {
                                            ...transform,
                                            communicationChannel: normalizeChannel(communicationChannel),
                                            password: "",
                                            roles: allRoles,
                                            tenant: {id: "total", name: "Total"},
                                            organizations: orgs
                                        }
                                    });

                                    let counter = 0;
                                    if (Object.keys(result).length === 0) {
                                        this.setState({loading: false, incorrectFile: true});
                                    } else {
                                        for (let r of result) {
                                            let res;
                                            try {
                                                res = await saveUsers({variables: {data: [r]}});
                                            } catch (err) {
                                                r.pos = result.indexOf(r) + 2;
                                                r.msg = err.message.substring(err.message.indexOf('\"') + '\"'.length).replace('\"', '');
                                                this.setState({results: [...this.state.results, r]});
                                                counter = --counter;
                                            }
                                            this.setState({loading: false, successCounter: ++counter});
                                        }
                                        ReactDOM.render(<ExpiringAlert
                                            color="success"
                                            message={"File successfully uploaded"}/>, document.getElementById('alert'));
                                    }
                                }}
                                onError={(error) => ReactDOM.render(<ExpiringAlert
                                    color="danger"
                                    message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")))}
                                parserOptions={papaparseOptions}
                                inputId="userUpload"
                                inputStyle={{color: 'red'}}
                                >
                             </CSVReader>
                        )}</Mutation>
                    {this.state.loading && <Loading/>}
                    {this.state.results.length > 0 && <div style={{marginTop: "20px"}}><h3>The following csv lines produced errors:</h3>
                        <BootstrapTable wrapperClasses="table-responsive" data={this.state.results}
                                        keyField="pos"
                                        columns={[{
                                            text: 'Line',
                                            dataField: 'pos'
                                        }, {
                                            text: 'Username',
                                            dataField: 'username'
                                        }, {
                                            text: 'Errors',
                                            dataField: 'msg'
                                        }]}/>
                    </div>}
                    {this.state.successCounter > 0 && <div style={{marginTop: "20px"}}><h3>Successfully inserted records: {this.state.successCounter}</h3></div>}
                    {this.state.incorrectFile  && <div style={{paddingTop: "20px"}}><h3>Incorrect File Format, please check that required items are populated and multiple Accounts or Customer or Brokers are separated by spaces</h3></div>}
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(UsersUpload));
