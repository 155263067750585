import React, {Component} from 'reactn';
import {Mutation, Query} from 'react-apollo';
import {createTaskCommentMutation, getTaskCommentsQuery} from "../../queries/Queries";
import {Col, Row} from "reactstrap";
import Form from "react-jsonschema-form";
import commentschema from "../../schemas/taskcomment.schema.json";
import Loading from "../Loading";
import Textarea from "react-textarea-autosize";
import ReactDOM from "react-dom";
import ModalDialog from "../ModalDialog";
import i18n from "../../views/Pages/Login/i18n";
import {Button} from "reactstrap";

class TasksComments extends Component {
    constructor(props){
        super(props);
        this.state = {comment:""};
    }

    render() {
        const taskReadonly = !this.props.task ||  this.props.task.variables.status === 'Closed' || this.global.permissions.includes('Users.ReadOnly');
        const service = this.props.task.service ? this.props.task.service : 'Camunda';
        return this.props.task
            ? <div className="task-comments-cnt">
                <h6>Comments</h6>

                {!taskReadonly && <Mutation mutation={createTaskCommentMutation} refetchQueries={[{
                    query: getTaskCommentsQuery,
                    variables: {taskId: this.props.task.id, service: service},
                }]} onCompleted={() => {ReactDOM.render(<ModalDialog title={i18n.t('taskComment.successfulCreateTitle')}
                                                                     text={i18n.t('taskComment.successfulCreate')}
                                                                     opened={true}
                                                                     closeLabel={"Close"}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                    this.forceUpdate();
                }}>
                    {(createTaskComment, {data}) => (<Form schema={commentschema}
                                                           uiSchema={{
                                                               "ui:widget": (props) => {
                                                                   return <div className="comments-text-area"><div className="user">
                                                                       <img src={`${CORE_URL}/avatar/${this.global.username}`}/>
                                                                   </div>
                                                                       <Textarea value = {
                                                                           typeof props.value === "undefined" ? "" : props.value
                                                                       }
                                                                                 placeholder = "Post a comment..."
                                                                                 onChange = {
                                                                                     (event) => {
                                                                                         props.onChange(event.target.value)
                                                                                     }
                                                                                 }
                                                                       /></div>
                                                               },
                                                               "classNames": 'comments-post-cnt',
                                                           }}
                                                           formData={this.state.comment}
                                                           ref={"form"}
                                                           onSubmit={({formData}) => {
                                                               createTaskComment({
                                                                   variables: {
                                                                       taskId: this.props.task.id,
                                                                       comment: {message: formData},
                                                                       service: service,
                                                                   }
                                                               });
                                                               this.setState({comment:""});
                                                               return true;
                                                           }}
                    >
                        <div className="buttons">
                            <button type="submit" className="btn btn-primary">Comment</button>
                            <Button type="reset" className="btn btn-secondary" onClick={() => {this.setState({comment:""})}}>Cancel</Button>
                        </div>
                    </Form>)
                    }
                </Mutation>
                }

                <Query query={getTaskCommentsQuery} variables={{taskId: this.props.task.id, service: service}} fetchPolicy='cache-and-network'>
                {({ loading, error, data }) => {
                    if (loading) return (<Loading/>);
                    if (error) return `Error! ${error.message}`;
                    if(!data || !data.getTaskComments || !Array.isArray(data.getTaskComments) || !data.getTaskComments.length) return null;

                    return (
                        data.getTaskComments.map(comment =>
                            <Row className="comments-list">
                                <Col  className="avatar"><img src={`${CORE_URL}/avatar/${comment.author}`}/></Col>
                                <Col >
                                    <Row>
                                        <Col className="comment-info">{comment.author} <span className="date">{comment.time ? new Date(comment.time).toLocaleDateString('en-GB') : ""}</span></Col>
                                        {/*<Col className="moredetails pull-right"><i class="icon ion-ios-more"></i></Col>*/}
                                    </Row>
                                    <Row className="message"><Col>{comment.message && comment.message.split('\n').reduce((r, a) => r.concat(a, <br/>), [<br/>]).splice(1) }</Col></Row></Col>
                            </Row>
                         )
                    );
                }}
                </Query>

            </div>
            : "";
    }
}




export default TasksComments;
