import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import i18n from "../../views/Pages/Login/i18n";
import {dateFormatter, productFormatter} from "../../utils/Formatters";
import QueryDataGrid from "../QueryDataGrid";


class ContractsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const id = this.props.match.params.id;
        return (<Row>
            <Col sm="12">
                <QueryDataGrid dataPath={'businessPartner.0.mDMContractList'} keyField={'documentnumber'} query={`
                query businessPartnerQuery {
                    businessPartner(startRow: 0, offset: 100, where: "id = '${id}'", orderBy: "name") {
                        mDMContractList(startRow: 0, offset: 100) {
                            id
                            documentnumber
                            signdate
                            expirationdate
                            offerDate
                            mDMContractStatus{
                                name
                            }
                            marketingProduct {
                                name
                                productBOMList {
                                    bOMProduct {
                                        name
                                    }
                                }
                            }

                            noteList{
                                id
                                note
                                creationDate
                                updated
                                createdBy{
                                    name
                                }
                                updatedBy{
                                    name
                                }
                            }
                            attachmentList{
                                id
                                name
                                creationDate
                                updated
                                text
                                createdBy{
                                    name
                                }
                                updatedBy{
                                    name
                                }
                            }
                        }
                    }
                }
            ` }
                    columns={[{
                        dataField: 'documentnumber',
                        text: i18n.t('contract.number'),
                        sort: true
                    }, {
                        dataField: 'marketingProduct.name',
                        text: i18n.t('contract.name'),
                        sort: true
                    }, {
                        dataField: 'signdate',
                        text: i18n.t('contract.startDate'),
                        formatter: dateFormatter,
                        sort: true
                    }, {
                        dataField: 'expirationdate',
                        text: i18n.t('contract.endDate'),
                        formatter: dateFormatter,
                        sort: true
                    }, {
                        dataField: 'mDMContractStatus.name',
                        text: i18n.t('contract.status'),
                        sort: true
                    }, {
                        dataField: 'marketingProduct.productBOMList',
                        text: i18n.t('contract.startDate'),
                        formatter: productFormatter,
                        sort: true
                    }, {
                        dataField: 'dummy1',
                        text: '',
                        formatter: (cell, row) => {
                            return row.noteList.length > 0 ? <i className="icon ion-android-document" title={"Notes"}></i> : null
                        }
                    }, {
                        dataField: 'dummy2',
                        text: '',
                        formatter: (cell, row) => {
                            return row.attachmentList.length > 0 ? <i className="icon ion-android-attach" title={"Attachments"}></i> : null
                        }
                    }]}
                   defaultSorted={{
                       dataField: 'signdate',
                       order: 'desc'
                   }}
                    filters={[
                        {
                            type: 'text',
                            fields: ['documentnumber', 'marketingProduct.name'],
                            placeholder: "Search by document number and product",
                            classes: "search",
                            cols: 5
                        },
                        {
                            type: 'date',
                            fields: ['signdate'],
                            cols: 5
                        },
                        {
                            type: 'dropdown',
                            fields: ['mDMContractStatus.name'],
                            placeholder: "All Statuses",
                            cols: 2
                        }
                    ]}
                    onSelectOpenPage={"contract"}
                />
            </Col>
        </Row>);
    }
}

export default ContractsTable;
