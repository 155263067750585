import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import SimpleDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import "../../../scss/total/_datepicker.scss";

function DatePicker(props) {

    const {
        id,
        options,
        placeholder,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        formContext,
    } = props;

    const _onChange = (value) => {
        return onChange(value ? value.format("YYYY-MM-DD") : options.emptyValue);
    };

    return (

        <SimpleDatePicker
            id="smpDatePicker"
            selected={value ? moment(value) : null}
            onChange={_onChange}
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            popperPlacement="right-end"
            dateFormat={options.dateFormat || "DD/MM/YYYY"}
            filterDate = {(date) => {
                return moment() > date;
            }}
        />
    )
}

DatePicker.defaultProps = {
    autofocus: false,
    options: {},
};

if (process.env.NODE_ENV !== "production") {
    DatePicker.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default DatePicker;
