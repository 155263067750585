import JSXEditor from './JSXEditor';
import MonthPicker from "./MonthPicker";
import DatePicker from "./DatePicker";
import QueryPicker from "./QueryPicker";

const widgets = {
    jsxEditor : JSXEditor,
    monthPicker: MonthPicker,
    datePicker: DatePicker,
    queryPicker: QueryPicker
}

export default widgets;
