import React, { Component } from 'react';
import {withApollo} from 'react-apollo';
import NotesAndAttachments from "../NotesAndAttachments";
import { Query } from "react-apollo";
import _ from "underscore";
import gql from "graphql-tag";
import i18n from "../../views/Pages/Login/i18n";
import Loading from "../Loading";
import {withRouter} from "react-router-dom";
import BasicQueryComponent from "../BasicQueryComponent";


class QueryNotesAndAttachments extends BasicQueryComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }



    render() {
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }

        const q = gql`${this.props.queries[this.props.queryKey]}`;
        return (

            <Query query={q} variables={variables} >
                {(result) => {
                    if (result.loading) return (<Loading />);//<Loading/>;
                    if (result.error) return <div>{result.error} </div>;
                    const { data } = result;
                    if(Object.keys(data).length == 0){
                        // setTimeout(() => { this.props.client.resetStore(); }, 0);
                    }
                    const { children } = this.props;
                    const { queries, queryKey, title, ...newProps } = this.props;
                    const dataObj = this.getFieldValue(data, this.props.dataPath);
                    return (<NotesAndAttachments
                        noteList={dataObj.noteList}
                        attachmentList={dataObj.attachmentList}
                        recordId={dataObj.id}
                        tableName={this.props.tableName}
                        refetchQueries={[{query: q, variables: variables}]}/>)
                }}

            </Query>
        );
    }
}

export default withApollo(withRouter(QueryNotesAndAttachments));