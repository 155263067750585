import React from 'react';
import { graphql, withApollo } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from 'react-router';
import { Query } from 'react-apollo';
import i18n from '../../views/Pages/Login/i18n';
import Loading from "../../components/Loading"
import BasicQueryComponent from "../BasicQueryComponent";
import _ from "underscore";
import {
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row
} from 'reactstrap';
import ServiceHeader from "../ServiceHeader";

class BusinessPartnerHeader extends BasicQueryComponent {

    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {

        let variables;

        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        }

        else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }

        return (
            <Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>
                {result => {

                    if (result.loading) return (<Loading />);
                    if (result.error) return <div>{result.error} </div>;

                    const { data } = result;

                    if (Object.keys(data).length == 0) {
                        this.props.client.query({ query: gql`${this.props.queries[this.props.queryKey]}`, variables: variables });
                        return <Loading />
                    }

                    const dataObj = this.getFieldValue(data, this.props.dataPath);

                    return (
                        <div className='bp busines-parnter-cnt'>
                            <Row className='subheader'>
                                <Col lg="8" xs="12">
                                    <h1>{dataObj.name}</h1>
                                </Col>
                                <Col lg="4" xs="12">
                                    {!this.global.permissions.includes('Users.ReadOnly') && !this.props.hideMenu && !this.props.showServices && (
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} >
                                            <DropdownToggle className='pull-right' color='primary'>
                                                <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('header.new')}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => this.props.history.push('/readsUpload/All')}>Bulk Reading</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    )}
                                    {this.props.showServices && <ServiceHeader />}
                                </Col>
                            </Row>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default withApollo(withRouter(BusinessPartnerHeader));