import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {ButtonDropdown, DropdownToggle, DropdownMenu,DropdownItem,Col,Row} from 'reactstrap';
import { withRouter } from 'react-router';
import {graphql, withApollo, compose, Mutation} from "react-apollo";
import {deleteUserMutation, unlockUserMutation, getUsers} from "../../queries/Queries";
import ExpiringAlert from "../ExpiringAlert";
import i18n from "../../views/Pages/Login/i18n";

class MoreInfoButton extends Component {

    constructor(arg) {
        super(arg)
        this.state = {dropdownOpen : false};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    render() {
        return (<ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle>
                ...
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={()=>this.props.history.push(this.props.editUrl+this.props.user.username)}>Edit</DropdownItem>

                { this.props.user.locked && <Mutation mutation={unlockUserMutation} variables={{username : this.props.user.username}} refetchQueries={[{query: getUsers}]}>
                    { unlockUser =>
                        <DropdownItem onClick={()=>{
                            unlockUser().then(({ data }) => {
                                console.log('got data', data);
                                ReactDOM.render(<ExpiringAlert color="success" message={ i18n.t('users.userUnlocked') } />, document.getElementById('alert'));
                            }).catch((error) => {
                                ReactDOM.render(<ExpiringAlert color="danger" message={error.message} />, document.getElementById('alert'));
                                console.log('there was an error sending the query', error);
                            });
                        }}>Unlock</DropdownItem>
                    }
                </Mutation>
                }
                <DropdownItem onClick={()=>{
                    if(confirm("Are you sure?")){

                        this.props.deleteUserMutation({
                            variables: {
                                username: this.props.user.username,
                            },
                            refetchQueries :[{query: getUsers}]
                        }).then(({ data }) => {
                            console.log('got data', data);
                            ReactDOM.render(<ExpiringAlert color="success" message={ i18n.t('users.userDeleted') } />, document.getElementById('alert').appendChild(document.createElement("div")));
                        }).catch((error) => {
                            ReactDOM.render(<ExpiringAlert color="danger" message={error.message} />, document.getElementById('alert').appendChild(document.createElement("div")));
                            console.log('there was an error sending the query', error);
                        });
                    }
                }}>Delete</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>);
    }
}

export default graphql(deleteUserMutation, { name: 'deleteUserMutation' }) (withRouter(MoreInfoButton));