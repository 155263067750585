import React, { Component } from "react";
import { Query } from 'react-apollo';
import gql from "graphql-tag";
import {withRouter} from "react-router-dom";
import _ from "underscore";

import InfoCard from "../InfoCard";
import i18n from '../../views/Pages/Login/i18n';
import {dateFormatter} from "../../utils/Formatters";
import Loading from "../Loading"

import jsonata from "./../../../node_modules/jsonata/jsonata-es5"
import QueryBillsTab from "../QueryBillsTab";
import BasicQueryComponent from "../BasicQueryComponent";
import {withApollo} from "react-apollo";

class QueryBillsTabWrapper extends BasicQueryComponent {

    constructor(props) {
        super(props);
        this.setParents = this.setParents.bind(this);
        this.dateFormatter = dateFormatter.bind(this);
    }

    render() {
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }

        return (<Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>
            {(result) => {
                if (result.loading) return (<Loading />);//<Loading/>;
                if (result.error) return <div>{result.error} </div>;
                const { data } = result;

                if(Object.keys(data).length == 0){
                    // setTimeout(() => { this.props.client.resetStore(); }, 0);
                }
                let expr = null;
                if (this.props.expression) {
                    const compiled = _.template(this.props.expression);
                    expr = compiled(this.props.match.params);
                }
                const expression = expr ? jsonata(expr) : null;
                const transformedData = expression ? expression.evaluate(data) : data;
                const dataWithParents = this.setParents(transformedData, []);

                const { children } = this.props;
                const { queries, queryKey, variables, title, ...newProps } = this.props;
                const dataObj = this.getFieldValue(data, this.props.dataPath);
                return (<QueryBillsTab {...newProps} title={i18n.t(title)} businessPartner={dataObj} />)
            }}</Query>);
    }
}
export default withApollo(withRouter(QueryBillsTabWrapper));
