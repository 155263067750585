import React, {Component} from 'react';
import JsxParser from 'react-jsx-parser';
import InfoCard from '../../components/InfoCard';
import QueryDataGrid1 from "../../components/QueryDataGrid1";
import {withRouter} from 'react-router';
import jsonata from '../../../node_modules/jsonata/jsonata-es5';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Nav,
    NavItem,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import NavTabs from "../../components/NavTabs";
import * as formatters from "../../utils/Formatters";
import i18n from '../../views/Pages/Login/i18n';
import ProcessesTable from "../ProcessesTable";
import QueryInfoCard from "../QueryInfoCard";
import QueryForm from "../QueryForm";
import TableHeader from "../../components/TableHeader";
import BillsTab from "../../components/BillsTab";
import QueryBillsTabWrapper from "../QueryBillsTabWrapper";
import QueryProcessesTabWrapper from "../QueryProcessesTabWrapper";
import ReadingsTab from "../../components/ReadingsTab";
import QueryNotesAndAttachments from "../QueryNotesAndAttachments";
import QueryBalance from "../QueryBalance";
import QueryLink from "../QueryLink";
import QueryBillsDetail from "../QueryBillsDetail";
import BusinessPartnerHeader from "../BusinessPartnerHeader";
import SiteHeader from "../SiteHeader";
import StartProcess from "../StartProcess";
import Tasks from "../../views/Tasks";
import PaginatedQueryDataGrid from "../PaginatedQueryDataGrid";
import {Query} from "react-apollo";
import ConfigurableReport from "../ConfigurableReport";
import ConfigurableReports from "../ConfigurableReports";
import QueryWrapper from "../QueryWrapper";
import DisplayValue from "../DisplayValue";
import * as reCharts from "recharts";
import DynamicLineChart from "../DynamicLineChart";
import ExportChartButton from "../ExportChartButton";
import SimpleDataGrid from "../SimpleDataGrid/SimpleDataGrid";
import CustomAreaChart from "../CustomAreaChart";
import ScheduledReports from "../ScheduledReports";
import ServiceHeader from "../ServiceHeader";
import AccessWrapper from "../AccessWrapper";

class GenericView extends Component {

    constructor(props) {
        super(props);
        this.getLayoutByName = this.getLayoutByName.bind(this);
        const layout = this.getLayoutByName(this.props.layout || this.props.match.params.name);
        this.queries = this.getQueries(layout);

    }

    getLayoutByName(layoutName){
        return this.props.configuration.uiLayouts.reduce( (result, current) => {
            if (current.name === layoutName) {
                result = current;
            }
            return result;
        });
    }

    getQueries(){

        const result = {};
        const uiQueries = this.props.configuration.uiQueries;
        for (let i=0; i<uiQueries.length; i++) {
                result[uiQueries[i].name] = uiQueries[i].query;
        }
        return result;
    }

    render() {
        if (this.props.not) {
            return (
                <Switch>
                <Route exact={true} path={"/view/:name" + this.props.path} render={(props) => {
                    return null;
                }}/>
                <Route render={(props) => {
                    const {path, not, ...newProps} = this.props;
                    return (<GenericView {...newProps} configuration={this.props.configuration} />);
                }}/>
                </Switch>
            )
        } else if (this.props.path) {
            return (
                <Route path={"/view/:name" + this.props.path} exact={true} render={(props) => {
                    const {path, ...newProps} = this.props;
                    return (<GenericView {...newProps} configuration={this.props.configuration}/>)
                }}/>
            )
        } else if (this.props.expr && this.props.data) {

            const expression = this.props.expr ? jsonata(this.props.expr) : null;
            const isValid = expression ? expression.evaluate(this.props.data) : null;

            return isValid
                ? (
                    <Switch>
                        <Route render={() => {
                            const {expr, data, ...newProps} = this.props;
                            return (<GenericView {...newProps} configuration={this.props.configuration} />);
                        }}/>
                    </Switch>
                )
                : (
                    <Route exact={true} path={"/view/:name" + this.props.path} render={() => null}/>
                );
        }

        const layout = this.getLayoutByName(this.props.layout || this.props.match.params.name);
        const queries = this.getQueries(layout);
        const configuration = this.props.configuration;

        const {ResponsiveContainer, ...re}  = {...reCharts};

        return (<JsxParser
            bindings={{...this.props,
                global: this.global,
                queries: queries,
                configuration: configuration,
                i18n: i18n,
                ...formatters
            }}
            components={{Dropdown, DropdownToggle, DropdownItem, DropdownMenu,
                Row, Col, Nav, NavItem, TabContent,
                TabPane, InfoCard, Card, CardText,
                Link, Switch, Route, Redirect,
                QueryDataGrid1, Form, FormGroup, NavTabs,
                ProcessesTable, QueryForm, QueryInfoCard,
                GenericView, TableHeader, BillsTab, ReadingsTab,
                QueryNotesAndAttachments, QueryBillsTabWrapper, QueryBalance,
                QueryProcessesTabWrapper, QueryBillsDetail, QueryLink, BusinessPartnerHeader, SiteHeader, ServiceHeader,
                Tasks, PaginatedQueryDataGrid, AccessWrapper,
                StartProcess,
                CardHeader, CardTitle, CardBody, Button, Query,
                ConfigurableReport, ConfigurableReports, QueryWrapper, DisplayValue, DynamicLineChart, ...re, ExportChartButton, SimpleDataGrid, CustomAreaChart, ScheduledReports}}
            jsx={layout.jsx}
            showWarnings={true}
        />)
    }
}

export default withRouter(GenericView)
