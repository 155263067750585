import React, {Component} from 'react';

class DisplayValue extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        var res = this.props.path.split('.').reduce(function(o, k) {
            return o && o[k];
        }, this.props.data);
        return (<span>{this.props.formatter ? this.props.formatter(res, res, null, this.props.formatExtraData) : res}</span>);
    }
}

export default DisplayValue;
