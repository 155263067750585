import React, { Component } from 'react';
import i18n from "../../views/Pages/Login/i18n";
import LoadingImage from "../../images/loading.svg"

class Loading extends Component {
  render() {
    return (
      <div className="loading"><img src={LoadingImage}/>{i18n.t('loading')}</div>
    )
  }
}

export default Loading;
