import React from 'react';
import { Query, withApollo } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from 'react-router';
import i18n from '../../views/Pages/Login/i18n';
import Loading from "../../components/Loading"
import CreateReading from "../../total/views/CreateReading";
import CreateReadingPower from "../../total/views/CreateReading/CreateReadingPower";
import BasicQueryComponent from "../BasicQueryComponent";
import _ from "underscore";
import {
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';
import CreateTask from "../../views/CreateTask";
import jsonata from "jsonata/jsonata-es5";
import { validateMeterPoints } from '../../utils/Helpers';

class SiteHeader extends BasicQueryComponent {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleReadingModal = this.toggleReadingModal.bind(this);
        this.toggleTaskModal = this.toggleTaskModal.bind(this);
        this.state = {
            dropdownOpen: false,
            showCreateReadingModal: false,
            showCreateTaskModal: false
        };
    }


    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleReadingModal() {
        this.setState({
            showCreateReadingModal: !this.state.showCreateReadingModal
        });
        if (window.gtag) {
            let pagename = ''
                if(document.location.href.split('/').length>7){
                    pagename = document.location.href.split('/')[5] +' '+ document.location.href.split('/')[7]
                }else{
                    pagename = document.location.href.split('/').pop()
                }
                pagename = pagename.charAt(0).toUpperCase() + pagename.slice(1) + ' ' + "Enter Read"
                const path = document.location.href + '/tab_' + 'enter_read';
                gtag('event', 'page_view', {
                    page_title: pagename,
                    page_location: path,
                    page_path: path,
                })
        }


    }

    toggleTaskModal() {
        this.setState({
            showCreateTaskModal: !this.state.showCreateTaskModal
        });
        if (window.gtag) {
            let pagename = ''
                if(document.location.href.split('/').length>7){
                    pagename = document.location.href.split('/')[5] +' '+ document.location.href.split('/')[7]
                }else{
                    pagename = document.location.href.split('/').pop()
                }
                pagename = pagename.charAt(0).toUpperCase() + pagename.slice(1) + ' ' + "Enter Query"
                const path = document.location.href + '/tab_' + 'enter_query';
                gtag('event', 'page_view', {
                    page_title: pagename,
                    page_location: path,
                    page_path: path,
                })
        }

    }

    render() {
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }
        return (
            <Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>
                {(result) => {

                    function getNested(obj, ...args) {
                        return args.reduce((obj, level) => obj && obj[level], obj)
                    }

                    if (result.loading) return (<Loading />);//<Loading/>;
                    if (result.error) return <div>{result.error} </div>;
                    const { data } = result;
                    if (Object.keys(data).length === 0) {
                        this.props.client.query({
                            query: gql`${this.props.queries[this.props.queryKey]}`,
                            variables: variables
                        });
                        return (<Loading />);
                    }
                    let expr = null;
                    if (this.props.expression) {
                        const compiled = _.template(this.props.expression);
                        expr = compiled(this.props.match.params);
                    }
                    const expression = expr ? jsonata(expr) : null;
                    const transformedData = expression ? expression.evaluate(data) : data;
                    const dataWithParents = this.setParents(transformedData, []);

                    const { children } = this.props;
                    const { queries, queryKey, variables, title, ...newProps } = this.props;
                    const dataObj = this.getFieldValue(data, this.props.dataPath);

                    let showActions = false;
                    let accountNumber;
                    let accountOrganization;

                    const accounts = [];
                    const meterPoints = [""];
                    const meterPointsData = [];
                    const sites = [];

                    let meterPoint;
                    let customer;
                    let siteName;
                    let customerName;

                    if (Array.isArray(dataObj)) {

                        for (let j = 0; j < dataObj.length; j++) {
                            if (dataObj[j].parentProperty === null) {
                                meterPoint = getNested(dataObj[j], "site", "meterPoints", "0");
                                customer = getNested(dataObj[j], "site", "meterPoints", "0", "contract", "account", "customer", "id");

                                accountNumber = dataObj[j].account.number;
                                accountOrganization = dataObj[j].account.organization;
                                accounts.push(getNested(dataObj[j], "account", "number"));

                                siteName = dataObj[j].name;
                                customerName = dataObj[j].account.customer.name;
                                
                                sites.push(dataObj[j].site);
                                if (dataObj[j].status === 'Active') {
                                    showActions = true;
                                }
                            }

                            if (dataObj[j]) for (const i in dataObj[j].site.meterPoints) {
                                meterPoints.push(getNested(dataObj[j], "site", "meterPoints", i, "identifier"));
                                let currMp = (getNested(dataObj[j], "site", "meterPoints", i));
                                currMp.startDate = dataObj[j].startDate;
                                currMp.endDate = dataObj[j].endDate;
                                meterPointsData.push(currMp);
                            }
                            if (dataObj[j]) for (const i in dataObj[j].properties) {
                                meterPoints.push(getNested(dataObj[j], "properties", i, "site", "meterPoints", 0, "identifier"));
                                let currMp = getNested(dataObj[j], "properties", i, "site", "meterPoints", 0);
                                currMp.startDate = getNested(dataObj[j], "properties", i).startDate;
                                currMp.endDate = getNested(dataObj[j], "properties", i).endDate;
                                meterPointsData.push(currMp);
                            }
                        }
                    } else {
                        if (dataObj) {
                            siteName = dataObj.name;
                            customerName = dataObj.account.customer.name;
                            sites.push(dataObj.site);
                            accountNumber = dataObj.account.number;
                            accountOrganization = dataObj.account.organization;
                            // if (dataObj.status !== 'Inactive') {
                            //     showActions = true;
                            // }
                            if (dataObj.status === 'Active') {
                                showActions = true;
                                accounts.push(getNested(dataObj, "account", "number"));
                            }

                            meterPoint = getNested(dataObj, "site", "meterPoints", "0");
                            customer = getNested(dataObj, "site", "meterPoints", "0", "contract", "account", "customer", "id");
                        }

                        if (dataObj) for (const i in dataObj.site.meterPoints) {
                            meterPoints.push(getNested(dataObj, "site", "meterPoints", i, "identifier"));
                            let currMp = (getNested(dataObj, "site", "meterPoints", i));
                            currMp.startDate = dataObj.startDate;
                            currMp.endDate = dataObj.endDate;
                            meterPointsData.push(currMp);
                        }
                        if (dataObj) for (const i in dataObj.properties) {
                            meterPoints.push(getNested(dataObj, "properties", i, "site", "meterPoints", 0, "identifier"));
                            let currMp = getNested(dataObj, "properties", i, "site", "meterPoints", 0);
                            currMp.startDate = getNested(dataObj, "properties", i).startDate;
                            currMp.endDate = getNested(dataObj, "properties", i).endDate;
                            meterPointsData.push(currMp);
                        }
                    }

                    let type = null;

                    if (sites && sites.length && sites[0].meterPoints && sites[0].meterPoints.length) {
                        type = sites[0].meterPoints[0].purpose;
                    }

                    customerName = customerName && customerName.trim();
                    siteName = siteName && siteName.trim();

                    return dataObj ? (
                        <div className='bp busines-parnter-cnt'>
                            <Row className='subheader'>
                                <Col lg="8" xs="12">
                                    <h1>{customerName || ""}</h1>
                                </Col>
                                <Col lg="4" xs="12">
                                    {!this.global.permissions.includes('Users.ReadOnly') && showActions && ((Array.isArray(dataObj) && jsonata("$boolean(account.status='A')").evaluate(dataObj[0])) || jsonata("$boolean(account.status='A')").evaluate(dataObj)) &&
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                            <DropdownToggle className='pull-right' color='primary'>
                                                <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('header.new')}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {( type === 'Gas' || validateMeterPoints(meterPointsData) > 0) && (
                                                    <DropdownItem onClick={() => this.setState({ showCreateReadingModal: true })}>
                                                        Reading
                                                    </DropdownItem>
                                                )}
                                                <DropdownItem onClick={() => this.props.history.push(`/readsUpload/${type || "All"}`)}>
                                                    Bulk Reading
                                                </DropdownItem>
                                                <DropdownItem onClick={() => this.setState({ showCreateTaskModal: true })}>
                                                    Query
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    }
                                </Col>
                            </Row>

                            { type === "Gas"
                                ? (
                                    <Modal size={"large"} toggle={this.toggleReadingModal} isOpen={this.state.showCreateReadingModal} style={{ "maxWidth": "500px", "width": "90%" }}>
                                        <ModalHeader toggle={this.toggleReadingModal}>Create Reading</ModalHeader>
                                        <ModalBody>
                                            <CreateReading
                                                estateList={sites}
                                                account={accountNumber}
                                                toggle={this.toggleReadingModal}
                                                meterPoints={meterPointsData}
                                            />
                                        </ModalBody>
                                    </Modal>
                                )
                                : type === 'Power' || type === 'Electricity'
                                    ? (
                                        <Modal size={"large"} toggle={this.toggleReadingModal} isOpen={this.state.showCreateReadingModal} style={{ "maxWidth": "1200px", "width": "90%" }}>
                                            <ModalHeader toggle={this.toggleReadingModal}>Create Reading</ModalHeader>
                                            <ModalBody>
                                                <CreateReadingPower
                                                    estateList={sites}
                                                    account={accountNumber}
                                                    accountOrganization={accountOrganization}
                                                    toggle={this.toggleReadingModal}
                                                />
                                            </ModalBody>
                                        </Modal>
                                    ) : null
                            }
                            <CreateTask
                                modalOpen={this.state.showCreateTaskModal}
                                meterPoints={meterPoints}
                                meterPoint={meterPoint}
                                meterPointsData={meterPointsData}
                                onClose={this.toggleTaskModal}
                                customer={customer}
                                accounts={accounts}
                                user={this.props.configuration.global.username}
                            />
                        </div>
                    ) : null
                }}
            </Query>
        );
    }
}

export default withApollo(withRouter(SiteHeader));
