import React, { Component } from 'react';
import {withRouter} from "react-router";
import {graphql, Query} from "react-apollo";
import BootstrapTable from 'react-bootstrap-table-next';
import QueryDataGrid from "../QueryDataGrid";
import i18n from '../../views/Pages/Login/i18n';

import { productFormatter, priceFormatter, dateFormatter, periodFormatter } from "../../utils/Formatters";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardHeader, CardBody, CardText, Row, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import Loading from '../Loading';
import NotesAndAttachments from "../../components/NotesAndAttachments";
import {agreementQuery, getBusinessPartnerQuery, getContractQuery} from "../../queries/Queries"
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import TariffOverview from "../TariffOverview";
import AddressOverview from "../AddressOverview";
import paginationFactory from "react-bootstrap-table2-paginator";
import AgreementOverview from "../AgreementOverview";

class ContractsDetailLocal extends Component {
    constructor(props) {
        super(props);
        this.state = {page:1, sizePerPage:5};
    }
    render() {
        return <div style={{padding:"20px"}} className="agreemen-details"><Query query={agreementQuery} variables={{id: this.props.match.params.contractid}} >
            {({loading, error, data}) => {
                if (loading) return <Loading/>;
                if (error) return `Error! ${error.message}`;
                return (<div className="agreement-details">
                    <Row>
                        <Col sm="12" className="header">
                            <Button onClick={this.props.history.goBack} className="btn btn-default"><i
                                className="icon ion-arrow-left-b"></i>{i18n.t('businessPartner.contract')}:&nbsp;&nbsp;
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <Card>
                                <CardHeader>
                                    <CardTitle>{i18n.t('businessPartner.agreementDetails')}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <Form>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.agreementStatus')}</FormText>
                                                    <Label>{data.getAgreement.status.name}</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.agreementNumber')}</FormText>
                                                    <Label></Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText></FormText>
                                                    <FormText>{i18n.t('contract.tariff')}</FormText>
                                                    <Label>{data.getAgreement.tariffName.name}</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText></FormText>
                                                    <FormText>{i18n.t('businessPartner.agreementDuration')}</FormText>
                                                    <Label>{data.getAgreement.contractDuration}</Label>
                                                </FormGroup>
                                                {!DYCE && <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.billingAccount')}</FormText>
                                                    <Label></Label>
                                                    {/* TODO OGI - Add Payment method */}
                                                </FormGroup>
                                                }
                                            </Form>
                                        </Col>
                                        <Col>
                                            <Form>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.agreementSignDate')}</FormText>
                                                    <Label></Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText></FormText>
                                                    <FormText>{i18n.t('businessPartner.agreementStartDate')}</FormText>
                                                    <Label></Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText></FormText>
                                                    <FormText>{i18n.t('businessPartner.agreementEndDate')}</FormText>
                                                    <Label></Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.paymentMethod')}</FormText>
                                                    <Label>{data.getAgreement.paymentMethod}</Label>
                                                    {/* TODO OGI - Add Payment method */}
                                                </FormGroup>
                                                {!DYCE && <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.billingAccountStatus')}</FormText>
                                                    <Label></Label>
                                                    {/* TODO OGI - Add Payment method */}
                                                </FormGroup>
                                                }
                                            </Form>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="8">
                            <Card>
                                <CardHeader>
                                    <CardTitle>{i18n.t('businessPartner.contractProducts')}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div  className="invoice-table">
                                        <BootstrapTable
                                            keyField={'id'}
                                            data={[{name:'Gas', unit:'kWh', standardPrice: data.getAgreement.tariffName.unitRate/100},
                                                {name:'Standing charges Gas', unit:'day', standardPrice: data.getAgreement.tariffName.sc/100},
                                                {name:'Gas Commission', unit:'kWh', standardPrice: data.getAgreement.products.reduce((acc, curr) => curr.name === 'Gas' ? curr : null).commissionUnitRate/100},
                                                {name:'Standing charges Gas Commission', unit:'day', standardPrice: data.getAgreement.products.reduce((acc, curr) => curr.name === 'Gas' ? curr : null).commissionStandingCharge/100}]}
                                            columns={[ {
                                                dataField: 'name',
                                                text: i18n.t('product.name'),
                                                sort: true
                                            }, {
                                                dataField: 'unit',
                                                text: i18n.t('product.unit'),
                                                sort: true
                                            }, {
                                                dataField: 'standardPrice',
                                                text: i18n.t('price'),
                                                sort: true,
                                                align: "right",
                                                headerAlign: "right",
                                                formatter: priceFormatter
                                            }]}
                                            pagination={paginationFactory({
                                                page: this.state.page,
                                                onPageChange: (page, sizePerPage) => {
                                                    this.state.page =  page;
                                                },
                                                sizePerPageList:[ {
                                                    text:"5", value: 5
                                                }, {
                                                    text:"10", value: 10
                                                }, {
                                                    text:"20", value: 20
                                                } ]
                                            })}
                                            bordered={false}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/*<Row className='step'>*/}
                        {/*{!DYCE ? <div><Col className='col-6'>*/}
                                {/*<Accordion className="overview" accordion={false}>*/}

                                    {/*<AccordionItem>*/}
                                        {/*<AccordionItemTitle>*/}
                                            {/*Company information*/}
                                            {/*<i className="icon ion-chevron-down"></i>*/}
                                        {/*</AccordionItemTitle>*/}
                                        {/*<AccordionItemBody>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Business Name</label>*/}
                                                    {/*<p>{data.getAgreement.clientName}</p>*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label class="">Business Type</label>*/}
                                                    {/*<p>{data.getAgreement.businessType}</p>*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label class="">Company Size</label>*/}
                                                    {/*<p>{data.getAgreement.companySize}</p>*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Company Registration Number</label>*/}
                                                    {/*<p>{data.getAgreement.companyNumber}</p>*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                        {/*</AccordionItemBody>*/}
                                    {/*</AccordionItem>*/}
                                    {/*<AccordionItem>*/}
                                        {/*<AccordionItemTitle>*/}
                                            {/*Site information*/}
                                            {/*<i className="icon ion-chevron-down"></i>*/}
                                        {/*</AccordionItemTitle>*/}
                                        {/*<AccordionItemBody>*/}
                                            {/*{data.getAgreement.mprn ? <Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>MPRN</label>*/}
                                                        {/*<p>{data.getAgreement.mprn}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null}*/}
                                            {/*{data.getAgreement.mprn ? <Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>AQ</label>*/}
                                                        {/*<p>{data.getAgreement.aq}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null}*/}
                                            {/*{data.getAgreement.mpan ? <Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label class="">MPAN</label>*/}
                                                        {/*<p>{data.getAgreement.mpan}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null}*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Site address</label>*/}
                                                    {/*<AddressOverview address={data.getAgreement.supplyAddress} />*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Primary address</label>*/}
                                                    {/*<AddressOverview address={data.getAgreement.primaryAddress} />*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Primary contact</label>*/}
                                                    {/*<ContactOverview contact={data.getAgreement.primaryContact} />*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*{DYCE*/}
                                                    {/*? <Col className="col-sm-6">*/}
                                                        {/*<label>Credit check address</label>*/}
                                                        {/*<AddressOverview address={data.getAgreement.creditCheckAddress} />*/}
                                                    {/*</Col>*/}
                                                    {/*: <Col className="col-sm-6">*/}
                                                        {/*<label>Secondary address</label>*/}
                                                        {/*<AddressOverview address={data.getAgreement.secondaryAddress} />*/}
                                                    {/*</Col>*/}
                                                {/*}*/}
                                                {/*{DYCE*/}
                                                    {/*? <Col className="col-sm-6">*/}
                                                        {/*<label>Credit check contact</label>*/}
                                                        {/*<ContactOverview contact={data.getAgreement.creditCheckContact} />*/}
                                                    {/*</Col>*/}
                                                    {/*: <Col className="col-sm-6">*/}
                                                        {/*<label>Secondary contact</label>*/}
                                                        {/*<ContactOverview contact={data.getAgreement.secondaryContact} />*/}
                                                    {/*</Col>*/}
                                                {/*}*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Billing address</label>*/}
                                                    {/*{data.getAgreement.billingContact*/}
                                                        {/*? (data.getAgreement.billingContact.sameAsPrimary*/}
                                                            {/*? <AddressOverview address={data.getAgreement.primaryAddress} />*/}
                                                            {/*: <AddressOverview address={data.getAgreement.billingAddress} />)*/}
                                                        {/*: null*/}
                                                    {/*}*/}
                                                {/*</Col>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Billing contact</label>*/}
                                                    {/*{data.getAgreement.billingContact*/}
                                                        {/*? (data.getAgreement.billingContact.sameAsPrimary*/}
                                                            {/*?  <ContactOverview contact={data.getAgreement.primaryContact} />*/}
                                                            {/*:  <ContactOverview contact={data.getAgreement.billingContact} />)*/}
                                                        {/*: null }*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*{DYCE ?*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Previous address</label>*/}
                                                        {/*<AddressOverview address={data.getAgreement.previousAddress} />*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null*/}
                                            {/*}*/}
                                            {/*{DYCE ?*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Climate change agreement</label>*/}
                                                        {/*<p>{data.getAgreement.climateChangeAgreement ? 'Yes' : 'No'}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null*/}
                                            {/*}*/}
                                            {/*{DYCE ?*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Change of tenancy</label>*/}
                                                        {/*<p>{data.getAgreement.changeOfTenancy ? 'Yes' : 'No'}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null*/}
                                            {/*}*/}
                                            {/*{DYCE ?*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Change of tenancy date</label>*/}
                                                        {/*<p>{data.getAgreement.changeOfTenancyDate ? new Date(data.getAgreement.changeOfTenancyDate).toLocaleDateString() : null}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null*/}
                                            {/*}*/}
                                        {/*</AccordionItemBody>*/}
                                    {/*</AccordionItem>*/}
                                {/*</Accordion>*/}
                            {/*</Col>*/}
                                {/*<Col className='col-6'>*/}
                                    {/*<Accordion className="overview" accordion={false}>*/}
                                        {/*<AccordionItem>*/}
                                            {/*<AccordionItemTitle>*/}
                                                {/*Payment information*/}
                                                {/*<i className="icon ion-chevron-down"></i>*/}
                                            {/*</AccordionItemTitle>*/}
                                            {/*<AccordionItemBody>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Bank Account Name:</label>*/}
                                                        {/*<p>{data.getAgreement.bankAccountName}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label class="">Sort Code:</label>*/}
                                                        {/*<p>{data.getAgreement.sortCode}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Account Number:</label>*/}
                                                        {/*<p>{data.getAgreement.accountNumber}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Payment Method:</label>*/}
                                                        {/*<p>{data.getAgreement.paymentMethod}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                            {/*</AccordionItemBody>*/}
                                        {/*</AccordionItem>*/}
                                        {/*<AccordionItem>*/}
                                            {/*<AccordionItemTitle>*/}
                                                {/*Acceptance*/}
                                                {/*<i className="icon ion-chevron-down"></i>*/}
                                            {/*</AccordionItemTitle>*/}
                                            {/*<AccordionItemBody>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Marketing consent:</label>*/}
                                                        {/*<p>{data.getAgreement.mail ? 'Mail, ' : ''}*/}
                                                            {/*{data.getAgreement.telephone ? 'Telephone, ' : ''}*/}
                                                            {/*{data.getAgreement.SMS ? 'SMS, ' : ''}*/}
                                                            {/*{data.getAgreement.email ? 'Email' : ''}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label class="">Proposed Supply Start Date:</label>*/}
                                                        {/*<p>{data.getAgreement.startDate ? new Date(data.getAgreement.startDate).toLocaleDateString() : null}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Current Supplier Contract End Date:</label>*/}
                                                        {/*<p>{data.getAgreement.endDate ? new Date(data.getAgreement.endDate).toLocaleDateString() : null}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*{DYCE ?*/}
                                                    {/*<Row>*/}
                                                        {/*<Col className="col-sm-6">*/}
                                                            {/*<label>Letter of Authority Start Date:</label>*/}
                                                            {/*<p>{data.getAgreement.loaStartDate ? new Date(data.getAgreement.loaStartDate).toLocaleDateString() : null}</p>*/}
                                                        {/*</Col>*/}
                                                    {/*</Row>*/}
                                                    {/*: null}*/}
                                                {/*{DYCE ?*/}
                                                    {/*<Row>*/}
                                                        {/*<Col className="col-sm-6">*/}
                                                            {/*<label>Letter of Authority End Date:</label>*/}
                                                            {/*<p>{data.getAgreement.loaEndDate ? new Date(data.getAgreement.loaEndDate).toLocaleDateString() : null}</p>*/}
                                                        {/*</Col>*/}
                                                    {/*</Row>*/}
                                                    {/*: null }*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Sale Type:</label>*/}
                                                        {/*<p>{data.getAgreement.saleType}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                            {/*</AccordionItemBody>*/}
                                        {/*</AccordionItem>*/}
                                    {/*</Accordion>*/}
                                {/*</Col></div>*/}
                            {/*: <div style={{width:"100%"}}><Col className='col-12'>*/}
                                {/*<Card className="overview" accordion={false}>*/}
                                    {/*<CardBody>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Business Name</label>*/}
                                                    {/*<p>{data.getAgreement.clientName}</p>*/}
                                                {/*</Col>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label className="">Business Type</label>*/}
                                                    {/*<p>{data.getAgreement.businessType}</p>*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Company Registration Number</label>*/}
                                                    {/*<p>{data.getAgreement.companyNumber}</p>*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*{data.getAgreement.mprn ? <Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>MPRN</label>*/}
                                                        {/*<p>{data.getAgreement.mprn}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null}*/}
                                            {/*{data.getAgreement.mprn ? <Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>AQ</label>*/}
                                                        {/*<p>{data.getAgreement.aq}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null}*/}
                                            {/*{data.getAgreement.mpan ? <Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label className="">MPAN</label>*/}
                                                        {/*<p>{data.getAgreement.mpan}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null}*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Site address</label>*/}
                                                    {/*<AddressOverview address={data.getAgreement.supplyAddress} />*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Primary address</label>*/}
                                                    {/*<AddressOverview address={data.getAgreement.primaryAddress} />*/}
                                                {/*</Col>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Primary contact</label>*/}
                                                    {/*<ContactOverview contact={data.getAgreement.primaryContact} />*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*{DYCE*/}
                                                    {/*? <Col className="col-sm-6">*/}
                                                        {/*<label>Credit check address</label>*/}
                                                        {/*<AddressOverview address={data.getAgreement.creditCheckAddress}/>*/}
                                                    {/*</Col>*/}
                                                    {/*: <Col className="col-sm-6">*/}
                                                        {/*<label>Secondary address</label>*/}
                                                        {/*<AddressOverview address={data.getAgreement.secondaryAddress}/>*/}
                                                    {/*</Col>*/}
                                                {/*}*/}
                                                {/*{DYCE*/}
                                                    {/*? <Col className="col-sm-6">*/}
                                                        {/*<label>Credit check contact</label>*/}
                                                        {/*<ContactOverview contact={data.getAgreement.creditCheckContact}/>*/}
                                                    {/*</Col>*/}
                                                    {/*: <Col className="col-sm-6">*/}
                                                        {/*<label>Secondary contact</label>*/}
                                                        {/*<ContactOverview contact={data.getAgreement.secondaryContact}/>*/}
                                                    {/*</Col>*/}
                                                {/*}*/}
                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Billing address</label>*/}
                                                    {/*{data.getAgreement.billingContact*/}
                                                        {/*? (data.getAgreement.billingContact.sameAsPrimary*/}
                                                            {/*? <AddressOverview address={data.getAgreement.primaryAddress}/>*/}
                                                            {/*: <AddressOverview address={data.getAgreement.billingAddress}/>)*/}
                                                        {/*: null*/}
                                                    {/*}*/}
                                                {/*</Col>*/}
                                                {/*<Col className="col-sm-6">*/}
                                                    {/*<label>Billing contact</label>*/}
                                                    {/*{data.getAgreement.billingContact*/}
                                                        {/*? (data.getAgreement.billingContact.sameAsPrimary*/}
                                                            {/*? <ContactOverview contact={data.getAgreement.primaryContact}/>*/}
                                                            {/*: <ContactOverview contact={data.getAgreement.billingContact}/>)*/}
                                                        {/*: null}*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            {/*{DYCE ?*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Previous address</label>*/}
                                                        {/*<AddressOverview address={data.getAgreement.previousAddress}/>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*: null*/}
                                            {/*}*/}
                                            {/*{DYCE ?*/}
                                                {/*<div><Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Climate change agreement</label>*/}
                                                        {/*<p>{data.getAgreement.climateChangeAgreement ? 'Yes' : 'No'}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}

                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Change of tenancy</label>*/}
                                                        {/*<p>{data.getAgreement.changeOfTenancy ? 'Yes' : 'No'}</p>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Change of tenancy date</label>*/}
                                                        {/*<p>{data.getAgreement.changeOfTenancyDate ? new Date(data.getAgreement.changeOfTenancyDate).toLocaleDateString() : null}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row></div>*/}
                                                {/*: null*/}
                                            {/*}*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Bank Account Name:</label>*/}
                                                        {/*<p>{data.getAgreement.bankAccountName}</p>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label className="">Sort Code:</label>*/}
                                                        {/*<p>{data.getAgreement.sortCode}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Account Number:</label>*/}
                                                        {/*<p>{data.getAgreement.accountNumber}</p>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Payment Method:</label>*/}
                                                        {/*<p>{data.getAgreement.paymentMethod}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Marketing consent:</label>*/}
                                                        {/*<p>{data.getAgreement.mail ? 'Mail, ' : ''}*/}
                                                            {/*{data.getAgreement.telephone ? 'Telephone, ' : ''}*/}
                                                            {/*{data.getAgreement.SMS ? 'SMS, ' : ''}*/}
                                                            {/*{data.getAgreement.email ? 'Email' : ''}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label className="">Proposed Supply Start Date:</label>*/}
                                                        {/*<p>{data.getAgreement.startDate ? new Date(data.getAgreement.startDate).toLocaleDateString() : null}</p>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Current Supplier Contract End Date:</label>*/}
                                                        {/*<p>{data.getAgreement.endDate ? new Date(data.getAgreement.endDate).toLocaleDateString() : null}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                                {/*{DYCE ?*/}
                                                    {/*<div><Row>*/}
                                                        {/*<Col className="col-sm-6">*/}
                                                            {/*<label>Letter of Authority Start Date:</label>*/}
                                                            {/*<p>{data.getAgreement.loaStartDate ? new Date(data.getAgreement.loaStartDate).toLocaleDateString() : null}</p>*/}
                                                        {/*</Col>*/}
                                                        {/*<Col className="col-sm-6">*/}
                                                            {/*<label>Letter of Authority End Date:</label>*/}
                                                            {/*<p>{data.getAgreement.loaEndDate ? new Date(data.getAgreement.loaEndDate).toLocaleDateString() : null}</p>*/}
                                                        {/*</Col>*/}
                                                    {/*</Row></div>*/}
                                                    {/*: null}*/}
                                                {/*<Row>*/}
                                                    {/*<Col className="col-sm-6">*/}
                                                        {/*<label>Sale Type:</label>*/}
                                                        {/*<p>{data.getAgreement.saleType}</p>*/}
                                                    {/*</Col>*/}
                                                {/*</Row>*/}
                                            {/*</CardBody>*/}
                                    {/*</Card>*/}
                                {/*</Col></div>}*/}
                    {/*</Row>*/}
                    <AgreementOverview agreement={data.getAgreement}/>
                </div>)
            }
            }
        </Query></div>
    }
}


export default withRouter(ContractsDetailLocal);
