import React from 'react';
import ButtonWithModal from '../components/ButtonWithModal';
import axios from "axios/index";
import jsonata from "./../../node_modules/jsonata/jsonata-es5"
import _ from "underscore";
import {Link} from "react-router-dom";
import {queryTypeMapping} from "../schemas/task.schema";
import fileSaver from "file-saver"
import GridDropDownMenu from "../components/GridDropDownMenu";
import CronFormatter from "../components/CronGenerator/CronFormatter";
import PeriodFormatter from "../components/CronGenerator/PeriodFormatter";

export function priceCustomFormatter(cell, row) {
    const findCurrency = function (row) {
        let result = null;
        if (row.currency) {
            result = row.currency;
        } else {
            const properties = Object.keys(row);
            for (let prop of properties) {
                if (!result && (row[prop] instanceof Object || row[prop] instanceof Array)) {
                    result = findCurrency(row[prop]);
                }
            }
        }
        return result;
    }
    const currency = row ? findCurrency(row) : {iSOCode: 'GBP'};
    const signDigits = this.significantDigits ? {
        maximumSignificantDigits: this.significantDigits,
        minimumSignificantDigits: this.significantDigits
    } : {};
    return cell ? cell.toLocaleString(undefined, {
        style: "currency",
        currency: currency && currency.iSOCode ? currency.iSOCode : 'GBP', ...signDigits
    }) : "";
}


export function priceFormatter(cell, row) {
    const findObject = function (row, objectName) {
        let result = null;
        if (row[objectName]) {
            result = row[objectName];
        } else {
            const properties = Object.keys(row);
            for (let prop of properties) {
                if (!result && (row[prop] instanceof Object || row[prop] instanceof Array)) {
                    result = findObject(row[prop], objectName);
                }
            }
        }
        return result;
    };
    const currency = row ? findObject(row, 'currency') : {iSOCode: 'GBP'};
    const subUnit = row ? findObject(row, 'mdmCoins') : 'p';
    const useSubUnit = row ? findObject(row, 'isMdmDisplayCoins') : false;
    if (!useSubUnit) {
        const fractionalDigits = cell && Math.floor(cell) !== cell ? Math.max(("" + Math.round(cell * Math.pow(10, 10)) / Math.pow(10, 10)).split(".")[1].length, 2) : 2;
        return cell ? cell.toLocaleString(undefined, {
            style: "currency",
            currency: currency && currency.iSOCode ? currency.iSOCode : 'GBP',
            minimumFractionDigits: fractionalDigits
        }) : "";
    } else {
        return (cell * Math.pow(10, currency.standardPrecision)).toFixed(2) + subUnit;
    }
}

export function invoicePriceFormatter(cell, row) {
    const findObject = function (row, objectName) {
        let result = null;
        if (row[objectName]) {
            result = row[objectName];
        } else {
            const properties = Object.keys(row);
            for (let prop of properties) {
                if (!result && (row[prop] instanceof Object || row[prop] instanceof Array)) {
                    result = findObject(row[prop], objectName);
                }
            }
        }
        return result;
    };
    const currency = row ? findObject(row, 'currency') : {iSOCode: 'GBP'};
    const subUnit = row ? findObject(row, 'mdmCoins') : 'p';
    const useSubUnit = row ? findObject(row, 'isMdmDisplayCoins') : false;
    const isCreditNote = row ? (findObject(row, 'totalAmount') < 0 ? true : false) : false;
    const totalAmmount = isCreditNote ? -1 * cell : cell;

    if (!useSubUnit) {
        //There is no need of this check.
        //const fractionalDigits = cell && Math.floor(cell) !== cell ? Math.max(("" + Math.round(cell * Math.pow(10, 10)) / Math.pow(10, 10)).split(".")[1].length, 2) : 2; 
        const fractionalDigits = 2;
        const curr = totalAmmount ? totalAmmount.toLocaleString(undefined, {
            style: "currency",
            currency: currency && currency.iSOCode ? currency.iSOCode : 'GBP',
            minimumFractionDigits: fractionalDigits,
            maximumFractionDigits: fractionalDigits
        }) : "";

        return isCreditNote ? 'CR ' + curr : curr;
    } else {
        const ammount = (totalAmmount * Math.pow(10, currency.standardPrecision)).toFixed(2) + subUnit;
        return isCreditNote ? 'CR ' + ammount : ammount;
    }
}

export function dateFormatter(cell, row) {
    return (
        cell ? new Date(cell).toLocaleDateString('en-GB') : null
    );
}

export function datetimeFormatter(cell, row) {
    return (
        cell ? new Date(cell).toLocaleDateString('en-GB') + ' ' + new Date(cell).toLocaleTimeString('en-GB') : null
    );
}


export function periodFormatter(cell, row) {
    return (
        cell ? new Date(cell.startingDate).toLocaleDateString() + ' – ' + new Date(cell.endingDate).toLocaleDateString() : null
    );
}

export function productFormatter(cell, row) {
    return (
        cell.map(item => item.bOMProduct.name).join(", ")
    );
}


export function invoiceEstateFormatter(cell, row) {
    return (
        cell.map(item => item.serviceInstance.estate.consumernumber).join(", ")
    );
}


export function reportedAmountFormatter(cell, row) {
    if (row) {
        var reportedAmount = row.mDMMeterMeasurement && row.mDMMeterMeasurement.reportedamount ? row.mDMMeterMeasurement.reportedamount : 0;
        return reportedAmount + row.product.uOM.name;
    }
}

export function rolesFormatter(cell, row) {
    return row.map(contact => contact.cRMContactRole.name).join(', ');
}

export function linkFormatter(cell, row, rowIndex, formatExtraData) {
    const compile = _.template(formatExtraData[1]);
    return <Link to={compile(row)}>{formatExtraData[0]}</Link>;
}

export function iconFormatter(cell, row, rowIndex, formatExtraData) {
    const image = formatExtraData[0];
    const compile = _.template(formatExtraData[1]);

    let result = '';
    if (image.startsWith("<svg")) {
        result = <Link to={compile(row)}>
            <div style={{height: '20px', width: '20px'}}>
                <img src={`data:image/svg+xml;utf8,${image}`}/>
            </div>
        </Link>;
    } else if (image.endsWith(".svg")) {
        result = <Link to={compile(row)}><img style={{height: '20px', width: '20px'}} src={"/img/categories/" + image} alt="icon"/></Link>;
    } else {
        result = <Link to={compile(row)}><img src={"/img/categories/" + image} alt="text describing the image"/></Link>;
    }
    return result;
}

export function supplyIconFormater(cell,row) {

    if (cell === 'Gas') {
        return (
            <div style={{display: 'flex'}}>
                <img src="/img/total/Gas-Blue.png" width="20px" height="20px" style={{marginRight: '5px'}} />
                <span>{cell}</span>
            </div>
        )
    }
    else if (cell === 'Electricity') {
        return (
            <div style={{display: 'flex'}}>
                <img src="/img/total/Power-Red.png" width="20px" height="20px" style={{marginRight: '5px'}} />
                <span>{cell}</span>
            </div>
        )
    }

    return cell;
}

export function prefCommChannelFormatter(cell, row) {
    return row.cRMCommunicationChannelList.map(channel => channel.preferredChannel).join(', ');
}

export function imageFormatter(cell, row) {
    return <ButtonWithModal id={row.id}/>
}

export function noteIconFormatter(cell, row) {
    return row.noteList.length > 0 ? <i className="icon ion-android-document" title={"Notes"}></i> : null
}

export function attachmentIconFormatter(cell, row) {
    return row.attachmentList.length > 0 ? <i className="icon ion-android-attach" title={"Attachments"}></i> : null
}

export function paymentCompleteFormatter(cell, row) {
    return cell
        ? <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/>
        </svg>
        : null;
}

export function downloadFormatter(cell, row, rowIndex, formatExtraData) {
    return <i className={"icon ion-android-download"} style={{fontSize: "18px", color: "#0DAEFF"}} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        let win = null;
        if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) { // for IE
            win = window.open('', '_blank');
            win.document.write("Loading invoice...")
        }
        ;

        axios.get(`${CORE_URL}/${formatExtraData ? formatExtraData : 'invoice'}/${row.id}`, {
            headers: {'Authorization': "Bearer " + localStorage.token},
            responseType: 'blob'
        }).then((response) => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                window.navigator.msSaveOrOpenBlob(response.data, row.id + '.pdf');
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                fileSaver.saveAs(url, row.documentNo + ".pdf");
            }
        })
    }}>
    </i>
}

export function invoiceDownloadFormatter(cell, row, rowIndex, formatExtraData) {
    return <i className={"icon ion-android-download"} style={{fontSize: "18px", color: "#0DAEFF"}} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        const FileDownload = require('js-file-download');
        axios.get(`/essence-services/essence-services/v1/${formatExtraData ? formatExtraData : 'invoice'}/${row.id}/download`, {
            headers: {'Authorization': "Bearer " + localStorage.token},
            responseType: 'blob'
        }).then((response) => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                window.navigator.msSaveOrOpenBlob(response.data, row.id + '.pdf');
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                fileSaver.saveAs(url, row.id + ".pdf");
            }
        })
    }}>
    </i>
}

export function jsonValueFormatter(cell, row, rowIndex, formatExtraData) {
    return formatExtraData.paths.map(path => {
        let pathArray = path.split('.');
        let value = JSON.parse(cell);
        while (pathArray.length > 0) {
            value = value[pathArray[0]];
            pathArray = pathArray.slice(1);
        }
        return value;
    }).reduce(eval(formatExtraData.reducer), null);
}

export function jsonToStringFormatter(cell, row, rowIndex, formatExtraData) {
    let result = "";
    let keys = formatExtraData.split(",");
    let obj = JSON.parse(cell);
    for (let p in obj) {
        const keyStartsWith = keys.filter((key) => key.toLocaleLowerCase() .startsWith(p.toLocaleLowerCase() + '='));
        if (obj.hasOwnProperty(p) && keyStartsWith.length == 1) {
            let keyName = keyStartsWith[0].split("=");
            result += keyName[1] + " " + obj[p] + "\n";
        }
    }
    return <div style={{'white-space': 'pre-wrap'}}>{result}</div>;
}

export function jsonataFormatter(cell, row, rowIndex, formatExtraData) {
    const expression = jsonata(formatExtraData);
    const transformedData = expression ? expression.evaluate(row) : row;
    return (JSON.stringify(transformedData || "")).replace(/,/g, ', ').replace(/[\[\]\"]/g, '');
}

export function billingDocumentTypeFormatter(cell, row) {
    return cell ? (cell.transactionDocument.isReversal ? 'Invoice' : 'Credit Memo') : '';
}

export function queryTypeFotmatter(cell, row, rowIndex, formatExtraData) {

    let power = false;

    if (formatExtraData.power) power = true;
    if (row && row.variables && row.variables.businessType === 'POWER') power = true;
    if (formatExtraData.Customer &&
        Array.isArray(formatExtraData.Customer.content) &&
        formatExtraData.Customer.content.length > 0 &&
        formatExtraData.Customer.content[0] &&
        Array.isArray(formatExtraData.Customer.content[0].contracts) &&
        formatExtraData.Customer.content[0].contracts.length > 0 &&
        formatExtraData.Customer.content[0].contracts[0] && 
        formatExtraData.Customer.content[0].contracts[0].product === 'Electricity') power = true;

    const res = power
        ? queryTypeMapping.E.filter(x => x.enum.indexOf(parseInt(cell)) > -1)
        : queryTypeMapping.G.filter(x => x.enum.indexOf(parseInt(cell)) > -1);

        return res.length ? res[0].title : "Unknown";
}

export function menuFotmatter(cell, row, rowIndex, formatExtraData) {
    return (<GridDropDownMenu formatExtraData={formatExtraData} row={row}/>)
}

//skip period
export function cronFormatter(cell, row, rowIndex, formatExtraData) {
    return (<CronFormatter cell={cell} row={row}/>)
}

export function reportPeriodFormatter(cell, row, rowIndex, formatExtraData) {
    return (<PeriodFormatter cell={cell} row={row}/>)
}

export function hiddenColFormatter(cell, row, rowIndex, formatExtraData) {
    return (<div style={{display: 'none'}}>{cell}</div>)
}