import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import TariffOverview from "../TariffOverview/TariffOverview";
import AddressOverview from "../AddressOverview/AddressOverview";

function ContactOverview(props){
    return props.contact ? <p>
            <Row>
                <Col sm={4}><span>First name</span>
                </Col>
                <Col>
                    {props.contact.firstName}
                </Col>
            </Row>
            <Row>
                <Col sm={4}><span>Last Name</span>
                </Col>
                <Col>
                    {props.contact.lastName}
                </Col>
            </Row>
            <Row>
                <Col sm={4}><span>Contact phone</span>
                </Col>
                <Col>
                    {props.contact.phone}
                </Col>
            </Row>
            <Row>
                <Col sm={4}><span>Contact email</span>
                </Col>
                <Col>
                    {props.contact.email}
                </Col>
            </Row>
            {(props.contact.billingPreferenceEmail || props.contact.billingPreferencePost)
            && <Row>
                <Col sm={4}>
                    <span>Billing preference</span>
                </Col>
                <Col>
                    {[props.contact.billingPreferenceEmail ? 'Email' : null, props.contact.billingPreferencePost ? 'Post' : null].filter(o => o).join(', ')}
                </Col>
            </Row>
            }
        </p>
        : null;
}

class AgreementOverview extends Component {
  constructor(props) {
    super(props);
  }


  render() {
          return (
              <Row className='step'>
                  <Col className='col-12'>
                      {!DYCE ?
                          <Accordion className="overview" accordion={false}>

                          <AccordionItem>
                              <AccordionItemTitle>
                                  Tariff details
                                  <i className="icon ion-chevron-down"></i>
                              </AccordionItemTitle>
                              <AccordionItemBody>
                                  <Row>
                                      <Col className="col-sm-12">
                                          <TariffOverview quote={this.props.agreement}/>
                                      </Col>
                                  </Row>
                              </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemTitle>
                                  Company information
                                  <i className="icon ion-chevron-down"></i>
                              </AccordionItemTitle>
                              <AccordionItemBody>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Business Name</label>
                                          <p>{this.props.agreement.clientName}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label class="">Business Type</label>
                                          <p>{this.props.agreement.businessType}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label class="">Company Size</label>
                                          <p>{this.props.agreement.companySize}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Company Registration Number</label>
                                          <p>{this.props.agreement.companyNumber}</p>
                                      </Col>
                                  </Row>
                              </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemTitle>
                                  Site information
                                  <i className="icon ion-chevron-down"></i>
                              </AccordionItemTitle>
                              <AccordionItemBody>
                                  {this.props.agreement.mprn ? <Row>
                                      <Col className="col-sm-6">
                                          <label>MPRN</label>
                                          <p>{this.props.agreement.mprn}</p>
                                      </Col>
                                  </Row>
                                  : null}
                                  {this.props.agreement.mprn ? <Row>
                                      <Col className="col-sm-6">
                                          <label>AQ</label>
                                          <p>{this.props.agreement.aq}</p>
                                      </Col>
                                  </Row>
                                  : null}
                                  {this.props.agreement.mpan ? <Row>
                                      <Col className="col-sm-6">
                                          <label class="">MPAN</label>
                                          <p>{this.props.agreement.mpan}</p>
                                      </Col>
                                  </Row>
                                      : null}
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Site address</label>
                                          <AddressOverview address={this.props.agreement.supplyAddress} />
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Primary address</label>
                                          <AddressOverview address={this.props.agreement.primaryAddress} />
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Primary contact</label>
                                          <ContactOverview contact={this.props.agreement.primaryContact} />
                                      </Col>
                                  </Row>
                                  <Row>
                                      {DYCE
                                          ? <Col className="col-sm-6">
                                              <label>Credit check address</label>
                                              <AddressOverview address={this.props.agreement.creditCheckAddress} />
                                          </Col>
                                          : <Col className="col-sm-6">
                                              <label>Secondary address</label>
                                              <AddressOverview address={this.props.agreement.secondaryAddress} />
                                          </Col>
                                      }
                                  </Row>
                                  <Row>
                                      {DYCE
                                          ? <Col className="col-sm-6">
                                              <label>Credit check contact</label>
                                              <ContactOverview contact={this.props.agreement.creditCheckContact} />
                                          </Col>
                                          : <Col className="col-sm-6">
                                              <label>Secondary contact</label>
                                              <ContactOverview contact={this.props.agreement.secondaryContact} />
                                          </Col>
                                      }
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Billing address</label>
                                            {this.props.agreement.billingContact
                                              ? (this.props.agreement.billingContact.sameAsPrimary
                                                  ? <AddressOverview address={this.props.agreement.primaryAddress} />
                                                  : <AddressOverview address={this.props.agreement.billingAddress} />)
                                                : null
                                          }
                                      </Col>
                                  </Row>

                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Billing contact</label>
                                          {this.props.agreement.billingContact
                                              ? (this.props.agreement.billingContact.sameAsPrimary
                                                  ?  <ContactOverview contact={this.props.agreement.primaryContact} />
                                                  :  <ContactOverview contact={this.props.agreement.billingContact} />)
                                              : null }
                                      </Col>
                                  </Row>
                                  {DYCE ?
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Previous address</label>
                                              <AddressOverview address={this.props.agreement.previousAddress} />
                                          </Col>
                                      </Row>
                                  : null
                                  }
                                  {DYCE ?
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Climate change agreement</label>
                                              <p>{this.props.agreement.climateChangeAgreement ? 'Yes' : 'No'}</p>
                                          </Col>
                                      </Row>
                                      : null
                                  }
                                  {DYCE ?
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Change of tenancy</label>
                                              <p>{this.props.agreement.changeOfTenancy ? 'Yes' : 'No'}</p>
                                          </Col>
                                      </Row>
                                      : null
                                  }
                                  {DYCE ?
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Change of tenancy date</label>
                                              <p>{this.props.agreement.changeOfTenancyDate ? new Date(this.props.agreement.changeOfTenancyDate).toLocaleDateString() : null}</p>
                                          </Col>
                                      </Row>
                                      : null
                                  }
                              </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemTitle>
                                  Payment information
                                  <i className="icon ion-chevron-down"></i>
                              </AccordionItemTitle>
                              <AccordionItemBody>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Bank Account Name:</label>
                                          <p>{this.props.agreement.bankAccountName}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label class="">Sort Code:</label>
                                          <p>{this.props.agreement.sortCode}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Account Number:</label>
                                          <p>{this.props.agreement.accountNumber}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Payment Method:</label>
                                          <p>{this.props.agreement.paymentMethod}</p>
                                      </Col>
                                  </Row>
                              </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemTitle>
                                  Acceptance
                                  <i className="icon ion-chevron-down"></i>
                              </AccordionItemTitle>
                              <AccordionItemBody>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Marketing consent:</label>
                                          <p>{this.props.agreement.mail ? 'Mail, ' : ''}
                                              {this.props.agreement.telephone ? 'Telephone, ' : ''}
                                              {this.props.agreement.SMS ? 'SMS, ' : ''}
                                              {this.props.agreement.email ? 'Email' : ''}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label class="">Proposed Supply Start Date:</label>
                                          <p>{this.props.agreement.startDate ? new Date(this.props.agreement.startDate).toLocaleDateString() : null}</p>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Current Supplier Contract End Date:</label>
                                          <p>{this.props.agreement.endDate ? new Date(this.props.agreement.endDate).toLocaleDateString() : null}</p>
                                      </Col>
                                  </Row>
                                  {DYCE ?
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Letter of Authority Start Date:</label>
                                          <p>{this.props.agreement.loaStartDate ? new Date(this.props.agreement.loaStartDate).toLocaleDateString() : null}</p>
                                      </Col>
                                  </Row>
                                  : null}
                                  {DYCE ?
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Letter of Authority End Date:</label>
                                          <p>{this.props.agreement.loaEndDate ? new Date(this.props.agreement.loaEndDate).toLocaleDateString() : null}</p>
                                      </Col>
                                  </Row>
                                  : null }
                                  <Row>
                                      <Col className="col-sm-6">
                                          <label>Sale Type:</label>
                                          <p>{this.props.agreement.saleType}</p>
                                      </Col>
                                  </Row>
                              </AccordionItemBody>
                          </AccordionItem>
                      </Accordion>
                          :

                          <Card className={'overview'}>
                              <CardBody>
                                      <Row>
                                          <Col className="col-sm-12">
                                              <TariffOverview quote={this.props.agreement}/>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Business Name</label>
                                              <p>{this.props.agreement.clientName}</p>
                                          </Col>
                                          <Col className="col-sm-6">
                                              <label class="">Business Type</label>
                                              <p>{this.props.agreement.businessType}</p>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Company Registration Number</label>
                                              <p>{this.props.agreement.companyNumber}</p>
                                          </Col>
                                      </Row>
                                      {this.props.agreement.mprn ? <Row>
                                              <Col className="col-sm-6">
                                                  <label>MPRN</label>
                                                  <p>{this.props.agreement.mprn}</p>
                                              </Col>

                                              <Col className="col-sm-6">
                                                  <label>AQ</label>
                                                  <p>{this.props.agreement.aq}</p>
                                              </Col>
                                          </Row>
                                          : null}
                                      {this.props.agreement.mpan ? <Row>
                                              <Col className="col-sm-6">
                                                  <label class="">MPAN</label>
                                                  <p>{this.props.agreement.mpan}</p>
                                              </Col>
                                          </Row>
                                          : null}
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Site address</label>
                                              <AddressOverview address={this.props.agreement.supplyAddress} />
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Primary address</label>
                                              <AddressOverview address={this.props.agreement.primaryAddress} />
                                          </Col>
                                          <Col className="col-sm-6">
                                              <label>Primary contact</label>
                                              <ContactOverview contact={this.props.agreement.primaryContact} />
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                                  <label>Credit check address</label>
                                                  <AddressOverview address={this.props.agreement.creditCheckAddress} />
                                              </Col>
                                           <Col className="col-sm-6">
                                                  <label>Credit check contact</label>
                                                  <ContactOverview contact={this.props.agreement.creditCheckContact} />
                                              </Col>

                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Billing address</label>
                                              {this.props.agreement.billingContact
                                                  ? (this.props.agreement.billingContact.sameAsPrimary
                                                      ? <AddressOverview address={this.props.agreement.primaryAddress} />
                                                      : <AddressOverview address={this.props.agreement.billingAddress} />)
                                                  : null
                                              }
                                          </Col>
                                          <Col className="col-sm-6">
                                              <label>Billing contact</label>
                                              {this.props.agreement.billingContact
                                                  ? (this.props.agreement.billingContact.sameAsPrimary
                                                      ?  <ContactOverview contact={this.props.agreement.primaryContact} />
                                                      :  <ContactOverview contact={this.props.agreement.billingContact} />)
                                                  : null }
                                          </Col>
                                      </Row>

                                          <div>
                                              <Row>
                                              <Col className="col-sm-6">
                                                  <label>Previous address</label>
                                                  <AddressOverview address={this.props.agreement.previousAddress} />
                                              </Col>

                                              <Col className="col-sm-6">
                                                  <label>Climate change agreement</label>
                                                  <p>{this.props.agreement.climateChangeAgreement ? 'Yes' : 'No'}</p>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col className="col-sm-6">
                                                  <label>Change of tenancy</label>
                                                  <p>{this.props.agreement.changeOfTenancy ? 'Yes' : 'No'}</p>
                                              </Col>
                                              <Col className="col-sm-6">
                                                  <label>Change of tenancy date</label>
                                                  <p>{this.props.agreement.changeOfTenancyDate ? new Date(this.props.agreement.changeOfTenancyDate).toLocaleDateString() : null}</p>
                                              </Col>
                                          </Row>
                                          </div>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Bank Account Name:</label>
                                              <p>{this.props.agreement.bankAccountName}</p>
                                          </Col>
                                          <Col className="col-sm-6">
                                              <label class="">Sort Code:</label>
                                              <p>{this.props.agreement.sortCode}</p>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Account Number:</label>
                                              <p>{this.props.agreement.accountNumber}</p>
                                          </Col>
                                          <Col className="col-sm-6">
                                              <label>Payment Method:</label>
                                              <p>{this.props.agreement.paymentMethod}</p>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col className="col-sm-6">
                                              <label>Marketing consent:</label>
                                              <p>{this.props.agreement.mail ? 'Mail, ' : ''}
                                                  {this.props.agreement.telephone ? 'Telephone, ' : ''}
                                                  {this.props.agreement.SMS ? 'SMS, ' : ''}
                                                  {this.props.agreement.email ? 'Email' : ''}</p>
                                          </Col>
                                          <Col className="col-sm-6">
                                              <label>Sale Type:</label>
                                              <p>{this.props.agreement.saleType}</p>
                                          </Col>
                                      </Row>
                                  <Row>
                                          <Col className="col-sm-6">
                                              <label class="">Proposed Supply Start Date:</label>
                                              <p>{this.props.agreement.startDate ? new Date(this.props.agreement.startDate).toLocaleDateString() : null}</p>
                                          </Col>
                                          <Col className="col-sm-6">
                                              <label>Current Supplier Contract End Date:</label>
                                              <p>{this.props.agreement.endDate ? new Date(this.props.agreement.endDate).toLocaleDateString() : null}</p>
                                          </Col>
                                      </Row>
                                        <Row>
                                              <Col className="col-sm-6">
                                                  <label>Letter of Authority Start Date:</label>
                                                  <p>{this.props.agreement.loaStartDate ? new Date(this.props.agreement.loaStartDate).toLocaleDateString() : null}</p>
                                              </Col>
                                              <Col className="col-sm-6">
                                                  <label>Letter of Authority End Date:</label>
                                                  <p>{this.props.agreement.loaEndDate ? new Date(this.props.agreement.loaEndDate).toLocaleDateString() : null}</p>
                                              </Col>
                                          </Row>
                              </CardBody>
                          </Card>}
                  </Col>
              </Row>
          )
  }
}

export default AgreementOverview;