"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = exports.schemaFunction = exports.queryTypeMapping = void 0;
const queryTypeMapping = {
    G: [{
        type: "number",
        title: "AMR Enquiry",
        enum: [975, 917, 1093]
    }, {
        type: "number",
        title: "Billing Enquiry",
        enum: [ 1010, 569, 782, 1026, 885, 1008, 780, 929, 919, 888, 960, 892, 554, 772, 1009, 631, 794, 953, 830, 863, 1105, 1113, 652, 1159]
    }, {
        hidden: "true",
        type: "number",
        title: "Complaint",
        enum: [1048, 1014, 1015, 1018, 1019, 1083, 1016, 1020, 1031, 1025, 1022, 1076, 1082, 1084, 1021, 1017, 1032, 1024, 1079, 1047, 1023, 1078, 1087, 1097, 1098, 1099, 1100, 1101, 1102, 1116]
    }, {
        hidden: "true",
        type: "number",
        title: "Gas Emergency",
        enum: [823, 586, 813]
    }, {
        type: "number",
        title: "Manage My Account",
        enum: [779, 1051, 1012, 648, 719, 1090, 865, 1085, 1007, 1005, 894, 1088, 1069, 1070, 1071, 568, 570, 1007, 842, 1012, 1013, 1069, 1073, 1070, 1074, 1071, 1075, 1091, 648, 668, 1086, 1103, 962, 1011, 1052, 1053]
    }, {
        type: "number",
        title: "Meter Enquiry",
        enum: [630, 943, 243, 242, 963, 843, 750, 941, 746, 762, 763, 784, 944, 566, 947, 564, 1126]
    }, {
        type: "number",
        title: "Moving Premises",
        enum: [862, 789, 676, 1144]
    }, {
        type: "number",
        title: "Payment Enquiry",
        enum: [621, 910, 583, 689, 900, 897, 896, 898, 899, 785, 887, 583, 653, 910, 911, 828, 1140]
    }, {
        type: "number",
        title: "Pricing Enquiry",
        enum: [125, 788, 879, 781, 1050, 877, 878, 884, 831]
    }, {
        type: "number",
        title: "Reading Enquiry",
        enum: [696, 866, 601, 627, 613, 988, 926, 922, 920, 998, 1003, 921, 1066, 624, 989, 999, 1000, 1004, 1002, 657]
    }, {
        type: "number",
        title: "Refund Request",
        enum: [617]
    }, {
        hidden: "true",
        type: "number",
        title: "Sales Enquiry",
        enum: [757, 687, 757, 758, 856, 684, 1154]
    }, {
        hidden: "true",
        type: "number",
        title: "Settlements Enquiry",
        enum: [955, 836, 864, 956, 995, 834, 958]
    }, {
        type: "number",
        title: "VAT/CCL Enquiry",
        enum: [618]
    }],
    E: [{
        type: "number",
        title: "AMR Enquiry",
        enum: [1055, 1093, 1129]
    }, {
        type: "number",
        title: "Billing Enquiry",
        enum: [1010, 652, 979, 708, 1026, 830, 1114, 656, 655, 1009, 685]
    }, {
        hidden: "true",
        type: "number",
        title: "Complaint",
        enum: [1016, 1017, 1018, 1019, 1020, 1021, 1023, 1024, 1025, 1031, 1032, 1047, 1048, 1076, 1078, 1079, 1082, 1083, 1084, 1087, 1097, 1098, 1099, 1100, 1101, 1102, 1115, 1116, 1117, 1118, 1119, 1120, 1121 ]
    }, {
        hidden: "true",
        type: "number",
        title: "Electricity Emergency",
        enum: [654, 1028]
    }, {
        type: "number",
        title: "Manage My Account",
        enum: [658, 1085, 1052, 941, 1053, 1013, 651, 1007, 1069, 1070, 1071, 1011, 662, 1122]
    }, {
        type: "number",
        title: "Meter Enquiry",
        enum: [935, 1061, 1059, 1060, 1064, 1058, 1062, 1063, 817, 818, 940, 939, 973, 937, 938, 936, 1029]
    }, {
        type: "number",
        title: "Moving Premises",
        enum: [676, 829, 789, 1145, 1147]
    }, {
        type: "number",
        title: "Payment Enquiry",
        enum: [828, 653, 911, 660, 688]
    }, {
        type: "number",
        title: "Pricing Enquiry",
        enum: [831, 792, 984, 986, 788]
    }, {
        type: "number",
        title: "Reading Enquiry",
        enum: [931, 942, 972, 987, 932, 971, 934, 657]
    }, {
        type: "number",
        title: "Refund Request",
        enum: [666]
    }, {
        hidden: "true",
        type: "number",
        title: "Sales Enquiry",
        enum: [1104, 1089, 1091, 960, 684, 757, 1111, 1108, 1112, 1106]
    }, {
        hidden: "true",
        type: "number",
        title: "Settlements Enquiry",
        enum: [970, 969]
    }, {
        type: "number",
        title: "VAT/CCL Enquiry",
        enum: [655]
    }]
};

exports.queryTypeMapping = queryTypeMapping;

const schemaFunction = filtered => {
    let typeType = JSON.parse(JSON.stringify(queryTypeMapping));

    switch (filtered) {
        case "filter-hidden-first":
            typeType.G = queryTypeMapping.G.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: t.enum
                };
            });
            typeType.E = queryTypeMapping.E.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: t.enum
                };
            });
            break;
        case "filter-hidden-first-one-element":
            typeType.G = queryTypeMapping.G.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            typeType.E = queryTypeMapping.E.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            break;
        case "filter-first":
            typeType.G = queryTypeMapping.G.map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            typeType.E = queryTypeMapping.E.map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            break;
    }

    return {
        type: 'object',
        properties: {
            id: {
                type: 'string',
                title: 'Id'
            },
            description: {
                type: 'string',
                title: 'Description',
                placeholder: 'Query Description'
            },
            type: {
                type: 'number',
                title: 'Type',
                anyOf: typeType
            },
            assignee: {
                type: 'string',
                title: 'Assignee'
            },
            customer: {
                type: 'string',
                title: 'Customer'
            },
            dueDate: {
                type: 'string',
                title: 'Due Date',
                default: ""
            },
            status: {
                type: 'string',
                title: 'Status',
                'default': 'Open',
                'enum': ['Open', 'Closed']
            },
            priority: {
                type: 'integer',
                title: 'Priority',
                'default': 0,
                'enum': [0, 50, 100],
                enumNames: ['Low', 'Medium', 'High']
            },
            contact_name: {
                type: 'string',
                title: 'Contact Name',
                format: 'lettersOnly'
            },
            contact_number: {
                type: 'string',
                title: 'Contact Number',
                format: 'phone'
            },
            contact_email: {
                type: 'string',
                title: 'Contact Email',
                format: 'email'
            },
            estate: {
                type: 'string',
                title: 'Site Name'
            },
            mprn: {
                type: 'string',
                title: 'MPR / MPAN'
            },
            account_code: {
                type: 'string',
                title: 'Account Number'
            },
            closed_by: {
                type: 'string',
                title: 'Completed by'
            },
            closed_on: {
                type: 'string',
                title: 'Completed on'
            }
        },
        required: ["type", "description", "contact_name", "contact_number", "contact_email", "account_code"],
        dependencies: {
            type: {
                oneOf: [{
                    properties: {
                        type: {
                            enum: [862]
                        },
                        change_of_od: {
                            title: "Change of Occupancy Details",
                            type: "object",
                            required: ["date_of_coo"],
                            properties: {
                                date_of_coo: {
                                    type: 'string',
                                    format: 'date',
                                    title: 'Date of COO',
                                    description: 'Please provide the date you moved out of the site'
                                },
                                meter_read: {
                                    type: 'string',
                                    title: 'Meter Read',
                                    description: 'Please provide a meter read and date taken (If not taken, an estimate will be used)'
                                },
                            }
                        },
                        outgoing_customer: {
                            title: "Outgoing Customer",
                            type: "object",
                            required: ["contact_name", "contact_phone", "forwarding_address"],
                            properties: {
                                contact_name: {
                                    type: 'string',
                                    title: 'Contact Name',
                                    description: 'Please provide the previous occupiers name',
                                    format: 'lettersOnly'
                                },
                                contact_phone: {
                                    type: 'string',
                                    title: 'Contact Telephone Number',
                                    description: 'Please provide the previous occupiers contact number',
                                    format: 'phone'
                                },
                                forwarding_address: {
                                    type: 'string',
                                    title: 'Forwarding Address',
                                    description: 'Please provide the previous occupiers forwarding address (if known)',
                                    format: 'lettersAndNumbers'
                                }
                            }
                        },
                        incomming_customer: {
                            title: "Incoming Customer",
                            type: "object",
                            required: ["contact_name", "company_name", "contact_phone"],
                            properties: {
                                company_name: {
                                    type: 'string',
                                    title: 'Company Name',
                                    description: 'Please provide your full company name',
                                    format: 'lettersAndNumbers'
                                },
                                contact_name: {
                                    type: 'string',
                                    title: 'Contact Name',
                                    description: 'Please provide your full name',
                                    format: 'lettersOnly'
                                },
                                contact_phone: {
                                    type: 'string',
                                    title: 'Contact Telephone Number',
                                    description: 'Please provide your contact number',
                                    format: 'phone'
                                }
                            }
                        }
                    }
                },{
                    properties: {
                        type: {
                            enum: [676]
                        },
                        change_of_od: {
                            title: "Change of Occupancy Details",
                            type: "object",
                            required: ["date_of_coo"],
                            properties: {
                                date_of_coo: {
                                    type: 'string',
                                    format: 'date',
                                    title: 'Date of COO',
                                    description: 'Please provide the date you moved out of the site'
                                },
                                meter_read: {
                                    type: 'string',
                                    title: 'Meter Read',
                                    description: 'Please provide a meter read and date taken (If not taken, an estimate will be used)'
                                },
                            }
                        },
                        outgoing_customer: {
                            title: "Outgoing Customer",
                            type: "object",
                            required: ["contact_name", "contact_phone", "forwarding_address"],
                            properties: {
                                contact_name: {
                                    type: 'string',
                                    title: 'Contact Name',
                                    description: 'Please provide the previous occupiers name',
                                    format: 'lettersOnly'
                                },
                                contact_phone: {
                                    type: 'string',
                                    title: 'Contact Telephone Number',
                                    description: 'Please provide the previous occupiers contact number',
                                    format: 'phone'
                                },
                                forwarding_address: {
                                    type: 'string',
                                    title: 'Forwarding Address',
                                    description: 'Please provide the previous occupiers forwarding address (if known)',
                                    format: 'lettersAndNumbers'
                                }
                            }
                        },
                        incomming_customer: {
                            title: "Incoming Customer",
                            type: "object",
                            required: ["contact_name", "company_name", "contact_phone"],
                            properties: {
                                company_name: {
                                    type: 'string',
                                    title: 'Company Name',
                                    description: 'Please provide your full company name',
                                    format: 'lettersAndNumbers'
                                },
                                contact_name: {
                                    type: 'string',
                                    title: 'Contact Name',
                                    description: 'Please provide your full name',
                                    format: 'lettersOnly'
                                },
                                contact_phone: {
                                    type: 'string',
                                    title: 'Contact Telephone Number',
                                    description: 'Please provide your contact number',
                                    format: 'phone'
                                }
                            }
                        }
                    }
                }]
            }
        }
    };
};

exports.schemaFunction = schemaFunction;
const schema = schemaFunction("filter-hidden-first");
var _default = schema;
exports.default = _default;