import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import {Query} from "react-apollo";
import {agreementQuery} from "../../queries/Queries";
import Loading from "../../components/Loading";
import AgreementOverview from "../../components/AgreementOverview";


class AgreementOverviewWrapper extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return <div style={{padding:"20px"}}><Query query={agreementQuery} variables={{id: this.props.match.params.contractid}} >
        {({loading, error, data}) => {
                if (loading) return <Loading/>;
                if (error) return `Error! ${error.message}`;
                return <AgreementOverview agreement={data.getAgreement}/>;
            }
        }
    </Query></div>
  }
}

export default withRouter(AgreementOverviewWrapper);