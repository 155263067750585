import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18n from '../Pages/Login/i18n';
import {DropdownToggle, ButtonDropdown, DropdownMenu, DropdownItem, Row, Col, Button} from 'reactstrap';
import QueryDataGrid from "../../components/QueryDataGrid/QueryDataGrid";
import {Mutation, Query} from "react-apollo";
import {
    disableOpenbravoSalesOrganization, getOrganizationParentsQuery,
    getQuoteStatusFlowFromQuery,
    salesOrganizationsQuery
} from "../../queries/Queries";
import PaginatedQueryDataGrid from "../../components/PaginatedQueryDataGrid";

class NoOrganisations extends Component {

    constructor(arg) {
        super(arg)
        if(document.getElementById('filters'))
         document.getElementById('filters').remove();
    }

    render() {
        return (
            <div className="nosalesagent"> 
                <i className="icon-atom ion-organisation-default"></i>
                <p className="text">There are no organizations added yet.</p>
                <Link to={"/create-organisation"} className='btn btn-primary '> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;Add Organization </Link>
            </div>
        );
    }
}

class Organisations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };
        this.toggle = this.toggle.bind(this);

    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {

        return (
            <div className="organization">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('header.organizations')}</h1>
                    </Col>
                    <Col className='col-4'>
                        <Link to={"/create-organisation"} >
                            <Button className='pull-right' color="primary"> <i className="icon ion-plus-round"/>&nbsp;&nbsp; {i18n.t('organizations.addOrganization')}
                            </Button>
                        </Link>

                    </Col>
                </Row>

                <div className="page-cnt">
                    <Query query={getOrganizationParentsQuery}>
                        { result => <Mutation mutation={disableOpenbravoSalesOrganization}>
                            {(disableOrg, data) =>
                                <PaginatedQueryDataGrid
                                    paginationPath={'getSalesOrganizations'}
                                    dataPath={'getSalesOrganizations.content'}
                                    keyField={'id'}
                                    query={salesOrganizationsQuery}
                                    filters={[
                                        {
                                            type: 'text',
                                            fields: ['name'],
                                            placeholder: "Search by name",
                                            classes: "search",
                                            cols: 5
                                        },
                                        {
                                            type: 'dropdown',
                                            fields: ['category'],
                                            placeholder: "All Categories",
                                            possibleValues:[{key:'Broker', value:'Broker'}, {key:'Broker Lead', value:'Broker Lead'}, {key:'Sub Broker', value:'Sub Broker'}],
                                            cols: 2
                                        },
                                        {
                                            type: 'dropdown',
                                            fields: ['parent.organizationId'],
                                            placeholder: "All Parents",
                                            possibleValues:result.loading ? [] : result.data.getOrganizationParents.map(({organizationId, name}) => ({key: organizationId, value:name})),
                                            cols: 2
                                        },
                                        {
                                            type: 'dropdown',
                                            fields: ['active'],
                                            possibleValues:[{key: true, value:"Active"}, {key:false, value:"Incative"}],
                                            placeholder: "All Statuses",
                                            cols: 2
                                        }
                                    ]}
                                    defaultSorted={{dataField : 'name', order: 'asc'}}
                                    columns={[
                                        {
                                            dataField: 'id',
                                            formatter: (cell, row) => <img width="32" height="32" src={CORE_URL+'/logo/'+cell} style={{borderRadius: "50%"}}/>
                                        },
                                        {
                                            dataField: 'name',
                                            text: i18n.t('organizations.salesOrganization'),
                                            sort: true
                                        },
                                        {
                                            dataField: 'category',
                                            text: i18n.t('organizations.category'),
                                            sort: true
                                        },
                                        {
                                            dataField: 'parent.name',
                                            text: i18n.t('organizations.parent'),
                                            sort: true
                                        },
                                        {
                                            dataField: 'active',
                                            text: i18n.t('organizations.status'),
                                            sort: true,
                                            formatter: (cell, row) => cell ? i18n.t('organizations.active') : i18n.t('organizations.deactivated')
                                        }

                                    ]}
                                    menuItems={() =>  Promise.resolve([{name:'Disable', callback:(obj) => disableOrg({variables:{id : obj.businessPartnerId}})}]) }
                                    onSelect={(row)=> this.props.history.push('/organization/' + row.businessPartnerId)}
                                    noDataView={<NoOrganisations/>}
                                />
                            }
                        </Mutation>
                        }
                    </Query>
                </div>
            </div>
        )
    }

}

export default Organisations;
