import React, { Component } from "react";
import { Card, CardText, CardTitle,CardHeader,CardBody, Col, Form, FormGroup, FormText, Label, Row } from "reactstrap";
import i18n from '../../views/Pages/Login/i18n';

class InfoCard extends React.Component {
    render() {

        const card = [];
        const content = [];
        for (let i = 0; i < this.props.columns; i++) {
            content[i] = [];
        }
        let i = 0;
        for (const cols in this.props.info) {
            if (!!!this.props.hideEmpty || this.props.info[cols].value) {
                content[i % this.props.columns].push(
                    <FormGroup key={i}>
                        <FormText >{i18n.t(this.props.info[cols].key)}</FormText>
                        {typeof(this.props.info[cols].value) === "boolean" ? (this.props.info[cols].value ? <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>: null) : <Label>{this.props.info[cols].value}</Label>}
                    </FormGroup>
                )
                i++;
            }
        }

        for (let i = 0; i < this.props.columns; i++) {
            card.push(<Col key={i}><Form>{content[i]}</Form></Col>);
        }

        return (<Card>
            <CardHeader>
                <CardTitle>{i18n.t(this.props.title)}</CardTitle>
            </CardHeader>
            <CardBody>
                <CardText tag="div">
                    <Row>
                        {card}
                    </Row>
                </CardText>
            </CardBody>
        </Card>);
    }
}

export default InfoCard;