/**
 * Created by ogi on 1/22/18.
 */

import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Query,withApollo} from 'react-apollo';
import gql from "graphql-tag";
import JsxParser from 'react-jsx-parser';
import Widget01 from '../../views/Widgets/Widget01';
import _ from 'lodash';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Col, Input, InputGroup, Row} from 'reactstrap';
import {withRouter} from 'react-router';
import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Loading from '../Loading';
import jsonata from "./../../../node_modules/jsonata/jsonata-es5"
import i18n from "../../views/Pages/Login/i18n";
//import {moment} from 'moment'
import { CSVLink } from "react-csv";

const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    firstPageText: 'First',
    prePageText: 'Previous  ',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    sizePerPageList: [{
        text: 'Display ', value: 5
    }, {
        text: '10', value: 10
    }]
};


class QueryDataGrid1 extends Component {

    constructor(props) {
        super(props);
        this.state = {page:1, sizePerPage:5};
        this.getColumns = this.getColumns.bind(this);
        this.getHeaders = this.getHeaders.bind(this);
        this.filter = this.filter.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.setParents = this.setParents.bind(this);
    }

    getQuery() {
        return this.props.query;
    }

    getColumns() {
        for (const col of this.props.columns) {
            col.text = i18n.t(col.text);
            if (col.template) {
                col.formatter = (cell, row, rowIndex, formatExtraData) =>
                (<JsxParser
                    bindings={{row}}
                    components={{Widget01, MoreInfoButton}}
                    jsx={_.template(this.props.columns[col].template)(row)}
                    showWarnings={true}
                    key={rowIndex + "_" + col}
                />)
            }
        }

        return this.props.columns;
    }
    getHeaders() {
        const headers = []
        for (const col of this.props.columns) {
            if (col.dataField) headers.push({label: i18n.t(col.text), key: col.dataField});
        }
        return headers;
    }
    getUniqueValues (data, fieldPath){
        return data.map(dataElem => this.getFieldValue(dataElem, fieldPath)).filter(function(value, index, self) {
            return self.indexOf(value) === index;
        });
    };

    setParents(obj, parents){
        let newObj = JSON.parse(JSON.stringify(obj));
        const ownProps = Object.keys(newObj);
        for (let ownProp of ownProps) {
            if (newObj[ownProp] instanceof Object || newObj[ownProp] instanceof Array) {
                let newparents = parents.slice();
                newparents.unshift(obj);
                newObj[ownProp] = this.setParents(newObj[ownProp], newparents);
            }
        }
        newObj.__parents = parents;
        return newObj;
    }

    getFilters(data) {
        if(this.props.filters) {
            let result = [];
            for (let i = 0; i < this.props.filters.length; i++) {

                const filter1 = this.props.filters[i];
                let elem = null;
                switch (filter1.type) {
                    case "text":
                        elem =  <InputGroup className={filter1.classes}>
                            <Input placeholder={i18n.t(filter1.placeholder)} value={this.state['search' + i]}
                                onChange={(e) => this.setState({['search' + i]: e.target.value})}/>
                            <i className='icon ion-ios-search-strong' />
                        </InputGroup>;
                        break;
                    case "date":
                        elem = <div className={filter1.classes}><DateRangePicker small noBorder
                            startDate={this.state['searchStart' + i]} // momentPropTypes.momentObj or null,
                            startDateId={"searchStart" + i} // PropTypes.string.isRequired,
                            endDate={this.state['searchEnd' + i]} // momentPropTypes.momentObj or null,
                            endDateId={"searchEnd" + i} // PropTypes.string.isRequired,
                            onDatesChange={({startDate, endDate}) =>
                                this.setState({
                                    ["searchStart" + i]: startDate,
                                    ["searchEnd" + i]: endDate
                                })} // PropTypes.func.isRequired,
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired,
                            showClearDates={true}
                            isOutsideRange={() => false}
                            showDefaultInputIcon={true}
                            noBorder={false}
                        /></div>;
                        break;
                    case "dropdown":

                        elem = <InputGroup className={filter1.classes}><Input type="select" value={this.state['search' + i]}
                            onChange={(e) => this.setState({['search' + i]: e.target.value})}>
                            <option key={-1} className='default-option' value="" defaultValue={true}>{filter1.placeholder}</option>
                            {this.getUniqueValues(this.getFieldValue(data, this.props.dataPath), filter1.fields[0]).map((value,index) =>
                                <option key={index}>{value}</option>)}
                        </Input></InputGroup>;
                        break;
                }
                result.push(<Col key={i} sm={filter1.cols}>{elem}</Col>);

            }

            return <div className="table-filers-elements"><Row>{result}</Row></div>;
        } else {
            return null;
        }
    }
    getTitle() {
        if (this.props.title) {
            return (
                <div className="table-filers-title" >
                    <h4>{this.props.title}</h4>
                </div>
            );
        } else {
            return null;
        }
    }
    getButtons() {
        if (this.props.buttons) {
            return (
                <div className="table-filers-buttons" >
                    {this.props.buttons}
                </div>
            );
        } else {
            return null;
        }
    }

    getFieldValue (obj, path){
        if(obj) {
            var pathArray = path.split(".");
            if (pathArray.length > 1) {
                if (pathArray[0] === "#") {
                    let concatArr = [];
                    for (let j = 0; j < obj.length; j++) {
                        concatArr = concatArr.concat(this.getFieldValue(obj[j], path.substring(path.indexOf(".") + 1))).filter((value, index, self) => {
                            for (let i =0 ;i<index; i++){
                                if(self[i].id !== undefined && self[i].id === value.id){
                                    return false;
                                }
                            }
                            return true;
                        });
                    }
                    return concatArr;
                } else {
                    return this.getFieldValue(obj[pathArray[0]], path.substring(path.indexOf(".") + 1));
                }
            } else {
                return obj[pathArray[0]];
            }
        } else {
            return [];
        }
    }


    filter(element) {

        const moment = require('moment');
        let result = true;
        if(this.props.filters) {
            for (let i = 0; i < this.props.filters.length; i++) {
                let result1 = false;
                for (let j = 0; j < this.props.filters[i].fields.length; j++) {
                    const fieldName = this.props.filters[i].fields[j];

                    const fieldValue = this.getFieldValue(element, fieldName);
                    const searchTerm = this.state['search' + i];
                    const searchTermStart = this.state["searchStart" + i];
                    const searchTermEnd = this.state["searchEnd" + i];
                    switch (this.props.filters[i].type) {
                        case "text":
                            result1 = result1 || !searchTerm || fieldValue.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
                            break;
                        case "date":
                            const fieldValueMoment = moment(fieldValue).utc();
                            result1 = result1
                                || (!searchTermStart || searchTermStart.isSameOrBefore(fieldValueMoment, "day"))
                                && (!searchTermEnd || searchTermEnd.isSameOrAfter(fieldValueMoment, "day"));
                            break;
                        case "dropdown":
                            result1 = result1 || !searchTerm || searchTerm.length === 0 || fieldValue === searchTerm;
                            break;
                    }
                }
                result = result && result1;
            }
        }
        this.state.page = 1;
        return result;
    }

    getRemoveDuplicatesFunction(key, removeDuplicates){
        return removeDuplicates ? (value, index, self) => self.findIndex(v => v[key] === value[key]) === index : () => true;
    }

    render() {

        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }

        return <Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>

            {(result) => {
                if (result.loading) return <Loading/>;
                if (result.error) return <div>{result.error} </div>;
                const { data } = result;

                if(Object.keys(data).length == 0){
                    //setTimeout(() => { this.props.client.resetStore(); }, 0);
                }
                const dataWithParents = this.setParents(data, []);
                let expr = null;
                if (this.props.expression) {
                    const compiled = _.template(this.props.expression);
                    expr = compiled(this.props.match.params);
                }
                const expression = expr ? jsonata(expr) : null;
                let transformedData = expression ? expression.evaluate(dataWithParents) : dataWithParents;
                if(this.props.filterFunction) {
                    let filterFunction = eval(this.props.filterFunction);
                    transformedData = filterFunction(transformedData, data, this.props);
                }
                console.log(this.getFieldValue(transformedData, this.props.dataPath).filter(this.filter), this.getHeaders(), this.props.queryKey);
                return <div className={(this.props.className || "") + (this.props.openPageOnSelect ? "table-with-links" : "")}>

                    {this.props.export && (<CSVLink filename={`${this.props.queryKey}.csv`} className="export-csv" data={this.getFieldValue(transformedData, this.props.dataPath).filter(this.filter)} headers={this.getHeaders()}><i className="icon icon-download"></i></CSVLink>)}

                    <div className='table-filers-cnt'>
                        {this.getTitle()}
                        {this.getFilters(data)}
                        {this.getButtons()}
                    </div>
                    {this.props.reload || !this.props.reload}
                    <BootstrapTable
                    wrapperClasses="table-responsive"
                    keyField={this.props.keyField}
                    data={ this.getFieldValue(transformedData, this.props.dataPath).filter(this.filter) }
                    columns={ this.getColumns() }
                    bordered={ false }
                    rowEvents={{
                        onMouseMove: (e, row, rowIndex) => {
                            e.target.move = true;
                        },
                        onMouseDown: (e, row, rowIndex) => {
                            e.target.move = false;
                        },
                        onClick: (e, row, rowIndex) => {
                            if(!e.target.move && this.props.openPageOnSelect){
                                const parameters = Object.assign(this.props.match.params, row);
                                var compiled = _.template(this.props.openPageOnSelect);
                                const url = compiled(parameters);
                                this.props.history.push(url);
                            }
                        }
                    }}

                    hover
                    defaultSorted={this.props.defaultSorted ? [this.props.defaultSorted] : []}
                    pagination={paginationFactory({
                        page: this.state.page,
                        onPageChange: (page, sizePerPage) => {
                            this.state.page =  page;
                        },
                        sizePerPageList:[ {
                            text:"5", value: 5
                        }, {
                            text:"10", value: 10
                        }, {
                            text:"20", value: 20
                        } ]
                    })}
                    noDataIndication={() => <div>No records in table</div>}
                    /></div>;
                }
            }
        </Query>
    }
}

export default withApollo(withRouter(QueryDataGrid1));
