import React, {Component} from "react";

class PeriodFormatter extends Component {

    constructor(props) {
        super(props);
    }

    getVal() {
        if (!this.props.row.periodType) {
            return ' ';
        } else {
            let val;
            val = "Last ";
            val += this.props.cell.toString() + ' ';
            val += this.props.row.periodType;
            return val;
        }
    }

    render() {
        return (
            <div>
                {<div>{this.getVal()} </div>}
            </div>)
    }
}

export default PeriodFormatter;