import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";
import i18n from "../../../views/Pages/Login/i18n";
import Form from "react-jsonschema-form";
import PhoneWidget from "../PhoneWidget/PhoneWidget";
import axios from "axios";
import {createUserMutation2} from "../../../queries/Queries";
import {withApollo} from "react-apollo";
import {withRouter} from 'react-router';
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
// import ErrLogger from "../../../components/ErrorLogger";
// import EventLogger from "../../../components/EventLogger";

const schema = {
    "type": "object",
    "required": [
        "password",
        "passwordConfirm",
        "firstName",
        "lastName"
    ],
    "properties": {
        "userName": {
            "type": "string",
            "title": "User Name"
        },
        "password": {
            "type": "string",
            "title": "Password",
            "description": "Password policy: minimum length: 8, minimum lowercase letters: 1, minimum uppercase letters: 1, minimum digits: 1, minimum special characters: 1"
        },
        "passwordConfirm": {
            "type": "string",
            "title": "Confirm Password"
        },
        "titleEnum": {
            "type": "string",
            "title": "Title",
            "enum": [
                "Ms.",
                "Mr."
            ]
        },
        "firstName": {
            "type": "string",
            "title": "First Name",
            "minLength": 2,
            "maxLength": 40
        },
        "middleName": {
            "type": "string",
            "title": "Middle Name",
            "maxLength": 40
        },
        "lastName": {
            "type": "string",
            "title": "Last Name",
            "minLength": 2,
            "maxLength": 40
        }
        // "phones": {
        //     "type": "string"
        // }
    }
};

const format = /^(?=.*[0-9]+)(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+).{8,}$/;

const uiSchema = {
    "userName": {
        "ui:readonly": true
    },
    "password": {
        "ui:widget": "password"
    },
    "passwordConfirm": {
        "ui:widget": "password"
    }
};

function validate(formData, errors) {

    const forbiddenCharacters = /[<>=/\\()\[\]%\"`{}]+/;
    const forbiddenCharactersMessage = 'Forbidden character! Please do not use any types of brackets, double quotes, percentage and equals signs';

    const spaceValidation = input => typeof input === 'undefined' || (typeof input === 'string' && !input.startsWith(" ") && !input.endsWith(" "));
    const spaceValidationMessage = 'Should not start or end with a space character.'

    if (errors) {
        if(forbiddenCharacters.test(formData.firstName)) errors.firstName.addError(forbiddenCharactersMessage);
        if(forbiddenCharacters.test(formData.middleName)) errors.middleName.addError(forbiddenCharactersMessage);
        if(forbiddenCharacters.test(formData.lastName)) errors.lastName.addError(forbiddenCharactersMessage);
        if(forbiddenCharacters.test(formData.titleEnum)) errors.titleEnum.addError(forbiddenCharactersMessage);

        if(!spaceValidation(formData.firstName)) errors.firstName.addError(spaceValidationMessage);
        if(!spaceValidation(formData.middleName)) errors.middleName.addError(spaceValidationMessage);
        if(!spaceValidation(formData.lastName)) errors.lastName.addError(spaceValidationMessage);
        if(!spaceValidation(formData.titleEnum)) errors.titleEnum.addError(spaceValidationMessage);

        if (formData.password !== formData.passwordConfirm) errors.passwordConfirm.addError("Passwords don't match");
        if (!format.test(formData.password)) errors.password.addError("Password policy: minimum length: 8, minimum lowercase letters: 1, minimum uppercase letters: 1, minimum digits: 1, minimum special characters: 1");
    }

    return errors;
}

function validatePassword(formData) {
    return (!(formData.password !== formData.passwordConfirm || !format.test(formData.password)));
}

const widgets = {
    phoneWidget: PhoneWidget
};

class RegisterUser extends Component {
    constructor({match, ...props}) {
        super(props);
        const token = match.params.token;

        this.state = {
            token: token,
            data: {}
        };

        let form = this;
        axios.get(`/oauth/spring-security-oauth-server/register/` + token, {
            headers: {'Content-Type': 'application/json'}
        }).then((response) => {
            if (response.status === 200) {
                form.state.data.userName = response.data.username;
                form.setState({data: form.state.data});
            }
        });

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.loginUser = this.loginUser.bind(this);
    }

    onSubmit() {
        // this.state.data.phones = JSON.parse(this.state.data.phones);

        let user = {
            "username": this.state.data.userName,
            "password": this.state.data.password,
            "enabled": true,
            "locked": false,
            "title": this.state.data.titleEnum,
            "firstName": this.state.data.firstName,
            "middleName": this.state.data.middleName,
            "lastName": this.state.data.lastName
        };

        let userToken = this.state.token;
        if (this.state.data.password && this.state.data.password !== "" && this.state.data.firstName && this.state.data.firstName !== "" && this.state.data.lastName && this.state.data.lastName !== "" && validatePassword(this.state.data)) {
            this.props.client.mutate({
                mutation: createUserMutation2,
                variables: {user: user, token: userToken}
            }).then(({data}) => {
                if (data.registerUser) {
                    this.loginUser(this.state.data);
                }
            });
        }
    }

    loginUser(data) {
        let form = this;
        axios.post(`${CORE_URL}/login`, {
            userName: data.userName,
            password: data.password
        }).then(function (response) {
            let expDate = new Date();
            expDate.setTime(response.data.expirationDate);
            document.cookie = "token=true;expires=" + expDate.toUTCString() + ";path=/";
            localStorage.token = response.data.token;
            form.props.history.push("/");
            // EventLogger(data, "USER_SETUP_OK");
        })
        //     .catch((error) => {
        //     ErrLogger(data, "USER_SETUP_ERROR", error)
        // });
    }

    onChange({formData}) {
        this.state.data = formData;
    }

    render() {
        const mainClasses = !!TOTAL ? 'align-items-center' : 'app flex-row align-items-center';

        if (this.state.data.userName) {
            return (
                <div className={mainClasses}>
                    <div className="app-body create-user">
                        <Header/>

                        <Container fluid={true} className="login">
                            <Row className="justify-content-center">
                                <Col md="8" style={{paddingLeft: 0, paddingRight: 0}}>
                                    <Row className='subheader'>
                                        <Col className='col-8'>
                                            <h1>{i18n.t('header.registerUsers')}</h1>
                                        </Col>
                                    </Row>
                                    <Row className="page-cnt">
                                        <Col className='col-8'>
                                            <Form
                                                schema={schema}
                                                uiSchema={uiSchema}
                                                widgets={widgets}
                                                validate={validate}
                                                formData={this.state.data}
                                                onChange={this.onChange}
                                                onSubmit={this.onSubmit}
                                            >
                                                <div className="buttons">
                                                    <button type="submit" className="btn btn-primary"
                                                    >{i18n.t('header.register')}
                                                    </button>
                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer/>
                </div>
            );
        } else {
            return (<div>ERROR</div>);
        }
    }
}

export default withApollo(withRouter(RegisterUser));
