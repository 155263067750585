import React, {Component} from 'react';
import {dateFormatter, reportedAmountFormatter} from "../../utils/Formatters";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import QueryDataGrid from "../QueryDataGrid";


class ReadingsTable extends Component {
    constructor(props) {
        super(props);

    }
    render() {

        const id = this.props.match.params.id;
        return (<div>

                <QueryDataGrid
                    keyField='id'
                    dataPath={"businessPartner.0.mDMBPEstateList.#.mDMEstate.mDMMeterPointList.#.mDMMeterRegisterList.#.meter.ammMeterReadingList"}
                    query={`
                                         query businessPartnerQuery {
                                             businessPartner(startRow: 0, offset: 100, where: "id = '${this.props.businessPartner.id}'", orderBy: "name") {
                                                mDMBPEstateList {
                                                    id
                                                    mDMEstate {
                                                        id
                                                        locationAddress {
                                                          addressLine1
                                                        }
                                                        mDMMeterPointList{
                                                            id
                                                            name
                                                            mDMMeterRegisterList{
                                                                id
                                                                meterReadingDigitsCount
                                                                mDMRegister{
                                                                    product{
                                                                        id
                                                                        name
                                                                    }
                                                                }
                                                                meter{
                                                                    id
                                                                    serialNumber
                                                                    ammMeterReadingList{
                                                                        id
                                                                        readingdate
                                                                        readStatus
                                                                        validationStatus
                                                                        newreadingmetervt
                                                                        customerReadType
                                                                        product {
                                                                            id
                                                                            searchKey
                                                                            mdmPurpose
                                                                            uOM {
                                                                                id
                                                                                name
                                                                            }
                                                                        }
                                                                        mDMMeterMeasurement {
                                                                            id
                                                                            reportedamount
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    `}
                    columns={[{
                        dataField: 'readingdate',
                        text: 'Read Date',
                        formatter: dateFormatter,
                        sort: true
                    }, {
                        dataField: '__parents.4.name',
                        text: 'MPR',
                        sort: true
                    }, {
                        dataField: 'product.searchKey',
                        text: 'Register',
                        sort: true
                    }, {
                        dataField: 'newreadingmetervt',
                        text: 'Reads',
                        align: "right",
                        headerAlign: "right",
                        sort: true
                    }, {
                        dataField: 'customerReadType',
                        text: 'Read Type',
                        sort: true
                    }, {
                        dataField: 'mDMMeterMeasurement.reportedamount',
                        text: 'Consumption',
                        align: "right",
                        headerAlign: "right",
                        formatter: reportedAmountFormatter,
                        sort: true
                    }, {
                        dataField: 'validationStatus',
                        text: 'Status',
                        sort: true
                    }]}
                    defaultSorted={{
                        dataField: 'readingdate',
                        order: 'desc'
                    }}
                    filters={[
                        {
                            type: "text",
                            fields: ['__parents.4.name'],
                            placeholder: "Search by Meter Point",
                            classes: "search"
                        },
                        {
                            type: "dropdown",
                            fields: ['customerReadType'],
                            placeholder: "All Types"
                        },
                        {
                            type: "dropdown",
                            fields: ['validationStatus'],
                            placeholder: "All Statuses"
                        }
                    ]}
                    onSelectOpenPage={"reading"}
                    removeDuplicates={true}
                />
        </div>

        );
    }
}

export default ReadingsTable;
