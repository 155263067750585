import React, {Component} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

class ReportPeriod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownOpen: false,
            dropDownTypeOpen: false,
            ...props.formData
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleDropdownType = this.toggleDropdownType.bind(this);

        this.changeValue = this.changeValue.bind(this);
        this.changeValueType = this.changeValueType.bind(this);
    }

    toggleDropdown() {
        this.setState({
            dropDownOpen: !this.state.dropDownOpen
        });
    }

    toggleDropdownType() {
        this.setState({
            dropDownTypeOpen: !this.state.dropDownTypeOpen
        });
    }

    changeValue(e) {
        this.setState({
            "periodCount": e.currentTarget.value,
            dropDownValue: e.currentTarget.value,
        }, () => this.props.onChange(this.state));
    }

    changeValueType(e) {
        this.setState({
            dropDownValueType: e.currentTarget.value
        })

        this.setState({
            "periodType": e.currentTarget.value,
            dropDownValueType: e.currentTarget.value
        }, () => this.props.onChange(this.state));
    }

    render() {
        const dropdownItemsCount = [];
        const dropdownItemsType = [];
        for (let i = 1; i <= 6; i++) {
            dropdownItemsCount.push(<DropdownItem value={i} onClick={this.changeValue}>{i}</DropdownItem>)
        }

        let defaultTypeName = '';
        if (this.props.uiSchema['ui:options']) {
            const options = this.props.uiSchema['ui:options'].types;
            options.forEach(element => dropdownItemsType.push(<DropdownItem value={element}
                                                                            onClick={this.changeValueType}>{element}/s</DropdownItem>));
            if (options.length === 1) {
                defaultTypeName = options[0] + '/s';
                this.state.periodType = options[0];
            }
        }

        return (<div id="reportPeriod">
                <div>Period:{this.props.required ? '*' : ''}</div>
                <div className='filter-item row'>
                    <div className="col-8 drop-down">
                        <Dropdown isOpen={this.state.dropDownOpen} toggle={this.toggleDropdown} className='filter-item'>
                            <DropdownToggle split={true}>
                                Report for previous: {this.state.dropDownValue ? this.state.dropDownValue : ''}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dropdownItemsCount}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="col-4 drop-down">
                        <Dropdown isOpen={this.state.dropDownTypeOpen} toggle={this.toggleDropdownType}
                                  className='filter-item'>
                            <DropdownToggle split={true}>
                                {defaultTypeName !== '' ? defaultTypeName : this.state.dropDownValueType ? this.state.dropDownValueType + '/s' : ''}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dropdownItemsType}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportPeriod;
