import QueryEditor from "./QueryEditor";
import CronGenerator from "../CronGenerator";
import ReportPeriod from "../CronGenerator/ReportPeriod";

const fields = {
    queryEditor: QueryEditor,
    cronGenerator: CronGenerator,
    reportPeriod: ReportPeriod
}

export default fields;
