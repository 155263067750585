import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { compose, graphql } from "react-apollo";
import i18n from '../../../views/Pages/Login/i18n';
import MaskInput from 'react-maskinput'
import { Button, Form, FormGroup, Input } from 'reactstrap';
import ReactDOM from 'react-dom';
import { createReadingsMutation } from "../../../queries/Queries";
import ExpiringAlert from "../../../components/ExpiringAlert";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import gql from "graphql-tag";
// import EventLogger from "../../../components/EventLogger";
import {pad} from '../../../utils/Helpers';

const isDesktop = screen.width > 768;

const style = {
    meterPoint: { marginBottom: '20px', padding: isDesktop ? '10px' : '0px', border: isDesktop ? '1px solid #e6e6e6' : "none" },
    meter: { padding: isDesktop ? '10px' : "0px", margin: isDesktop ? '10px' : '0px', border: isDesktop ? '1px solid #e6e6e6' : "none" },
    registers: { margin: '10px 0px' },
    registerClassName: isDesktop ? "col-4" : "col-12",
    register: { padding: isDesktop ? '10px' : '0px', margin: isDesktop ? '10px 0px' : '0px', border: isDesktop ? '1px solid #e6e6e6' : 'none' },
}

class CreateReading extends Component {

    constructor(props) {
        super(props);
        this.state = {
            estateId: "",
            estate: null,
            readings:
                this.props.estateList &&
                    Array.isArray(this.props.estateList) &&
                    this.props.estateList.length > 0
                    ? this.props.estateList.map(estate => 
                        estate.meterPoints
                        .filter(meterPoint => meterPoint.status === 'Live' && meterPoint.supplyEndDate === null)
                        .map(meterPoint =>
                            meterPoint.registers
                            .map(register => ({ id: register.meter.id, serialNumber: register.meter.serialNumber, type: register.meter.type }))
                            .filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index && value.type==='N')
                            .map(msn => 
                                meterPoint.registers
                                .filter(register => register.meter.id === msn.id && !register.effectiveTo && register.settlementType === 'S')
                                .map(register => {
                                    const lastReading = register.readings
                                    .filter(x => x.uploadMethod === 'Invoices')
                                    .map(reading => ({
                                        ...reading,
                                        date: moment(reading.date, "YYYY-MM-DDTHH:mm:ssZ"),
                                        type: reading.type === 'C' ? 'Customer' : (reading.type === 'A' ? 'Actual' : (reading.type === 'E' ? 'Estimated' : (reading.type === 'AE' ? 'Estimated' : 'Actual')))
                                    }))
                                    .sort((a, b) => b.date.isBefore(a.date) ? -1 : 1)[0] || null;

                                    return {
                                        reading: null,
                                        rate: register.rate,
                                        digits: register.digits,
                                        lastReading: lastReading,
                                        registerId: register.id,
                                        meterRegisterId: register.meterRegisterId,
                                        meterSerialNumber: msn.serialNumber,
                                        meterPointIdentifier: meterPoint.identifier,
                                        estateId: estate.id,
                                    };
                                })
                            )
                        )
                    ).flat(3)
                    : [],
            date: moment(),
            submittingReading: false,
            isOutsideRange: (day => day.isAfter(moment()))
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {

        if (this.state.readings.filter(reading => !parseInt(reading.reading)).length > 0) {
            ReactDOM.render(<ExpiringAlert color="danger" message={"All readings must be filled."} />, document.getElementById('alert').appendChild(document.createElement("div")));
            return;
        }

        this.setState({ submittingReading: true });

        const refetchQuery = sessionStorage.listOfReadsQuery && JSON.parse(sessionStorage.listOfReadsQuery);

        if (refetchQuery) refetchQuery.query = refetchQuery.query && gql`${refetchQuery.query}`;

        if (!this.state.isOutsideRange(this.state.date)) {

            this.props

                .createReadingsMutation({
                    variables: {
                        readings: this.state.readings.map(reading => ({
                            value: reading.reading,
                            register: {
                                id: "" + reading.registerId
                            },
                            date: this.state.date,
                            organization: { id: "" + this.props.accountOrganization.id },
                            tenant: { id: "total" },
                            type: "C",
                        }))
                    },
                    refetchQueries: refetchQuery && [refetchQuery]
                })

                .then(({ data }) => {

                    ReactDOM.render(
                        <ExpiringAlert color="success" message={i18n.t('readings.readingCreated')} />,
                        document.getElementById('alert').appendChild(document.createElement("div"))
                    );

                    console.log('got data', data);
                    // EventLogger(data, "SBMT_READ_OK")
                    this.props.toggle();
                })

                .catch((error) => {

                    ReactDOM.render(
                        <ExpiringAlert color="danger" message={error.message} />,
                        document.getElementById('alert').appendChild(document.createElement("div"))
                    );

                    console.log('there was an error sending the query', error.message);
                    this.props.toggle();
                });
        } else {
            this.setState({ submittingReading: false })
        }
    }

    render() {

        let estateReadingList = [];

        if (this.props.estateList && Array.isArray(this.props.estateList)) {

            if (this.props.estateList.length === 1) {
                const [estate] = this.props.estateList;
                estateReadingList = this.state.readings.filter(r => r.estateId === estate.id);
            }

            else if (this.state.estateId) {
                estateReadingList = this.state.readings.filter(r => r.estateId === this.state.estateId);
            }
        }

        const uniqueMeterPoints = estateReadingList.map(x => x.meterPointIdentifier).filter((x, index, self) => self.indexOf(x) === index);
        const uniqueMeters = estateReadingList.map(x => x.meterSerialNumber).filter((x, index, self) => self.indexOf(x) === index);

        const meterPointList = uniqueMeterPoints.map(meterPointIdentifier => ({
            meterPointIdentifier: meterPointIdentifier,
            meters: uniqueMeters.map(meterSerialNumber => {

                const registers = estateReadingList.filter(x => x.meterPointIdentifier === meterPointIdentifier && x.meterSerialNumber === meterSerialNumber);

                if (registers && registers.length) {
                    return {
                        meterSerialNumber: registers[0].meterSerialNumber,
                        registers: registers
                    }
                }

                return null;
            }).filter(x => x)

        }));

        return (
            <div className="reading-modal">
                <Form>
                    <FormGroup>
                        <label>{i18n.t('readings.date')}:&nbsp;</label>
                        <SingleDatePicker
                            readOnly
                            openDirection={"down"}
                            date={this.state.isOutsideRange(this.state.date) ? null : this.state.date}
                            numberOfMonths={1}
                            onDateChange={date => this.setState({ date })}
                            isOutsideRange={this.state.isOutsideRange}
                            displayFormat={"DD/MM/YYYY"}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            id="readings-date"
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>{i18n.t('readings.address')}:&nbsp;</label>
                        <Input type="select" value={this.state.estateId} onChange={e => this.setState({ estateId: e.target.value })}>
                            {this.props.estateList && (
                                <React.Fragment>
                                    {this.props.estateList.length !== 1 && <option value="">---</option>}
                                    {this.props.estateList.map(estate => {

                                        const address = JSON.parse(estate.address);
                                        const addressData = [
                                            address.door, address.street, address.countryCode, address.houseNumberAdd, address.streetType, address.dependentStreet,
                                            address.doubleDependentCity, address.dependentCity, address.city, address.region, address.postalCode
                                        ];

                                        return (
                                            <option value={estate.id}>
                                                {addressData.filter(value => value).join(', ')}
                                            </option>
                                        )
                                    })}
                                </React.Fragment>
                            )}
                        </Input>
                    </FormGroup>
                    {meterPointList.map(meterPoint => (

                        <div style={style.meterPoint}>
                            <label>MPAN {meterPoint.meterPointIdentifier}</label>

                            {meterPoint.meters.map(meter => (
                                <div style={style.meter}>
                                    <label>MSN {meter.meterSerialNumber}</label>

                                    <div className="row" style={style.registers}>
                                        {meter.registers.sort((a,b) => (a.rate > b.rate) ? 1 : ((b.rate > a.rate) ? -1 : 0)).map(register => (

                                            <div className={style.registerClassName}>
                                                <div style={style.register}>

                                                    <FormGroup>
                                                        <label>Register: {register.meterRegisterId}</label>
                                                        <label>Description: {register.rate || "N/A"}</label>

                                                        <div className="last-reading">
                                                            <label>Last Read {register.lastReading && (<span><br /> Type: {register.lastReading.type} <br /></span>)} {register.lastReading && "From: " + register.lastReading.date.format("DD/MM/YYYY")}</label>
                                                            <p>{register.lastReading ? pad(register.lastReading.value, register.digits) : "N/A"}</p>
                                                        </div>

                                                        <MaskInput
                                                            maskChar="0"
                                                            placeholder=""
                                                            alwaysShowMask
                                                            className="reading-input"
                                                            mask={"0".repeat(register.digits)}
                                                            style={{ width: register.digits * 24 }}
                                                            value={0}
                                                            onChange={event => this.setState({
                                                                readings: this.state.readings.map(reading => reading.registerId == register.registerId ? {...reading, reading: event.target.value} : reading)
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <Button onClick={this.handleSubmit} disabled={this.state.submittingReading} color="primary"> Submit </Button> &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.props.toggle} color="seconday">{this.props.cancelButton ? this.props.cancelButton : 'Cancel'}</Button>
                </Form>
            </div>
        )
    }
}

export default compose(
    graphql(createReadingsMutation, { name: 'createReadingsMutation' })
)(withRouter(CreateReading));
