import React, {Component} from 'react';
import {
    Area,
    AreaChart,
    LineChart,
    Line,
    ScatterChart,
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    Scatter,
    ResponsiveContainer, ZAxis
} from "recharts";
import {Card, CardBody, Row, Col, CardText, CardTitle, CardHeader} from 'reactstrap';

const templateFromDatabase = `<div>
    Name <%=name %>
    <div>
        <small>Legal Entity: <%= isMdmIslegalentity ? 'Yes' : 'No' %><div style="color: red"><%= mdmEik %></div></small>
    </div>
</div>`;


const filterByFileds = ["name"];

const searchWhere = "lower(name) like '%<%= query %>%'";

const searchOrderBy = "name";
const data = [
    {
        name: 'Jan', "2018": 4000, "2019": 2400, amt: 2400,
    },
    {
        name: 'Feb', "2018": 3000, "2019": 1398, amt: 2210,
    },
    {
        name: 'Mar', "2018": 2000, "2019": 9800, amt: 2290,
    },
    {
        name: 'Apr', "2018": 2780, "2019": 3908, amt: 2000,
    },
    {
        name: 'May', "2018": 1890, "2019": 4800, amt: 2181,
    },
    {
        name: 'Jun', "2018": 2390, "2019": 3800, amt: 2500,
    },
    {
        name: 'Jul', "2018": 3490, "2019": 4300, amt: 2100,
    },
    {
        name: 'Aug', "2018": 1190, "2019": 1200, amt: 2100,
    },
    {
        name: 'Sep', "2018": 6490, "2019": 7300, amt: 2100,
    },
    {
        name: 'Oct', "2018": 5490, "2019": 6300, amt: 2100,
    },
    {
        name: 'Nov', "2018": 4390, "2019": 8800, amt: 2100,
    },
    {
        name: 'Dec', "2018": 6590, amt: 2100,
    },
];

class Reports extends Component {
    constructor(props) {
        super();
        this.state = {};
    }

    render() {
        return (<div className="page-cnt">
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle>Average Consumption</CardTitle>
                            </CardHeader>
                            <CardBody style={{height: "300px"}}>
                                <ResponsiveContainer>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={data}
                                        margin={{
                                            top: 5, right: 30, left: 20, bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <Bar dataKey="2018" fill="#09509c"/>
                                        <Bar dataKey="2019" fill="#e81a40"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        )
    }
}

export default Reports;
