import React, {lazy, Suspense} from 'react';
import 'graphiql/graphiql.css';
import fetch from 'isomorphic-fetch';
import {FormGroup, Input, Label} from "reactstrap";
import Loading from "../Loading";

const GraphiQL = lazy(() => import('graphiql'));

class QueryEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...props.formData};
    }

    onChange(name) {
        return (event) => {
            this.setState({
                [name]: event.target.value
            }, () => this.props.onChange(this.state));
        };
    }

    render() {
        const {query, variables, name} = this.state;
        return (
            <Suspense fallback={<Loading/>}>
            <div style={{height: "500px", marginBottom: "100px"}}>
                <FormGroup>
                    <Label for="name">Name: </Label>
                    <Input type="text" name="name" id="name" placeholder="Enter the name of the query" value={name} onChange={this.onChange("name")} />
                </FormGroup>
                <GraphiQL fetcher={this.graphQLFetcher} query={query} variables={variables} onEditQuery={(value) => this.setState({
                    query: value
                }, () => this.props.onChange(this.state))}
                onEditVariables={(value) => this.setState({
                    variables: value
                }, () => this.props.onChange(this.state))} />
            </div>
            </Suspense>
        );
    }

    graphQLFetcher(graphQLParams) {
        return fetch(`${GRAPHQL_URL}/graphql`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.token},
            body: JSON.stringify(graphQLParams),
        }).then(response => response.json());
    }

}



export default QueryEditor;
