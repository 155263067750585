import React, {Component} from "react";

import {Field, reduxForm, SubmissionError} from "redux-form";
import {Button, Card, CardBody, CardGroup, Col, Container, Row} from "reactstrap";
import {renderInput} from "./../../../RenderInput";
import i18n from "./i18n";
//import showResults from './../../../components/showResults';
import {compose} from "react-apollo";
import {withRouter} from "react-router-dom";
import axios from "axios";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
// import EventLogger from "../../../components/EventLogger";

const validate = values => {
    const errors = {};
    const requiredFields = ['username', 'password'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = i18n.t('login.required')
        }
    });
    if (values.username && !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
        errors.username = i18n.t('login.invalidEmail')
    }
    return errors
};


class Login extends Component {

    errorHandler(error, props) {
        console.log('there was an error sending the query', error);
        if (error.response.data.message && error.response.data.message.includes('expired')) {
            props.history.push('/change-expired-password');
        }
        throw new SubmissionError({_error: error.response.data.message});
    }

    handleFormSubmit(values, event, props) {
        const _this = this;
        return axios.post(`${CORE_URL}/login`, {
            userName: values.username.toLowerCase().trim(),
            password: values.password
        }).then(function (response) {
            if (response.data.error) {
                return _this.errorHandler.bind(this)({response: response}, props)
            }
            var expDate = new Date();
            expDate.setTime(response.data.expirationDate);
            document.cookie = "token=true;expires=" + expDate.toUTCString() + ";path=/";
            localStorage.token = response.data.token;
            const {pathname} = props.location;
            const navigateTo = pathname.slice(6) //remove /login from the beginning
            // EventLogger(values.username, "LOGIN");
            props.history.push(navigateTo);
        })
        //.catch(this.errorHandler.bind(this));

    }

    render() {

        const mainClasses = !!TOTAL ? 'align-items-center' : 'app flex-row align-items-center';

        const {handleSubmit, onSubmit, pristine, reset, invalid, submitting, error} = this.props;

        const errorMessage = error ? <p className="text-danger">{error}</p> :
            <p className="signin-text-muted">{i18n.t('login.subtitle')}</p>
        return (
            <div className={mainClasses}>
                <div className="app-body">
                    <Header/>

                    <Container fluid={true} className="login">
                        <Row className="justify-content-center">
                            <Col md={window.config.consul.DOWNTIME_MESSAGE ? 8 : 12} sm="12"
                                 style={{paddingLeft: 0, paddingRight: 0}}>
                                <CardGroup>
                                    <Card
                                        className={DYCE ? "login-panel" : (window.config.consul.DOWNTIME_MESSAGE ? "p-4 login-panel downtime-message" : "p-4 login-panel")}>
                                        <CardBody className='credentials-card'>
                                            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                                                {!TOTAL && <div className='login-logo '></div>}
                                                {!!TOTAL && <div style={{marginTop: "100px"}}></div>}
                                                {errorMessage}
                                                <label htmlFor="username"
                                                       className='label-name'>{i18n.t('login.username')}</label>
                                                <Field inputGroup={{className: "mb-3"}}
                                                       placeholder={i18n.t('login.userEmail')}
                                                    //inputAddonGroup={{className: 'icon-user'}}
                                                       name="username"
                                                       component={renderInput} type="email"/>
                                                <label htmlFor="password"
                                                       className='label-name'>{i18n.t('login.password')}</label>
                                                <Field inputGroup={{className: "mb-4"}}
                                                       placeholder={i18n.t('login.password')}
                                                    //inputAddonGroup={{className: 'icon-lock'}}
                                                       name="password"
                                                       component={renderInput} type="password"/>

                                                <Row className='margin-b-30'>
                                                    {/*<Col>*/}
                                                    {/*<Field name="autologin"*/}
                                                    {/*id="autologin"*/}
                                                    {/*component="input"*/}
                                                    {/*className='css-checkbox'*/}
                                                    {/*type="checkbox"/>*/}

                                                    {/*<label htmlFor="autologin"*/}
                                                    {/*className="css-label"><i className="icon ion-android-checkbox"></i>{ i18n.t('login.autoLogin') }</label>*/}
                                                    {/*</Col>*/}
                                                    <Col xs="12" className="text-right">
                                                        <Button color="link"
                                                                onClick={() => this.props.history.push('/reset-password')}
                                                                className="px-0">{i18n.t('login.forgottenPasswordLink')}</Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="6">
                                                        <Button color="primary"
                                                                className="px-4 sign-in"
                                                                disabled={invalid || submitting}>{i18n.t('login.buttonLabel')}</Button>
                                                    </Col>
                                                </Row>


                                            </form>

                                        </CardBody>
                                    </Card>
                                    {!DYCE && !TOTAL && <Card>
                                        <CardBody className="text-center side-image">
                                        </CardBody>
                                    </Card>}
                                </CardGroup>
                            </Col>
                            <Col md="4" sm="12" style={{paddingLeft: 0, paddingRight: 0}}>
                                {TOTAL && window.config.consul.DOWNTIME_MESSAGE &&
                                <CardGroup>
                                    <Card>
                                        <CardBody style={{'margin-top': '50%'}}>
                                            {window.config.consul.DOWNTIME_MESSAGE}
                                        </CardBody>
                                    </Card>
                                </CardGroup>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer/>

            </div>

        );
    }
}

const reduxFormWrapper = reduxForm({
    form: 'login',
    validate
});


export default compose(
    //gqlWrapper,
    reduxFormWrapper,
    withRouter
)(Login);
