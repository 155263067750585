import React, {Component} from "react";

import {Field, reduxForm, SubmissionError} from "redux-form";
import {Button, Card, CardBody, CardGroup, Col, Container, Row, FormGroup} from "reactstrap";
import {renderInput} from "./../../../RenderInput";
import i18n from "../Login/i18n";
import { translate } from 'react-i18next';
//import showResults from './../../../components/showResults';
import {compose, graphql} from "react-apollo";
import {withRouter} from "react-router-dom";
import axios from "axios";
import PasswordInputMatch from "../../../components/PasswordInputMatch";
import ExpiringAlert from "../../../components/ExpiringAlert";
import ReactDOM from "react-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
// import EventLogger from "../../../components/EventLogger";

const validate = values => {
    const errors = {}
    const requiredFields = ['username', 'password']
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = i18n.t('login.required')
        }
    })
    if (values.username && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.username)) {
        errors.username = i18n.t('login.invalidEmail')
    }
    return errors
}


class ResetPassword extends Component {

    constructor(props){
        super(props);
        this.state = {password: ''};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
    }

    errorHandler(error) {
        console.log('there was an error sending the query', error);
        let errorMessage = i18n.t('resetPassword.passwordResetError') + " " + error;
        this.setState({ errorMessage: errorMessage});
    }

    handleSubmit() {
        return axios.post(`${CORE_URL}/reset-password`, {token: this.props.match.params.token, password: this.state.password})
        .then(response => {
            if (response.data.startsWith("Error:")) {
                this.errorHandler(response.data);
            } else {
                ReactDOM.render(<ExpiringAlert color="success"
                                               message={i18n.t('resetPassword.passwordReset')}/>, document.getElementById('alert'));
                this.setState({errorMessage: null});
                this.props.history.push('/');
                //EventLogger(this.props.user.username, "PASS_RESET");
            }
        })
        .catch(error => {
            this.errorHandler(error);
        });
    }

    render() {
        const mainClasses = !!TOTAL ? 'align-items-center' : 'app flex-row align-items-center';

            <p className="signin-text-muted">{ i18n.t('resetPassword.explanation') }</p>
        return (
            <div className={mainClasses}>
                <div className="app-body">
                    <Header/>

                    <Container fluid={true} className="login">
                        <Row className="justify-content-center">
                            <Col md="12" style={{paddingLeft: 0, paddingRight: 0}}>
                                <CardGroup>
                                    <Card className={DYCE ? "login-panel" : "p-4 login-panel"}>
                                        <CardBody className='credentials-card col-3'>
                                            <form>
                                                <div className='login-logo'></div>
                                                <div className="text-danger">{this.state.errorMessage}</div>
                                                <PasswordInputMatch password={this.state.password} handleChange={(_, password) => this.setState({password})}
                                                handleConfirm={() => this.handleSubmit()}/>
                                            </form>
                                        </CardBody>
                                    </Card>
                                    {!DYCE && !TOTAL && <Card>
                                        <CardBody className="text-center side-image">
                                        </CardBody>
                                    </Card>}
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer/>
            </div>
        );
    }
}

const reduxFormWrapper = reduxForm({
    form: 'login',
    validate
});


export default compose(
    //gqlWrapper,
    reduxFormWrapper,
    withRouter
)(ResetPassword);
