import React, { Component } from 'react';
import i18n from "../Pages/Login/i18n";
import SalesAgentsGrid from "../../components/SalesAgentsGrid/SalesAgentsGrid";
import { Breadcrumb, BreadcrumbItem,  Col,  Row} from "reactstrap";

//---------------------------------------------------------

class SalesAgents extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="manage-users">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('Sales Agents')}</h1>
                    </Col>
                    <Col className='col-4'>
                        <Breadcrumb>
                            <BreadcrumbItem><i className="icon ion-android-home"></i><a href="#">Home</a></BreadcrumbItem>
                            <BreadcrumbItem active>Sales Agents</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <SalesAgentsGrid/>
                </div>
            </div>
        )
    }
}

export default SalesAgents;
