import React, {Component} from 'react';
import { Label, Input } from 'reactstrap';

class SelectBoxes extends Component {
  constructor(props) {
      super(props);   
  }
  render() {
    return (
    <div className={ "select-boxes " + this.props.className } >
      {this.props.options
          .filter(({active})=>active)
          .map( (e) => (
        <div key={e.value}>
        <Input type="radio"
               name={this.props.name? this.props.name: 'selecboxes'}
               disabled={this.props.disabled}
               id={e.value} value={e.value}
               checked={this.props.value === e.value || this.props.options.length === 1}
               onChange={(e) => this.props.onChange(e)}/>
        <Label htmlFor={e.value} className='select-box'>
            <div style={{verticalAlign:"middle"}}><i className={'box-icon '+e.icon}></i>&nbsp;<span>{e.title}</span></div>
        </Label>
        </div>
      ))}
    </div>
    );
  }
}

export default SelectBoxes;