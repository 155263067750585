import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import GenericViewPreview from "../GenericViewPreview/GenericViewPreview";

class PreviewSwitcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        const preview = this.state.modal ? (<GenericViewPreview jsx={this.props.jsx} layouts={this.props.layouts} configuration={this.props.configuration} context={this.props.exampleParams} />) : ""

        return (
            <div>
                <Button color="primary" onClick={this.toggle}>{this.state.modal ? "Hide preview" : "Show Preview"}</Button>
                {preview}
            </div>
        );
    }
}

export default PreviewSwitcher;