import React, { Component } from 'react';
import i18n from "../Pages/Login/i18n";
import { Button, Breadcrumb, BreadcrumbItem, Col, Row, FormGroup, InputGroup, ListGroup, ListGroupItem, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalHeader } from "reactstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import BootstrapTable from 'react-bootstrap-table-next';
import {priceFormatter} from "../../utils/Formatters";
import axios from "axios/index";
import ProcessesDetail from "../../components/ProcessesDetail";


class Processes extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            modalOpen:false,
        };
    }

    toggle() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }
    render() {
        return (
            <div className="manage-users">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Processes</h1>
                    </Col>
                    <Col className='col-4'>
                        <Breadcrumb>
                            <BreadcrumbItem><i className="icon ion-android-home"></i><a href="#">Home</a></BreadcrumbItem>
                            <BreadcrumbItem active>Processes</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <Row className='filter-bar'>
                        <Col className='col-3'>

                        </Col>
                        <Col className='col-3'>

                        </Col>
                        <Col className='col-3'>

                        </Col>
                        <Col className='col-2'>
                            <FormGroup>
                                {/*<Button onClick={() => this.setState({showCreateTask:true})} className='btn primary pull-right'> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;New Task </Button>*/}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <BootstrapTable wrapperClasses="table-responsive" keyField={"processDefinitionId"} data={[
                            {
                                processDefinitionId:"cumulativeBillingProcess",
                                name: "Start Billing Cycle"
                            }]}
                            columns={[
                                {
                                    dataField: 'name',
                                    text: "Name",
                                    sort: true
                                },{
                                    formatter: (cell, row) =>
                                        <span><Button className={"icon ion-play"}  onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.setState({modalOpen:true, processDefinitionId: row.processDefinitionId});
                                        }}></Button>
                                        </span>

                                }

                            ]}

                            rowEvents={{
                                onMouseMove: (e, row, rowIndex) => {
                                    e.target.move = true;
                                },
                                onMouseDown: (e, row, rowIndex) => {
                                    e.target.move = false;
                                },
                                onClick: (e, row, rowIndex) => {
                                    if(!e.target.move){
                                        this.props.history.push("/view/bulk-processes/Billing");
                                    }
                                }
                            }}>
                        </BootstrapTable>
                    </Row>
                </div>
                <Modal isOpen={this.state.modalOpen} toggle={this.toggle} style={{maxWidth:"90%"}}  >
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <ModalBody>
                        <ProcessesDetail onClose={this.toggle} processDefinitionId={this.state.processDefinitionId}/>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default Processes;
