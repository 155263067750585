import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { compose, graphql } from "react-apollo";
import i18n from '../../../views/Pages/Login/i18n';
import MaskInput from 'react-maskinput'
import { Button, Form, FormGroup, Input } from 'reactstrap';
import ReactDOM from 'react-dom';
import { createReadingMutation } from "../../../queries/Queries";
import ExpiringAlert from "../../../components/ExpiringAlert";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import ModalDialog from "../../../components/ModalDialog";
import gql from "graphql-tag";
// import EventLogger from "../../../components/EventLogger";
import {getOptions, pad} from '../../../utils/Helpers';

class CreateReading extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'C',
            date: moment(),
            isOutsideRange: (day => day.isAfter(moment()))
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {

        this.setState({ submittingReading: true });

        if (this.state.meterPoint.hasCorrector) {
            this.props.toggle();
            ReactDOM.render(<ModalDialog title={i18n.t('createReading.meterPointWithCorrectorTitle')}
                text={i18n.t('createReading.meterPointWithCorrector')}
                opened={true}
                closeLabel={"Close"} />, document.getElementById('alert').appendChild(document.createElement("div")));
            return;
        }

        const refetchQuery = sessionStorage.listOfReadsQuery && JSON.parse(sessionStorage.listOfReadsQuery);

        if (refetchQuery) {
            refetchQuery.query = refetchQuery.query && gql`${refetchQuery.query}`;
        }

        if (!this.state.isOutsideRange(this.state.date)) {
            this.props.createReadingMutation({
                variables: {
                    reading: {
                        value: this.state.reading,
                        date: this.state.date,
                        type: this.state.type,
                        tenant: { id: "total" },
                        register: { id: "" + this.state.registerId },
                        organization: { id: "" + this.props.account }
                    }
                },
                refetchQueries: refetchQuery && [refetchQuery]
            }).then(({ data }) => {
                console.log('got data', data);
                // EventLogger(data, "SBMT_READ_OK")
                this.props.toggle();
                ReactDOM.render(<ExpiringAlert color="success"
                    message={i18n.t('readings.readingCreated')} />, document.getElementById('alert').appendChild(document.createElement("div")));

            }).catch((error) => {
                this.props.toggle();
                ReactDOM.render(<ExpiringAlert color="danger"
                    message={error.message} />, document.getElementById('alert').appendChild(document.createElement("div")));
                console.log('there was an error sending the query', error.message);
            });
        } else this.setState({ submittingReading: false })
    }

    render() {

        let selectedEstate = null;
        let selectedMeterPoint = null;
        let selectedMeter = null;
        let meterPointList = [];
        let meterRegisterList = [];
        let meterList = [];
        let registerList = [];

        let isOutsideRange = day => day.isAfter(moment());

        if (this.state.estateId) selectedEstate = this.props.estateList.filter(estate => estate.id === this.state.estateId)[0];
        else if (this.props.estateList.length === 1) selectedEstate = this.props.estateList[0];

        const uniqueMeterPoints = new Map();
        this.props.meterPoints.forEach(mp => {
            if(!uniqueMeterPoints.has(mp.id)){
                uniqueMeterPoints.set(mp.id, mp);
            } else {
                if(uniqueMeterPoints.get(mp.id).endDate && uniqueMeterPoints.get(mp.id).endDate !== null && uniqueMeterPoints.get(mp.id).endDate < mp.endDate){
                    uniqueMeterPoints.set(mp.id, mp);
                }
            }
        });

        uniqueMeterPoints.forEach(function(value, key) {
            meterPointList.push(uniqueMeterPoints.get(key));
        });

        if (this.state.meterPointId) selectedMeterPoint = meterPointList.filter(meterPoint => meterPoint.id === this.state.meterPointId)[0];
        else if (meterPointList.length === 1) selectedMeterPoint = meterPointList[0];

        if (selectedMeterPoint) meterRegisterList = selectedMeterPoint.registers;

        if (meterRegisterList.length) meterList = meterRegisterList.map(register => register.meter);

        if (this.state.meterId) selectedMeter = meterList.filter(meter => meter.id === this.state.meterId)[0];
        else if (meterList.length === 1) selectedMeter = meterList[0].meter;

        if (selectedMeter) registerList = meterRegisterList.filter(reg => reg.meter.id === this.state.meterId);
        else if (meterRegisterList.length === 1) registerList = meterRegisterList;

        if (selectedMeterPoint) isOutsideRange = day => day.isBefore(selectedMeterPoint.startDate) || day.isAfter(moment()) || day.isAfter(selectedMeterPoint.endDate)

        return (
            <div className="reading-modal">
                <Form>
                    <FormGroup>
                        <label>{i18n.t('readings.address')}:&nbsp;</label>
                        <Input type="select" value={this.state.estateId} onChange={e => this.setState({ estateId: e.target.value })}>
                            {this.props.estateList && (
                                <React.Fragment>
                                    {this.props.estateList.length !== 1 && <option value="">---</option>}
                                    {this.props.estateList.map(estate => {

                                        const address = JSON.parse(estate.address);
                                        const addressData = [
                                            address.door, address.street, address.countryCode, address.houseNumberAdd, address.streetType, address.dependentStreet,
                                            address.doubleDependentCity, address.dependentCity, address.city, address.region, address.postalCode
                                        ];

                                        return (
                                            <option value={estate.id}>
                                                {addressData.filter(value => value).join(', ')}
                                            </option>
                                        )
                                    })}
                                </React.Fragment>
                            )}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label>{i18n.t('readings.meterPoint')}:&nbsp;</label>
                        <Input type="select" value={this.state.meterPointId} onChange={e => this.setState({ meterPointId: e.target.value })}>
                            {getOptions(meterPointList, 'id', 'identifier')}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label>{i18n.t('readings.meter')}:&nbsp;</label>
                        <Input type="select" value={this.state.meterId} onChange={e => this.setState({ meterId: e.target.value })}>
                            {getOptions(meterList, 'id', 'serialNumber')}
                        </Input>
                    </FormGroup>

                    {registerList.map(register => {

                        const lastReading = register.readings.filter(x => x.uploadMethod === 'Invoices' && x.type !== 'AMR')
                            .map(x => ({
                                ...x,
                                date: moment(x.date, "YYYY-MM-DDTHH:mm:ssZ"),
                                type: x.type === 'C' ? 'Customer' : (x.type === 'A' ? 'Actual' : (x.type === 'E' ? 'Estimated' : (x.type === 'AE' ? 'Estimated' : 'Actual')))
                            }
                            ))
                            .sort((a, b) => b.date.isBefore(a.date) ? -1 : 1)[0];


                        if (!this.state['reading' + register.id]) this.state['reading' + register.id] = "0";

                        return (
                            <FormGroup>
                                <div className="last-reading">
                                    <label>Last Read {lastReading && (<span><br /> Type: {lastReading.type}
                                        <br /></span>)} {lastReading && "From: " + lastReading.date.format("DD/MM/YYYY")}</label>
                                    <p>{lastReading ? pad(lastReading.value, register.digits) : "N/A"}</p>
                                </div>

                                <label>{register.rate}:&nbsp;</label><br />

                                <MaskInput
                                    value={this.state.reading}
                                    onChange={e => this.setState({
                                        reading: e.target.value,
                                        registerId: register.id,
                                        lastReading: lastReading,
                                        meterPoint: selectedMeterPoint,
                                    })}
                                    alwaysShowMask
                                    maskChar="0"
                                    mask={"0".repeat(register.digits)}
                                    placeholder=""
                                    className={'reading-input'}
                                    style={{ width: register.digits * 24 }}
                                />
                            </FormGroup>
                        )
                    })}
                    <FormGroup>
                        <label>{i18n.t('readings.date')}:&nbsp;</label>
                        <SingleDatePicker
                            openDirection={"up"}
                            date={isOutsideRange(this.state.date) ? null : this.state.date}
                            numberOfMonths={1}
                            onDateChange={date => this.setState({ ...this.state, date })}
                            isOutsideRange={isOutsideRange}
                            readOnly
                            displayFormat={"DD/MM/YYYY"}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            id="create-reading-date"
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>{i18n.t('readings.type')}:&nbsp;</label>
                        <Input type="select" value={this.state.type}
                            onChange={(e) => this.setState({ ...this.state, type: e.target.value })}>
                            <option value="C">Customer</option>
                        </Input>
                    </FormGroup>

                    <Button onClick={this.handleSubmit} disabled={this.state.submittingReading}
                        color="primary">Submit</Button> &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.props.toggle}
                        color="seconday">{this.props.cancelButton ? this.props.cancelButton : 'Cancel'}</Button>
                </Form>
            </div>
        )
    }
}

let withRouter2 = withRouter(CreateReading);
export default compose(
    graphql(createReadingMutation, { name: 'createReadingMutation' })
)(withRouter2);