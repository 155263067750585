import React, {Component} from 'reactn';

import i18n from '../../views/Pages/Login/i18n';
import axios from "axios";
import Dropzone from "react-dropzone";
import fileDownload from "js-file-download";
import ReactDOM from "react-dom";
import ModalDialog from "../ModalDialog";
// import EventLogger from "../EventLogger";
// import ErrLogger from "../ErrorLogger";

class TasksAttachments extends Component {
    constructor(props) {
        super(props);
        this.state = {attachments:[], newAttachments: [], showWarning: false};
        this.uploadAttachments = this.uploadAttachments.bind(this);
        this.getAttachments = this.getAttachments.bind(this);
        this.getAttachments(props.task);
    }

    componentWillReceiveProps(nextProps) {
        this.getAttachments(nextProps.task);
    }

    getAttachments(task) {
        if(task) {
            const taskId = task.id;
            axios.get(`${CORE_URL}/tasks/${taskId}/attachments?service=${task.service || 'Camunda'}`, {
                headers: {'Authorization': "Bearer " + localStorage.token}
            }).then((response) => {
                this.setState({attachments: response.data});
                // EventLogger(response.data, "SBMT_QUIRY_ATTACH_OK");
            })
                // .catch((error) => ErrLogger("SBMT_QUIRY_ATTACH_ERR", error));
        } else {
            this.state.attachments = [];
        }
    }

    uploadAttachments(task, showMessageOnSuccess){
        if (!showMessageOnSuccess) showMessageOnSuccess = false;
        Promise.all(this.state.newAttachments.map(attachment => {
            const data = new FormData();
            data.append('file', attachment);
            return axios.post(`${CORE_URL}/tasks/${task.id}/attachments?service=${task.service || 'Camunda'}`, data, {
                headers: {'Authorization': "Bearer " + localStorage.token}
            });
        })).then(() => {
            this.getAttachments(task);
            if (showMessageOnSuccess) ReactDOM.render(<ModalDialog title={i18n.t('attachment.successfulCreateTitle')}
                                         text={i18n.t('attachment.successfulCreate')}
                                         opened={true}
                                         closeLabel={"Close"}/>, document.getElementById('alert').appendChild(document.createElement("div")));
            this.setState({newAttachments:[]});
        })
    }

    render() {
        const taskReadonly = this.props.task && this.props.task.variables.status === 'Closed';
        return !this.global.permissions.includes('Users.ReadOnly') &&  (this.props.task || !this.props.uploadImmediately)
            ?  <div className="files">
                    <h6>Attachments</h6>
                {this.state.showWarning && <div className="error">Files bigger than 10MB are excluded.</div>}
                    {this.state.attachments && Array.isArray(this.state.attachments) && this.state.attachments.length && this.state.attachments.map(attachment => <div><i className="icon ion-android-attach"  style={{fontSize:"18px"}} title={i18n.t('attachments.attachment')}></i>
                        <a href='javascript:void(0)'
                           onClick={() => axios.get(`${CORE_URL}/tasks/${ this.props.task.id}/attachments/${attachment.id}?service=${this.props.task.service || 'Camunda'}`, {
                        headers: {'Authorization': "Bearer " + localStorage.token},
                        responseType: 'arraybuffer'
                    }).then((response) => {
                        fileDownload(response.data, attachment.name);
                    })
                    }>{attachment.name}</a></div>)}

                {!taskReadonly && <div><Dropzone accept="image/jpeg,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,application/pdf,text/csv,application/xml,text/xml,text/plain" data-placeholder="Drop files to attach, or browse (max 10MB). " className="droparea" onDrop={ files => {
                    const smallfiles = files.filter(el => el.size < 10*1024*1024);
                    console.log(this.props.parent);
                    this.props.parent.attachments = true;
                    this.setState({newAttachments:[...smallfiles, ...this.state.newAttachments], showWarning: smallfiles.length != files.length}, ()=>{
                        if(this.props.uploadImmediately){
                                this.uploadAttachments(this.props.task, true);
                            }
                        });
                    }}/>
                    {this.state.newAttachments.map(attachment => <div><i className="icon ion-android-attach"  style={{fontSize: "18px"}} title={i18n.t('attachments.attachment')}></i> {attachment.name}</div>)}
                    </div>
                }
                </div>
            : ""
    }
}




export default TasksAttachments;
