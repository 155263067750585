import React, {Component} from 'reactn';
import i18n from '../Pages/Login/i18n';
import {Col, Row} from 'reactstrap';
import Tariffs from "../../components/Tariffs/Tariffs";

class TariffsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };
        this.toggle = this.toggle.bind(this);

    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {
            return (
                <div className="organization">
                    <Row className='subheader'>
                        <Col className='col-8'>
                            <h1>{i18n.t('Tariffs.Tariffs')}</h1>
                        </Col>
                    </Row>
                    <div className="page-cnt">
                        <Tariffs/>
                    </div>

                </div>
            )
    }

}

export default TariffsView;
