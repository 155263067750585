import React, {Component} from 'react';

import {compose, graphql, Mutation, Query} from "react-apollo";
import {
    Breadcrumb,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {
    createRolePermissionsMutation,
    createUpdateRolesMutation,
    deleteRolePermissionsMutation,
    getAllPermissions,
    getAllRoles,
    allUiConfigurationsQuery,
    allUiMenuItemsQuery,
    saveUiTransportMutation
} from "../../queries/Queries";
import Loading from "../../components/Loading";
import classnames from "classnames";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import i18n from "../Pages/Login/i18n";
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import Sidebar from "../../components/Sidebar";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import classNames from 'classnames';

const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, {uiMenuItem : removed});

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

class Roles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roles:[],
            activeTab: '0'
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(!nextProps.rolesQuery.loading) {
            if(!nextProps.permissionsQuery.loading) {
                const roles = nextProps.rolesQuery.getAllRoles.map(role => ({
                    role_key: role.key,
                    role_name: role.name,
                    permissions: nextProps.permissionsQuery.getAllPermissions.map(permission => ({
                        selected: role.permissions.map(({permission})=> permission.key).includes(permission.key),
                        initialSelected: role.permissions.map(({permission})=> permission.key).includes(permission.key),
                        permission_key: permission.key,
                        permission_name:permission.name}))}));

                if(!nextProps.uiConfigurationsQuery.loading) {
                    if(!nextProps.uiMenuItemsQuery.loading) {
                        this.setState({...this.state,
                            roles: roles,
                            getAllUIConfigurations: nextProps.uiConfigurationsQuery.getAllUIConfigurations,
                            getAllUIMenuItems: nextProps.uiMenuItemsQuery.getAllUIMenuItems});
                    }
                }
            }
        }
    }
    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        if (result.source.droppableId === result.destination.droppableId) {
            const [role_key, itemName] = result.draggableId.split('~');
            let items = reorder(
                this.state.getAllUIConfigurations.filter(roleConfig => roleConfig.role === role_key)[0].uiConfiguration.uiMenus.uiMenuItemOrders,
                result.source.index,
                result.destination.index
            );
            items = items.map((item, index) => ({...item, order: index}));
            this.state.getAllUIConfigurations.filter(roleConfig => roleConfig.role === role_key)[0].uiConfiguration.uiMenus.uiMenuItemOrders = items;
            this.setState({
                getAllUIConfigurations: this.state.getAllUIConfigurations
            });
        } else {
            const [source_role_key, sourceItemName, sourceAvailable] = result.draggableId.split('~');
            let roleConfiguration = this.state.getAllUIConfigurations.filter(roleConfig => roleConfig.role === source_role_key)[0];
            if(!roleConfiguration){
                roleConfiguration = {role: source_role_key, uiConfiguration: {uiMenus: {uiMenuItemOrders:[]}, uiLayouts:[], uiQueries:[]}};
                this.state.getAllUIConfigurations.push(roleConfiguration);
            }
            const orderedItems = roleConfiguration.uiConfiguration.uiMenus.uiMenuItemOrders;
            const availableItems = this.state.getAllUIMenuItems.filter(item => !orderedItems.map(({uiMenuItem}) => uiMenuItem.name ).includes(item.name))
                .sort((item1, item2) => item1.defaultLabel < item2.defaultLabel ? -1 : item1.defaultLabel > item2.defaultLabel ? 1 : 0);
            let items = move(
                sourceAvailable
                    ? availableItems
                    : orderedItems,
                sourceAvailable
                    ? orderedItems
                    : availableItems,
                result.source,
                result.destination
            )[source_role_key];
            items = items.map((item, index) => ({...item, order: index}));
            roleConfiguration.uiConfiguration.uiMenus.uiMenuItemOrders = items;
            this.setState({
                getAllUIConfigurations: this.state.getAllUIConfigurations
            });
        }
    }

    render() {
        if (this.props.rolesQuery.loading)  return <Loading/>;
        if (this.props.rolesQuery.error)  return this.props.rolesQuery.error.message;
        return <div>
            <div className = "bp busines-parnter-cnt" >
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Roles</h1>
                    </Col>
                    <Col className='col-4'>
                        <Breadcrumb>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col sm = "12">
                        <div className='bp-nav-items'>
                            <Nav tabs>
                                {
                                    [...this.state.roles.map((role, index) =>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.activeTab === index.toString() })}
                                                     onClick={() => { this.setState({activeTab: index.toString()}); }}>{role.role_name}</NavLink>
                                        </NavItem>
                                    ),
                                        <NavItem>
                                            <NavLink onClick={() => { this.setState({roles : [...this.state.roles, {
                                                    role_key:'NEWROLE',
                                                    role_name:'New Role',
                                                    new:true,
                                                    permissions:this.props.permissionsQuery.getAllPermissions.map(permission => ({
                                                        selected: false,
                                                        permission_key: permission.key,
                                                        permission_name:permission.name}))
                                                }]
                                            })
                                            }}
                                            >+Add Role</NavLink>
                                        </NavItem>
                                    ]
                                }
                            </Nav>
                        </div>
                    </Col>
                </Row>
            </div>
            <Query query={allUiConfigurationsQuery} >
                {uiConfResult => {
                    if (uiConfResult.loading) return <Loading/>
                    return <Query query={getAllPermissions}>
                        {result => {
                            if (result.loading) return <Loading/>
                            return <TabContent activeTab={this.state.activeTab} className='bp-tab-content'>
                                {this.state.roles.map((role, index) => <TabPane tabId={index.toString()}>
                                    <Row>
                                        <Col xs="12" sm="12" md="10" lg="8" xl="6">
                                            <Form id={"user-details"}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup>
                                                        <Row>
                                                            <Col xs="4">
                                                                <Label className='user-label'>
                                                                    {i18n.t('roles.roleKey')}
                                                                </Label>
                                                            </Col>
                                                            <Col xs="8"><Input value={role.role_key} disabled={!role.new}
                                                                               onChange={(e) => {
                                                                                   let newState = {...this.state};
                                                                                   newState.roles[index].role_key = e.target.value;
                                                                                   this.setState(newState);
                                                                               }}/></Col>
                                                        </Row>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Row>
                                                            <Col xs="4">
                                                                <Label className='user-label'>
                                                                    {i18n.t('roles.roleName')}
                                                                </Label>
                                                            </Col>
                                                            <Col xs="8"><Input value={role.role_name} onChange={(e) => {
                                                                let newState = {...this.state};
                                                                newState.roles[index].role_name = e.target.value;
                                                                this.setState(newState);
                                                            }}/></Col>
                                                        </Row>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Accordion>
                                        <AccordionItem>
                                            <AccordionItemTitle>
                                                Permissions
                                                <i className="icon ion-chevron-down"/>
                                            </AccordionItemTitle>
                                            <AccordionItemBody>
                                                <Row>
                                                    <Col>
                                                        <BootstrapTable wrapperClasses="table-responsive" data={role.permissions}
                                                                        keyField={'permission_key'}
                                                                        columns={[
                                                                            {
                                                                                dataField: 'selected',
                                                                                formatter: (cell, row) => <span>
                                                                                        <Input onChange={(e) => {
                                                                                            let newState = {...this.state};
                                                                                            let i = 0, j = 0;
                                                                                            let roleObj = {...newState.roles.filter(({role_key}, index) => (i = (role_key === role.role_key ? index : i), role_key === role.role_key))[0]};
                                                                                            let permissionObj = {...roleObj.permissions.filter(({permission_key}, index) => (j = (permission_key === row.permission_key ? index : j), permission_key === row.permission_key))[0]};
                                                                                            permissionObj.selected = e.target.checked;
                                                                                            roleObj.permissions[j] = permissionObj;
                                                                                            newState.roles[i] = roleObj;
                                                                                            this.setState(newState);
                                                                                            }}
                                                                                           type="checkbox"
                                                                                           name={role.role_key + '~' + row.permission_key}
                                                                                           id={role.role_key + '~' + row.permission_key}
                                                                                           value={cell ? 'on' : 'off'}
                                                                                           checked={cell}/>
                                                                                        <label
                                                                                            htmlFor={role.role_key + '~' + row.permission_key}
                                                                                            className="css-label">
                                                                                            <i className="icon ion-android-checkbox"/>
                                                                                        </label>
                                                                                        </span>
                                                                            },
                                                                            {
                                                                                dataField: 'permission_key',
                                                                                text: i18n.t('roles.permissionKey'),
                                                                                // filter: textFilter()
                                                                            },
                                                                            {
                                                                                dataField: 'permission_name',
                                                                                text: i18n.t('roles.permissionName'),
                                                                                // filter: textFilter()
                                                                            }
                                                                        ]}

                                                                        filter={filterFactory()}
                                                                        pagination={paginationFactory({
                                                                            // page: this.state.page,
                                                                            onPageChange: (page, sizePerPage) => {
                                                                                this.state.page = page;
                                                                            },
                                                                            sizePerPageList: [{
                                                                                text: "10", value: 10
                                                                            }]
                                                                        })}/>
                                                    </Col>
                                                </Row>
                                            </AccordionItemBody>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemTitle>
                                                Sidebar Menu
                                                <i className="icon ion-chevron-down"/>
                                            </AccordionItemTitle>
                                            <AccordionItemBody>
                                                <DragDropContext onDragEnd={this.onDragEnd}>
                                                    <Row>
                                                        <Col sm={'6'}>
                                                            <h5>Menu</h5>
                                                            <div className="sidebar-config">
                                                                <nav className="sidebar-nav" style={{width:'100%'}}>
                                                                    <Nav style={{width:'100%'}}>
                                                                        <Droppable droppableId={role.role_key}>
                                                                            {(provided, snapshot) => (
                                                                                <div {...provided.droppableProps}
                                                                                     ref={provided.innerRef} style={{padding: 8}}
                                                                                >
                                                                                    {this.state.getAllUIConfigurations
                                                                                        .reduce((acc, roleConfig) => roleConfig.role === role.role_key ? roleConfig : acc, {uiConfiguration:{uiMenus:{uiMenuItemOrders:[]}}})
                                                                                        .uiConfiguration.uiMenus.uiMenuItemOrders
                                                                                        .sort((item1, item2) => item1.order - item2.order)
                                                                                        .map((uiMenuItemOrder) => ({...uiMenuItemOrder.uiMenuItem, defaultMenu: uiMenuItemOrder.defaultMenu}))
                                                                                        .map((mi, itemIndex) =>
                                                                                            <Draggable key={role.role_key+'~'+mi.name} draggableId={role.role_key+'~'+mi.name} index={itemIndex}>
                                                                                                {(provided, snapshot) => (
                                                                                                    <div ref={provided.innerRef}
                                                                                                         {...provided.draggableProps}
                                                                                                         {...provided.dragHandleProps}
                                                                                                    ><NavItem className={'nav-'+mi.type}><NavLink>

                                                                                                        <i className={mi.icon}/>{mi.defaultLabel}
                                                                                                        <Button id={role.role_key+'~'+itemIndex} color={mi.defaultMenu ? 'primary' : 'secondary'} size="sm" outline  style={{float:'right'}} onClick={(e) => {
                                                                                                            this.state.getAllUIConfigurations
                                                                                                                .reduce((acc, roleConfig) => roleConfig.role === role.role_key ? roleConfig : acc)
                                                                                                                .uiConfiguration.uiMenus.uiMenuItemOrders
                                                                                                                .forEach((item, index)=> item.defaultMenu = (role.role_key+'~'+index === e.target.id));
                                                                                                            this.setState({getAllUIConfigurations: this.state.getAllUIConfigurations});
                                                                                                        }}
                                                                                                                active={mi.defaultMenu}>

                                                                                                            {mi.defaultMenu ? 'Default' : 'Set Default'}</Button></NavLink></NavItem>
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        )}
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </Nav>
                                                                </nav>
                                                            </div>
                                                        </Col>
                                                        <Col sm={'6'}>
                                                            <h5>Available menu items</h5>
                                                            <div className="sidebar-config">
                                                                <nav className="sidebar-nav" style={{width:'100%'}}>
                                                                    <Nav style={{width:'100%'}}>
                                                                        <Droppable droppableId={role.role_key+'~Available'}>
                                                                            {(provided, snapshot) => (
                                                                                <div {...provided.droppableProps}
                                                                                     ref={provided.innerRef} style={{padding: 8}}
                                                                                >{this.state.getAllUIMenuItems
                                                                                    .filter(item => !this.state.getAllUIConfigurations
                                                                                        .reduce((acc, roleConfig) => roleConfig.role === role.role_key ? roleConfig : acc, {uiConfiguration:{uiMenus:{uiMenuItemOrders:[]}}})
                                                                                        .uiConfiguration.uiMenus.uiMenuItemOrders
                                                                                        .map(({uiMenuItem}) => uiMenuItem.name).includes(item.name))
                                                                                    .sort((item1, item2) => item1.defaultLabel < item2.defaultLabel ? -1 : item1.defaultLabel > item2.defaultLabel ? 1 : 0)
                                                                                    .map((uiMenuItem, itemIndex) =>
                                                                                        <Draggable key={role.role_key+'~'+uiMenuItem.name+'~Available'} draggableId={role.role_key+'~'+uiMenuItem.name+'~Available'} index={itemIndex}>
                                                                                            {(provided, snapshot) => (
                                                                                                <div ref={provided.innerRef}
                                                                                                     {...provided.draggableProps}
                                                                                                     {...provided.dragHandleProps}
                                                                                                ><NavItem className={'nav-'+uiMenuItem.type}><NavLink><i className={uiMenuItem.icon}/>{uiMenuItem.defaultLabel}</NavLink></NavItem></div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    )}
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </Nav>
                                                                </nav>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </DragDropContext>
                                            </AccordionItemBody>
                                        </AccordionItem>
                                    </Accordion>
                                </TabPane>)}
                            </TabContent>
                        }
                        }
                    </Query>
                }
                }
            </Query>
            <Row>
                <Col>
                    <Mutation mutation={saveUiTransportMutation}>
                        {
                            (saveUiTransport) =>  <Mutation mutation={createUpdateRolesMutation}>
                                {
                                    (createUpdateRoles) =>  <Mutation mutation={createRolePermissionsMutation}>
                                        {
                                            (createRolePermissions) =>  <Mutation mutation={deleteRolePermissionsMutation}>
                                                {
                                                    (deleteRolePermissions, {client}) => <Button color={"primary"} className="buttonSpace buttonSpaceTop pull-right" onClick={() => {
                                                        const roles = this.state.roles.map(({role_key, role_name}) => ({key: role_key, name: role_name}));
                                                        createUpdateRoles({variables: {roles}}).then(result => {
                                                            let changedRolePermissions = this.state.roles.flatMap(({role_key, permissions}) => permissions.filter(({selected, initialSelected}) => selected !== initialSelected).map(({permission_key, selected}) => ({
                                                                role_key,
                                                                permission_key,
                                                                selected
                                                            })));
                                                            return createRolePermissions({
                                                                variables: {
                                                                    rolePermissions: changedRolePermissions.filter(({selected}) => selected).map(({role_key, permission_key}) => ({
                                                                        id: {
                                                                            role_key,
                                                                            permission_key
                                                                        }
                                                                    }))
                                                                }
                                                            })
                                                                .then(result => deleteRolePermissions({
                                                                    variables: {
                                                                        rolePermissions: changedRolePermissions.filter(({selected}) => !selected).map(({role_key, permission_key}) => ({
                                                                            id: {
                                                                                role_key,
                                                                                permission_key
                                                                            }
                                                                        }))
                                                                    }
                                                                }))
                                                                .then(result => saveUiTransport({
                                                                    variables: {
                                                                        uiTransportWrapper: {uiRoleConfigurationList: this.state.getAllUIConfigurations, uiTranslationList:[]}
                                                                    }
                                                                }))
                                                                .then(result => {
                                                                    client.resetStore();
                                                                    alert('success')
                                                                });
                                                        })
                                                    }
                                                    } value="Submit">Submit</Button>
                                                }
                                            </Mutation>
                                        }
                                    </Mutation>
                                }
                            </Mutation>
                        }
                    </Mutation>
                </Col>
            </Row>
        </div>
    }
}

export default compose(
    graphql(getAllRoles, {name: 'rolesQuery'}),
    graphql(getAllPermissions, { name: 'permissionsQuery'}),
    graphql(allUiConfigurationsQuery, { name: 'uiConfigurationsQuery'}),
    graphql(allUiMenuItemsQuery, { name: 'uiMenuItemsQuery'}))(Roles);
