import React, {Component} from 'react';
import BasicQueryComponent from "../BasicQueryComponent";

class DataRepeat extends BasicQueryComponent {

    constructor(props) {
        super(props);
    }

    recursiveMap(children, extraProps) {
        return React.Children.map(children, child => {
            if (!React.isValidElement(child)) {
                return child;
            }

            if (child.props.children) {
                child = React.cloneElement(child, {
                    children: this.recursiveMap(child.props.children, extraProps),
                    ...extraProps
                });
            }

            return React.cloneElement(child, extraProps);
        });
    }


    render() {
        let children = [];
        console.log("data is: " + JSON.stringify(this.props.data));
        this.props.data.forEach(entry => {
            console.log("entry is: " + JSON.stringify(entry));
            const data = this.transformData(entry, this.props.expression);
            console.log("entry data is: " + JSON.stringify(data));
            //children = [this.recursiveMap(this.props.children, {dataKey: data}), ...children];
        });
        //
        console.log(this.props.children);
        return this.props.children;
    }
}

export default DataRepeat;
