import React, {Component} from 'react';
import {getBusinessPartnerShortQuery} from '../../queries/Queries'
import QueryDataGrid from "../../components/QueryDataGrid";
import Widget01 from '../../views/Widgets/Widget01';
import {Col, Dropdown, DropdownMenu, DropdownToggle, InputGroup, Row} from "reactstrap";
import TreeMenu from "react-simple-tree-menu";

const treeData = [
    {
        key: 'all',
        label: 'All Organizations'},
    {
        key: '0',
        label: 'Total Gas & Power',
        nodes: [
            {
                key: '06764C52E6494CE3B9C6BDCE742536E8',
                label: 'CCS Energy',
                nodes: [
                    {
                        key: '07C034249D7543308B29C26B7C1C4B64',
                        label: 'Manchester City Council',
                        nodes: [] // you can remove the nodes property or leave it as an empty array
                    },

                    {
                        key: 'D491E5F963F54E46A4A8C7DE2BE49684',
                        label: 'Liverpool City Council',
                        nodes: [] // you can remove the nodes property or leave it as an empty array
                    },
                ],
            },
        ],
    },
];


class BusinessPartnersList extends Component{
    constructor(props) {
        super(props);
        this.state = {isOpen: false, selectedOrganization: 'all', selectedOrganizationName: 'All Organizations'};
        this.toggle = this.toggle.bind(this);
    }


    toggle() {
        this.setState({isOpen: !this.state.isOpen});
    }


    render(){
        let orgFilter;
        if (this.state.selectedOrganization && this.state.selectedOrganization != 'all') {
            const orgs = this.state.selectedOrganization.split("/");
            orgFilter = orgs[orgs.length-1];
        } else {
            orgFilter = '';
        }

        const initialWhere = `('${orgFilter}' = '' or organization.id = '${orgFilter}') and businessPartnerCategory.id != '3D2AD5DCF936489EA76DF96CA01C7427'`;

        return (
            <div className="manage-users">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Customers</h1>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <Row>
                        <Col sm="12">
                            <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}
                                      className={'ticket-module-selector'}>
                                <DropdownToggle caret>
                                    <i className={this.state.dropDownIcon}></i>{this.state.selectedOrganizationName}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <TreeMenu
                                        data={treeData}
                                        onClickItem={({ key, label, ...props }) => {
                                            this.setState({selectedOrganization: key, selectedOrganizationName: label,  isOpen: false});
                                        }}
                                        hasSearch={false}
                                        initialActiveKey='all' // the path to the active node
                                        debounceTime={125}>
                                    </TreeMenu>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <QueryDataGrid variables={{ where: initialWhere, orderBy : "name"}}
                                           columns={[{
                                               dataField: 'name',
                                               text: 'Business Partner Name'
                                           }, {
                                               dataField: 'isMdmIslegalentity',
                                               text: 'Customer Type',
                                               formatter: (cell, row) => cell ? "Legal Entity" : "Private Estate"
                                           }, {
                                               dataField: 'isMdmIslegalentity',
                                               text: 'Customer Number',
                                               formatter: (cell, row) => cell ? row.mdmEik :row.mdmPersonalno
                                           }, {
                                               dataField: 'businessPartnerCategory.name',
                                               text: 'Category'
                                           }]}

                                           filters={[
                                               {
                                                   type: "text",
                                                   fields: ["name"],
                                                   placeholder: "Search by name",
                                                   classes:'search'
                                               }
                                           ]}
                                           keyField={'id'}
                                           dataPath={'businessPartner'}
                                           query={getBusinessPartnerShortQuery}
                                           serverSidePaging

                                           onSelect={row =>  {
                                               this.props.history.push('/view/customer/' + row.id);
                                           }}/>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}


export default BusinessPartnersList;
