import React, {Component} from 'reactn';
import {
    Col,
    InputGroup,
    Input,
    Nav,
    NavItem,
    NavbarToggler,
    NavbarBrand,
    NavLink,
    Badge,
    Row, DropdownItem, Dropdown, DropdownToggle, DropdownMenu,
} from 'reactstrap';
import Search from '../../components/Search';
import i18n from "../../views/Pages/Login/i18n";
import HeaderDropdown from './HeaderDropdown';
import {getBusinessPartnerShortQuery} from "../../queries/Queries"
import logo from '../../images/total-logo.png'
import {Link, withRouter} from "react-router-dom";
import $ from "jquery";
import jQuery from "jquery";
import {Query} from "react-apollo";
import gql from "graphql-tag";


const templateFromDatabase = `<div className="user-row">
    <div className="user-col"><i className="icon ion-person"></i></div>
    <div className="user-col"><Highlighter search="'ni'"><%= name %></Highlighter></div>
    <div className="user-col"><%= email %></div>
    <div className="user-col"><%= phone %></div>
</div>`;


const filterByFileds = ["name", "phone", "email", "billingAccountNumber", "meterpoint"];
// /bp.ogranization.id
//'<%= orgQuery %>'

const searchWhere = "";
    /*
    "('<%= org %>' = '' or organization.id = '<%= org %>') and (lower(name) like '%<%= query %>%'" +
    " or id in (select bp.id from BusinessPartner bp join bp.cRMBpartnerContactList u where u.user.mdmIsowner='Y' and (u.user.email like '%<%= query %>%' or u.user.phone like '%<%= query %>%'))" +
    " or id in (select bp.id from BusinessPartner bp join bp.bILLBillingAccountList u where lower(u.billingAccountNumber) like '%<%= query %>%')" +
    " or id in (select bp.id from BusinessPartner bp join bp.mDMBPEstateList u where u.id in (select e.id from MDM_BPEstate e join e.mDMEstate es join es.mDMMeterPointList v where v.name like '%<%= query %>%')))";
    */
const searchOrderBy = "name";


const jsonataTemplate = '[$.{"name" : name,' +
    ' "id" : id,' +
    ' "phone" : (cRMBpartnerContactList.user[isMdmIsowner=true])[0].phone & "",' +
    ' "email" : (cRMBpartnerContactList.user[isMdmIsowner=true])[0].email & "",' +
    ' "billingAccountNumber": bILLBillingAccountList.billingAccountNumber,' +
    ' "meterpoint": mDMBPEstateList.mDMEstate.mDMMeterPointList.name}]';

class Header extends Component {

    sidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    sidebarMinimize(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-minimized');
    }

    mobileSidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    asideToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('aside-menu-hidden');
    }

    componentDidMount() {
        (function ($, window) {
            'use strict';
            $(function () {
                var windowWidth = $(window).width();
                var $mainMenu = $('.main-menu');
                var $menuBtnOpen = $('.js-btn-open');
                var $menuBtnClose = $('.js-btn-close');
                var $blockSharing = $('.block-sharing-search');
                var $menuContent = $('.js-menu-content');
                var $hoverFlying = $('.hover-flying');
                var $hoverFlyingActive = $('.hover-flying-active');
                var $activeMainLink = $('.js-hover-flying-active');
                var menuOpenClass = 'menu-mob__open';
                var searchOpenClass = 'sharing-search__open';
                var activeClass = 'on';
                var hoverFlyingDuration = 300;
                var defaultHoverFlyingPosition = 0;
                var defaultHoverFlyingWidth = 0;
                var defaultHoverFlyingOpacity = 0;

                // Hover inner mega menu
                $('.mega-menu-sub').each(function () {
                    var $defaultItem = $('.mega-menu-sub-item.js-default-active', this);
                    $('.mega-menu-sub-item', this).hover(function () {
                        $defaultItem.removeClass(activeClass);
                        $(this).addClass(activeClass);
                    }, function () {
                        $(this).removeClass(activeClass);
                        $defaultItem.addClass(activeClass);
                        $(".diagonal-helper").removeAttr("style");
                    });
                });
                $(".diagonal-helper").hover(function () {
                    $(this).hide(1500);
                });
                $('.mega-menu-item').hover(function () {
                }, function () {
                    $(".diagonal-helper").removeAttr("style");
                });

                // Menu mobile
                $menuBtnOpen.on('click tap taphold', function (e) {
                    $(this).hide();
                    $blockSharing.addClass(searchOpenClass);
                    $menuBtnClose.show();
                    $menuContent.slideDown();
                    $mainMenu.addClass(menuOpenClass);
                    e.preventDefault();
                });

                $menuBtnClose.on('click tap taphold', function (e) {
                    $(this).hide();
                    $menuBtnOpen.show();
                    $mainMenu.removeClass(menuOpenClass);
                    $blockSharing.removeClass(searchOpenClass);
                    $menuContent.slideUp();
                    e.preventDefault();
                    return false;
                });

                /* Menu mobile */
                $(window).resize(function () {
                    var newWindowWidth = jQuery(window).width();
                    if (getScreenSizeMenu(newWindowWidth) !== getScreenSizeMenu(windowWidth)) {
                        if ($(window).width() < 959) {
                            $menuBtnClose.hide();
                            $menuBtnOpen.show();
                            $menuContent.slideUp('normal', function () {
                                $mainMenu.removeClass(menuOpenClass);
                            });
                        } else {
                            $menuContent.show();
                        }
                    }
                    windowWidth = $(window).width();
                });

                function getScreenSizeMenu(resolution) {
                    var result = 1;
                    if (resolution >= 959) {
                        result = 2;
                    }
                    return result;
                }

                // Hover flying
                setDefaultValuesHoverFlying();
                $('.js-hover-flying, .js-hover-flying-active').mouseenter(function () {
                    setPositionHoverFlying($(this));
                    if ($(this).find('.mega-menu-sub').length) {
                        $('body').addClass('layered');
                        $('.menu-overlay').show();
                    }
                });
                $('.js-hover-flying, .js-hover-flying-active').mouseleave(function () {
                    $('body').removeClass('layered');
                    $('.menu-overlay').hide();
                });

                $menuContent.mouseleave(function () {
                    $hoverFlying.stop().animate({
                        opacity: defaultHoverFlyingOpacity,
                        left: defaultHoverFlyingPosition,
                        width: defaultHoverFlyingWidth
                    }, hoverFlyingDuration);
                });

                /* Hover Flying functions */
                function setDefaultValuesHoverFlying() {
                    if ($activeMainLink.length) {
                        defaultHoverFlyingPosition = 0;
                        defaultHoverFlyingWidth = 0;
                        defaultHoverFlyingOpacity = 0;
                        setPositionActiveHoverFlying($activeMainLink);
                    }
                }

                function setPositionHoverFlying($link) {
                    var values = calculatePositionHoverFlying($link);
                    $hoverFlying.animate({
                        opacity: 1,
                        left: values.position,
                        width: (values.width - 20)
                    }, hoverFlyingDuration);
                }

                function setPositionActiveHoverFlying($link) {
                    var values = calculatePositionHoverFlying($link);
                    // Avoid weird apparition of normal border bar.
                    $hoverFlying.animate({
                        opacity: 0,
                        left: values.position,
                        width: (values.width - 20)
                    }, hoverFlyingDuration);

                    $hoverFlyingActive.animate({
                        opacity: 1,
                        left: values.position,
                        width: (values.width - 20)
                    }, hoverFlyingDuration);
                }

                var navbar = jQuery('.sliding-menu');
                var navbarHeight = navbar.offset()['top'];
                var sloganHeight = 37;
                var mobileHeaderBreakpoint = 960;
                var header = jQuery('header.region');
                var headerTop = jQuery('.header-top.display-none-mob.inner.clearfixlead-text-empty');

                function setStickyMenu() {
                    var topOffset = jQuery(window).scrollTop();
                    var windowWidth = jQuery(window).width();
                    var activeMenu = jQuery('.js-hover-flying-active');
                    if ((topOffset + sloganHeight >= navbarHeight || windowWidth < mobileHeaderBreakpoint) && !navbar.hasClass('sticky-menu')) {
                        navbar.addClass('sticky-menu');
                        headerTop.addClass('header-top-hidden');
                        if (activeMenu.length > 0) {
                            jQuery('.hover-flying-active').animate({
                                left: activeMenu.position().left + 100
                            }, 300);
                        }
                    } else if ((topOffset + sloganHeight < navbarHeight || topOffset === 0) && navbar.hasClass('sticky-menu') && windowWidth >= mobileHeaderBreakpoint) {
                        navbar.removeClass('sticky-menu');
                        headerTop.removeClass('header-top-hidden');
                        if (activeMenu.length > 0) {
                            jQuery('.hover-flying-active').animate({
                                left: activeMenu.position().left
                            }, 300);
                        }
                    }
                    if (!navbar.hasClass('sticky-menu')) {
                        header.height(header.height());
                    } else {
                        header.height("auto");
                    }
                }

                setStickyMenu();

                jQuery(window).scroll(setStickyMenu);

                function calculatePositionHoverFlying($link) {
                    var width = $link.innerWidth();
                    var position = $link.position().left;

                    return {
                        width: width,
                        position: position
                    };
                }
            });


            /* Language switcher */
            var lswitch = $('.sliding-menu .lang-switcher');
            var langs = $('a', lswitch);

            if (lswitch.length) {
                lswitch.on('click ', function () {
                    $(this).toggleClass('active');
                });

                langs.on('click ', function () {
                    $(this).prependTo(lswitch);
                });
            }

            // Search
            var search = $('.search-cta');
            var ctaToHide = $('.user-cta, .msg-cta, .lang-switcher');
            var searchbar = $('.search-bar-header');
            var searchClose = $('.search-bar-header .search-bar--close');
            var searchResult = $('.search-results');
            var searchText = $('.search-autocomplete input.rbt-input-mainf[type=text]');

            if (search.length) {
                search.on('click ', function () {

                    searchbar.toggleClass('hidden');
                    $('.sliding-menu').addClass('with-search-bar');
                    $('header').addClass('search-bar-open');

                    if ($('.sliding-menu').hasClass('sticky-menu')) {
                        // menu sticky
                        $('.search-results').addClass('sticky-search');
                        $('.search-bar-header').addClass('sticky-search');

                    } else {
                        $('.sliding-menu').hide();
                    }
                    searchText.focus();
                    searchResult.toggleClass('hidden');
                    $('body').css("overflow", '');
                    //$('body').toggleClass('stop-scrolling').addClass('layered').find('.menu-overlay').show();
                });

                searchClose.on('click', function () {
                    searchbar.toggleClass('hidden');
                    $('.sliding-menu').show();
                    searchResult.toggleClass('hidden').find('.search-results-data').empty();
                    searchResult.find('.no-result-header').addClass('hidden');
                    searchText.val('');
                    $('body').toggleClass('stop-scrolling').removeClass('layered');


                    $('.sliding-menu').removeClass('with-search-bar');
                    $('header').removeClass('search-bar-open');
                    $('.search-results').removeClass('sticky-search');
                    $('.search-bar-header').removeClass('sticky-search');


                });

                $('body').on("click", function (evt) {
                    if (!evt.target.classList.contains(".search-cta") && $(evt.target).parents(".search-cta").length === 0) {
                        if ($(".main-menu .total-menu").is(".search-menu-expanded") || search.parents(".search-cta").is(".active") || ctaToHide.is(".wait-search")) {
                            $('.main-menu .total-menu').toggleClass('search-menu-expanded');
                            search.parents('.search-cta').toggleClass('active');
                            search.siblings(".form-text").blur();
                            ctaToHide.toggleClass('wait-search');
                            $('.sliding-menu').removeClass('with-search-bar');
                            $('header').removeClass('search-bar-open');
                        }
                    }
                    if (evt.target.classList.contains('menu-overlay') && !searchbar.hasClass('hidden')) {
                        $('.search-bar--close').trigger('click');
                    }
                });
            }

            /* Menu */
            var toggle = $('.sliding-menu-toggle');
            var menu = $('.sliding-menu');
            var subMenu = $('.sliding-sub-menu');
            var menuTab = $('.sliding-menu .distinction span');
            var placeHolders = $('.sliding-menu .sliding-menu-item .menu-title.placeholder');
            var menuList = $('.sliding-menu-list');
            var menuItem = $('.sliding-menu-item.menu-item');
            var menuItems = $('.items-parent', menuList);
            var backBtn = $('.back-link', subMenu);
            var overlay = $('body .menu-overlay');
            var menuHeight = 60;

            $('.sliding-menu-content').css('height', ($(window).height() - menuHeight) + 'px');
            $(window).resize(function () {
                $('.sliding-menu-content').css('height', ($(window).height() - menuHeight) + 'px');
            });

            if (overlay.length === 0) {
                overlay = $('<div class="menu-overlay">');
                $('body').append(overlay);
            }

            if (menu.length) {
                toggle.on('click ', function () {

                    if ($(this).hasClass('menu-active')) {
                        // Reset the menu.
                        menu.find('.sliding-menu-item.display-hidden').removeClass('c');
                        $('.sliding-menu-content>.sliding-menu-list').removeAttr('style');
                    }

                    $(this).toggleClass('menu-active');
                    menu.toggleClass('active');
                    $('body').toggleClass('layered');
                    menuItems.removeClass('active-section');
                });

                menuTab.on('click ', function () {
                    menuTab.removeClass('active-menu');
                    $(this).addClass('active-menu');
                    menuList.removeClass('visible-menu').eq($(this).index()).addClass('visible-menu');
                });

                $('a:first', menuItems).on('click ', function (e) {
                    e.preventDefault();
                });

                // Prevent placeholders to change the menu behaviour.
                placeHolders.on('click', function (e) {
                    e.stopPropagation();
                });

                menuItems.on('click ', function () {
                    var activeSectionParents = $(this).closest('.active-section');
                    var menuParent = $(this).parent('.visible-menu');
                    $(this).addClass('active-section');
                    var heightMenu;

                    // Hide the others first lvl menu items that are not opened to prevent blank spaces.
                    menuList.each(function () {
                        if ($(this).children('.active-section').length < 1) {
                            $(this).css('display', 'none');
                        }
                    });

                    if (menuParent.length === 1) {
                        menuParent.find('>.sliding-menu-item:not(.active-section)').addClass('display-hidden');

                        // Set the height of the menu if we are at the first level.
                        if (!$(this).parent('.visible-menu').attr("style")) {
                            heightMenu = 0;
                            menuParent.find('>.sliding-menu-item.active-section').children('.sliding-sub-menu').children('li').each(function () {
                                heightMenu += $(this).innerHeight();
                            });
                            $(this).parent('.visible-menu').height(heightMenu);
                        }
                    } else if (activeSectionParents.length === 1) {
                        activeSectionParents.find('>ul>.sliding-menu-item:not(.active-section)').addClass('display-hidden');

                        // Set the height of the menu.
                        heightMenu = 0;
                        activeSectionParents.find('>ul>.sliding-menu-item.active-section').children('.sliding-sub-menu').children('li').each(function () {
                            heightMenu += $(this).innerHeight();
                        });
                        $('.sliding-menu-content>.sliding-menu-list').first().height(heightMenu);
                    }
                });

                menuItem.on('click ', function () {
                    toggle.toggleClass('menu-active');
                    menu.toggleClass('active');
                    $('body').toggleClass('layered');
                    menuItems.removeClass('active-section');
                });

                backBtn.on('click ', function (e) {
                    e.stopPropagation();

                    var activeSectionParents = $(this).parents('.active-section');
                    var closestActiveSection = $(this).closest(".active-section");
                    var menuParent = closestActiveSection.parent('.visible-menu');

                    // if we are in a submenu.
                    if (closestActiveSection.parents('.sliding-sub-menu').length > 0) {
                        var heightMenu = 0;
                        closestActiveSection.closest('.sliding-sub-menu').children('li').each(function () {
                            heightMenu += $(this).innerHeight();
                        });
                        $('.sliding-menu-content>.sliding-menu-list').height(heightMenu);
                    } else {
                        $('.sliding-menu-content>.sliding-menu-list').removeAttr('style');
                    }

                    closestActiveSection.removeClass('active-section');
                    if (menuParent.length === 1) {
                        menuParent.find('>.sliding-menu-item:not(.active-section)').removeClass('display-hidden');
                    } else if (activeSectionParents.length === 2) {
                        $(this).closest(".active-section").find('>ul>.sliding-menu-item:not(.active-section)').removeClass('display-hidden');
                    }
                });

                overlay.on('click tap taphold', function (e) {
                    e.preventDefault();
                    if ($(this).closest('body').hasClass('layered') && searchbar.hasClass('hidden')) {
                        toggle.click();
                    }
                });
            }


        }(jQuery, window));
    }

    componentDidUpdate() {
        //$('.search-bar-header .search-bar--close').click();
    }
    constructor() {
        super();
        this.state = {dropdownOpen: false}
    }
    render() {
        if (this.global.permissions && this.props.nav) {
            return (
                <header className="app-header navbar region region-header">
                    <div className="header-top display-none-mob inner clearfixlead-text-empty">
                        <div className="header-top-logo">
                            <a href="/" title="Back to main page" itemProp="url"><img itemProp="logo"
                                                                                      data-hd-screen="https://www.gas-power.total.co.uk/sites/g/files/wompnd341/f/logo_2017-v2.png"
                                                                                      typeof="foaf:Image"
                                                                                      src={logo}
                                                                                      alt="Back to main page"/></a>
                        </div>
                        <div className="header-top-slogan">
                            <span>TotalEnergies Gas &amp; Power</span>
                        </div>
                        <div className="header-top-menu">
                            <ul className="display-none-mob border-right">
                            {
                                this.props.messages && this.props.messages.totalElements ?
                                (<Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.setState({dropdownOpen : !this.state.dropdownOpen})}><DropdownToggle
                                        tag="li"><Link className="link-total" to={""} onClick={e => {e.preventDefault()}}><i
                                className="fa fa-bell"></i>&nbsp;{this.props.messages.totalElements} new messages</Link></DropdownToggle><DropdownMenu>
                                {this.props.messages.content.map(item => item.account.properties && item.account.properties.length > 0 && <DropdownItem onClick={() => this.props.history.push('/view/site/'  + (item.account.properties.filter(p => p.status=='Active').length &&  item.account.properties.filter(p => p.status=='Active') || item.account.properties)[0].id + (item.account && item.account.customer && item.account.customer.contracts && item.account.customer.contracts.length > 0 ? '/' + item.account.customer.contracts[0].product : '') + '/tab_site_messages')}>{item.type} for site {(item.account.properties.filter(p => p.status=='Active').length &&  item.account.properties.filter(p => p.status=='Active') || item.account.properties)[0].site.name}</DropdownItem>)}</DropdownMenu></Dropdown>) :
                                (<li><Link
                                    to={""}><i
                                    className="fa fa-bell"></i>&nbsp;no messages</Link></li>)
                            }
                            </ul>
                            <ul className="display-none-mob border-right">
                                <li><Link
                                    to={"/profile/" + this.global.user.username}><i
                                    className="fa fa-user"></i>&nbsp;Profile</Link></li>
                            </ul>
                            <ul className="display-none-mob">
                                <li><Link to="/changepassword"><i className="fa fa-key"></i>&nbsp;Change Password</Link>
                                </li>
                            </ul>
                            <Link title="Logout"
                                  className="link-total top-menu-link border-left"
                                  aria-label="Total.com (New Window)" to="/logout"><i
                                className="fa fa-lock"></i>&nbsp;Logout</Link></div>
                    </div>
                    <div className="sliding-menu has-mega-menu">
                        <div className="sliding-menu-header">
                            <div className="wrapper wrapper2">
                                <div className="sticky-logo sticky-visible">
                                    <a href="/" title="Back to main page" itemProp="url"><img itemProp="logo"
                                                                                              data-hd-screen="https://www.gas-power.total.co.uk/sites/g/files/wompnd341/f/logo_2017-v2.png"
                                                                                              typeof="foaf:Image"
                                                                                              src={logo}
                                                                                              alt="Back to main page"/></a>
                                </div>
                                <div className="sticky-top-slogan sticky-visible">
    <span>
      TotalEnergies Gas &amp; Power    </span>
                                </div>
                                <div className="menu-container js-menu-content clearfix">
                                    <div id="block-total-webfactory-main-menu-total-main-menu--3"
                                         className="block block-total-webfactory-main-menu">
                                        <div>
                                            <nav className="main-menu">
                                                <div className="menu-container mega-menu js-menu-content">
                                                    <nav role="navigation">
                                                        <ul className="total-menu two_lines">
                                                            {this.props.nav.items.map( (item, index) =>

                                                                <li key={index} className="mega-menu-item js-hover-flying ">
                                                                    <div className="link-wrapper">
                                                                        <a href={"/#" + item.url}
                                                                           className="menu-mega-link hover-help nolink"
                                                                           tabIndex="0">
                                                                            {item.name}
                                                                        </a>

                                                                    </div>
                                                                </li>

                                                            )}
                                                            {/*<li className="mega-menu-item js-hover-flying ">
                                                                <div className="link-wrapper">
                                                                    <a href="/help-support"
                                                                       className="menu-mega-link hover-help menu-mega-link">Administration</a>
                                                                </div>
                                                            </li>*/}
                                                        </ul>
                                                        <span className="hover-flying"></span>
                                                        <span className="hover-flying-active"></span>
                                                    </nav>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="sliding-menu-right">
                                    <div className="cta-block">
                                        <div className="cta">


                                            <div className="hidden sliding-menu-right--cta search-cta" role="search"
                                                 aria-label="Search">
                                                <div className="search-cta--text">Search</div>
                                                <form className="">
                                                    <div>
                                                        <fieldset>
                                                            <label className="element-invisible"
                                                                   htmlFor="edit-keys-1">Search </label>
                                                            <input autoComplete="off" placeholder="Search" type="text"
                                                                   id="edit-keys-1" name="keys" value="" size="15"
                                                                   maxLength="128" className="form-text"/><span
                                                            className="submit-btn"></span> <input
                                                            className="form-submit icons-btn-search-header form-submit"
                                                            type="submit" id="edit-submit" name="op" value="Search"/>
                                                            <input type="hidden" name="form_build_id"
                                                                   value="form-ENsL3oNzXRZuhj72AGYYcO7Keg8xQQ2gYgUiz7CnawI"/>
                                                            <input type="hidden" name="form_id"
                                                                   value="search_block_form"/>
                                                        </fieldset>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="sliding-menu-toggle">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sliding-menu-content">
                            <ul className="sliding-menu-list visible-menu">
                                {this.props.nav.items.map( (item, index) =>
                                    <li key={index} className="sliding-menu-item menu-item"><a href={"/#" + item.url}>
                                        {item.name}
                                    </a></li>
                                )}
                                <li className="sliding-menu-item items-parent">
                                    {this.props.messages && this.props.messages.totalElements ? (
                                        [
                                            <a href="#">
                                                <i className="fa fa-bell"></i>&nbsp;{this.props.messages.totalElements}{" "}
                                                new messages
                                            </a>,
                                            <ul className="sliding-sub-menu">
                                                <li className="back-link">
                                                    <a href="#">Back</a>
                                                </li>
                                                {this.props.messages.content.map((item) => (
                                                    <li className="sliding-menu-item menu-title">
                                                        {item.account.properties.length > 0 ? (
                                                            <Link
                                                                to={
                                                                    "/view/site/" +
                                                                    ((item.account.properties.filter((p) => p.status == "Active")
                                                                        .length &&
                                                                        item.account.properties.filter(
                                                                            (p) => p.status == "Active"
                                                                        )) ||
                                                                        item.account.properties)[0].id +
                                                                    "/tab_site_messages"
                                                                }
                                                            >
                                                                {item.type} for site{" "}
                                                                {
                                                                    ((item.account.properties.filter((p) => p.status == "Active")
                                                                        .length &&
                                                                        item.account.properties.filter(
                                                                            (p) => p.status == "Active"
                                                                        )) ||
                                                                        item.account.properties)[0].site.name
                                                                }
                                                            </Link>
                                                        ) : (
                                                            item.type
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>,
                                        ]
                                    ) : (
                                        <div></div>
                                    )}
                                </li>
                            </ul>
                            <ul className="sliding-menu-list visible-menu">
                                <li className="sliding-menu-item menu-item"><Link
                                    to={"/profile/" + this.global.user.username}><i
                                    className="fa fa-user"></i>&nbsp;Profile</Link>
                                </li>
                                <li className="sliding-menu-item menu-item"><Link to="/changepassword">
                                    CHANGE PASSWORD
                                </Link></li>
                                <li className="sliding-menu-item menu-item"><Link title="Logout"
                                  aria-label="Total.com (New Window)" to="/logout"><i
                                    className="fa fa-lock"></i>&nbsp;Logout</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="search-bar-header hidden">
                        <div className="search-bad-header--input inner">
                            {this.global.permissions.includes('Customer.Search') &&
                            <Search query={getBusinessPartnerShortQuery}
                                    template={templateFromDatabase}
                                    filterByFields={filterByFileds}
                                    jsonata={jsonataTemplate}
                                    where={searchWhere}
                                    orderBy={searchOrderBy}/>}
                            <div className="search-bar--close">
                                <div className="search-bar--close-icon"></div>
                            </div>
                        </div>
                    </div>

                </header>
            );

        } else {
            return (
                <header role="banner" className="app-header navbar region region-header">
                    <div className="header-top display-none-mob inner clearfixlead-text-empty">
                        <div className="header-top-logo">
                           <img itemProp="logo"
                                                                                      data-hd-screen="https://www.gas-power.total.co.uk/sites/g/files/wompnd341/f/logo_2017-v2.png"
                                                                                      typeof="foaf:Image"
                                                                                      src={logo}
                                                                                      alt="Back to main page"/>
                        </div>


                        <div className="header-top-slogan">
    <span>
      TotalEnergies Gas &amp; Power    </span>
                        </div>


                        <div className="header-top-menu" style={{display: 'none'}}>
                            <ul className="display-none-mob border-right">
                                <li><a
                                    href="https://services.totalgp.com/c/portal/login?__hstc=250381657.10ffb8b0bcf9b7531edde25df6681432.1572009265395.1573727692631.1573734941203.19&amp;__hssc=250381657.7.1573734941203&amp;__hsfp=2749065856"
                                    target="_blank">Log-on</a></li>
                                <li><a href="http://www.careers.total.com/" target="_blank">Careers</a></li>
                            </ul>
                            <ul className="display-none-mob">
                                <li><a href="/information-centre/about-us">About us</a></li>
                            </ul>
                            <a href="http://total.com/en" title="Total.com" target="_blank"
                               className="link-total top-menu-link border-left"
                               aria-label="Total.com (New Window)">Total.com</a></div>
                    </div>

                    <div className="sliding-menu has-mega-menu">
                        <div className="sliding-menu-header">
                            <div className="wrapper">
                                <div className="sticky-logo sticky-visible">
                                    <img itemProp="logo"
                                         data-hd-screen="https://www.gas-power.total.co.uk/sites/g/files/wompnd341/f/logo_2017-v2.png"
                                         typeof="foaf:Image"
                                         src={logo}
                                         alt="Back to main page"/>
                                </div>
                                <div className="sticky-top-slogan sticky-visible">
                                    <span>TotalEnergies Gas &amp; Power</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="search-bar-header hidden">
                        <div className="search-bad-header--input inner">
                            <form className=""
                                  action="/business-gas-electricity/business/submit-meter-reading/submit-your-electricity-meter-reading"
                                  method="post" id="search-api-page-search-form" acceptCharset="UTF-8">
                                <div>
                                    <fieldset>
                                        <label className="element-invisible" htmlFor="edit-keys-1">Search </label>
                                        <input autoComplete="off" placeholder="Search" type="text" id="edit-keys-1"
                                               name="keys" value="" size="15" maxLength="128"
                                               className="form-text"/><span className="submit-btn"/> <input
                                        className="form-submit icons-btn-search-header form-submit" type="submit"
                                        id="edit-submit" name="op" value="Search"/> <input type="hidden"
                                                                                           name="form_build_id"
                                                                                           value="form-ENsL3oNzXRZuhj72AGYYcO7Keg8xQQ2gYgUiz7CnawI"/>
                                        <input type="hidden" name="form_id" value="search_block_form"/>
                                    </fieldset>
                                </div>
                            </form>
                            <div className="search-bar--close">
                                <div className="search-bar--close-icon"></div>
                            </div>
                        </div>
                    </div>

                    <div className="search-results inner hidden">
                        <div className="search-results-data inner"></div>
                        <p className="no-result-header hidden">Sorry, your search did not return any results.
                            Double-check your spelling or try a new search.</p></div>
                </header>
            );
        }
    }
}

export default withRouter(Header);
