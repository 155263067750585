import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";

class DownloadReport extends Component {
    constructor(props) {
        super(props);

        let path = this.props.location.pathname;
        let index = this.getPosition(path, '/', 2) + 1;
        let urn = path.substring(index);

        index = path.lastIndexOf('.') + 1;
        let format = path.substring(index);
        this.state = {urn: urn, format: format};

        this.onSubmit = this.onSubmit.bind(this);
        this.download = this.download.bind(this);
        this.getPosition = this.getPosition.bind(this);
    }

    componentDidMount() {
        this.download();
    }

    onSubmit() {
        this.download();
    }

    getPosition(string, subString, index) {
        return string.split(subString, index).join(subString).length;
    }

    download() {
        let urn = this.state.urn;
        let format = this.state.format;
        axios.get(`${CDN_URL}/cdn/resource/${urn}/download`, {
            responseType: 'blob'
        }).then((response) => {
            fileDownload(response.data, 'ScheduledReport' + moment(new Date()).format('YYYYMMDD-HHmm') + '.' + format);
        }).catch((error) => {
            console.log('there was an error downloading the report', error);
        });
    }

    render() {
        return (
            <form ref="form" onSubmit={this.onSubmit}>
                <div className="download-panel">
                    <h4>Your file should download automatically.</h4>
                    <h5>If it doesn't start within a few moments, try using the link below.</h5>

                    <button type="submit" className="btn btn-primary download-btn">Download</button>
                </div>
            </form>
        );
    }
}

export default withRouter(DownloadReport);
