import React, {Component} from 'react';

import i18n from '../../views/Pages/Login/i18n';
import {Mutation, Query} from 'react-apollo';
import {periodsQuery, startProcessMutation} from "../../queries/Queries";
import Form from "react-jsonschema-form";
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";
import {Button} from "reactstrap";
import Loading from "../../components/Loading"

class ProcessesDetail extends Component {

    constructor(arg) {
        super(arg);
        this.state = {
            buttonStyle: {visibility: "hidden", isLoading: false, period: null}
        }
    }

    render() {return <Mutation mutation={startProcessMutation} refetchQueries={[]}>
        {(startProcess, {data}) => (<Query query={periodsQuery}>
                {(result) => {
                    if (result.loading) return (<Loading/>);
                    if (result.error) return <div>{result.error} </div>;
                    const { data } = result;
                    const loadingIcon = this.state.isLoading ? (<span><i style={this.state.buttonStyle} className='fa fa-circle-o-notch fa-spin'></i> </span>) : null;
                    return <Form schema={{
                                        title: "Start Billing Cycle",
                                        type: "object",
                                        properties: {
                                            period: {
                                                type: "string",
                                                title: "Period",
                                                enum: data.periods.map(({id})=>id),
                                                enumNames: data.periods.map(({name})=>name),
                                                default: this.state.period ? this.state.period : data.periods[0].id
                                            }
                                        }

                                    }}
                                 onSubmit={({formData}) => {
                                     this.setState({buttonStyle: {visibility: "visible"}, isLoading: true, period: formData.period});
                                     startProcess({
                                         variables:
                                             {
                                                 processId: this.props.processDefinitionId,
                                                 variables: [
                                                     {
                                                         name: "C_Period_ID",
                                                         type: "string",
                                                         value: formData.period
                                                     },
                                                     {
                                                         name: "periodid",
                                                         type: "string",
                                                         value: formData.period
                                                     }
                                                 ]
                                             }
                                     }).then(response => {
                                         this.setState({buttonStyle: {visibility: "hidden", isLoading: false}});
                                         if (response.data.startProcess) {
                                             this.props.onClose();
                                             ReactDOM.render(<ExpiringAlert color="success"
                                                                            message={i18n.t('processes.successfulProcessStart')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                         }
                                     });

                                 }}

                                 onChange={(event) => {
                                     event.formData;
                                 }}>
                        <div>
                            <Button type="submit" disabled={this.state.isLoading} color='primary'>{loadingIcon}Start Process</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button type="button" onClick={() => this.props.onClose()}>Cancel</Button>
                        </div>
                    </Form>
                }
                }
            </Query>
        )
        }
    </Mutation>
    }
}




export default ProcessesDetail;
