import React, { Component } from 'react';
import i18n from "../Pages/Login/i18n";
import { Col, Row, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from "reactstrap";
import QuotesGrid from "../../components/QuotesGrid/QuotesGrid";
import { Link } from 'react-router-dom';



class Quotes extends Component {
    render() {
        return (
            <div className={"organization"}>
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('Quotes')}</h1>
                    </Col>
                    <Col className="col-4">
                        <Link to={"/create-quote"}>
                            <Button className='pull-right' color="primary"> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;Add Quote </Button>
                        </Link>
                    </Col>
                </Row>
                <div className="page-cnt">
                     <QuotesGrid/>
                </div>
            </div>
        )
    }
}

export default Quotes;
