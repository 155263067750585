import React from 'react';
import { withRouter } from 'react-router';
import gql from "graphql-tag";
import BasicQueryComponent from '../BasicQueryComponent';
import { Query } from 'react-apollo';
import Loading from "../Loading";
import jsonata from "jsonata/jsonata-es5";

const style = { display: "flex", alignContent: "center", justifyContent: "center", padding: '100px 200px', textAlign: 'center' };

class AccessWrapper extends BasicQueryComponent {

    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
    }

    validate(data) {

        const { expression } = this.props;

        if (expression) return jsonata(expression).evaluate(data);
        return false;
    }

    render() {
        return (
            <Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={this.transformVariables()}>

                {(result) => {

                    if (result.loading) return <Loading />;
                    if (result.error) return <div>{result.error} </div>;

                    const valid = this.validate(result.data)

                    const { param } = this.props.match.params;

                    if (!valid) {
                        return (
                            <div style={style}>
                                <h3>You no longer have access to Account {param && param.length > 10 ? param.substring(param.length - 10): param}. Please contact your Account Manager</h3>
                            </div>
                        )
                    }

                    return <div>{this.props.children}</div>
                }}
            </Query>
        );
    }
}

export default withRouter(AccessWrapper);