import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Route, Redirect} from 'react-router-dom'

/**
 * Component that protects route from unauthorized users.
 * @type {Object}
 */
class PrivateRoute extends Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        canAccess: PropTypes.func,
        component: PropTypes.func,
        path: PropTypes.string,
        name: PropTypes.string,
        exact: PropTypes.bool,
        strict: PropTypes.bool
    }

    render() {
        const {canAccess, ...routeProps} = this.props;
        const redirectNeeded = "/login" + routeProps.location.pathname.replace(/^\/login|\/logout/,"") != routeProps.location.pathname;
        return canAccess() ? <Route {...routeProps} /> : redirectNeeded ? <Redirect to={"/login" + routeProps.location.pathname.replace(/^\/login|\/logout/,"")} /> : null
    }
}

export default PrivateRoute
