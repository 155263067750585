import React, {Component} from 'reactn';
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import {ValidatingFormGroup} from 'reactstrap-validation';
import Select from 'react-select';
import {compose, graphql, Query} from "react-apollo";
import i18n from '../Pages/Login/i18n';
import classnames from "classnames";
import 'react-select/dist/react-select.css';
import {getAllRoles, systemsQuery, organizationsQuery} from "../../queries/Queries";
import PasswordPolicy from "../../components/PasswordPolicy";
import Loading from "../../components/Loading";



class UserProfile extends Component {
    constructor(props){
        super(props);
    }
    render() {

        const isAdmin =  this.global.permissions.includes('Users.AssignRoles');
        // const initialValid  = true;
        const passwordField = (show) => {
            if (show) {
                return (
                    <ValidatingFormGroup trigger="change"  onStateChange={console.log}>
                        <Row>
                            <Col lg="4" xs="12">
                                <Label className='user-label'>
                                   {i18n.t('header.password')}
                                </Label>
                            </Col>
                            <Col lg="8" xs="12">
                                <Input required type="password" value={this.props.user.password}
                                    placeholder={i18n.t('header.passwordPlaceholder')}
                                    onChange={(e) => this.props.onChange("password", e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="4">
                            </Col>
                        <Col lg="8" xs="12">
                            <PasswordPolicy/>
                        </Col>
                        </Row>
                    </ValidatingFormGroup>)
            }
        };
        const phoneRepeater =  () => {
            let result = [];
            for (let i = 0; i < this.props.user.phoneNumbers.length; i++) {
                result.push(

                    <FormGroup>
                        <Row>
                            <Col xs={"12"}>
                                <Input type="text" value={this.props.user.phoneNumbers[i].phoneNumber}
                                    placeholder={i18n.t('header.phonePlaceholder')}

                                    onChange={(e) => {
                                        var newArr = this.props.user.phoneNumbers.slice();
                                        newArr[i].phoneNumber = e.target.value;
                                        this.props.onChange("phoneNumbers", newArr)
                                    }} /><br/>
                            </Col>
                            <Col xs={"4"}>
                                <Input type="radio" name="primaryNumber" id={'primaryNumber' + i} checked={this.props.user.phoneNumbers[i].primaryNumber}
                                    onChange={(e) => {
                                        var newArr = this.props.user.phoneNumbers.slice();
                                        for (let j = 0; j < newArr.length; j++) {
                                            newArr[j].primaryNumber = false;
                                        }
                                        newArr[i].primaryNumber = e.target.checked;
                                        this.props.onChange("phoneNumbers", newArr)
                                    }} />
                                <label htmlFor={'primaryNumber' + i} className="css-label">
                                    <i className="icon ion-android-radio-button-off"></i>
                                    <i className="icon ion-android-radio-button-on"></i>
                                    &nbsp;&nbsp;{i18n.t('header.primaryNumber')}
                                </label>
                            </Col>

                            <Col xs={"6"}>
                                <Input type="select" value={this.props.user.phoneNumbers[i].type}
                                    placeholder={i18n.t('header.typePlaceholder')}
                                    onChange={(e) => {
                                        var newArr = this.props.user.phoneNumbers.slice();
                                        newArr[i].type = e.target.value;
                                        this.props.onChange("phoneNumbers", newArr)
                                    }}>

                                    <option value="" disabled ></option>
                                    <option value="Mobile">{i18n.t('phoneTypes.mobile')}</option>
                                    <option value="Home">{i18n.t('phoneTypes.home')}</option>
                                    <option value="Office">{i18n.t('phoneTypes.office')}</option>
                                </Input>
                            </Col>
                            <Col xs={"2"}>
                                <Button onClick={(e) => {
                                    this.props.user.phoneNumbers.splice(i, 1);
                                    this.props.onChange("phoneNumbers", this.props.user.phoneNumbers)
                                }} color="primary">-</Button>
                            </Col>


                        </Row>
                    </FormGroup>
                );
            }

            result.push(
                <Row>
                    <Col xs={"1"}>
                        <Button onClick={(e) => {
                            this.props.user.phoneNumbers.push({ phoneNumber: "", type: "", primaryNumber: (this.props.user.phoneNumbers.length == 0) ? true : false });
                            this.props.onChange("phoneNumbers", this.props.user.phoneNumbers)
                        }} color="primary">+</Button>
                    </Col>
                </Row>);



            return result;
        }
        const systemsRepeater = () => {
            let result = [];
            for (let i = 0; i < this.props.user.userSystems.length; i++) {
                result.push(

                    <FormGroup>
                        <Row >
                            <Col xs={"12"}>
                                <Select clearable={false} options={this.props.getSystems.loading ? [{ value: null, label: "Loading...", disabled: true }] : this.props.getSystems.getSystems.map(({ systemName, displayName }) => ({ value: systemName, label: displayName }))}
                                    value={this.props.user.userSystems[i].system.systemName}
                                    placeholder={i18n.t('header.systemPlaceholder')}
                                    onChange={(e) => {
                                        var newArr = this.props.user.userSystems.slice();
                                        newArr[i].system.systemName = e.value;
                                        this.props.onChange("userSystems", newArr);
                                    }}
                                /><br />
                            </Col>
                            <Col xs={"4"}>
                                <Input required value={this.props.user.userSystems[i].systemUser}
                                    placeholder={i18n.t('header.username')}

                                    onChange={(e) => {
                                        var newArr = this.props.user.userSystems.slice();
                                        newArr[i].systemUser = e.target.value;
                                        this.props.onChange("userSystems", newArr);
                                    }}
                                />
                            </Col>

                            <Col xs={"4"}>
                                <Input required type="password" value={this.props.user.userSystems[i].systemPassword}
                                    placeholder={i18n.t('header.password')}
                                    onChange={(e) => {
                                        var newArr = this.props.user.userSystems.slice();
                                        newArr[i].systemPassword = e.target.value;
                                        this.props.onChange("userSystems", newArr);
                                    }}
                                />
                            </Col>
                            <Col xs={"4"}>
                                <div className="btn-group">
                                    <Button onClick={(e) => {
                                        this.props.user.userSystems.splice(i + 1, 0, { system: { systemName: "" } });
                                        this.props.onChange("userSystems", this.props.user.userSystems)
                                    }} color="primary">+</Button>

                                    <Button onClick={(e) => {
                                        this.props.user.userSystems.splice(i, 1);
                                        this.props.onChange("userSystems", this.props.user.userSystems)
                                    }} color="primary">-</Button>
                                </div>
                            </Col>

                        </Row>
                    </FormGroup>);
            }
            if (result.length == 0) {
                result.push(
                    <Row >
                        <Col xs={"1"}>
                            <Button onClick={(e) => {
                                this.props.user.userSystems.push({ system: { systemName: "" } });
                                this.props.onChange("userSystems", this.props.user.userSystems)
                            }} color="primary">+</Button>
                        </Col>

                    </Row>);
            }
            return result;
        }
        return (

            <Form id={"user-details"}>
                <FormGroup tag="fieldset">
                    <FormGroup>
                        <Row>
                            <Col lg="4" xs="12">
                                <Label className='user-label'>
                                    {i18n.t('header.username')}
                                </Label>
                            </Col>
                            <Col lg="8" xs="12">
                                <Input required disabled={!isAdmin} type="email" value={this.props.user.username}
                                    onChange={(e) => this.props.onChange("username", e.target.value)}/>
                            </Col>
                            <FormFeedback>
                                {i18n.t('user.validemail')}
                            </FormFeedback>
                        </Row>
                    </FormGroup>
                    {passwordField(this.props.showPasswordField)}
                    {/*<FormGroup check>
                        <Row>
                            <Col xs="4">
                                <Label className='user-label'>
                                    {i18n.t('header.enabled')}
                                </Label>
                            </Col>
                            <Col xs="8">
                                <Input className='role-field css-checkbox' type="checkbox" disabled={!isAdmin} value={this.props.user.enabled ? 'on' : 'off'} checked={this.props.user.enabled}
                                    onChange={
                                        (e) => this.props.onChange("enabled", e.target.checked)
                                    } id="enableuser" />
                                <label htmlFor="enableuser" className="css-label"><i className="icon ion-android-checkbox"></i></label>
                            </Col>
                        </Row>
                    </FormGroup>*/}
                    <FormGroup>
                        <Row >
                            <Col lg="4" xs="12">
                                <Label className='user-label'>
                                    {i18n.t('header.picture')}
                                </Label>
                            </Col>
                            <Col lg="8" xs="12">
                                <img src={this.props.user.avatarUrl} width={"32"} height={"32"} style={{borderRadius: "50%"}}/>
                                <Input
                                    type='file' accept="image/*,.jpg,.png,.gif"  label='Upload' style={{display:"inline"}}
                                    onChange={(e) => {
                                        this.props.onChange("avatarUrl", URL.createObjectURL(e.target.files[0]), "avatar", e.target.files[0]);
                                    }}
                                />
                                <p className="info">Square logo with minimum size 80 x 80px</p>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col lg="4" xs="12">
                                <Label className='user-label'>
                                    {i18n.t('header.title')}
                                </Label>
                            </Col>
                            <Col lg="8" xs="12">
                                <Input type="select" value={this.props.user.title} placeholder="" defaultValue=""
                                    onChange={(e) => this.props.onChange("title", e.target.value)}>{' '}
                                    <option value="" disabled >{i18n.t('header.titlePlaceholder')}</option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Ms.">Ms.</option>
                                </Input>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col lg="4" xs="12">
                                <Label className='user-label'>
                                    {i18n.t('header.firstName')}
                                </Label>
                            </Col>
                            <Col lg="8" xs="12">
                                <Input type="text" value={this.props.user.firstName}
                                    required
                                    placeholder={i18n.t('header.firstNamePlaceholder')}
                                    onChange={(e) => this.props.onChange("firstName", e.target.value)} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col lg="4" xs="12">
                                <Label className='user-label'>
                                    {i18n.t('header.middleName')}
                                </Label>
                            </Col>
                            <Col lg="8" xs="12">
                                <Input type="text" value={this.props.user.middleName}

                                    placeholder={i18n.t('header.middleNamePlaceholder')}
                                    onChange={(e) => this.props.onChange("middleName", e.target.value)} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col lg="4" xs="12">
                                <Label className='user-label'>
                                    {i18n.t('header.lastName')}
                                </Label>
                            </Col>
                            <Col lg="8" xs="12">
                                <Input type="text" value={this.props.user.lastName}

                                    placeholder={i18n.t('header.lastNamePlaceholder')}
                                    onChange={(e) => this.props.onChange("lastName", e.target.value)} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col lg="4" xs="12">
                                <Label className='user-label'>
                                    {i18n.t('header.phones')}
                                </Label>

                            </Col>
                            <Col lg="8" xs="12">
                                {phoneRepeater()}
                            </Col>
                        </Row>
                    </FormGroup>

                    {false && this.props.showSystems &&
                        <FormGroup>
                            <Row>
                                <Col lg="4" xs="12">
                                    <Label className='user-label'>
                                        {i18n.t('header.systemUsers')}
                                    </Label>

                                </Col>
                                <Col lg="8" xs="12">
                                    {systemsRepeater()}
                                </Col>
                            </Row>
                        </FormGroup>
                    }
                    {/*<FormGroup>
                        <Row>
                            <Col xs="4">
                                <Label className='user-label'>
                                    {i18n.t('header.jobTitle')}
                                </Label>
                            </Col>
                            <Col xs="8">
                                <Input type="text" value={this.props.user.jobTitle}

                                    placeholder={i18n.t('header.jobTitlePlaceholder')}
                                    onChange={(e) => this.props.onChange("jobTitle", e.target.value)} />
                            </Col>
                        </Row>
                    </FormGroup>*/}
                    {/*<FormGroup>
                        <Row>
                            <Col xs="4">
                                <Label className='user-label'>
                                    {i18n.t('header.organizations')}
                                </Label>
                            </Col>
                            <Col xs="8">
                                <Select.Creatable multi={true}
                                           value={this.props.user.organizations}
                                                  required={this.props.organizationRequired}
                                            defaultValue={this.props.getOrganizations.loading
                                                ? [{ value: null, label: "Loading...", disabled: true }]
                                                : this.props.getOrganizations.getOrganizations
                                                    .filter(({businessPartnerId}) => businessPartnerId === this.props.defaultOrganization)

                                                    .map((o) => ({ value: o, label: o.name }))[0]}
                                           options={this.props.getOrganizations.loading
                                               ? [{ value: null, label: "Loading...", disabled: true }]
                                               : this.props.getOrganizations.getOrganizations
                                                   .filter(({businessPartnerId}) => this.global.permissions.includes('Users.AssignAnyOrganization') || businessPartnerId === this.global.salesOrganization )
                                                   .map((o) => ({ value: o, label: o.name }))}
                                           onChange={(e) => {
                                               this.props.onChange("organizations", e)
                                           }
                                } />
                            </Col>
                        </Row>
                    </FormGroup>*/}
                </FormGroup>
                {/*<FormGroup tag="fieldset">
                    <Query query={getAllRoles}>
                        {({ loading, error, data }) => {
                            if (loading) return <Loading/>;
                            if (error) return `Error! ${error.message}`;

                            return data.getAllRoles.filter(({key})=> !this.props.allowedRoles || this.props.allowedRoles.includes(key))
                                .map((role, index) => <Row>
                                <Col xs="4">
                                    {index === 0 && <Label className='user-label'>
                                        {i18n.t('header.userRoles')}
                                    </Label> }
                                </Col>
                                <Col xs="8">
                                    <Input type="checkbox"
                                           value={this.props.user.roles[role.key] ? 'on' : 'off'}
                                           checked={this.props.user.roles[role.key]}
                                            disabled={!isAdmin}
                                           onChange={
                                               (e) => {
                                                   this.props.onChange("roles", {
                                                       ...this.props.user.roles,
                                                       [role.key]: e.target.checked
                                                   })
                                               }
                                           } className="css-checkbox" id={"user-"+role.key}/>
                                    <label htmlFor={"user-"+role.key} className="css-label"><i
                                        className="icon ion-android-checkbox"></i> {role.name}</label>
                                </Col>
                            </Row> );

                        }}
                    </Query>
                </FormGroup>*/}
            </Form>)
    }
}


export default compose(
    graphql(organizationsQuery, {
        name: 'getOrganizations',
        options: props => ({

            variables: {}
        }),
    }),
    graphql(systemsQuery, {
        name: 'getSystems',
        options: props => ({

            variables: {}
        }),
    })
)(UserProfile);

