import React, {Component} from 'react'
import Cron from "./cron";

class CronGenerator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props.formData
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        let val = e;
        let skipDate = "false";
        if (val.includes('skipNextExecutionDate')) {
            val = e.replace(' skipNextExecutionDate', '');
            skipDate = "true";
        }
        this.setState({
            "cron": val,
            "skipNextExecutionDate": skipDate
        }, () => this.props.onChange(this.state));
    }

    render() {
        return (<div id="cronExpression">
            <Cron
                onChange={(e) => this.onChange(e)}
                value={this.props.value}
                showResultText={false}
                showResultCron={false}
            />

        </div>)
    }
}

export default CronGenerator;
