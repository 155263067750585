import React, {Component} from 'reactn';
import i18n from "../../views/Pages/Login/i18n";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import QueryDataGrid from "../QueryDataGrid/QueryDataGrid";
import 'react-dates/initialize';
import {SingleDatePicker} from 'react-dates';
import moment from "moment";
import 'react-dates/lib/css/_datepicker.css';
import {createCommissionReportMutation, getCommissionReportsQuery} from "../../queries/Queries";
import {Mutation} from "react-apollo";
import fileDownload from "js-file-download";


const columns = [
    {
        dataField: 'id',
        text: i18n.t('Id'),
        sort: true
    },
    {
        dataField: 'startDate.time',
        text: i18n.t('Start Date'),
        sort: true,
        formatter: (cell, row) => {
            return new Date(cell).toLocaleDateString();
        }
    },
    {
        dataField: 'endDate.time',
        text: i18n.t('End Date'),
        sort: true,
        formatter: (cell, row) => {
            return new Date(cell).toLocaleDateString();
        }
    },
    {
        dataField: 'document',
        text: i18n.t('File'),
        classes: 'text-center',
        headerAlign: "center",
        formatter: (cell, row) => <i className="icon ion-android-download" style={{cursor:"pointer"}}
                                     onClick={() => fileDownload(((b64Data, contentType = '', sliceSize = 512) => {
                                         const byteCharacters = atob(b64Data);
                                         const byteArrays = [];

                                         for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                                             const slice = byteCharacters.slice(offset, offset + sliceSize);

                                             const byteNumbers = new Array(slice.length);
                                             for (let i = 0; i < slice.length; i++) {
                                                 byteNumbers[i] = slice.charCodeAt(i);
                                             }

                                             const byteArray = new Uint8Array(byteNumbers);

                                             byteArrays.push(byteArray);
                                         }

                                         const blob = new Blob(byteArrays, {type: contentType});
                                         return blob;
                                     })(cell, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"), `report-${row.id}.xlsx`)}/>

    }

];
class NoCommissionReport extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="nosalesagent">
                <i className="icon-atom ion-stats-bars"></i>
                <p className="text">There are no commission reports created yet.</p>
                {this.global.permissions.includes("CommissionReports.CreateReport") &&  <Button className='btn btn-primary' color="primary" onClick={this.props.previewGenerateReport}>Generate Commission Report</Button>}
            </div>
        );
    }
}

class GenerateCommissionReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            startDate: moment(),
            endDate: moment(),
            isLoading: false
        }
    }

    render() {
        const loadingIcon = this.state.isLoading ? (<span><i className='fa fa-circle-o-notch fa-spin'></i> </span>) : null;
        return (
            <Form className="step generate-commision-report">
                <Row>
                    <Col className='col-12 step-header'>
                        <h2>Generate Commission Report</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <Row>
                            <Col className=" col-md-6 col-lg-6 col-sm-12">
                                <FormGroup tag="fieldset">
                                    <Label htmlFor="bankAccountName">Start Date</Label><br />
                                    <SingleDatePicker date={this.state.startDate} // momentPropTypes.momentObj or null
                                        numberOfMonths={1}
                                        onDateChange={date => {
                                            this.setState({
                                                startDate: date,
                                            })
                                        }}
                                                      displayFormat="DD/MM/YY"
                                        isOutsideRange={() => false}
                                        showDefaultInputIcon={true}
                                        inputIconPosition="after"
                                        focused={this.state.focusedstart} // PropTypes.bool
                                        onFocusChange={({ focused }) => this.setState({ focusedstart: focused })} // PropTypes.func.isRequired
                                        id="report-start-date" // PropTypes.string.isRequired,
                                        displayFormat={"DD-MM-YYYY"}
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="col-md-6 col-lg-6 col-sm-12">
                                <FormGroup tag="fieldset">
                                    <Label htmlFor="bankAccountName">End Date</Label><br />
                                    <SingleDatePicker date={this.state.endDate} // momentPropTypes.momentObj or null
                                        numberOfMonths={1}
                                        onDateChange={date => {
                                            this.setState({
                                                endDate: date,
                                            })
                                        }}

                                         displayFormat="DD/MM/YY"
                                        isOutsideRange={() => false}
                                        focused={this.state.focusedEnd} // PropTypes.bool
                                        onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })} // PropTypes.func.isRequired
                                        id="report-end-date" // PropTypes.string.isRequired,
                                        showDefaultInputIcon={true}
                                        inputIconPosition="after"
                                        displayFormat={"DD-MM-YYYY"}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="btn-cnt">
                    <Col xs="12" sm="12" className="spacer"></Col>
                    <Col xs="8" sm="8">
                        <Mutation mutation={createCommissionReportMutation}
                            variables={{
                                startDate: this.state.startDate.toISOString(),
                                endDate: this.state.endDate.toISOString(),
                                brokerId: this.props.organizationId
                            }}
                            refetchQueries={[{ query: getCommissionReportsQuery, variables: { organizationId: this.props.organizationId } }]}>
                            {createCommissionReport => <Button color='primary' disabled={this.state.isLoading} type="button"
                                onClick={() => {
                                    this.setState({isLoading: true});
                                    createCommissionReport().then(() => {this.props.previewGenerateReport(false); this.setState({isLoading: false});})

                                }}>{loadingIcon}Generate</Button>
                            }
                        </Mutation>
                        &nbsp;<Input className="btn btn-secondary" type="button" value={i18n.t('header.cancel')} onClick={(event) => {
                            this.props.previewGenerateReport(false);
                        }} />
                    </Col>
                </Row>
            </Form >
        );
    }
}
class CommissionReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showGenerateReport: false,
        }
    }
    render() {


        return (
            <div>
                {this.state.showGenerateReport ? <GenerateCommissionReport hidefilters={this.hidefilters} organizationId={this.props.organizationId} previewGenerateReport={(e) => { this.setState({ showGenerateReport: e }) }} /> :
                    <div className="commission-report">
                        <QueryDataGrid dataPath={'getCommissionReports'} keyField={'id'}
                                       query={getCommissionReportsQuery}
                                       variables={{ organizationId: this.props.organizationId }}
                                       columns={columns}
                                       filters={[ {
                                           type: 'date',
                                           label: 'Start',
                                           fields: ['startDate.time'],
                                           cols: 4
                                       },
                                           {
                                               type: 'date',
                                               label: 'End',
                                               fields: ['endDate.time'],
                                               cols: 4
                                           }
                                       ]}
                                       buttons={this.global.permissions.includes("CommissionReports.CreateReport") && <Button onClick={() => { this.setState({ showGenerateReport: true }); }} className='btn btn-primary pull-right' color="primary"> <i className="icon ion-plus-round"/>&nbsp;&nbsp;New Report </Button>}
                                       noDataView={<NoCommissionReport previewGenerateReport={() => { this.setState({ showGenerateReport: true }) }}
                                                                       hidefilters={this.hidefilters} />}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default CommissionReport;
