import React, {Component} from 'react';
import {
    Area,
    AreaChart,
    CartesianGrid,
    LineChart,
    Line,
    Legend,
    XAxis,
    YAxis,
    Tooltip,
    ScatterChart,
    Scatter,
    ResponsiveContainer, ZAxis, PieChart, Pie, Cell, RadialBarChart, RadialBar
} from "recharts";
import {Card, CardBody, Row, Col, CardText, CardTitle, CardHeader} from 'reactstrap';
import ExportChartButton from "../../components/ExportChartButton";

const templateFromDatabase = `<div>
    Name <%=name %>
    <div>
        <small>Legal Entity: <%= isMdmIslegalentity ? 'Yes' : 'No' %><div style="color: red"><%= mdmEik %></div></small>
    </div>
</div>`;


const dataPieChart = [{name: 'Group A', value: 400}, {name: 'Group B', value: 300},
    {name: 'Group C', value: 300}, {name: 'Group D', value: 200}];
const COLORS = ['#09509c', '#4295cc', '#f89726', '#e81a40'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy  + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} 	dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const filterByFileds = ["name"];

const searchWhere = "lower(name) like '%<%= query %>%'";

const searchOrderBy  = "name";
const areaData = [
    {name: 'Jan', year2016: 4000, year2017: 2400, year2018: 2400},
    {name: 'Feb', year2016: 3000, year2017: 1398, year2018: 2210},
    {name: 'Mar', year2016: 2000, year2017: 9800, year2018: 2290},
    {name: 'Apr', year2016: 2780, year2017: 3908, year2018: 2000},
    {name: 'May', year2016: 1890, year2017: 4800, year2018: 2181},
    {name: 'Jun', year2016: 2390, year2017: 3800, year2018: 2500},
    {name: 'Jul', year2016: 3490, year2017: 4300, year2018: 2100},
    {name: 'Aug', year2016: 3430, year2017: 4780, year2018: 3440},
    {name: 'Sep', year2016: 3450, year2017: 4770, year2018: 2400},
    {name: 'Oct', year2016: 3290, year2017: 4340, year2018: 2300},
    {name: 'Nov', year2016: 3430, year2017: 4200, year2018: 4100},
    {name: 'Dec', year2016: 3690, year2017: 5300, year2018: 3100},
];
const scatterData = [{month: 'Jan', y: 200, z: 200}, {month: 'Feb', y: 100, z: 260},
    {month: 'Mar', y: 300, z: 400}, {month: 'Apr', y: 250, z: 280},
    {month: 'May', y: 400, z: 500}, {month: 'Jun', y: 280, z: 200},
    {month: 'Jul', y: 200, z: 200}, {month: 'Aug', y: 100, z: 260},
    {month: 'Sep', y: 300, z: 400}, {month: 'Oct', y: 250, z: 280},
    {month: 'Nov', y: 400, z: 500}, {month: 'Dec', y: 280, z: 200}];
const scatterData2 = [{month: 'Jan', y: 220, z: 200}, {month: 'Feb', y: 100, z: 260},
    {month: 'Mar', y: 300, z: 400}, {month: 'Apr', y: 250, z: 280},
    {month: 'May', y: 400, z: 500}, {month: 'Jun', y: 280, z: 200},
    {month: 'Jul', y: 200, z: 200}, {month: 'Aug', y: 100, z: 260},
    {month: 'Sep', y: 300, z: 400}, {month: 'Oct', y: 250, z: 280},
    {month: 'Nov', y: 400, z: 500}, {month: 'Dec', y: 280, z: 200}];

const lineData = [
    {name: 'Jan', year2016: 34234, year2017: 24324, year2018: 67577},
    {name: 'Feb', year2016: 30080, year2017: 13988, year2018: 67868},
    {name: 'Mar', year2016: 20000, year2017: 9800, year2018: 89089},
    {name: 'Apr', year2016: 27880, year2017: 38908, year2018: 43457},
    {name: 'May', year2016: 18950, year2017: 48000, year2018: 21891},
    {name: 'Jun', year2016: 23960, year2017: 38900, year2018: 52500},
    {name: 'Jul', year2016: 34930, year2017: 24300, year2018: 26100},
    {name: 'Aug', year2016: 34490, year2017: 84300, year2018: 72100},
    {name: 'Sep', year2016: 33190, year2017: 74300, year2018: 82100},
    {name: 'Oct', year2016: 34490, year2017: 64300, year2018: 21400},
    {name: 'Nov', year2016: 34390, year2017: 34300, year2018: 22100},
    {name: 'Dec', year2016: 23490, year2017: 54300, year2018: 21005},
];


const dataRadial = [
    {name: '21k-30k', uv: 31.47, pv: 2400, fill: '#8884d8'},
    {name: '31k-40k', uv: 26.69, pv: 4567, fill: '#83a6ed'},
    {name: '41k-50k', uv: 21.95, pv: 1398, fill: '#8dd1e1'},
    {name: '51k-60k', uv: 8.22, pv: 9800, fill: '#82ca9d'},
    {name: 'unknow', uv: 6.67, pv: 4800, fill: '#ffc658'}
];

const style = {
    top: 0,
    left: 350,
    lineHeight: '24px'
};


class Dashboard extends Component {
    constructor(props){
        super();
        this.state = {};
    }
    render() {
        return (<div className="page-cnt">
                <Row>
                    <Col sm={6}>
                        <ExportChartButton elementId={"yyy"} color={"primary"} filename={`OpenQueries.pdf`} title={"Open Queries"}/>
                        <Card id={"yyy"}>
                            <CardHeader>
                                <CardTitle>Number of MPRs</CardTitle>
                            </CardHeader>
                            <CardBody style={{height:"300px"}}>
                                <ResponsiveContainer>
                                    <AreaChart width={400} height={400} data={areaData}>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name" />
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <Area type='monotone' dataKey='year2016' stackId="1" stroke='#8884d8' fill='#8884d8' />
                                        <Area type='monotone' dataKey='year2017' stackId="1" stroke='#82ca9d' fill='#82ca9d' />
                                        <Area type='monotone' dataKey='year2018' stackId="1" stroke='#ffc658' fill='#ffc658' />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <ExportChartButton elementId={"xxx"} color={"primary"} filename={`OpenQueries.pdf`} title={"Open Queries"}/>
                        <Card id={"xxx"}>
                            <CardHeader>
                                <CardTitle>Open Queries</CardTitle>
                            </CardHeader>
                            <CardBody style={{height:"300px"}}>
                                <ResponsiveContainer>
                                    <ScatterChart width={400} height={400}>
                                        <CartesianGrid />
                                        <XAxis dataKey={'month'} />
                                        <YAxis dataKey={'y'} type="number"/>
                                        <ZAxis dataKey={'z'} type="number"/>
                                        <Legend/>
                                        <Scatter name='Total Positive vs. Negative comments' data={scatterData} fill='#8884d8'/>
                                        {/*<Scatter name='Business' data={scatterData2} fill='#456789'/>*/}
                                        <Tooltip cursor={{strokeDasharray: '3 3'}}/>
                                    </ScatterChart>
                                </ResponsiveContainer>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle>Consumption & Billed Amount</CardTitle>
                            </CardHeader>
                            <CardBody style={{height:"300px"}}>
                                <ResponsiveContainer>
                                    <PieChart width={500} height={300} onMouseEnter={this.onPieEnter}>
                                        <Pie
                                            dataKey="consumption-and-billed-amount"
                                            data={dataPieChart}
                                            cx={250}
                                            cy={130}
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            outerRadius={120}
                                            fill="#8884d8">
                                            {
                                                dataPieChart.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                                            }
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle>Average daily consumption per site</CardTitle>
                            </CardHeader>
                            <CardBody style={{height:"300px"}}>
                                <ResponsiveContainer>
                                    <RadialBarChart width={500} height={300} cx={150} cy={130} innerRadius={20} outerRadius={140} barSize={20} data={dataRadial}>
                                        <RadialBar minAngle={15} label={{ position: 'insideStart', fill: '#fff' }} background clockWise={true} dataKey='uv'/>
                                        <Legend iconSize={10} width={120} height={160} layout='vertical' verticalAlign='middle' wrapperStyle={style}/>
                                    </RadialBarChart>
                                </ResponsiveContainer>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle>Monthly Revenues</CardTitle>
                            </CardHeader>
                            <CardBody style={{height:"300px"}}>
                                <ResponsiveContainer>
                                    <LineChart width={800} height={400} data={lineData}>
                                        <XAxis dataKey="name"/>
                                        <YAxis/>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <Tooltip/>
                                        <Legend />
                                        <Line type="monotone" dataKey="year2016" stroke="#8884d8" />
                                        <Line type="monotone" dataKey="year2017" stroke="#82ca9d" />
                                        <Line type="monotone" dataKey="year2018" stroke="#ffc658" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        )
    }
}

export default Dashboard;
