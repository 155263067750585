import React, { Component } from 'react';

import QueryProcessesTable from "../QueryProcessesTable";


class QueryProcessesTab extends Component {
    constructor(props) {
        super(props);

    }


    render() {

        return (
            <QueryProcessesTable {...this.props}/>
        );
    }
}

export default QueryProcessesTab;