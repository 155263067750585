import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';

class Mpan extends Component {
  constructor(props) {
    super(props);

    this.moveOnMax = this.moveOnMax.bind(this);
  }
  moveOnMax(field, nextField) {
    if (field.value.length >= field.maxLength) {
      var el = document.getElementById(nextField)
      if (el) {
        el.focus();
      }
    }

    document.getElementById('mpan').value = (document.getElementById('first').value || '') +
      (document.getElementById('second').value || '') +
      (document.getElementById('third').value || '') +
      (document.getElementById('forth').value || '') +
      (document.getElementById('fifth').value || '') +
      (document.getElementById('sixth').value || '');
  }

  render() {
    return (<Row className="mpan">
      <Col className="leadingcol">
        S <Input type="hidden" name="mpan" id="mpan" ref="mpan"></Input>
      </Col>
      <Col>
        <Row>
          <Col><Input placeholder="01" maxLength="2"  id="first" value={this.props.mpan.substr(0, 2)} onInput={(e) => { this.moveOnMax(e.nativeEvent.target, 'second') }} /></Col>
          <Col><Input placeholder=" 801" maxLength="3"  id="second" value={this.props.mpan.substr(2, 3)} onInput={(e) => { this.moveOnMax(e.nativeEvent.target, 'third') }} /></Col>
          <Col><Input placeholder="100" maxLength="2"  id="third" value={this.props.mpan.substr(5, 3)} onInput={(e) => { this.moveOnMax(e.nativeEvent.target, 'forth') }} /></Col>
        </Row>
        <Row>
          <Col className="col-3"><Input maxLength="3" placeholder="100" value={this.props.mpan.substr(8, 3)}  id="forth" onInput={(e) => { this.moveOnMax(e.nativeEvent.target, 'fifth') }} /></Col>
          <Col className="col-6"><Input maxLength="2" placeholder="00" value={this.props.mpan.substr(11, 2)} className="more-letterspacing" id="fifth" onInput={(e) => { this.moveOnMax(e.nativeEvent.target, 'sixth') }} /></Col>
          <Col className="col-3"><Input maxLength="3" placeholder="100" value={this.props.mpan.substr(13, 3)}  id="sixth" onInput={(e) => { this.moveOnMax(e.nativeEvent.target, 'seventh') }} /></Col>
        </Row>
      </Col>
    </Row>)
  }
}

export default Mpan;