import React from "react";
import {Button, Col, Input, Label, Row} from "reactstrap";
import i18n from "../../../views/Pages/Login/i18n";

function PhoneWidget(props) {
    let {
        options,
        onChange,
        value
    } = props;

    value = value ? JSON.parse(value) : [];

    const _onChange = (value) => {
        return onChange(value === "" ? options.emptyValue : JSON.stringify(value));
    };

    const phoneRepeater = () => {
        let result = [];
        for (let i = 0; i < value.length; i++) {
            result.push(
                <Row>
                    <Col xs={"12"}>
                        <Input type="text" value={value[i].phoneNumber}
                               placeholder={i18n.t('header.phonePlaceholder')}
                               onChange={(e) => {
                                   value[i].phoneNumber = e.target.value;
                                   let arr = value.slice();
                                   _onChange(arr);
                               }}/><br/>
                    </Col>
                    <Col xs={"4"}>
                        <Input type="radio" name="primaryNumber" id={'primaryNumber' + i}
                               checked={value[i].primaryNumber}
                               onChange={(e) => {
                                   let arr = value.slice();
                                   for (let j = 0; j < arr.length; j++) {
                                       arr[j].primaryNumber = false;
                                   }
                                   arr[i].primaryNumber = e.target.checked;
                                   _onChange(arr);
                               }}/>
                        <label htmlFor={'primaryNumber' + i} className="css-label">
                            <i className="icon ion-android-radio-button-off"></i>
                            <i className="icon ion-android-radio-button-on"></i>
                            &nbsp;&nbsp;{i18n.t('header.primaryNumber')}
                        </label>
                    </Col>

                    <Col xs={"6"}>
                        <Input type="select" value={value[i].type}
                               placeholder={i18n.t('header.typePlaceholder')}
                               onChange={(e) => {
                                   value[i].type = e.target.value;
                                   let arr = value.slice();

                                   _onChange(arr);
                               }}>

                            <option value="" disabled></option>
                            <option value="Mobile">{i18n.t('phoneTypes.mobile')}</option>
                            <option value="Home">{i18n.t('phoneTypes.home')}</option>
                            <option value="Office">{i18n.t('phoneTypes.office')}</option>
                        </Input>
                    </Col>
                    <Col xs={"2"}>
                        <Button onClick={(e) => {
                            value.splice(i, 1);
                            let arr = value.slice();
                            _onChange(arr);
                        }} color="primary">-</Button>
                    </Col>
                </Row>
            );
        }

        result.push(<Row>
            <Col xs={"1"}>
                <Button onClick={() => {
                    value.push({
                        phoneNumber: "",
                        type: "",
                        primaryNumber: (value.length == 0) ? true : false
                    });
                    let arr = value.slice();
                    _onChange(arr);
                }} color="primary">+</Button>
            </Col>
        </Row>);
        return result;
    };

    return (<div>
        <Row>
            <Col xs="4">
                <Label className='user-label'>
                    {i18n.t('header.phones')}
                </Label>

            </Col>
            <Col xs="8">
                {phoneRepeater()}
            </Col>
        </Row>
    </div>);
}

export default PhoneWidget;