import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const ModalDialog = (props) => {
    const {
        title,
        text,
        opened,
        className,
        closeLabel
    } = props;

    const [modal, setModal] = useState(true);

    const toggle = () => setModal(!modal);

    return (
        <Modal isOpen={modal && opened} toggle={toggle} className={className}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                {text}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>{closeLabel}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalDialog;
