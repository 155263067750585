import React, {Component} from 'react';
import jsonata from "jsonata/jsonata-es5";

export class CustomizedAxisTick extends Component {

    constructor(props) {
        super(props);
    }

    transformData(data, exp) {
        let expr = exp;
        const expression = expr ? jsonata(expr) : null;
        const transformedData = expression ? expression.evaluate(data) : data;
        return transformedData;
    }

    render() {

        const {x, y, payload, tickFormatterExpression, tickRotation} = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill="#666"
                      transform={`rotate(${tickRotation ? tickRotation : 0})`}>
                    {this.transformData(payload.value, tickFormatterExpression)}</text>
            </g>
        );
    }
};