import React, {Component} from 'react';
import { withRouter } from 'react-router';
import {compose, graphql, withApollo} from "react-apollo";
import {branch, renderComponent, lifecycle} from "recompose";
import {TabContent, TabPane, Button, Breadcrumb, BreadcrumbItem, Form, FormFeedback, FormGroup, Label, Input, FormText, Container, Row, Col, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import { ValidatingFormGroup } from 'reactstrap-validation';
import i18n from '../Pages/Login/i18n';
import classnames from "classnames";
import PasswordInputMatch from "../../components/PasswordInputMatch";
import UserDetails from "../UserDetails/UserDetails";
import Loading from '../../components/Loading';
import ReactDOM from 'react-dom';
import {changeOwnPasswordMutation} from '../../queries/Queries';
import ExpiringAlert from "../../components/ExpiringAlert";

class ChangePassword extends Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.handleChange = this.handleChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.showAlert = this.showAlert.bind(this);

    }


    handleChange(name, value){
        this.setState({[name] : value});
    }


    handlePasswordChange(event) {

        this.props.changeOwnPasswordMutation({

            variables: {
                passwordHolder: {oldPassword : this.state.oldPassword, newPassword : this.state.password}
            }
        }).then(({data}) => {
            console.log('got data', data);
            this.setState({showalert : !data});
            if(data){
                ReactDOM.render(<ExpiringAlert color="success" message={"Password successfully changed"} />, document.getElementById('alert').appendChild(document.createElement("div")));
                this.props.history.push('/');
            }
        }).catch((error) => {
            ReactDOM.render(<ExpiringAlert color="danger" message={error.message} />, document.getElementById('alert').appendChild(document.createElement("div")));
            console.log('there was an error sending the query', error);
        });

        event.preventDefault();
    }

    showAlert(){
        if(this.state.showalert){
            return <Alert color="danger">
                Wrong old password!
            </Alert>
        }
    }

    render() {
                return (

                <div>
                    <div className='manage-users'>
                        <Row className='subheader'>
                            <Col className='col-8'>
                                <h1>{ i18n.t('header.changePassword') }</h1>
                            </Col>
                            <Col className='col-4'>
                                <Breadcrumb>
                                    <BreadcrumbItem><a href="#">Home</a></BreadcrumbItem>
                                    <BreadcrumbItem active>Change Password</BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <div className='breadcrumbs'>

                        </div>
                    </div>
                    <div className="page-cnt">
                        <Form>
                            <div>
                                <Row>
                                    <Col xs="12" sm="12" md="8" lg="6" xl="6">
                            <FormGroup tag="fieldset">
                                <Row>
                                    <Col sm="4">
                                        <Label className='user-label'>
                                            { i18n.t('header.oldpassword') }
                                        </Label>
                                    </Col>
                                    <Col sm="8">
                                        <Input type="password" value={this.state.oldPassword}
                                               className='create-user-input'
                                               placeholder={ i18n.t('header.passwordPlaceholder') }
                                               onChange={ (e)=> this.handleChange("oldPassword", e.target.value)}/>
                                    </Col>
                                </Row>
                            </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col xs="12" sm="12" md="8" lg="6" xl="6">
                                    <PasswordInputMatch password={this.state.password} handleChange={this.handleChange} handleConfirm={this.handlePasswordChange} />
                                </Col>
                            </Row>
                        </Form>
                        {this.showAlert()}
                    </div>
                </div>
                )

    }
}



const LoadingComponent = () => <Loading/>

let withRouter2 = withRouter(ChangePassword);
let withApollo1 = withApollo(withRouter2);
export default compose(graphql(changeOwnPasswordMutation,   { name: 'changeOwnPasswordMutation' }) )(withApollo1);
