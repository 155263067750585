import React, {Component} from 'react';

import {graphql, Mutation} from "react-apollo";
import {Breadcrumb, Button, Col, Input, Row} from "reactstrap";
import {systemsQuery, updateSystemMutation} from "../../queries/Queries";
import Loading from "../../components/Loading";


class Systems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            systems:[]
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(!nextProps.systemsQuery.loading) {
            this.setState({...this.state, systems: nextProps.systemsQuery.getSystems})
        }
    }

    render() {
        if (this.props.systemsQuery.loading)  return <Loading/>;
        if (this.props.systemsQuery.error)  return this.props.systemsQuery.error.message;
        return <div><Row className='subheader'>
            <Col className='col-8'>
                <h1>Systems</h1>
            </Col>
            <Col className='col-4'>
                <Breadcrumb>
                </Breadcrumb>
            </Col>
        </Row>
            <div className = "page-cnt" >
                <Row>
                    <Col sm = "12">
                        {
                            this.state.systems.map((system, index) => <div style={{display: 'table-row'}}>
                                <span style={{display: 'table-cell'}}>{system.systemName}</span>
                                <span style={{display: 'table-cell'}}><Input value={system.displayName} onChange={(e)=>{
                                    let newSystems = [...this.state.systems];
                                    newSystems[index] = {...newSystems[index], displayName: e.target.value};
                                    this.setState({systems: newSystems});}}/></span>
                                <span style={{display: 'table-cell'}}><Input value={system.url} onChange={(e)=>{
                                    let newSystems = [...this.state.systems];
                                    newSystems[index] = {...newSystems[index], url: e.target.value};
                                    this.setState({systems: newSystems});}}/></span></div>)
                        }

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Mutation mutation={updateSystemMutation}>
                            {
                                (updateSystem) => <Button color={"primary"} className="buttonSpace buttonSpaceTop pull-right" onClick={() => {
                                    this.state.systems.forEach(system => updateSystem({variables : system}));
                                }
                                } value="Submit">Submit</Button>
                            }
                        </Mutation>
                    </Col>
                </Row>
            </div>
        </div>
    }
}

export default graphql(systemsQuery, {name: 'systemsQuery'})(Systems);
