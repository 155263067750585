import React from "react";
import {Col, Row, NavItem, NavLink, Nav, TabContent} from "reactstrap";
import i18n from '../../views/Pages/Login/i18n';
import classnames from 'classnames';
import { withRouter, matchPath } from 'react-router';
import jsonata from '../../../node_modules/jsonata/jsonata-es5';

class NavTabs extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.getActiveTab = this.getActiveTab.bind(this);
        this.tabKey = 'tab_' + this.props.tabKey || 'tab_default';
        for (const key in this.props.items) {
            const item = this.props.items[key];
            if (item.path) {
                const match = matchPath(location.hash.substr(1), {
                    path: "/view/:name" + item.path,
                    exact: false,
                    strict: false
                })
                if (match) {
                    sessionStorage[this.tabKey] = item.key;
                };
            }
        }

        this.state = {
            activeTab: this.getActiveTab() || sessionStorage[this.tabKey] || '1'};
    }

    getActiveTab() {
        const tabPos = this.props.location.pathname.indexOf(this.tabKey);
        if(tabPos > -1) {
            const endPos = this.props.location.pathname.indexOf('/', tabPos);
            const param = this.props.location.pathname.substring(tabPos + this.tabKey.length + 1, endPos==-1 ? undefined : endPos);
            const linkedItem = this.props.items.find(item => item.title==param);
            if (linkedItem && linkedItem.key) {
                return linkedItem.key
            }
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            sessionStorage[this.tabKey] = tab;
            this.setState({
                activeTab: tab
            });
            if (window.gtag) {
                let pagename = ''
                if(document.location.href.split('/').length>7){
                    pagename = document.location.href.split('/')[5] +' '+ document.location.href.split('/')[7]
                }else{
                    pagename = document.location.href.split('/').pop()
                }
                const title_tab = this.props.items.filter(i => i.key === tab)[0].title;
                pagename = pagename.charAt(0).toUpperCase() + pagename.slice(1) + ' ' + title_tab
                const path = document.location.href + '/tab_' + title_tab;
                gtag('event', 'page_view', {
                    page_title: pagename,
                    page_location: path,
                    page_path: path,
                })
            }

        }
    }

    render() {

        const navItems = [];
        let activeTab = this.state.activeTab;

        if (this.props.tabSelections && this.props.tabSelector) {

            let selection = null;

            if (this.props.expr) {
                const expression = this.props.expr ? jsonata(this.props.expr) : null;
                const selector = expression ? expression.evaluate(this.props.tabSelector) : null;
                selection = this.props.tabSelections[selector];
            }
            else {
                selection = this.props.tabSelections[this.props.tabSelector];
            }

            if (selection.indexOf(activeTab) < 0) {
                activeTab = '1';
            }

            for (const key in this.props.items) {
                const item = this.props.items[key];
                if (selection.includes(item.key)) {
                    navItems.push(
                        <NavItem key={item.key} >
                            <NavLink
                                className={classnames({active: activeTab === item.key})}
                                onClick={() => { this.toggle(item.key); }}
                            > {i18n.t(item.title)} </NavLink>
                        </NavItem>
                    );
                }
            }
        }
        else {
            for (const key in this.props.items) {
                const item = this.props.items[key];
                navItems.push(
                    <NavItem key={item.key} >
                        <NavLink
                            className={classnames({active: this.state.activeTab === item.key})}
                            onClick={() => { this.toggle(item.key); }}
                        > {i18n.t(item.title)} </NavLink>
                    </NavItem>
                );
            }
        }
        return (<div style={{width:"100%"}}>
            <div className='bp-nav-items'>
                <Nav tabs>
                    {navItems}
                </Nav>
            </div>
            <TabContent activeTab={activeTab} className='bp-tab-content'>
                {this.props.children}
            </TabContent></div>);
    }
}

export default withRouter(NavTabs);
