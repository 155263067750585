import React, {Component} from 'reactn';

import i18n from '../../views/Pages/Login/i18n';
import gql from "graphql-tag";
import {Mutation, Query} from 'react-apollo';
import {deleteTaskMutation, getTaskCommentsQuery, getTasksQuery, updateTaskMutation} from "../../queries/Queries";
import schema, {schemaFunction} from "../../schemas/task.schema.js"
import uischema from "../../schemas/task.uischema.js"
import uischemareadonly from "../../schemas/taskreadonly.uischema"
import Form from "react-jsonschema-form";
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";
import TasksAttachments from "../../components/TasksAttachments";
import moment from "moment";
import Loading from "../Loading";
import customFormats from "../../schemas/customFormats";
import {CSVLink} from "react-csv";
import {queryTypeMapping as queryTypes} from "../../schemas/task.schema";
import {transformSchema} from '../../utils/Helpers';

class TasksDetail extends Component {

    render() {
        let taskType;
        let closeDate;
        if (this.props.task) {
            const queryTypeMapping = this.props.power ? queryTypes.E : queryTypes.G 
            taskType = queryTypeMapping.filter(x => x.enum.indexOf(parseInt(this.props.task.variables.type))>-1);
            taskType = taskType[0] && taskType[0].enum[0];
            closeDate = this.props.task.variables.closed_on && moment(this.props.task.variables.closed_on).format("DD/MM/YYYY");
        }
        const formdata = this.props.task ? {
            ...this.props.task ,...(Object.entries(this.props.task.variables)).reduce((accumulator, [name, value]) => {
                return {
                    ...accumulator,
                    [name]: ["change_of_od", "incomming_customer", "outgoing_customer", "coo_pricing"].indexOf(name) >= 0 && value && value != "null" ? JSON.parse(value) : value,
                };
            }, {}),  type: taskType, closed_on: closeDate
        } : {};
        const taskReadonly = true || formdata.status === 'Closed' || formdata.status === 'Cancelled';

        const csv = [];

        if (formdata) {
            formdata.type = parseInt(formdata.type);
            const schemaNonFiltered = this.props.power || (this.props.task && this.props.task.variables.businessType === 'POWER') ? transformSchema(schemaFunction(false), "E") : transformSchema(schemaFunction(false), "G") ;
            for (const f of uischemareadonly["ui:order"]) {
                if (schemaNonFiltered.properties[f] && (!uischemareadonly[f] || uischemareadonly[f]["ui:widget"] != "hidden")) {
                    if (schemaNonFiltered.properties[f].anyOf && schemaNonFiltered.properties[f].type == "number") {
                        const v = schemaNonFiltered.properties[f].anyOf.find(el => el.enum.indexOf(parseInt(formdata[f]))>-1);
                        csv.push({title: schemaNonFiltered.properties[f].title, value: v ? v.title : "Unknown"});
                    } else if (schemaNonFiltered.properties[f].type === "string") {
                        csv.push({title: schemaNonFiltered.properties[f].title, value: formdata[f]});
                    } else if (schemaNonFiltered.properties[f].type === "object") {
                        for (const f1 of Object.keys(schemaNonFiltered.properties[f].properties)) {
                            if (schemaNonFiltered.properties[f].properties[f1].type === "string") {
                                csv.push({
                                    title: schemaNonFiltered.properties[f].properties[f1].title,
                                    value: formdata[f] && formdata[f][f1]
                                });
                            }
                        }
                    }
                } else if (schemaNonFiltered.dependencies.type.oneOf[0].properties[f] && schemaNonFiltered.dependencies.type.oneOf[0].properties[f].type == "object" && formdata[f] && formdata[f] != "null") {
                    for (const f1 of Object.keys(schemaNonFiltered.dependencies.type.oneOf[0].properties[f].properties)) {
                        if (schemaNonFiltered.dependencies.type.oneOf[0].properties[f].properties[f1] && schemaNonFiltered.dependencies.type.oneOf[0].properties[f].properties[f1].type === "string") {
                            csv.push({title: schemaNonFiltered.dependencies.type.oneOf[0].properties[f].properties[f1].title, value: formdata[f][f1]});
                        }
                    }
                }
            }
            csv.push({title:"Raised by", value:this.props.task.owner});
            csv.push({title:"Raised on", value:this.props.task.createTime});
        }

        return this.props.task
            ?
            <div className="task-details">
                <div className="task-labels clearfix">
                    <span
                        className="pull-left">{i18n.t('tasks.id')} {this.props.task.variables.external_id ? this.props.task.variables.external_id : 'Processing'} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span className="pull-left delete-task">
                        <a href="javascript:void(0)" onClick={() => this.props.resetView()}><i
                            className="icons ion-android-checkbox-outline"></i> Close </a>
                        {false && this.global.permissions.includes("Tasks.DeleteTasks") &&
                        <Mutation mutation={deleteTaskMutation} variables={{taskId: this.props.task.id}}
                                  refetchQueries={[{query: getTasksQuery}]}>
                            {(deleteTask, {data}) => (<a href="javascript:void(0)" onClick={() => {
                                if (confirm("Are you sure?")) deleteTask().then(() => {
                                    ReactDOM.render(<ExpiringAlert color="success"
                                                                   message={i18n.t('tasks.successfulDelete')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                    this.props.resetView();
                                })
                            }}><i className="icons ion-trash-b"></i>&nbsp;&nbsp;{i18n.t('tasks.deletetask')}</a>)}
                        </Mutation>}
                    </span>
                    <span
                        className="pull-right">by {this.props.task.owner} on {moment(this.props.task.createTime).format("DD/MM/YYYY")}&nbsp;
                        <Query query={getTaskCommentsQuery}
                            variables={{taskId: this.props.task.id, service: this.props.task.service || 'Camunda'}}>
                            {(result) => {
                                console.log(result);
                                if (result.error) return <ErrorPage />;
                                if (result.loading) return (<Loading/>);
                                if (!result.data  || !result.data.getTaskComments || !Array.isArray(result.data.getTaskComments) || !result.data.getTaskComments.length) return null;

                                const comments = result.data.getTaskComments.map(task => [task.message, task.author, new Date(task.time).toLocaleDateString() + " " + new Date(task.time).toLocaleTimeString()]);
                                if(!comments || !Array.isArray(comments) || comments.length) return null; 

                                const comments0 = comments[0] || [];
                                const regex = /power_\d+\./;
                                return <CSVLink filename={`Query.csv`} className="export-csv"
                                                data={[[...csv.map(el => el.value != null ? el.value.replace(regex, "") : el.value || "" ), ...comments0 ],...comments.splice(1).map(x => [...csv.map(el=>" "),...x])]}
                                                headers={[...csv.map(el => el.title), 'Message', 'Author', 'Date']}><i className="icon icon-download"></i></CSVLink>
                            }}
                        </Query>
                    </span>


                </div>
                <Mutation mutation={gql`${updateTaskMutation}`} refetchQueries={[{query: getTasksQuery}]}>
                    {(updateTask, {data}) => {
                        const submit = ({formData}) => {
                            ReactDOM.render(<Loading/>,
                                document.getElementById('alert'));
                            updateTask({ variables: { task:{
                                        id:formData.id,
                                        name:formData.name,
                                        dueDate:formData.dueDate,
                                        description: formData.description,
                                        assignee: formData.assignee,
                                        priority: formData.priority,
                                        variables: [
                                            {
                                                name: "type",
                                                type: "string",
                                                value : formData.variables.type
                                            },
                                            {
                                                name: "status",
                                                type: "string",
                                                value: formData.variables.status
                                            }
                                        ]
                                    }, service : this.props.task.service}}).then(()=>{
                                ReactDOM.render(null, document.getElementById('alert'));
                                ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('tasks.successfulEdit')}  />, document.getElementById('alert').appendChild(document.createElement("div")));

                            });
                            // this.props.resetView();
                        };
                        formdata.type = parseInt(formdata.type);
                        return (
                            <div>

                                <Form schema={
                                      taskReadonly
                                          ? this.props.power || (this.props.task && this.props.task.variables.businessType === 'POWER') ? transformSchema(schemaFunction("filter-first"), 'E') : transformSchema(schemaFunction("filter-first"), 'G')
                                          : this.props.power || (this.props.task && this.props.task.variables.businessType === 'POWER') ? transformSchema(schema, 'E') : transformSchema(schema, 'G')
                                      }
                                      customFormats={customFormats}
                                      noValidate={true}
                                      noHtml5Validate={true}
                                      uiSchema={taskReadonly ? uischemareadonly : uischema}
                                      task={this.props.task} resetView={() => this.setState({task: null})}

                                      onSubmit={submit}

                                      formData={formdata}
                                      formContext={{formdata: formdata, submit: submit}}>
                                    <TasksAttachments task={this.props.task} uploadImmediately={true} parent={this} />
                                    {!taskReadonly && <div className="buttons">
                                        <div style={{marginBottom: "35px"}}>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                            <button type="button" className="btn btn-secondary">Cancel</button>
                                        </div>
                                    </div>
                                    }
                                </Form></div>)
                    }}

                </Mutation>
            </div>
            : <div className="no-tasks">Select query on the left to open it</div>;

    }
}


export default TasksDetail;
