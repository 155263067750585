import React, { Component } from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import FindAddress from '../FindAddress/FindAddress';
import i18n from "../../views/Pages/Login/i18n";

class AddressDetails extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const firstName = <Col>
      <FormGroup>
        <Label for={"firstName_"+this.props.name}>First Name:</Label>
        <Input type="text" name={"firstName_"+this.props.name} id={"firstName_"+this.props.name}
               onChange={e => this.props.setContact(this.props.name, {...this.props.contact, firstName: e.target.value})}
               value={this.props.contact.firstName}
               placeholder="Enter First Name"  required={this.props.required}/>
      </FormGroup>
    </Col>;
    const lastName = <Col>
      <FormGroup>
        <Label for={"lastName_" + this.props.name}>Last Name:</Label>
        <Input type="text" name={"lastName_" + this.props.name} id={"lastName_" + this.props.name}
               onChange={e => this.props.setContact(this.props.name, {
                 ...this.props.contact,
                 lastName: e.target.value
               })}
               value={this.props.contact.lastName}
               placeholder="Enter Last Name" required={this.props.required}
               value={this.props.contact.lastName}/>
      </FormGroup>
    </Col>;
    return (
      <div className="contactDetails">
        <h3>{this.props.title}</h3>
        {this.props.isbilling &&
          <FormGroup tag="fieldset">
            <Input type="checkbox" name={"sameAsPrimary_"+this.props.name} id={"sameAsPrimary_"+this.props.name}
                   checked={this.props.contact.sameAsPrimary}
                   onChange={e => this.props.setContact(this.props.name, {...this.props.contact, sameAsPrimary: e.target.checked})}/>
            <label htmlFor={"sameAsPrimary_"+this.props.name} className="css-label"><i className="icon ion-android-checkbox"/>{i18n.t('contact.sameAsPrimaryContact')}</label>
          </FormGroup>
        }
          {!this.props.contact.sameAsPrimary && <div><Row>
            {DYCE
                ? [firstName,lastName]
                : [lastName,firstName]}
          </Row>
            <FormGroup>
              <Label for={"phone_" + this.props.name}>Contact Phone:</Label>
              <Input type="text" name={"phone_" + this.props.name} id={"phone_" + this.props.name}
                     onChange={e => this.props.setContact(this.props.name, {
                       ...this.props.contact,
                       phone: e.target.value
                     })}
                     value={this.props.contact.phone}
                     placeholder="Enter Contact Phone" required={this.props.required}/>
            </FormGroup>
            <FormGroup>
              <Label for={"email_" + this.props.name}>Contact Email:</Label>
              <Input type="email" name={"email_" + this.props.name} id={"email_" + this.props.name}
                     onChange={e => this.props.setContact(this.props.name, {
                       ...this.props.contact,
                       email: e.target.value
                     })}
                     value={this.props.contact.email}
                     placeholder="Enter Contact Email" required={this.props.required}/>
            </FormGroup>
            <FormGroup>
              <Label for="address">Address:</Label>
              <FindAddress name={this.props.name} address={this.props.address}
                           onChange={(address) => this.props.setAddress(this.props.name, address)}
                           required={this.props.required}/>
            </FormGroup>
            {this.props.isprimary ?
                <FormGroup>
                  <Label for={"accountPassword_" + this.props.name}>Account Password:</Label>
                  <Input type="text" name={"accountPassword_" + this.props.name}
                         id={"accountPassword_" + this.props.name}
                         onChange={e => this.props.setContact(this.props.name, {
                           ...this.props.contact,
                           accountPassword: e.target.value
                         })}
                         value={this.props.contact.accountPassword}
                         placeholder="Enter Account Password"/>
                </FormGroup>
                : ""}

          </div>}
          {this.props.isbilling && DYCE &&
          <FormGroup>
              <Label for="billingPreference">Billing Preference: </Label>
              {["Email", "Post"].map((billPref) =>
                  <div><Input type="checkbox" name={"billingPreference"+billPref} id={"billingPreference"+billPref}
                              checked={this.props.contact["billingPreference"+billPref]}
                              onChange={e => this.props.setContact(this.props.name, {...this.props.contact, ["billingPreference"+billPref]: e.target.checked})}/>
                      <label htmlFor={"billingPreference"+billPref} className="css-label"><i className="icon ion-android-checkbox"/>{billPref}</label>
                  </div>)
              }
              <div><small>*Please be aware that paper billing will be charged at £2 per invoice per month</small></div>
          </FormGroup>
          }
      </div>
    )
  }
}

export default AddressDetails;