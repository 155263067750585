/**
 * Created by ogi on 1/22/18.
 */

import React, {Component} from 'reactn';
import BootstrapTable from 'react-bootstrap-table-next';
import {Query, withApollo} from 'react-apollo';
import gql from "graphql-tag";
import JsxParser from 'react-jsx-parser';
import Loading from '../Loading'
import _ from 'lodash';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import Form from "react-jsonschema-form";
import {
    Button,
    ButtonDropdown,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';
import {withRouter} from 'react-router';
import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {Typeahead} from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import jsonata from "./../../../node_modules/jsonata/jsonata-es5"
import {CSVLink} from "react-csv";
import LoadingImage from "../../images/loading-white.svg";
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";
import axios from "axios";
import fileSaver from "file-saver";
import {v4 as uuidv4} from "uuid";
import i18n from "../../views/Pages/Login/i18n";
import ErrorPage from '../ErrorPage/ErrorPage';

const MAX_SELECT = 50;

class EmailTypeAhead extends Component {
    constructor(arg) {
        super(arg);
        this.state = {
            selected: null
        };

    }
    render() {
        return <Typeahead
            {...this.state}
            allowNew
            labelKey="email"
            multiple
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    document.querySelector('.emails .dropdown-item').click();
                }
            }}
            onInputChange={(text, event) => {
                if (text.length && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(text) && (!this.state.selected || this.state.selected.length <= 0)) {
                    this.setState({...this.state, inputText: text}, () => this.props.onChange(this.state.inputText));
                }
            }}
            onChange={(selected) => {
                if (selected.length && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(selected[selected.length - 1].email)) {
                    alert('Invalid email!');
                    selected.pop(); // Clear the invalid selection
                }
                const emails = selected && selected.map(x => x.email).join(',');
                this.setState({...this.state, selected});
                this.props.onChange(emails)
            }}
            options={[]}
        />
    }
}

class MoreInfoButton extends Component {

    constructor(arg) {
        super(arg);
        this.state = {
            dropdownOpen: false,
            modalOpen: false,
            reasonModalOpen: false,
            loading: false
        };
        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleReasonModal = this.toggleReasonModal.bind(this);

    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleModal() {
        this.setState({modalOpen: !this.state.modalOpen})
    }

    toggleReasonModal(statusToSet) {
        this.setState({reasonModalOpen: !this.state.reasonModalOpen, statusToSet: statusToSet})
    }

    componentWillMount() {
        this.props.menuItems(this.props.rowObject).then(result => {
            this.setState({statuses: result});
        });
    }

    render() {
        if (!this.state.statuses || this.state.loading) {
            return <Loading/>;
        } else {
            return <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                    className={this.state.loading ? "btn btn-secondary-loading" : "btn btn-secondary"}>
                    ...
                </DropdownToggle>
                <DropdownMenu>
                    {this.state.statuses.map(menuItem => <span>
                                            <DropdownItem
                                                onClick={() => {
                                                    this.setState({
                                                            loading: true
                                                        },
                                                        () => menuItem.callback(this.props.rowObject).then(() => {
                                                            this.setState({
                                                                loading: false
                                                            });
                                                            if (this.props.refetch) {
                                                                this.props.refetch();
                                                            }
                                                        }));
                                                }
                                                }>{menuItem.name}</DropdownItem>
                                        </span>
                    )}
                </DropdownMenu>
            </ButtonDropdown>

        }
    }
}

class PaginatedQueryDataGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            downloadPercentage: 0,
            totalSize: 0, sort: {
                orders: props.defaultSorted ? (Array.isArray(props.defaultSorted) ? props.defaultSorted : [props.defaultSorted]).map(ds => {
                    return {property: ds.dataField, direction: ds.order}
                }) : []
            }, filters: [], selection: [],
            ...(
                this.props.filters &&
                this.props.filters.length &&
                this.props.filters.filter(f => typeof f.placeholder === "object").length > 0 &&
                Object.assign({}, ...this.props.filters.filter(f => typeof f.placeholder === "object").map(f => ({[this.props.dataPath + "," + f.fields[0]]: f.placeholder.key})))
            )
        };
        this.getColumns = this.getColumns.bind(this);
        this.filter = this.filter.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.setParents = this.setParents.bind(this);
        this.getFilterValue = this.getFilterValue.bind(this);
        this.setFilterValue = this.setFilterValue.bind(this);
        this.maximumSelection = this.props.maxSelected || MAX_SELECT;
        this.uuid = uuidv4();

        const moment = require('moment');
        for (const key in props.initialFilters) {
            const filter = props.initialFilters[key];
            if (Array.isArray(filter)) {
                this.setFilterValue(key + 'Start', moment().add(filter[0], filter[1]), true);
                this.setFilterValue(key + 'End', moment().add(filter[2], filter[3]), true);
            } else {
                //check if the filter is a typeahead
                const filterForKey = props.filters.find((value) => {
                    return value.fields[0] === key;
                });
                let filterValue;
                if (filterForKey) {
                    if (filterForKey.type === 'typeahead') {
                        //typeahead needs an option object, not just a string value
                        const options = this.getTypeaheadPossibleValues(filterForKey);

                        filterValue = options.find((opt) => {
                            return opt.id === filter
                        });
                    } else {
                        filterValue = filter;
                    }
                    filterValue = [filterValue];
                } else {
                    filterValue = filter;
                }
                this.setFilterValue(key, filterValue, true);
            }
        }
    }

    getQuery() {
        return this.props.query;
    }

    getColumns(refetch) {
        const permissions = this.global.permissions;
        for (const col of this.props.columns) {
            if (col.hideFor) {
                if (permissions.includes(col.hideFor)) {
                    this.props.columns.splice(this.props.columns.indexOf(col), 1);
                }
            }
            if (col.template) {
                col.formatter = (cell, row, rowIndex, formatExtraData) =>
                    (<JsxParser
                        bindings={{row}}
                        components={{MoreInfoButton}}
                        jsx={_.template(this.props.columns[col].template)(row)}
                        showWarnings={true}
                        key={rowIndex + "_" + col}
                    />)
            }
            if (col.sort) {
                col.sortCaret = (order, column) => {
                    const multiSort = this.state.sort.orders.filter((s => s.property === column.dataField));
                    if (!multiSort.length) return (
                        <span className="order"><span className="dropdown"><span className="caret"></span></span><span
                            className="dropup"><span className="caret"></span></span></span>);
                    else if (multiSort[0].direction === 'asc') return (
                        <span className="react-bootstrap-table-sort-order dropup">
                            <span className="caret"></span>
                            <span style={{fontSize: "1em", position: "absolute"}}>
                                &nbsp;{this.state.sort.orders.length > 1 ? this.state.sort.orders.indexOf(multiSort[0]) + 1 : null}
                            </span>
                        </span>);
                    else if (multiSort[0].direction === 'desc') return (
                        <span className="react-bootstrap-table-sort-order">
                            <span className="caret"></span>
                            <span style={{fontSize: "1em", position: "absolute"}}>
                                &nbsp;{this.state.sort.orders.length > 1 ? this.state.sort.orders.indexOf(multiSort[0]) + 1 : null}
                            </span>
                        </span>);
                    return null;
                }
            }
        }
        if (this.props.menuItems) {
            return [...this.props.columns, {
                dataField: 'options',
                text: ' ',
                formatter: (cell, row) => <MoreInfoButton
                    className="more-options-btn"
                    rowObject={row}
                    menuItems={this.props.menuItems}
                    refetch={refetch}
                    history={this.props.history}/>,
            }]
        } else {
            return this.props.columns;
        }
    }

    getUniqueValues(data, fieldPath) {
        return data.map(dataElem => this.getFieldValue(dataElem, fieldPath)).filter(function (value, index, self) {
            return self.indexOf(value) === index;
        });
    };

    setParents(obj, parents) {
        let newObj = JSON.parse(JSON.stringify(obj));
        const ownProps = Object.keys(newObj);
        for (let ownProp of ownProps) {
            if (newObj[ownProp] instanceof Object || newObj[ownProp] instanceof Array) {
                let newparents = parents.slice();
                newparents.unshift(obj);
                newObj[ownProp] = this.setParents(newObj[ownProp], newparents);
            }
        }
        newObj.__parents = parents;
        return newObj;
    }

    getFilters(data) {
        // if (this.props.filters) {
        //     let result = [];
        //     for (let i = 0; i < this.props.filters.length; i++) {
        //
        //         const filter1 = this.props.filters[i];
        //
        //     }
        //
        //     return {result}</Row></div>;
        // } else {
        //     return null;
        // }
    }

    getTitle() {
        if (this.props.title) {
            return (
                <div className="table-filers-title">
                    <h3>{this.props.title}</h3>
                </div>
            );
        } else {
            return null;
        }
    }

    getButtons() {
        if (this.props.buttons) {
            const disabled = !this.state.selection.length || (this.state.selection.length > this.maximumSelection);
            const toggleModal = () => this.setState({showModal: !this.state.showModal});
            return <div className="table-filers-buttons" style={{marginBottom: "20px"}}>
                <button className={"btn btn-primary"} disabled={disabled} style={{marginRight: "10px"}} onClick={
                    (e) => {
                        this.setState({showModal: true});
                    }}>{i18n.t("grid.archiveSendSelectedButtonLabel")}</button>
                <button className={"btn btn-primary"} onClick={
                    (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        /*let win = null;
                        if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) { // for IE
                            win = window.open('', '_blank');
                            win.document.write("Loading invoice...")
                        };*/

                        axios.post(`/essence-services/essence-services/v1/archive`, {invoices: this.state.selection}, {
                            headers: {'Authorization': "Bearer " + localStorage.token},
                            responseType: 'blob'
                        }).then((response) => {
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                                window.navigator.msSaveOrOpenBlob(response.data, row.id + '.pdf');
                            } else {
                                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/zip'}));
                                fileSaver.saveAs(url, "invoices.zip");
                            }
                        })
                    }
                } disabled={disabled}>{i18n.t("grid.archiveDownloadSelectedButtonLabel")}</button>

                <Modal isOpen={this.state.showModal} toggle={this.toggleModal} size={"large"}
                       style={{"maxWidth": "500px", "width": "90%"}}>
                    <ModalHeader toggle={this.toggleModal}>Send Selected Invoices to</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={({formData}) => {
                            const data = {
                                invoices: this.state.selection,
                                recipients: formData.email.split(','),
                                description: formData.description,
                                idempotencyKey: this.uuid
                            };
                            console.log('formDataEMail ->', formData.email);
                            axios.post(`/essence-services/essence-services/v1/list/send`, data, {
                                headers: {'Authorization': "Bearer " + localStorage.token},
                                responseType: 'blob'
                            }).then((response) => {
                                ReactDOM.render(<ExpiringAlert color="success"
                                                               message={i18n.t("grid.archiveSendSuccess", data)}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                            }).catch((error) => {
                                ReactDOM.render(<ExpiringAlert color="danger"
                                                               message={i18n.t("grid.archiveSendError", {error})}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                            }).finally(() => {
                                this.setState({showModal: false})
                            })
                        }}
                              onChange={({formData}) => {
                                  console.log(formData);
                                  this.uuid = uuidv4()
                              }} schema={{
                            type: "object",
                            required: [
                                "email",
                                "description"
                            ],
                            properties: {
                                email: {
                                    type: "string",
                                    title: "Send to"
                                },
                                description: {
                                    type: "string",
                                    title: "Description"
                                }
                            }
                        }}
                              uiSchema={{
                                  email: {classNames: "emails", "ui:widget": (props) => <EmailTypeAhead {...props} />},
                                  description: {"ui:widget": "textarea"}
                              }}
                        >
                            <button type="submit"
                                    className="btn btn-primary">{i18n.t("grid.archiveSendButtonLabel")}</button>
                            &nbsp;
                            <button type="reset" className="btn btn-secondary"
                                    onClick={toggleModal}>{i18n.t("grid.archiveCancelButtonLabel")}</button>
                        </Form>
                    </ModalBody>
                </Modal>

            </div>

        }
    }

    getFieldValue(obj, path) {
        if (obj) {
            var pathArray = path.split(".");
            if (pathArray.length > 1) {
                if (pathArray[0] === "#") {
                    let concatArr = [];
                    for (let j = 0; j < obj.length; j++) {
                        concatArr = concatArr.concat(this.getFieldValue(obj[j], path.substring(path.indexOf(".") + 1)));
                    }
                    return concatArr;
                } else {
                    return this.getFieldValue(obj[pathArray[0]], path.substring(path.indexOf(".") + 1));
                }
            } else {
                return obj[pathArray[0]];
            }
        } else {
            return [];
        }
    }

    getFilterValue(name) {
        let value = this.props.saveFilter ? this.global[this.props.dataPath + "," + name] : this.state[this.props.dataPath + "," + name];
        return value ? value : null;
    }

    setFilterValue(name, value, isInitial) {
        if (this.props.saveFilter) {
            this.setGlobal({[this.props.dataPath + "," + name]: value});
            this.setState({changed: !this.state.changed});
        } else {
            if (isInitial) {
                this.state = {...this.state, [this.props.dataPath + "," + name]: value};
                this.setState({changed: !this.state.changed});
            } else {
                this.setState({changed: !this.state.changed, [this.props.dataPath + "," + name]: value});
            }
        }

    }

    getPaging() {
        let value = this.global["pagination." + this.props.dataPath];
        return !value ? {page: 1, sizePerPage: this.props.recordsPerPage || 10} : JSON.parse(value);
    }

    setPaging(value) {
        this.setGlobal({["pagination." + this.props.dataPath]: JSON.stringify(value)});
    }

    filter(element) {

        const moment = require('moment');
        let result = true;
        if (this.props.filters) {
            for (let i = 0; i < this.props.filters.length; i++) {
                let result1 = false;
                for (let j = 0; j < this.props.filters[i].fields.length; j++) {
                    const fieldName = this.props.filters[i].fields[j];

                    const fieldValue = this.getFieldValue(element, fieldName);
                    const searchTerm = this.getFilterValue(this.props.filters[i].fields[0]); //this.state['search' + i];
                    const searchTermStart = this.getFilterValue(this.props.filters[i].fields[0] + 'Start') === null ? null : moment(this.getFilterValue(this.props.filters[i].fields[0] + 'Start'));
                    const searchTermEnd = this.getFilterValue(this.props.filters[i].fields[0] + 'End') === null ? null : moment(this.getFilterValue(this.props.filters[i].fields[0] + 'End'));
                    switch (this.props.filters[i].type) {
                        case "text":
                            result1 = result1 || !searchTerm || (fieldValue && fieldValue.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
                            break;
                        case "date":
                            const fieldValueMoment = moment(fieldValue);
                            result1 = result1
                                || (!searchTermStart || searchTermStart.isSameOrBefore(fieldValueMoment, "day"))
                                && (!searchTermEnd || searchTermEnd.isSameOrAfter(fieldValueMoment, "day"));
                            break;
                        case "dropdown":
                            result1 = result1 || !searchTerm || searchTerm.length === 0 || fieldValue === searchTerm;
                            break;
                        case "typeahead":
                            result1 = result1 || !searchTerm || searchTerm.length === 0 || fieldValue === searchTerm;
                            break;
                    }
                }
                result = result && result1;
            }
        }
        // this.state.page = 1;
        return result;
    }

    getRemoveDuplicatesFunction(key, removeDuplicates) {
        return removeDuplicates ? (value, index, self) => self.findIndex(v => v[key] === value[key]) === index : () => true;
    }

    getTypeaheadPossibleValues(filter1) {
        return filter1.possibleValues ? filter1.possibleValues.map(value =>
                ({
                    id: value.key,
                    label: value.value
                }))
            :
            filter1.enumValues ? filter1.enumValues.map(type => ({
                id: type.enum,
                label: type.title
            })) : [{
                id: 'No Data',
                label: 'Either possibleValues or enumValues must be set!'
            }];
    }

    render() {
        const moment = require('moment');
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }
        let valDD;
        let query;
        if (this.props.query) {
            query = this.props.query;
        } else if (this.props.queries[this.props.queryKey]) {
            query = this.props.queries[this.props.queryKey];
        } else {
            query = null;
            console.log('Missing query for role and key: ' + this.props.queryKey);
        }
        const where = this.props.filters ? this.props.filters.map(filter => {
                switch (filter.type) {
                    case "text":
                        const val = this.getFilterValue(filter.fields[0]);
                        return val && val.toString().length > 2 ? filter.fields.map((field) => field + "=='*" + val.replace(/_/g, '\\_') + "*'").join(",") : null;
                    case "date":
                        let valArr = [];
                        const valStart = this.getFilterValue(filter.fields[0] + 'Start');
                        const valEnd = this.getFilterValue(filter.fields[0] + 'End');
                        if (valStart) valArr.push(filter.fields[0] + ">=" + moment(valStart).format('YYYY-MM-DD'));
                        if (valEnd) valArr.push(filter.fields[0] + "<=" + moment(valEnd).format('YYYY-MM-DDT23:59:59'));
                        return valArr.length > 0 ? valArr.join(";") : null;
                    case "dropdown":
                        valDD = this.getFilterValue(filter.fields[0]);
                        return valDD ? filter.fields[0] + "=='*" + valDD + "*'" : null;
                    case "dropdown-eq":
                        valDD = this.getFilterValue(filter.fields[0]);
                        return valDD ? filter.fields[0] + "=='" + valDD + "'" : null;
                    case "typeahead":
                        const valTA = this.getFilterValue(filter.fields[0]);
                        let filterValue = null;
                        if (valTA && typeof valTA !== 'undefined') {
                            const filterValues = [];
                            for(let i = 0; i < valTA.length; i++){
                                for(let j = 0; j < filter.fields.length; j++){
                                    if(Array.isArray(valTA[i].id)){
                                        for(let t = 0; t < valTA[i].id.length; t++){
                                            filterValues.push(filter.fields[j] + "==" + valTA[i].id[t]);
                                        }
                                    } else {
                                        filterValues.push(filter.fields[j] + "==" + valTA[i].id);
                                    }
                                }
                            }
                            filterValue = filterValues.join();
                        } else {
                            filterValue = null;
                        }
                        return filterValue;
                }
            }
        ).filter(operand => operand)
            .map(operand => "(" + operand + ")")
            .join(";") || null : null;

        const escapeQuote = (str) => {
            let expression = "(\\'\\*([^,]*\\'[^,]*)?\\*\\'){1}(,(\\'\\*([^,]*\\'[^,]*)?\\*\\'))*";
            let searchParts = str.split(";");
            let result = "";

            searchParts.forEach(part => {
                if (part.match(expression) && part.match(expression) != null) {
                    let subStr = part.match(expression)[0];
                    part = part.replaceAll(subStr, subStr.replace(/(')(.*)(')/, '\"$2\"'));
                }
                result = result + ";" + part;
            });
            result = result.replace(/^;/, '');
            return result;
        }

        const mixWhere = [this.props.variables && this.props.variables.where ? this.props.variables.where : null, where]
            .filter(operand => operand)
            .map(operand => "(" + escapeQuote(operand) + ")")
            .join(";") || null;

        const vars = {
            ...variables,
            pageNumber: Math.max(0, this.getPaging().page - 1),
            pageSize: this.getPaging().sizePerPage,
            where: mixWhere,
            sort: {...this.state.sort}
        };

        if (this.props.updateKey) {
            sessionStorage[this.props.updateKey] = JSON.stringify({query: query, variables: vars});
        }

        return <Query query={gql`${query}`} fetchPolicy={"network-only"} variables={JSON.parse(JSON.stringify(vars))}>
            {(result) => {
                if (result.error) return <ErrorPage />;
                const {data} = result;
                const fields = this.getFieldValue(data, this.props.paginationPath) || null

                let page = !result.loading && fields? fields.pageable.pageNumber + 1 : this.getPaging().page;
                const pageSize = !result.loading && fields? fields.pageable.pageSize : this.getPaging().sizePerPage;
                const totalElements = !result.loading && fields? fields.totalElements : this.state.totalSize;
                if (!result.loading && (page - 1) * pageSize > totalElements) { // we are requesting a page out of bounds
                    this.setPaging({
                        page: Math.max(Math.ceil(totalElements / pageSize), 1),
                        sizePerPage: this.getPaging().sizePerPage
                    });
                    this.setState({reload: !this.state.reload});
                }
                const expression = this.props.expression ? jsonata(this.props.expression) : null;
                const transformedData = expression ? expression.evaluate(data) : data;
                const dataWithParents = this.setParents(transformedData, []);
                let tableData = this.getFieldValue(dataWithParents, this.props.dataPath);
                // if(tableData.length > 0 && !tableData.reduce((ac, cu) => ac || Object.values(cu).reduce((acc, curr) => acc || Boolean(curr), false), false)){
                //     this.setState({reload: !this.state.reload});
                //     // client.resetStore();
                // }            Row -> filter-bar
                const displayData = tableData.filter(this.getRemoveDuplicatesFunction(this.props.keyField, this.props.removeDuplicates));
                const selectRow = this.props.buttons && {
                    mode: 'checkbox',
                    clickToSelect: true,
                    selectionHeaderRenderer: ({indeterminate, ...rest}) => (
                        <div>
                            <input
                                id={"chkall"}
                                type="checkbox"
                                className="css-checkbox"
                                ref={(input) => {
                                    if (input) input.indeterminate = indeterminate;
                                }}
                                {...rest}
                            />
                            <label for={"chkall"} className="css-label"><i
                                className="icon ion-android-checkbox"></i></label>
                        </div>

                    ),
                    selectionRenderer: ({mode, ...rest}) => (
                        <div>
                            <input id={"chk" + rest.rowIndex} className="css-checkbox" type={mode} {...rest} />
                            <label for={"chk" + rest.rowIndex} className="css-label">
                                <i className="icon ion-android-checkbox"></i>
                            </label>
                        </div>
                    ),
                    onSelect: (row, isSelect, rowIndex, e) => {
                        const oldSelection = this.state.selection.length;
                        if (isSelect) {
                            this.state.selection.push(row.id);
                            if (oldSelection <= this.maximumSelection && this.state.selection.length > this.maximumSelection) {
                                ReactDOM.render(<ExpiringAlert color="warning"
                                                               message={`You have selected more than maximum allowed ${this.maximumSelection} records. Download and Sending will be disabled.`}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                            }
                            this.setState({selection: [...this.state.selection]}, () => {
                                console.log(this.state.selection);
                            });
                        } else {
                            this.setState({selection: this.state.selection.filter(v => v !== row.id)}, () => {
                                console.log(this.state.selection);
                            });
                        }

                    },
                    onSelectAll: (isSelect, rows, e) => {
                        const oldSelection = this.state.selection.length;
                        if (isSelect) {
                            this.state.selection.push(...rows.map(x => x.id));
                            if (oldSelection <= this.maximumSelection && this.state.selection.length > this.maximumSelection) {
                                ReactDOM.render(<ExpiringAlert color="warning"
                                                               message={i18n.t('grid.moreThanMaximumSelected', {maximumSelection: this.maximumSelection})}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                            }
                            this.setState({selection: [...this.state.selection]}, () => {
                                console.log(this.state.selection);
                            });
                        } else {
                            this.setState({selection: this.state.selection.filter(v => rows.map(x => x.id).indexOf(v) === -1)}, () => {
                                console.log(this.state.selection);
                            });
                        }

                    }
                };
                return <div
                    className={(this.props.className || "") + ((this.props.onSelectOpenPage || this.props.onSelect || this.props.openPageOnSelect) && totalElements ? " table-with-links" : "")}>
                    <div className='table-filers-cnt'>
                        {this.getTitle()}
                        <div className="table-filers-elements">
                            <Row>
                                {this.props.filters ? this.props.filters.map((filter1, i) => {
                                        let elem = null;
                                        switch (filter1.type) {
                                            case "text":
                                                elem = <InputGroup className={filter1.classes}>
                                                    <Input placeholder={filter1.placeholder}
                                                           value={this.getFilterValue(filter1.fields[0])}
                                                           onChange={(e) => this.setFilterValue(filter1.fields[0], e.target.value, false)}/>
                                                    <i className='icon ion-ios-search-strong'/>
                                                </InputGroup>;
                                                break;
                                            case "date":
                                                elem = <div className={filter1.classes}><DateRangePicker small
                                                                                                         startDate={this.getFilterValue(filter1.fields[0] + 'Start') === null ? null : moment(this.getFilterValue(filter1.fields[0] + 'Start'))} // momentPropTypes.momentObj or null,
                                                                                                         startDateId={filter1.fields[0] + 'Start'} // PropTypes.string.isRequired,
                                                                                                         endDate={this.getFilterValue(filter1.fields[0] + 'End') === null ? null : moment(this.getFilterValue(filter1.fields[0] + 'End'))} // momentPropTypes.momentObj or null,
                                                                                                         endDateId={filter1.fields[0] + 'End'} // PropTypes.string.isRequired,
                                                                                                         onDatesChange={({
                                                                                                                             startDate,
                                                                                                                             endDate
                                                                                                                         }) => {
                                                                                                             this.setFilterValue(filter1.fields[0] + 'Start', startDate, false);
                                                                                                             this.setFilterValue(filter1.fields[0] + 'End', endDate, false);
                                                                                                         }
                                                                                                         } // PropTypes.func.isRequired,
                                                                                                         displayFormat={"DD/MM/YYYY"}
                                                                                                         focusedInput={this.state['focusedInput' + filter1.fields[0]]} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                                                                         onFocusChange={focusedInput => this.setState({['focusedInput' + filter1.fields[0]]: focusedInput})} // PropTypes.func.isRequired,
                                                                                                         showClearDates={true}
                                                                                                         isOutsideRange={(day) => filter1.disableFutureDates ? day.isAfter(moment()) : false}
                                                                                                         showDefaultInputIcon={true}
                                                                                                         noBorder={false}
                                                /></div>;
                                                break;
                                            case "dropdown":

                                                elem = (
                                                    <InputGroup className={filter1.classes}>
                                                        <Input type="select" value={this.getFilterValue(filter1.fields[0])}
                                                               onChange={(e) => this.setFilterValue(filter1.fields[0], e.target.value, false)}>
                                                            {
                                                                typeof filter1.placeholder === 'string'
                                                                    ? <option key={-1} className='default-option'
                                                                              value="">{filter1.placeholder}</option>
                                                                    : typeof filter1.placeholder === 'object'
                                                                    ? <option key={-1} className='default-option'
                                                                              value={filter1.placeholder.key}>{filter1.placeholder.value}</option>
                                                                    : null
                                                            }
                                                            {
                                                                filter1.possibleValues.map(value => (
                                                                    <option key={value.key}
                                                                            value={value.key}>{value.value}</option>
                                                                ))
                                                            } </Input></InputGroup>);
                                                break;
                                            case "dropdown-eq":

                                                elem = (
                                                    <InputGroup className={filter1.classes}>
                                                        <Input type="select" value={this.getFilterValue(filter1.fields[0])}
                                                               onChange={(e) => this.setFilterValue(filter1.fields[0], e.target.value, false)}>
                                                            {
                                                                typeof filter1.placeholder === 'string'
                                                                    ? <option key={-1} className='default-option'
                                                                              value="">{filter1.placeholder}</option>
                                                                    : typeof filter1.placeholder === 'object'
                                                                        ? <option key={-1} className='default-option'
                                                                                  value={filter1.placeholder.key}>{filter1.placeholder.value}</option>
                                                                        : null
                                                            }
                                                            {
                                                                filter1.possibleValues.map(value => (
                                                                    <option key={value.key}
                                                                            value={value.key}>{value.value}</option>
                                                                ))
                                                            } </Input></InputGroup>);
                                                break;
                                            case "typeahead":

                                                elem = <Typeahead
                                                    className={filter1.classes}
                                                    multiple
                                                    selected={this.getFilterValue(filter1.fields[0])}
                                                    placeholder={filter1.placeholder}
                                                    onChange={(selected) => {
                                                        let value;
                                                        if (typeof selected !== 'undefined' && selected.length > 0) {
                                                            value = selected;
                                                        } else {
                                                            value = null;
                                                        }
                                                        this.setFilterValue(filter1.fields[0], value, false);
                                                    }}
                                                    options={this.getTypeaheadPossibleValues(filter1)}/>;
                                                break;
                                        }
                                        elem = filter1.label ? [<label
                                            className="filter-label">{filter1.label}</label>, elem] : elem;
                                        return <Col key={i} sm={filter1.cols}>{elem}</Col>;
                                    }
                                ) : null}
                            </Row>
                        </div>
                    </div>
                    {this.getButtons()}
                    {this.props.selectorButtons && (this.props.selectorButtons)}
                    {this.props.reload || !this.props.reload || this.state.reload || !this.state.reload}
                    {!this.global.permissions.includes('Users.ReadOnly') && this.props.note && (
                        <Row><Col sm={this.props.gridColSM ? this.props.gridColSM : ''}>{this.props.note}</Col></Row>)}
                    {this.props.export && (
                        // <div style={{'margin-top': '10px'}}>
                        <div>
                            <Button style={{marginBottom: '20px'}} color="primary" onClick={() => {
                                this.setState({csvloading: true, csvdata: null});
                                const query = gql`${this.props.queries[this.props.queryKey]}`;

                                function getAllPages(allData, startFrom) {
                                    return this.props.client.query({
                                        query: query,
                                        fetchPolicy: 'no-cache',
                                        variables: {...vars, pageNumber: startFrom, pageSize: this.props.csvPageSize || 100}
                                    }).then(({data}) => {
                                        const d = this.getFieldValue(data, this.props.dataPath);
                                        allData.push(...d);

                                        // response object 'data' has a single key which is the
                                        // data path used to access the content 
                                        const dataObj = data[Object.keys(data)[0]]; 

                                        // calculate progress based on which page we're on
                                        const totalPages = dataObj.totalPages;
                                        const currentPage = dataObj.number + 1;
                                        this.setState({downloadPercentage: Math.round((currentPage / totalPages) * 100)});

                                        if (dataObj.last) {
                                            return allData;
                                        } else {
                                            return getAllPages(allData, startFrom+1)
                                        }
                                    }).catch((error) => {
                                        ReactDOM.render(<ExpiringAlert color="danger"
                                                                       message={i18n.t("grid.archiveSendError", {error})}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                    })
                                }
                                getAllPages = getAllPages.bind(this);

                                getAllPages([],0).then( d  => {
                                    const cells = [];
                                    const header = [];
                                    for (const col of this.props.columns) {
                                        header.push(col.text)
                                    }
                                    for (const x of d) {
                                        const row = [];
                                        for (const col of this.props.columns) {
                                            if (col.csvformatter) {
                                                row.push(col.csvformatter(this.getFieldValue(x, col.dataField), x, d.indexOf(x), col.formatExtraData));
                                            } else if (col.formatter) {
                                                const res = col.formatter(this.getFieldValue(x, col.dataField), x, d.indexOf(x), col.formatExtraData);
                                                row.push(typeof res === 'object' ? this.getFieldValue(x, col.dataField) : res);
                                            } else {
                                                row.push(this.getFieldValue(x, col.dataField));
                                            }

                                        }
                                        cells.push(row);
                                    }
                                    this.setState({csvdata: cells, csvheader: header, csvloading: false});
                                    this.csvDownload.link.click();
                                }).finally(() => {
                                    this.setState({csvloading: false})
                                })
                            }}>{this.state.csvloading && <b>{this.state.downloadPercentage}%</b>} Export as CSV</Button>
                            {this.state.csvdata &&
                            <CSVLink filename={this.props.title ? this.props.title + "-export.csv" : "export.csv"}
                                     ref={csvDownload => this.csvDownload = csvDownload} data={this.state.csvdata}
                                     headers={this.state.csvheader} style={{display: 'none'}}/>}
                        </div>
                    )}
                    <Row className={this.props.gridRowClass ? this.props.gridRowClass : ''}>
                        <Col sm={this.props.gridColSM ? this.props.gridColSM : ''}
                             className={this.props.gridColClass ? this.props.gridColClass : ''}>
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                keyField={this.props.keyField}
                                data={displayData}
                                columns={this.getColumns(result.refetch)}
                                bordered={false}
                                rowEvents={{
                                    onMouseMove: (e, row, rowIndex) => {
                                        e.target.move = true;
                                    },
                                    onMouseDown: (e, row, rowIndex) => {
                                        e.target.move = false;
                                    },
                                    onClick: (e, row, rowIndex) => {
                                        if (e.target.move) return;
                                        if (this.props.openPageOnSelect) {
                                            const parameters = Object.assign(this.props.match.params, row);
                                            var compiled = _.template(this.props.openPageOnSelect);
                                            const url = compiled(parameters);
                                            this.props.history.push(url);
                                        } else if (this.props.onSelect) {
                                            if (e.target.type !== "button") {
                                                this.props.onSelect(row);
                                            }
                                        }
                                    }
                                }}
                                remote={true}
                                onTableChange={(type, newState) => {
                                    switch (type) {
                                        case 'pagination':
                                            this.setPaging({page: newState.page, sizePerPage: newState.sizePerPage});
                                            this.setState({
                                                ...this.state,
                                                totalSize: this.getFieldValue(data, this.props.paginationPath).totalElements
                                            });
                                            break;
                                        case 'sort':
                                            const multiSort = this.state.sort.orders;
                                            const sorted = multiSort.filter(el => el.property === newState.sortField);
                                            if (sorted.length) {
                                                if (sorted[0].direction === "asc") {
                                                    sorted[0].direction = "desc";
                                                } else {
                                                    multiSort.splice(multiSort.indexOf(sorted[0]), 1);
                                                }
                                            } else {
                                                if (this.props.reverseSort) {
                                                    multiSort.push({direction: "asc", property: newState.sortField});
                                                } else {
                                                    multiSort.unshift({direction: "asc", property: newState.sortField});
                                                }
                                            }

                                            this.setState({
                                                ...this.state,
                                                sort: {
                                                    orders: JSON.parse(JSON.stringify(multiSort))
                                                }
                                            }, () => console.log(this.state.sort.orders, multiSort));
                                            break;
                                    }
                                }}
                                hover
                                pagination={paginationFactory({
                                    page: page,
                                    sizePerPage: pageSize,
                                    onPageChange: (page, sizePerPage) => {
                                        this.setPaging({page, sizePerPage});
                                    },
                                    sizePerPageList: this.props.sizePerPageList || [{
                                        text: "5", value: 5
                                    }, {
                                        text: "10", value: 10
                                    }, {
                                        text: "20", value: 20
                                    }],
                                    totalSize: totalElements
                                })}
                                loading={result.loading}
                                overlay={overlayFactory({spinner: true, background: 'rgba(192,192,192,0.3)'})}
                                noDataIndication={() => <div>No records in table</div>}
                                selectRow={selectRow}

                            />
                        </Col>
                        {this.props.children}
                    </Row>
                </div>;
            }
            }
        </Query>
    }
}

export default withRouter(withApollo(PaginatedQueryDataGrid))
