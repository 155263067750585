import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {createTaskMutation,} from "../../queries/Queries";
import ExpiringAlert from "../../components/ExpiringAlert";
import gql from "graphql-tag";
import schema from "../../schemas/task.schema.js"
import uischema from "../../schemas/task.uischema.js"
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Mutation} from "react-apollo";
import Form from "react-jsonschema-form";
import i18n from "../Pages/Login/i18n";
import TasksAttachments from "../../components/TasksAttachments";
import ModalDialog from "../../components/ModalDialog";
import {withRouter} from 'react-router';
import customFormats from "../../schemas/customFormats";
// import EventLogger from "../../components/EventLogger";
// import ErrLogger from "../../components/ErrorLogger";
import LoadingImage from "../../images/loading-grey.svg";
import {getSchemaCreteQuery, getType} from '../../utils/Helpers';

class CreateTask extends Component {

    constructor(props) {
        super(props);
        this.minimizeTask = this.minimizeTask.bind(this);
        this.state = {
            isMinimized: false,
            schema: getSchemaCreteQuery(schema, this.props.meterPointsData)
        }
    }

    minimizeTask() {
        this.setState({
            isMinimized: !this.state.isMinimized,
            loading: false
        });
    }

    render() {

        const refetchQuery = sessionStorage.listOfTasksQuery && JSON.parse(sessionStorage.listOfTasksQuery);
        if (refetchQuery) {
            refetchQuery.query = refetchQuery.query && gql`${refetchQuery.query}`;
        }
        const taskComponent = this;
        return (
        <Modal isOpen={this.props.modalOpen} size={"large"}
               modalClassName={this.state.isMinimized ? "minimized" : ""} className="create-task-modal">

            <ModalHeader toggle={this.props.onClose}>

                <button className="btn btn-primary btn-minimize" onClick={this.minimizeTask}><i
                    className={this.state.isMinimized ? "icon ion-android-arrow-up" : "icon ion-android-arrow-down"}></i>
                </button>
            </ModalHeader>
            <ModalBody>
                <div style={{display: this.state.loading ? 'block' : 'none'}} className="loading-img">
                    <img src={LoadingImage}/>
                </div>

                <div>
                    <div className="task-labels clearfix">
                        <span className="pull-left">{i18n.t('tasks.addTask')}</span>
                    </div>

                    <Mutation mutation={gql`${createTaskMutation}`} refetchQueries={refetchQuery && [refetchQuery]}>
                        {(createTask, {data}) => (
                            <Form showErrorList={false} customFormats={customFormats} schema={this.state.schema} noHtml5Validate
                                  transformErrors={errors => errors.map(err => {
                                      if (err.message.startsWith("should match format")) {
                                          const format = err.message.substr()
                                          switch (format.substr(20)) {
                                              case '"lettersOnly"' :
                                                  err.message = "should contain only letters";
                                                  break;
                                              case    '"phone"':
                                                  err.message = "should contain only numbers";
                                                  break;
                                              case    '"numbersOnly"':
                                                  err.message = "should contain only numbers";
                                                  break;
                                              case    '"lettersAndNumbers"':
                                                  err.message = "should contain only letters and numbers";
                                                  break;
                                          }
                                      }
                                      return err;
                                  })}
                                  uiSchema={uischema}
                                  onSubmit={({formData}) => {

                                      this.setState({loading: true});

                                      const variables = [
                                          {
                                              name: "type",
                                              type: "string",
                                              value: formData.type + ""
                                          },
                                          {
                                              name: "status",
                                              type: "string",
                                              value: formData.status
                                          },
                                          {
                                              name: "contact_name",
                                              type: "string",
                                              value: formData.contact_name,
                                          },
                                          {
                                              name: "contact_number",
                                              type: "string",
                                              value: formData.contact_number
                                          },
                                          {
                                              name: "contact_email",
                                              type: "string",
                                              value: formData.contact_email
                                          },
                                          {
                                              name: "mprn",
                                              type: "string",
                                              value: formData.mprn
                                          },
                                          {
                                              name: "estate",
                                              type: "string",
                                              value: formData.estate
                                          },
                                          {
                                              name: "account_code",
                                              type: "string",
                                              value: formData.account_code
                                          }
                                      ];


                                      formData.change_of_od && Object.keys(formData.change_of_od).length > 0 && variables.push({
                                          name: "change_of_od",
                                          type: "string",
                                          value: JSON.stringify(formData.change_of_od)
                                      });
                                      formData.outgoing_customer && Object.keys(formData.outgoing_customer).length > 0 && variables.push({
                                          name: "outgoing_customer",
                                          type: "string",
                                          value: JSON.stringify(formData.outgoing_customer)
                                      });
                                      formData.incomming_customer && Object.keys(formData.incomming_customer).length > 0 && variables.push({
                                          name: "incomming_customer",
                                          type: "string",
                                          value: JSON.stringify(formData.incomming_customer)
                                      });
                                      formData.coo_pricing && Object.keys(formData.coo_pricing).length > 0 && variables.push({
                                          name: "coo_pricing",
                                          type: "string",
                                          value: JSON.stringify(formData.coo_pricing)
                                      });

                                      taskComponent.attachments && variables.push({
                                          name: "attachments",
                                          type: "string",
                                          value: "true"
                                      });

                                      createTask({
                                          variables: {
                                              task: {
                                                  name: formData.name,
                                                  delegationState: "pending",
                                                  dueDate: formData.dueDate,
                                                  description: formData.description,
                                                  assignee: formData.assignee,
                                                  priority: formData.priority,
                                                  variables: variables
                                              },
                                              type: getType(this.props.meterPointsData)
                                          }
                                      }).then((response) => {
                                          this.setState({loading: false});
                                          const taskId = response.data.createTask || response.data.createRemoteTask;
                                          if (!taskId) {
                                              // ErrLogger(formData, "SBMT_QUIRY_ERR", "Task id not returned");
                                              this.setState({loading: false});
                                              ReactDOM.render(<ExpiringAlert color="danger" message={i18n.t('tasks.errorOnCreate')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                          } else {
                                              this.child.uploadAttachments({
                                                  id: taskId
                                                  //service: "activiti"
                                              });
                                              this.props.onClose();
                                              ReactDOM.render(<ModalDialog title={i18n.t('tasks.successfulCreateTitle')}
                                                                           text={i18n.t('tasks.successfulCreate')}
                                                                           opened={true}
                                                                           closeLabel={"Close"}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                              // EventLogger(formData, "SBMT_QUIRY_OK");
                                          }
                                      }).catch(
                                          (error) => {
                                              // ErrLogger(formData, "SBMT_QUIRY_ERR", error);
                                              this.setState({loading: false});
                                              ReactDOM.render(<ExpiringAlert color="danger" message={i18n.t('tasks.errorOnCreate')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                          }
                                      );

                                  }}
                                  formData={{
                                      dueDate: "",
                                      customer: this.props.match.params.param,
                                      contact_email: this.props.user,
                                      estate: this.props.meterPoint.site.name,
                                      mprn: this.props.meterPoints[0],
                                      account_code: this.props.accounts[0]
                                  }}
                                  formContext={{accounts: this.props.accounts, meterPoints: this.props.meterPoints, meterPointsData: this.props.meterPointsData}}>
                                <TasksAttachments ref={instance => {
                                    this.child = instance;
                                }} uploadImmediately={false} parent={this}/>
                                <div className="buttons">
                                    <button type="submit" className="btn btn-primary">Add Query</button>
                                    <button type="button" className="btn btn-secondary"
                                            onClick={() => this.props.onClose()}>Cancel
                                    </button>
                                </div>
                            </Form>)
                        }
                    </Mutation>
                </div>
            </ModalBody>
        </Modal>)

    }
}


export default withRouter(CreateTask);
