import React, { Component } from 'react';
import {Button, InputGroup, InputGroupAddon, Input, FormGroup, Label, Col, Row} from 'reactstrap';
import { getAddresses } from "../../queries/Queries";
import { compose, graphql, withApollo } from "react-apollo";
import { withRouter } from 'react-router';
import i18n from "../../views/Pages/Login/i18n";

class FindAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      postalCode: null,
      noAddress: false,
        newAddress: false
    }
    this.findAddress = this.findAddress.bind(this);
    this.addressChange = this.addressChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  findAddress() {
    if (this.state.postalCode) {
      this.props.getAddresses.refetch({ postcode: this.state.postalCode }).then(({ data: { getAddresses } }) => {
        this.setState({ options: getAddresses.map(address => ({...address, mprn: address.meterData[0].mprn, gas_meter_serial_number: address.meterData[0].gas_meter_serial_number})).filter(e => e.postalCode) })
      }).catch(err => {
        this.setState({ options: [] });
      })
    }
  }
  addressChange(event) {
    this.setState({ postalCode: event.target.value });
  }
  onChange(e) {
      if (document.getElementsByName('createquote')[0]) document.getElementsByName('createquote')[0].checkValidity();
      this.props.onChange({...this.props.address, [e.target.name]: e.target.value});
  }

    resetAddress(e) {
        this.props.onChange({});
        this.setState({options:[]});
    }

  render() {
      const houseNumber =  <FormGroup>
              <Label for="houseNumber">{i18n.t('address.houseNumber')}</Label>
              <Input type="text" name="houseNumber" id="houseNumber" placeholder="Enter House Number"  value={this.props.address.houseNumber} onChange={(e) => this.onChange(e)} maxlength="4"/>
          </FormGroup>;
      const block = <FormGroup>
              <Label for="block">{i18n.t('address.block')}</Label>
              <Input type="text" name="block" id="block" placeholder="Enter Block"
                     value={this.props.address.block} onChange={(e) => this.onChange(e)} maxlength="50"/>
          </FormGroup>;
      const dependentStreet = <FormGroup>
          <Label for="dependentStreet">{i18n.t('address.dependentStreet')}</Label>
          <Input type="text" name="dependentStreet" id="dependentStreet"
                 placeholder="Enter Dependent Street" value={this.props.address.dependentStreet}
                 onChange={(e) => this.onChange(e)} maxlength="40"/>
      </FormGroup>;
      const street = <FormGroup>
          <Label for="street">{i18n.t('address.street')}</Label>
          <Input type="text" name="street" id="street" placeholder="Enter Street"
                 value={this.props.address.street} onChange={(e) => this.onChange(e)} maxlength="40"/>
      </FormGroup>;
      const city = <FormGroup>
              <Label for="city">{i18n.t('address.city')}</Label>
              <Input type="text" name="city" id="city" placeholder="Enter City/Town"
                     value={this.props.address.city} onChange={e => this.onChange(e)} maxlength="40"/>
          </FormGroup>;
      const county = <FormGroup>
          <Label for="county">{i18n.t('address.county')}</Label>
          <Input type="text" name="county" id="county" placeholder="Enter County"
                 value={this.props.address.county} onChange={e => this.onChange(e)} maxlength="40"/>
      </FormGroup>;
      const postalCode = <FormGroup>
              <Label for="postalCode">{i18n.t('address.postalCode')}</Label>
              <Input type="text" name="postalCode" id="postCode" placeholder="Enter Post Code"
                     value={this.props.address.postalCode} onChange={e => this.onChange(e)} pattern={"^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? [0-9][A-Za-z]{2}|[Gg][Ii][Rr] 0[Aa]{2})$"}/>
          </FormGroup>;
      return (
      <div className="findAddress">

          <p>
              <Input type="radio" name={"newAddress_"+this.props.name} id={'existingAddress_'+this.props.name} value={!this.state.newAddress}
                     checked={!this.state.newAddress} onChange={(e) => this.setState({newAddress: false})} disabled={this.props.disabled}/>
              <label htmlFor={'existingAddress_'+this.props.name} className="css-label">
                  <i className="icon ion-android-radio-button-off"/>
                  <i className="icon ion-android-radio-button-on"/>
                  &nbsp; &nbsp;Search and select an existing address:
              </label>
          </p>
          {!this.state.newAddress && <div><InputGroup>
              <Input autoFocus name="postalCode" onInput={this.addressChange} placeholder="Enter Postcode" disabled={this.props.disabled}/>
              <InputGroupAddon><Button color="primary" onClick={this.findAddress}
                                       disabled={this.props.disabled}>Search</Button></InputGroupAddon>
          </InputGroup>
          <FormGroup>
          <Input type="select"
                 name={this.props.name}
                 id="address"
                 // defaultValue={JSON.stringify(this.props.address)}
                 value={this.props.address.postalCode && this.props.address.postalCode === this.state.postalCode? JSON.stringify(this.props.address) : null}
                 size={5}
                 disabled={this.props.disabled}
                 onChange={(e) => this.props.onChange(JSON.parse(e.target.value))} >
              {this.state.options.length === 0 && !this.props.address.city && <option value="" selected >No Address found</option>}
              {this.state.options.length === 0 && this.props.address.city && <option value={JSON.stringify(this.props.address)}>
                  {(DYCE ? [this.props.address.houseNumberAdd, this.props.address.block, this.props.address.houseNumber, this.props.address.dependentStreet, this.props.address.street, this.props.address.doubleDependentCity, this.props.address.dependentCity, this.props.address.city, this.props.address.county ]
                     : [this.props.address.city, this.props.address.city, this.props.address.street, this.props.address.block , this.props.address.houseNumber ])
                      .filter(v => v && v.length > 0).join(', ')}
              </option>}
            {this.state.options.map((option, index) => <option value={JSON.stringify(option)} key={index}>{
                (DYCE ?  [option.houseNumberAdd, option.block, option.houseNumber, option.dependentStreet, option.street, option.doubleDependentCity, option.dependentCity, option.city, option.county]
                : [option.county, option.city, option.street, option.block , option.houseNumber]).filter(v => v && v.length > 0).join(', ')}</option>)}
          </Input>
        </FormGroup></div>}
        {[

            <p>
                <Input type="radio"
                       name={"newAddress_"+this.props.name}
                       id={'newAddress_'+this.props.name}
                       value={this.state.newAddress}
                       checked={this.state.newAddress}
                       disabled={this.props.disabled}
                       onChange={(e) => {
                           this.setState({newAddress: true})
                           this.resetAddress();
                       }}/>
                <label htmlFor={'newAddress_'+this.props.name} className="css-label">
                    <i className="icon ion-android-radio-button-off"></i>
                    <i className="icon ion-android-radio-button-on"></i>
                    &nbsp; &nbsp;or enter a new one:
                </label>
            </p>, this.state.newAddress
                ? <div>
                    {DYCE
                    ? <div><Row>
                        <Col>
                            {houseNumber}
                        </Col>
                        <Col>
                            {block}
                        </Col>
                    </Row>
                    {street}
                    {dependentStreet}
                    <Row>
                        <Col>
                            {city}
                        </Col>
                        <Col>
                            {county}
                        </Col>
                        <Col>
                            {postalCode}
                        </Col>
                    </Row></div>

                    : <div><Row>
                        <Col>
                            {postalCode}
                        </Col>
                        <Col>
                            {county}
                        </Col>
                        <Col>
                            {city}
                        </Col>
                    </Row>
                    {street}
                    {dependentStreet}
                    <Row>
                        <Col>
                            {block}
                        </Col>
                        <Col>
                            {houseNumber}
                        </Col>
                    </Row></div>}
                </div>
                : null
            ]}
      </div>
    )
  }
}
export default compose(graphql(getAddresses, {
  name: 'getAddresses', options: props => ({
    variables: { postcode: '' }
  }),
}))(withApollo(withRouter(FindAddress)));