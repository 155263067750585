import React, { Component } from 'react';
import axios from "axios";
import i18n from '../../views/Pages/Login/i18n';

class PasswordPolicy extends Component {
  constructor(props){
    super(props);
    this.state = {policy: null};
  }
  render() {
    if(!this.state.policy) {
      axios.get(`${CORE_URL}/password-policy`)
          .then((response) => {
            this.setState({policy : Object.entries(response.data).filter(pair => pair[1] > 0)});
          });
    }
    return <span style={{fontSize: 'small', color:'gray'}}> {this.state.policy ? 'Password policy: ' + this.state.policy.map(pair => i18n.t('passwordPolicy.' + pair[0]) +': '+pair[1]).join(', ') : null}</span>
  }
}

export default PasswordPolicy;
