import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { apolloReducer } from 'apollo-cache-redux';

const rootReducer = combineReducers({
    // ...your other reducers here
    // you have to pass formReducer under 'form' key,
    // for custom keys look up the docs for 'getFormState'
    //apollo: apolloReducer,
    form: formReducer
});

export default function configureStore() {
    return createStore(
        rootReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
}