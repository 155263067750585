import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {HashRouter, Switch} from 'react-router-dom';
import reactGuard from '../../components/ReactGuard';
import {I18n} from 'react-i18next';
import axios from "axios";
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie'
// Styles
// Import Font Awesome Icons Set
import 'segoe-fonts/segoe-fonts.css';
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Temp fix for reactstrap
import '../../../scss/core/_dropdown-menu-right.scss'
// Containers
import Full from '../../containers/Full/'
// Views
import Login from '../../views/Pages/Login/'
import Logout from '../../views/Pages/Logout/'


import configureStore from '../../store/configureStore';

import PrivateRoute from '../../components/PrivateRoute';
import LoginRoute from '../../components/LoginRoute';

import {ApolloProvider} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import { onError } from "apollo-link-error";
import {createHttpLink} from 'apollo-link-http';
// import { BatchHttpLink } from "apollo-link-batch-http";
//import { ReduxCache } from 'apollo-cache-redux';
import {InMemoryCache} from 'apollo-cache-inmemory';

import {setContext} from 'apollo-link-context';
import ForgottenPassword from "../../views/Pages/ForgottenPassword";
import ResetPassword from "../../views/Pages/ResetPassword";
import ChangeExpiredPassword from "../../views/ChangeExpiredPassword";
import RegisterUser from "../../total/views/RegisterUser";
import ExpiringAlert from "../../components/ExpiringAlert/ExpiringAlert";
import ReactDOM from "react-dom";

// Import Main styles for this application

// import '../../../scss/style.scss'
if (DYCE) {
    require('../../../scss/dyce-style.scss');
} else if (TOTAL) {
    require('../../../scss/total-style.scss');
} else {
    require('../../../scss/style.scss');
}

// Store
const store = configureStore();

const httpLink = createHttpLink({
    uri: `${GRAPHQL_URL}/graphql`,
});
// const httpLink = new BatchHttpLink({
//     uri: `${CORE_URL}/graphql`,
// });

const getCookie = function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = document.cookie;
    var ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.token;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
        }
    }
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation, response }) => {
    if (graphQLErrors) {
        console.error(`[GraphQL errors]: ${graphQLErrors}`);
        graphQLErrors.map(error => {
            if (error.extensions.code === "UNAUTHENTICATED") {
                window.location = '/#/login/';
                const expiringAlert = <ExpiringAlert color="danger" message={error.message}/>
                ReactDOM.render(expiringAlert, document.getElementById('alert'));
            }
        });
    }
    if (networkError) console.error(`[Network error]: ${networkError}`);
});


const client = new ApolloClient({
    // link: authLink.concat(httpLink),
    link: authLink.concat(errorLink).concat(httpLink),
    // link: ApolloLink.from([authLink.concat(httpLink), errorLink]),
    cache: new InMemoryCache({
        addTypename: false
    }),
    shouldBatch: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
    }
});
const setMarketingCookies = function setMarketingCookies() {
    Cookies.set('stats', 'allow', { expires: 365 });
    Cookies.set('acceptedCookies', '1', { expires: 365 });
}

//this.props.authed

const canAccess = () => !!getCookie('token');

if (!document.reactGuardLoaded) {
    reactGuard(React, function (err, componentInfo) {
        // Print stacktrace to the console
        console && console.error && console.error(err.stack);
        console && console.error && console.error(componentInfo);
        axios.post(CORE_URL + '/log', {message: err.message, stackTrace: err.stack});
        // Notify Sentry (replace with your service of choice)
        // Raven.captureException(err, {
        //     extra: {
        //         props: componentInfo.props,
        //         state: componentInfo.state,
        //         displayName: componentInfo.displayName
        //     }
        // })

        // Replace failed component with "Failed to render".
        // Use `return null` to render nothing.
        return <div>Failed to render component. Please report this error: <div
            style={{color: "red"}}>{err.message}<br/>{err.stack}</div></div>
    });
    document.reactGuardLoaded = true;
}

class App extends Component {
    constructor(props) {
        super(props);
        if (DYCE) {
            document.querySelectorAll("link[rel*='icon']").forEach(link => {
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = link.href.replace('dyce/', '').replace('img/', 'img/dyce/');
                document.getElementsByTagName('head')[0].appendChild(link);
                setMarketingCookies()
            });
        }
    }

    render() {
        document.title = config.consul.title;
        const history = createBrowserHistory();
        window.gtag && history.listen((location, action) => {
            let path = document.location.href
            let pagename = ''
             if(document.location.href.split('/').length>7){
                pagename = document.location.href.split('/')[5] +' '+ document.location.href.split('/')[7]

             }else{
                pagename = document.location.href.split('/').pop()
                if(pagename.includes('@')){
                    pagename = "profile"
                }
             }
            pagename = pagename.charAt(0).toUpperCase() + pagename.slice(1)
            gtag('event', 'page_view', {
                page_title: pagename,
                page_location: window.location.href,
                page_path: path,
            })
        });
        return (<div>

            <I18n>{
                (t, {i18n}) => (
                    <HashRouter history={history}><Provider store={store}>
                        <ApolloProvider client={client}>
                            <Switch>
                                <LoginRoute canAccess={canAccess} exact path="/login" name="Login Page"
                                            component={Login}/>
                                <LoginRoute canAccess={canAccess} exact path="/login/:url+" name="Login Page"
                                            component={Login}/>
                                <LoginRoute canAccess={canAccess} exact path="/reset-password/:token"
                                            name="Reset Password Page" component={ResetPassword}/>
                                <LoginRoute canAccess={canAccess} exact path="/reset-password"
                                            name="Forgotten Password Page" component={ForgottenPassword}/>
                                <LoginRoute canAccess={canAccess} exact path="/change-expired-password"
                                            name="Change Password Page" component={ChangeExpiredPassword}/>
                                <LoginRoute canAccess={canAccess} exact path="/register/:token"
                                            name="Register User Page" component={RegisterUser}/>
                                <PrivateRoute canAccess={canAccess} path="/logout" name="Logout" component={Logout}/>
                                <PrivateRoute canAccess={canAccess} path="/" name="Home" component={Full}/>
                            </Switch>
                        </ApolloProvider>
                    </Provider></HashRouter>
                )
            }
            </I18n>
        </div>);
    }
}

export default App;
