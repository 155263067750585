/**
 * Created by ogi on 1/22/18.
 */

import React, {Component} from 'reactn';
import BootstrapTable from 'react-bootstrap-table-next';
import JsxParser from 'react-jsx-parser';
import Widget01 from '../../views/Widgets/Widget01';
import MoreInfoButton from '../MoreInfoButton'
import _ from 'lodash';
import {withRouter} from 'react-router';
import i18n from "../../views/Pages/Login/i18n";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {CSVLink} from "react-csv";
import jsonata from "jsonata/jsonata-es5";
import {isArray} from "min-dash";

class SimpleDataGrid extends Component {

    constructor(props) {
        super(props);
        this.getColumns = this.getColumns.bind(this);
        //this.state = {page: 1, sizePerPage: 5};
    }

    getQuery() {
        return this.props.query;
    }

    getColumns() {
        for (const col in this.props.columns) {
            if (this.props.columns[col].template) {
                this.props.columns[col].formatter = (cell, row, rowIndex, formatExtraData) =>
                    (<JsxParser
                        bindings={{row}}
                        components={{Widget01, MoreInfoButton}}
                        jsx={_.template(this.props.columns[col].template)(row)}
                        showWarnings={true}
                        key={rowIndex + "_" + col}
                    />)
            }
        }

        return this.props.columns;
    }

    // componentWillReceiveProps(nextProps) {
    //     // reset the timer if children are changed
    //     if (!nextProps.dataQuery.loading) {
    //         this.setState({
    //             page: Math.min(Math.ceil(nextProps.getData(nextProps).filter(
    //                 nextProps.filter
    //                     ? nextProps.filter
    //                     : (o) => true
    //             ).length / this.state.sizePerPage), this.state.page)
    //         });
    //     }
    // }

    getHeaders() {
        const headers = []
        for (const col of this.props.columns) {
            if (col.dataField) headers.push({label: i18n.t(col.text), key: col.dataField});
        }
        return headers;
    }

    transformData(data, exp) {
        let expr = exp;
        console.log(expr, data);
        const expression = expr ? jsonata(expr) : null;
        const transformedData = expression ? expression.evaluate(data) : data;
        return transformedData;
    }

    render() {

        const transformedData = this.transformData(this.props.data, this.props.expression);
        let data = transformedData;
        if (data && !isArray(data)) data = [data];
        if (!data || data.length == 0) return this.props.noDataView ? this.props.noDataView :
            <div>No records in table</div>;
        return (
            <div className={(this.props.className || "") + (this.props.openPageOnSelect ? "table-with-links" : "")}>
                {this.props.export && (
                    <CSVLink data={data} filename={`${this.props.documentName}.csv`} className="export-csv"
                             headers={this.getHeaders()}>
                        <button className="btn btn-primary">Export as CSV</button></CSVLink>)}
                {this.props.exportComponent}
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    keyField={this.props.keyField}
                    data={data}
                    columns={this.getColumns()}
                    bordered={false}
                    hover
                    pagination={paginationFactory()}
                    noDataIndication={() => <div>No records in table</div>}/>
            </div>
        )
    }
}


export default SimpleDataGrid
