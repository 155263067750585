import React from 'react';
import BasicQueryComponent from "../BasicQueryComponent";
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {FormGroup, Input} from "reactstrap";

class CustomAreaChart extends BasicQueryComponent {

    constructor(props) {
        super(props);
        this.state = {checked : false, color : [], smallCircles: false};
    }


    render() {

        const noDataComponent = <div>No records in graph</div>;

        var checkBoxesDisplayed;

        if(this.props.checkBoxes === "hidden") {
            checkBoxesDisplayed = false;
        } else {
            checkBoxesDisplayed = true;
        }

        if (this.props.data && this.props.data !== 'undefined' && Object.keys(this.props.data).length > 0) {

            return (
                <div><ResponsiveContainer width={"100%"} height={this.props.height || 300}>
                    <AreaChart width={this.props.width || 400} height={this.props.height || 300} data={this.props.data}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey={this.props.dataKey || "month"} />
                        {this.state.checked ? <YAxis type="number" domain={['auto', 'auto']}/> : <YAxis />}
                        <Tooltip/>
                        <Legend/>
                        <Area type='monotone' dataKey='query_count' name="Queries" stackId="1" stroke='#F59600' fill='#F59600' />
                    </AreaChart>
                </ResponsiveContainer>
                    {checkBoxesDisplayed && <div>
                        <FormGroup tag="fieldset">
                            <Input className='role-field css-checkbox' type="checkbox" checked={this.state.checked}
                                   onChange={
                                       (e) => this.setState({ checked: !this.state.checked })
                                   } id="scale" />
                            <label htmlFor="scale" className="css-label"><i className="icon ion-android-checkbox"></i>Dynamic Y Axis Minimum</label>
                        </FormGroup>
                    </div>}
                </div>);
        } else {
            return (noDataComponent);
        }
    }
}

export default CustomAreaChart;
