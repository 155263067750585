import React, {Component} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

export default class CustomCron extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hour: 0,
            minute: 0,
            dropdownDaysOpen: false,
            dropdownWeekOpen: false,
            dropdownTwoWeeksOpen: false,
            everyDay: false
        };

        this.toggleDropdownDays = this.toggleDropdownDays.bind(this);
        this.toggleDropdownWeek = this.toggleDropdownWeek.bind(this);
        this.toggleDropdownTwoWeeks = this.toggleDropdownTwoWeeks.bind(this);

        this.changeValue = this.changeValue.bind(this);
        this.changeValueWeek = this.changeValueWeek.bind(this);
        this.changeValueTwoWeeks = this.changeValueTwoWeeks.bind(this);

        this.onEveryDayChecked = this.onEveryDayChecked.bind(this);
        this.onDaySelected = this.onDaySelected.bind(this);
        this.onWeekDaySelected = this.onWeekDaySelected.bind(this);

    }

    toggleDropdownDays() {
        this.setState({
            dropdownDaysOpen: !this.state.dropdownDaysOpen
        });
    }

    toggleDropdownWeek() {
        this.setState({
            dropdownWeekOpen: !this.state.dropdownWeekOpen
        });
    }


    toggleDropdownTwoWeeks() {
        this.setState({
            dropdownTwoWeeksOpen: !this.state.dropdownTwoWeeksOpen
        });
    }

    changeValue(e) {
        let val = this.state.value;
        val[0] = '0';
        this.onDaySelected(val, e);
        this.props.onChange(val);

        this.setState({
            dropDownDaysValue: e.currentTarget.textContent,
            dropDownWeekValue: '',
            dropDownTwoWeeksValue: '',
            everyDay: false
        })
    }

    changeValueWeek(e) {
        let val = this.state.value;
        val[0] = '0';
        this.onWeekDaySelected(val, e);
        this.props.onChange(val);

        this.setState({
            dropDownWeekValue: e.currentTarget.textContent,
            dropDownDaysValue: '',
            dropDownTwoWeeksValue: '',
            everyDay: false
        })
    }

    changeValueTwoWeeks(e) {
        // 0 0 * * Sun [ $(expr $(date +%W) % 2) -eq 1 ]
        let val = this.state.value;
        val[0] = '0';
        this.onWeekDaySelected(val, e);
        val[5] = 'skipNextExecutionDate';
        this.props.onChange(val);

        this.setState({
            dropDownWeekValue: '',
            dropDownDaysValue: '',
            dropDownTwoWeeksValue: e.currentTarget.textContent,
            everyDay: false
        })
    }

    onEveryDayChecked(e) {
        //0 0 * * *	Every day at 12:00 AM
        let val = this.state.value;
        if (!this.state.everyDay) {
            val[0] = '0';
            val[1] = '0';
            val[2] = '*';
            val[3] = '*';
            val[4] = '*';
        } else {
            val[0] = '*';
            val[1] = '*';
            val[2] = '*';
            val[3] = '*';
            val[4] = '*';
        }
        this.props.onChange(val);

        this.setState({
            everyDay: !this.state.everyDay,
            dropDownWeekValue: '',
            dropDownTwoWeeksValue: '',
            dropDownDaysValue: ''
        })
    }

    onDaySelected(val, e) {
        //0 0 1 * *	At 12:00 AM, on day 1 of the month
        val[0] = '0';
        val[1] = '0';
        val[2] = e.target.value;
        val[3] = '*';
        val[4] = '*';
    }

    onWeekDaySelected(val, e) {
        //0 0 * * FRI	At 12:00 AM, only on Friday
        val[0] = '0';
        val[1] = '0';
        val[2] = '*';
        val[3] = '*';
        val[4] = e.target.value;
    }

    render() {
        this.state.value = this.props.value;
        const dropdownItems = [];
        for (let i = 1; i <= 28; i++) {
            dropdownItems.push(<DropdownItem value={i} onClick={this.changeValue}>{i < 10 ? '0' + i : i}</DropdownItem>)
        }


        return (
            <div>
                <div className='row'>
                    <div className='col-5'>
                        <div className='filter-item row'>
                            <div>Every day</div>
                            <input type="checkbox" style={{width : "unset"}} onChange={this.onEveryDayChecked} id="everyDay" name="everyDay"
                                   checked={this.state.everyDay}/>
                        </div>
                    </div>

                    <div className='col-7 drop-down'>
                        <Dropdown isOpen={this.state.dropdownTwoWeeksOpen} toggle={this.toggleDropdownTwoWeeks}
                                  id='everyTwoWeeks' className='filter-item'>
                            <DropdownToggle caret>
                                Every two weeks
                                on: {this.state.dropDownTwoWeeksValue ? this.state.dropDownTwoWeeksValue : ''}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem value="MON"
                                              onClick={this.changeValueTwoWeeks}>Monday</DropdownItem>
                                <DropdownItem value="TUE"
                                              onClick={this.changeValueTwoWeeks}>Tuesday</DropdownItem>
                                <DropdownItem value="WED"
                                              onClick={this.changeValueTwoWeeks}>Wednesday</DropdownItem>
                                <DropdownItem value="THU"
                                              onClick={this.changeValueTwoWeeks}>Thursday</DropdownItem>
                                <DropdownItem value="FRI"
                                              onClick={this.changeValueTwoWeeks}>Friday</DropdownItem>
                                <DropdownItem value="SAT"
                                              onClick={this.changeValueTwoWeeks}>Saturday</DropdownItem>
                                <DropdownItem value="SUN"
                                              onClick={this.changeValueTwoWeeks}>Sunday</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-6 drop-down'>
                        <Dropdown isOpen={this.state.dropdownWeekOpen} toggle={this.toggleDropdownWeek}
                                  id='week' className='filter-item float-left left-dropdown'>
                            <DropdownToggle caret>
                                Every week
                                on: {this.state.dropDownWeekValue ? this.state.dropDownWeekValue : ''}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem value="MON" onClick={this.changeValueWeek}>Monday</DropdownItem>
                                <DropdownItem value="TUE" onClick={this.changeValueWeek}>Tuesday</DropdownItem>
                                <DropdownItem value="WED"
                                              onClick={this.changeValueWeek}>Wednesday</DropdownItem>
                                <DropdownItem value="THU" onClick={this.changeValueWeek}>Thursday</DropdownItem>
                                <DropdownItem value="FRI" onClick={this.changeValueWeek}>Friday</DropdownItem>
                                <DropdownItem value="SAT" onClick={this.changeValueWeek}>Saturday</DropdownItem>
                                <DropdownItem value="SUN" onClick={this.changeValueWeek}>Sunday</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className='col-6 drop-down'>
                        <Dropdown isOpen={this.state.dropdownDaysOpen} toggle={this.toggleDropdownDays}
                                  id='month' className='filter-item'>
                            <DropdownToggle split={true}>
                                Every month
                                on: {this.state.dropDownDaysValue ? this.state.dropDownDaysValue : ''}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dropdownItems}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        );
    }
}
