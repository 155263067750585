import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {Route, Switch} from 'react-router-dom';
import ReadingsTable from '../../components/ReadingsTable';
import ReadingsDetail from '../../components/ReadingsDetail';

class ReadingsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSubTab: '5',
        };
    }

    toggleSubTab(tab) {
        if (this.state.activeSubTab !== tab) {
            this.setState({
                activeSubTab: tab
            });
        }
    }

    render() {

        const id = this.props.match.params.id;
        const businessPartner = this.props.businessPartner;

        return (<div>
                <Row>
                    <Col sm="12">
                        <Switch>
                            <Route path="/bp/:id/reading/:readingid" render={(props) =><ReadingsDetail {...props} businessPartner={businessPartner} />}/>
                            <Route path="/bp/:id" render={(props) => <ReadingsTable {...props} businessPartner={businessPartner} />} />
                        </Switch>
                    </Col>
                </Row>
        </div>

        );
    }
}

export default ReadingsTab;