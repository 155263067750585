import React, { Component } from 'react';

import { graphql } from "react-apollo";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardHeader, CardBody, CardTitle, CardText, Row, Col, Form, FormGroup, Label, Input, FormText, InputGroup } from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { priceFormatter, dateFormatter, periodFormatter } from "../../utils/Formatters";
import i18n from "../../views/Pages/Login/i18n";
import QueryDataGrid from "../QueryDataGrid";
import classnames from "classnames";
import QueryBalance from "../../components/QueryBalance";
import QueryBillsDetail from '../../components/QueryBillsDetail';
import QueryBillsTable from '../../components/QueryBillsTable';


class QueryBillsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSubTab: '5',
        };
    }

    toggleSubTab(tab) {
        if (this.state.activeSubTab !== tab) {
            this.setState({
                activeSubTab: tab
            });
        }
    }

    render() {

        const id = this.props.match.params.id;
        const businessPartner = this.props.businessPartner;
        let bankAccount = businessPartner.businessPartnerBankAccountList.filter(({isActive}) => isActive)[0];

        return (<div>

                <Row className='billing-details'>
                    <Col sm="6" md="6" lg="4" xl="3">
                        <QueryBalance {...this.props} setInvoiceFilter={this.setInvoiceFilter} toggleTab={()=>this.toggleSubTab("5")}/>
                    </Col>
                    <Col sm="6" md="6" lg="4" xl="3">
                        <Card >
                            <CardHeader >
                                <CardTitle>{i18n.t('businessPartner.PaymentDetails')}</CardTitle>
                            </CardHeader>
                            <CardBody className='bp-card-body'>

                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.PreferredMethod')}</FormText>
                                                <Label>{businessPartner.paymentMethod.name}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.PreferredDate')}</FormText>
                                                <Label>{bankAccount ? bankAccount.mdmPreferredPaymentDate : ''}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.FixedDirectDebitAmount')}</FormText>
                                                <Label>{businessPartner.mDMContractList
                                                && businessPartner.mDMContractList[0]
                                                    && businessPartner.mDMContractList[0].billBillingPlanList
                                                    ? priceFormatter(businessPartner.mDMContractList[0].billBillingPlanList.reduce(
                                                        (a, b) =>
                                                            ({amountWithTax : Math.max(a.amountWithTax, b.amountWithTax)}), {amountWithTax : 0}
                                                    ).amountWithTax, businessPartner)
                                                    : ''}</Label>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12" md="6" lg="4" xl="6">
                        <Card>
                            <CardHeader>
                                <CardTitle>{i18n.t('businessPartner.BankingDetails')}</CardTitle>
                            </CardHeader>
                            <CardBody >
                                <Row>
                                    <Col sm='6'>
                                        <Form>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.AccountHolder')}</FormText>
                                                <Label>{bankAccount && bankAccount.userContact ? bankAccount.userContact.firstName+' '+bankAccount.userContact.lastName : ''}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText ></FormText>
                                                <FormText >{i18n.t('businessPartner.AdditionalAccountHolder')}</FormText>
                                                <Label>{bankAccount ? bankAccount.ukmAdditionalAccntHldr : ''}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText >{i18n.t('businessPartner.BankName')}</FormText>
                                                <Label>{bankAccount && bankAccount.mdmBankinfo ? bankAccount.mdmBankinfo.name : ''}</Label>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col sm='6'>
                                        <Form>
                                            <FormGroup>
                                                <FormText >Account Number</FormText>
                                                <Label>{bankAccount ? bankAccount.accountNo : ''}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText >Sort Code</FormText>
                                                <Label>{bankAccount ? bankAccount.swiftCode : ''}</Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormText >IBAN</FormText>
                                                <Label>{bankAccount ? bankAccount.iBAN : ''}</Label>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div className='bp-nav-items'>
                    <Nav tabs>
                        <NavItem><NavLink className={classnames({ active: this.state.activeSubTab === '5' })}
                                          onClick={() => { this.toggleSubTab('5'); }}>Bills</NavLink></NavItem>
                        <NavItem><NavLink className={classnames({ active: this.state.activeSubTab === '6' })}
                                          onClick={() => { this.toggleSubTab('6'); }}>Payments</NavLink></NavItem>
                    </Nav>
                </div>
                <Row>
                    <Col sm="12">
                        <TabContent activeTab={this.state.activeSubTab}>
                            <TabPane tabId="5">
                                <Switch>
                                    <Route path="/view/:param1/:id/bill/:billid" render={(props) =><QueryBillsDetail {...props} businessPartner={businessPartner} />}/>
                                    <Route path="/view/:param1/:id" render={(props) => <QueryBillsTable {...props} businessPartner={businessPartner} />} />
                                </Switch>
                            </TabPane>
                            <TabPane tabId="6">
                                <Row className='payments-table-row'>
                                    <Col sm="12">
                                        <QueryDataGrid query={`
                                                            query businessPartnerQuery {
                                                                businessPartner(startRow: 0, offset: 100, where: "id = '${businessPartner.id}'", orderBy: "name") {
                                                                                fINPaymentList(startRow: 0, offset: 100)  {
                                                                                    id
                                                                                    organization {
                                                                                        name
                                                                                    }
                                                                                    documentType {
                                                                                        name
                                                                                    }
                                                                                    documentNo
                                                                                    paymentDate
                                                                                    paymentMethod {
                                                                                        name
                                                                                    }
                                                                                    status
                                                                                    amount
                                                                                    isBillRefunddepositBtn
                                                                                    billContract {
                                                                                        documentnumber
                                                                                    }
                                                                                    tspayName
                                                                                    posted
                                                                                    isAprmExecutepayment
                                                                                    billEstate {
                                                                                        name
                                                                                        consumernumber
                                                                                    }
                                                                                    usedCredit
                                                                                    generatedCredit
                                                                                    description
                                                                                    currency {
                                                                                        iSOCode
                                                                                        symbol
                                                                                    }
                                                                                    referenceNo
                                                                                    isBillForcedreverse
                                                                                    billLicensingTerritory {
                                                                                        name
                                                                                    }
                                                                                    mdmTerritory
                                                                                    isBillFinSysExported
                                                                                }

                                                                }
                                                            }
                                                        ` }
                                                       keyField='id'
                                                       dataPath={"businessPartner.0.fINPaymentList"}
                                                       columns={[{
                                                           dataField: 'amount',
                                                           text: 'Amount',
                                                           formatter: priceFormatter,
                                                           sort: true,
                                                           align: "right",
                                                           headerAlign: "right"
                                                       }, {
                                                           dataField: 'paymentMethod.name',
                                                           text: 'Payment Method',
                                                           sort: true
                                                       }, {
                                                           dataField: 'paymentDate',
                                                           text: 'Date Created',
                                                           formatter: dateFormatter,
                                                           sort: true
                                                       }, {
                                                           dataField: 'status',
                                                           text: 'Payment Status',
                                                           sort: true
                                                       }, {
                                                           dataField: 'download',
                                                           text: 'Download'
                                                       }]}
                                                       defaultSorted={{
                                                           dataField: 'paymentDate',
                                                           order: 'desc'
                                                       }}
                                                       filters={[
                                                           {
                                                               type: "text",
                                                               fields: ['amount'],
                                                               placeholder: "Search Payments by Type, Amount, Status",
                                                               classes: "search"
                                                           },
                                                           {
                                                               type: "dropdown",
                                                               fields: ['status'],
                                                               placeholder: "Search"
                                                           },
                                                           {
                                                               type: 'date',
                                                               fields: ['paymentDate'],
                                                           },
                                                       ]}
                                        />

                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
        </div>

        );
    }
}

export default QueryBillsTab;