import React from 'reactn';
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router';
import BasicQueryComponent from "../BasicQueryComponent";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import * as i18n from "i18next";


class ServiceHeader extends BasicQueryComponent {
    
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {

        const type = this.props.match.params.param1;

        return (
            <div>
                {!this.global.permissions.includes('Users.ReadOnly') &&
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                        <DropdownToggle className='pull-right service-button' color='primary'>
                            <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('header.new')}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => this.props.history.push('/CreateReading')}>Reading</DropdownItem>
                            <DropdownItem onClick={() => this.props.history.push(`/readsUpload/${type || 'All'}`)}>Bulk Reading</DropdownItem>
                            <DropdownItem onClick={() => this.props.history.push('/CreateTask')}>Query</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                }
            </div>
        );
    }
}

export default withApollo(withRouter(ServiceHeader));
