import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormGroup, InputGroup, Input, FormFeedback, InputGroupAddon} from 'reactstrap';

export class renderInput extends React.PureComponent {
    static propTypes = {
        input: PropTypes.object,
        inputGroup: PropTypes.object,
        inputAddonGroup: PropTypes.object,
        placeholder: PropTypes.string,
        type: PropTypes.string.isRequired,
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.any,
        }),
    };

    render() {


        const {
            input,
            placeholder,
            type,
            inputAddonGroup,
            inputGroup,
            meta: {
                touched,
                error,
                warning
            },
        } = this.props;

        const classes = classNames({
            success: touched && !error,
            danger: touched && error,
        });

        let valid = null;

        if (touched) {
            valid = !error;
        }

        return (
            <InputGroup {...inputGroup}>
                {inputAddonGroup && <InputGroupAddon className={classes}><i {...inputAddonGroup}></i></InputGroupAddon>}
                <Input {...input} type={type} placeholder={placeholder} valid={valid} />
                {touched && error && <FormFeedback>{error}</FormFeedback>}
            </InputGroup>
        );
    }
}