import React, {Component} from 'react';
import {contractsListPagedQuery, getQuotesQuery} from "../../queries/Queries";
import i18n from "../../views/Pages/Login/i18n";
import QuerySearch from "../QuerySearch";
import {SingleDatePicker} from "react-dates";
import {Col, Form, FormGroup, FormText, Row, Input, Button} from "reactstrap";
import {withRouter} from 'react-router';
import axios from "axios";
import moment from "moment";

class StartProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {contractNumber:this.props.match.params.contractNumber,
            businessPartnerId: this.props.match.params.businessPartnerId,
            disabled: !!this.props.match.params.contractNumber};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div><Row className='subheader'>
                <Col className='col-8'>
                    <h1>Start Process</h1>
                </Col>
            </Row>

                <div className="page-cnt create-user ">
                <Row>
                    <Col>
                <Form>
                    <FormGroup>
                        <FormText>Process</FormText>
                        <Input type={"select"}>
                            <option>Contract Termination</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <FormText>Document number</FormText>
                        <QuerySearch dataPath={'contractsListPaged.content'}
                                     paginationPath={'contractsListPaged'}
                                     query={contractsListPagedQuery}
                                     filters={[
                                         {
                                             type: 'text',
                                             fields: ['documentnumber', 'estate.mDMEstate.estateNumber', 'estate.mDMEstate.mDMMeterPointList.#.name'],
                                             placeholder: "Search by document number",
                                             classes: "search",
                                             cols: 5
                                         },
                                         {
                                             type: 'text',
                                             fields: ['businessPartner.id=='+(this.state.businessPartnerId ? this.state.businessPartnerId : '*')],
                                         }
                                     ]}
                                     ky={'documentnumber'}
                                     label={'documentnumber'}
                                     value={this.state.contractNumber}
                                     disabled={this.state.disabled}
                                     columns={[

                                         {
                                             dataField: 'documentnumber',
                                             text: 'Contract Number'
                                         },{
                                             dataField: 'estate.mDMEstate.estateNumber',
                                             text: i18n.t('Estate')
                                         }

                                     ]}
                                     onChange={(contract) => {if(contract.id !== this.state.contractNumber) {
                                         this.setState({contractNumber:contract.documentnumber});
                                     }}}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormText>Date</FormText>
                        <SingleDatePicker
                            date={this.state.endDate} // momentPropTypes.momentObj or null
                            numberOfMonths={1}
                            onDateChange={date => {
                                this.setState({...this.state, endDate: date});
                            }}
                            isOutsideRange={() => false}
                            focused={this.state.focused} // PropTypes.bool
                            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                            id="process-date" // PropTypes.string.isRequired,
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button onClick={
                            () => axios.post(`${CORE_URL}/bpm/crm-${PROFILE}/rest/engine/default/process-definition/key/ContractTermination/start`,
                                {variables: {contractNumber: {value: this.state.contractNumber, type:"String"}, endDate: {value: moment(this.state.endDate).format("YYYY-MM-DD[T]HH:mm:ss.SSSZZ"), type:"Date"}}}
                                        )
                                .then((response) => alert('Success'))
                                .catch(error => alert('Error: ' + error))
                        }>
                           Start process
                        </Button>
                    </FormGroup>
                </Form>
                    </Col>
                </Row>
                </div>
                <iframe src={`${CORE_URL}/bpm/crm-${PROFILE}/app/cockpit/default/`} />
            </div>

        )
    }
}

export default withRouter(StartProcess);
